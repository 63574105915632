import React from "react";

type Props = {
  title: string;
};

export const ComponentSectionLayout: React.FC<Props> = (props) => {
  const { title, children } = props;
  return (
    <div className={"space-x-2 space-y-2"}>
      <h1 className={"py-10 text-xl font-bold capitalize"}>{title}</h1>
      {children}
    </div>
  );
};
