import React from "react";

const AddWorkSvg = () => {
  return (
    <svg
      width={"20"}
      height={"22"}
      viewBox={"0 0 20 22"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M3 11V1.6C3 1.44087 3.06321 1.28826 3.17574 1.17574C3.28826 1.06321 3.44087 1 3.6 1H15.252C15.4111 1.00014 15.5636 1.06345 15.676 1.176L18.824 4.324C18.88 4.3799 18.9243 4.44632 18.9545 4.51943C18.9847 4.59254 19.0002 4.6709 19 4.75V20.4C19 20.4788 18.9845 20.5568 18.9543 20.6296C18.9242 20.7024 18.88 20.7685 18.8243 20.8243C18.7685 20.88 18.7024 20.9242 18.6296 20.9543C18.5568 20.9845 18.4788 21 18.4 21H10"
        }
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M15 1V4.4C15 4.55913 15.0632 4.71174 15.1757 4.82426C15.2883 4.93679 15.4409 5 15.6 5H19M0.992004 18H3.992M3.992 18H6.992M3.992 18V15M3.992 18V21"
        }
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  );
};

export default AddWorkSvg;
