import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const ContactsSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"25"}
        height={"29"}
        viewBox={"0 0 25 29"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}>
        <path
          d={
            "M1.28174 27.5129V25.8863C1.28174 22.8665 2.46363 19.9703 4.5674 17.835C6.67117 15.6997 9.5245 14.5001 12.4997 14.5001M12.4997 14.5001C15.4749 14.5001 18.3282 15.6997 20.432 17.835C22.5357 19.9703 23.7176 22.8665 23.7176 25.8863V27.5129M12.4997 14.5001C14.1998 14.5001 15.8303 13.8146 17.0324 12.5944C18.2346 11.3742 18.9099 9.71926 18.9099 7.99365C18.9099 6.26805 18.2346 4.61312 17.0324 3.39293C15.8303 2.17274 14.1998 1.48724 12.4997 1.48724C10.7996 1.48724 9.16911 2.17274 7.96695 3.39293C6.76479 4.61312 6.08943 6.26805 6.08943 7.99365C6.08943 9.71926 6.76479 11.3742 7.96695 12.5944C9.16911 13.8146 10.7996 14.5001 12.4997 14.5001Z"
          }
          stroke={"#7C66F0"}
          strokeWidth={"2.1875"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
      </svg>
    </>
  );
};
