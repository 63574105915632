import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { ROUTE_IDS } from "../../../app/constants/TextConstants";
import { BankDocDetailsView } from "./BankDocDetailsView";

export const BankDocView: React.FC<unknown> = () => {
  return (
    <>
      <Routes>
        <Route
          path={`:${ROUTE_IDS.bankDocId}/*`}
          element={<BankDocDetailsView />}
        />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </>
  );
};
