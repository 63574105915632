import React, { useState } from "react";

import { getInitialsFromFullName } from "../../constants/constants";
import { useGetSearchCustomersQuery } from "../../slices/ContactsSlice";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../types/types";
import AddContact from "../modal/addContact";
import CustomSelect from "./Select";
import { useDebounce } from "usehooks-ts";
import { useAppSelector } from "../../redux/redux";

type Props = {
  onChange: (value: SelectOptionType | null) => void;
  value: SelectOptionType | null;
};

export const SelectContacts: React.FC<Props> = ({ onChange, value }) => {
  const [searchContact, setSearchContact] = useState("");
  const { user } = useAppSelector((state) => state.appReducer);

  const debounceSearchContact = useDebounce(searchContact, 300);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: debounceSearchContact || "a",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const [isOpenAddContact, setIsOpenAddContact] = useState(false);

  const getOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  return (
    <>
      <CustomSelect
        fullWidth
        withAvatar={!!value?.value}
        options={getOptions()}
        onChange={(data) => {
          const newValue = data as SelectOptionType;
          if (newValue?.value === "create") {
            onChange(value);
            setIsOpenAddContact(true);
          } else {
            onChange(newValue);
          }
        }}
        placeholder={"Search contacts"}
        value={value}
        isLoading={isLoadingContacts}
        inputValue={searchContact}
        onChangeInput={setSearchContact}
      />
      {isOpenAddContact && (
        <AddContact
          isSearch
          isOpen={isOpenAddContact}
          closeModal={() => setIsOpenAddContact(false)}
          setContactsForSort={(newContact) => {
            setIsOpenAddContact(false);
            onChange({
              label: newContact?.name || "",
              value: newContact?.customerId || "",
              avatar: getInitialsFromFullName(newContact?.name || ""),
              avatarColor: newContact?.isBusinessContact ? "purple" : "blue",
            });
          }}
        />
      )}
    </>
  );
};
