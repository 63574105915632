import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDebounce } from "usehooks-ts";
import * as devConfig from "../../app/configs/devConfig";
import { getAuthToken } from "../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import Button from "../../components/button/Button";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { PageLayout } from "../../components/layouts/PageLayout";
import { getInitialsFromFullName } from "../../constants/constants";
import { Email } from "../../factories/emails/models/emails.models";
import useSignalR from "../../hooks/useSignalR";
import { useAppSelector } from "../../redux/redux";
import {
  useDisconnectEmailMutation,
  useGetEmailFoldersQuery,
  useLazyGetLinkedWorkQuery,
  useGetRedirectUrlMutation,
  useLazyGetEmailsQuery,
  useLazySearchEmailQuery,
  useUnlinkEmailMutation,
} from "../../slices/EmailsSlice";
import { SelectOptionType } from "../../types/types";
import { AddEmailModal } from "../contacts/modals/AddEmailModal";
import { ComposeEmailModal } from "../contacts/modals/ComposeEmailModal";
import { AddWorkModal } from "../works/modals/AddWork.modal";
import { EmailsListView } from "./EmailsListView";
import { useNavigate } from "react-router-dom";
import { GrUnlink } from "react-icons/gr";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsPeople } from "react-icons/bs";
import { IoSync } from "react-icons/io5";
import { SyncModal } from "../contacts/modals/SyncModal";
import { Dropdown } from "../../components/dropdown/new-dropdown/Dropdown";
import { HiEllipsisVertical } from "react-icons/hi2";

export const EmailsView: React.FC<unknown> = () => {
  const { user } = useAppSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState<string[]>(["1"]);
  const [folder, setFolder] = useState<SelectOptionType | null>(null);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenAddTask, setIsOpenAddTask] = useState(false);
  const [isOpenCompose, setIsOpenCompose] = useState(false);
  const [selectedMail, setSelectedMail] = useState<Email | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [isSync, setIsSync] = useState(false);
  const [isOpenProvider, setIsOpenProvider] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const [getEmails, { data: dataEmails, error, isLoading }] =
    useLazyGetEmailsQuery();
  const {
    data: folders,
    isLoading: isLoadingFolders,
    error: errorFolder,
  } = useGetEmailFoldersQuery({
    userId: user?.id || "",
  });
  const [getLinkedWork, { data: workId }] = useLazyGetLinkedWorkQuery();

  useEffect(() => {
    if (selectedMail) {
      getLinkedWork({ threadId: selectedMail?.thread_id || "" });
    }
  }, [selectedMail]);

  const [searchEmail, { data: dataSearchEmails }] = useLazySearchEmailQuery({});
  const [disconnectEmail] = useDisconnectEmailMutation();
  const [connectEmail, { data, isSuccess }] = useGetRedirectUrlMutation();
  const [unlinkWork] = useUnlinkEmailMutation();

  const { message } = useSignalR(
    `${devConfig.getServerBaseUrlForEnv()}/signalRHub`,
    getAuthToken().Authorization,
  );

  useEffect(() => {
    if (message && folder?.value) {
      getEmails({
        userId: user?.id || "",
        folderId: folder?.value || "",
        pageNumber: page === 1 ? undefined : pages[page - 1] || "",
      });
    }
  }, [message]);

  const isConnected =
    error !== "Grant for this User doesn't exists. Try authorizing again." &&
    errorFolder !==
      "Grant for this User doesn't exists. Try authorizing again.";

  useEffect(() => {
    if (folder?.value) {
      getEmails({
        userId: user?.id || "",
        folderId: folder?.value || "",
        pageNumber: page === 1 ? undefined : pages[page - 1] || "",
      });
    }
  }, [page, user?.id, folder?.value]);

  useEffect(() => {
    if (dataEmails?.nextPageValue && page + 1 > pages.length) {
      setPages((prev) => [...prev, dataEmails?.nextPageValue]);
    }
  }, [dataEmails?.nextPageValue]);

  useEffect(() => {
    if (debouncedSearchValue) {
      searchEmail({
        userId: user?.id || "",
        pageNumber: dataSearchEmails?.nextPageValue || "1",
        query: debouncedSearchValue,
      });
    }
  }, [page, user?.id, debouncedSearchValue]);

  useEffect(() => {
    if (folders) {
      const folder = folders.find(
        (item) => item.name.toUpperCase() === "INBOX",
      );
      if (folder) {
        setFolder({
          value: folder?.id,
          label: folder?.name.toUpperCase(),
          totalCount: folder?.total_count || 0,
        });
      }
    }
  }, [folders?.length]);

  const handleButtonClick = async () => {
    disconnectEmail({
      userId: user.id || "",
    });
  };

  useEffect(() => {
    if (isSuccess) {
      const a = document.createElement("a");
      a.setAttribute("href", data);
      a.setAttribute("target", "_blank");
      a.click();
    }
  }, [isSuccess]);

  const folderOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    const desiredFolderNames = [
      "inbox",
      "starred",
      "trash",
      "draft",
      "sent",
      "spam",
      "unread",
      "archive",
      "conversation history",
      "deleted items",
      "drafts",
      "junk email",
      "outbox",
      "sent items",
    ];
    if (folders) {
      folders?.forEach((folder) => {
        if (desiredFolderNames.includes(folder?.name?.toLowerCase())) {
          options.push({
            label: folder?.name.toUpperCase(),
            value: folder?.id,
            totalCount: folder?.total_count,
          });
        }
      });
    }
    options.sort((a, b) => {
      if (a.label.toLowerCase() === "inbox") return -1;
      if (b.label.toLowerCase() === "inbox") return 1;
      return 0;
    });

    return options;
  };

  const addUnlinkWork = () => {
    workId
      ? unlinkWork({
          body: {
            messageId: selectedMail?.id || "",
            threadId: selectedMail?.thread_id || "",
            workId: workId || "",
          },
        })
      : setIsOpenAddTask(true);
  };
  const viewCreateWork = () => {
    workId ? navigate("/work/" + workId) : setIsOpenAdd(true);
  };

  if (isLoadingFolders) {
    return <LottieLoading />;
  }

  return (
    <PageLayout
      title={
        <div className={"flex items-center gap-2"}>
          <div className={"mr-2 !font-notoSans"}>Email</div>
          {isConnected && (
            <>
              <AvatarPlaceholder
                type={"blue"}
                size={"extra-small"}
                label={getInitialsFromFullName(user?.nylasToken?.email || "")}
              />
              <div className={"flex w-[270px] items-center justify-between"}>
                <div
                  className={
                    "flex items-center !font-notoSans text-[20px] font-normal text-gray-600"
                  }>
                  {user?.nylasToken?.email}
                  <ReactTooltip
                    id="tooltip-disconnect"
                    place="bottom"
                    content="Disconnect email"
                    className={"!rounded-[8px] !p-2 !text-sm"}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      }
      extraClasses={"px-6"}>
      {!isConnected ? (
        <div
          className={
            "mt-[200px] flex w-full flex-col items-center justify-center gap-2"
          }>
          <div className={"font-bold text-gray-500"}>Not connected</div>
          <Button
            label={"Connect"}
            onClick={() => {
              setIsOpenProvider(true);
            }}
          />
        </div>
      ) : (
        <div
          className={
            "mt-3 grid w-full grid-cols-[30fr_70fr] items-center gap-4"
          }>
          <div
            className={
              "mb-4 mt-5 flex h-[40px] w-full gap-2 rounded-[10px] border-[2px] border-gray-300 bg-white"
            }>
            <input
              className={
                "input h-[36px] w-full !font-notoSans focus:outline-none active:outline-none"
              }
              value={searchValue}
              placeholder={"Search"}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div
              className={
                "close-popup mr-2 flex cursor-pointer items-center text-end"
              }
              onClick={() => setSearchValue("")}>
              <MdClose />
            </div>
          </div>
          <div className={"flex w-full items-center justify-between"}>
            <div>
              <Button
                size={"custom"}
                customSize={"px-3"}
                label={"Compose email"}
                extraClasses={"!font-notoSans h-[40px] min-h-[40px]"}
                onClick={() => setIsOpenCompose(true)}
              />
            </div>
            <div className={"flex items-center gap-2 !font-notoSans"}>
              <Button
                label={
                  workId ? "View linked work" : "Add email to existing work"
                }
                size={"custom"}
                customSize={"px-3"}
                extraClasses={"!font-notoSans h-[40px] min-h-[40px]"}
                onClick={viewCreateWork}
                disabled={!selectedMail}
              />
              <Button
                size={"custom"}
                customSize={"px-2"}
                extraClasses={"!font-notoSans h-[40px] min-h-[40px]"}
                label={workId ? "Unlink work" : "Create work from email"}
                colorType={"outline"}
                onClick={addUnlinkWork}
                disabled={!selectedMail}
              />
              <Dropdown
                triggerElement={
                  <Button
                    label={""}
                    colorType={"ghost"}
                    size={"custom"}
                    icon={<HiEllipsisVertical size={22} />}
                    extraClasses={"min-h-fit h-fit w-fit p-1"}
                  />
                }
                propsPosition={true}>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    className={
                      "cursor-pointer grid-rows-[1fr_1fr] justify-between rounded-[5px] text-center"
                    }>
                    <div
                      className="flex w-[250px] items-center gap-4 px-2 py-2 hover:bg-gray-200"
                      onClick={handleButtonClick}>
                      <div className={"cursor-pointer pl-1"}>
                        <GrUnlink data-tooltip-id="tooltip-disconnect" />
                      </div>
                      <div>Disconnect my email</div>
                    </div>
                    <div
                      className="flex w-[250px] items-center gap-1 px-2 py-2 text-white hover:bg-gray-200 hover:text-gray-200"
                      onClick={() => setIsSync(true)}>
                      <div className={"flex cursor-pointer pl-1"}>
                        <BsPeople size={"17px"} className={"text-black"} />
                        <IoSync
                          className={
                            "relative right-[5px] top-[10px] rounded-[50%] bg-[currentColor] stroke-black"
                          }
                          size={"10px"}
                        />
                      </div>
                      <div className={"text-black"}>
                        Sync emails with clients/works
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {isConnected && (
        <EmailsListView
          emails={
            debouncedSearchValue
              ? dataSearchEmails?.data || []
              : dataEmails?.data || []
          }
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          setPages={setPages}
          folder={folder}
          setFolder={setFolder}
          folderOptions={folderOptions}
          selectedMail={selectedMail}
          setSelectedMail={setSelectedMail}
        />
      )}
      {isOpenAdd && (
        <AddEmailModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={user?.id || ""}
          selectedMail={selectedMail}
        />
      )}
      {isOpenCompose && (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
        />
      )}
      {isOpenAddTask && (
        <AddWorkModal
          isOpen={isOpenAddTask}
          onClose={() => {
            setIsOpenAddTask(false);
          }}
          selectedMail={selectedMail}
        />
      )}
      {isSync && (
        <SyncModal isActive={isSync} onClose={() => setIsSync(false)} />
      )}
      {isOpenProvider && (
        <div
          className={
            "fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/20"
          }
          onClick={() => setIsOpenProvider(false)}>
          <div className={"modal-box absolute w-[300px] bg-white shadow-box"}>
            <div className={"pb-2 font-bold"}>Choose your provider:</div>
            <div
              className={
                "flex cursor-pointer items-center gap-4 rounded-[8px] px-1 py-2 text-lg font-semibold hover:bg-gray-300"
              }
              onClick={() =>
                connectEmail({
                  provider: "google",
                  returnUrl: document.URL,
                  userId: user?.id || "",
                })
              }>
              <img
                className={"h-[20px] w-[25px]"}
                alt={"Gmail"}
                src={
                  "https://mailmeteor.com/logos/assets/PNG/Gmail_Logo_512px.png"
                }
              />
              Google
            </div>
            <div
              className={
                "flex cursor-pointer items-center gap-4 rounded-[8px] px-1 py-2 text-lg font-semibold hover:bg-gray-300"
              }
              onClick={() =>
                connectEmail({
                  provider: "microsoft",
                  returnUrl: document.URL,
                  userId: user?.id || "",
                })
              }>
              <img
                className={"h-[25px] w-[25px]"}
                alt={"Outlook"}
                src={
                  "https://mailmeteor.com/logos/assets/PNG/Microsoft_Office_Outlook_Logo_512px.png"
                }
              />
              Microsoft
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};
