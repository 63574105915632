import classNames from "classnames";
import React from "react";
import { Link, Outlet, Route } from "react-router-dom";

import { IconButton } from "../../components/button/IconButton";
import { HamburgerSvg } from "../../components/svg/HamburgerSvg";
import { HomeSvg } from "../../components/svg/HomeSvg";
import { componentsRouteMap } from "./ComponentsRouteMap";

const ComponentsSidebarView = () => {
  const [toggleCollapse, setToggleCollapse] = React.useState(false);
  const [isCollapsible, setIsCollapsible] = React.useState(false);

  const collapseIconClasses = classNames("rounded absolute right-0", {
    "rotate-180": toggleCollapse,
  });

  const getNavItemClasses = () => {
    return classNames(
      "flex items-center cursor-pointer hover:bg-light-lighter rounded w-full overflow-hidden whitespace-nowrap",
    );
  };

  const onMouseOver = () => {
    setIsCollapsible(!isCollapsible);
  };

  const handleSidebarToggle = () => {
    setToggleCollapse(!toggleCollapse);
  };

  return (
    <div
      className={classNames(
        "bg-light flex h-screen flex-col justify-between overflow-y-auto px-4 pt-8 pb-4",
        {
          "w-96": !toggleCollapse,
          "w-25": toggleCollapse,
        },
      )}
      style={{ transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s" }}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOver}>
      <div className={"flex flex-col"}>
        <div className={"relative flex items-center justify-between"}>
          {/*<div className={"flex items-center gap-4 pl-1"}>*/}
          {/*  <span*/}
          {/*    className={classNames("text-text mt-2 text-lg font-medium", {*/}
          {/*      hidden: toggleCollapse,*/}
          {/*    })}>*/}
          {/*    <LogoText />*/}
          {/*  </span>*/}
          {/*</div>*/}
          {
            <IconButton
              colorType={"primary"}
              size={"medium"}
              IconSvg={HamburgerSvg}
              extraCx={collapseIconClasses}
              onClick={handleSidebarToggle}
            />
          }
        </div>

        <div className={"mt-24 flex flex-col items-start"}>
          {componentsRouteMap.map(({ ...menu }) => {
            const classes = getNavItemClasses();
            const link = `/components/${menu.name}`;
            return (
              <div className={classes} key={menu.name}>
                <Link to={link}>
                  <a className={"flex h-full w-full items-center py-4 px-3"}>
                    <div style={{ width: "2.5rem" }}>
                      <HomeSvg />
                    </div>
                    {!toggleCollapse && (
                      <span
                        className={classNames(
                          "text-md text-text-light font-medium",
                        )}>
                        {menu.name}
                      </span>
                    )}
                  </a>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className={`${getNavItemClasses()} px-3 py-4`}>
        {!toggleCollapse && (
          <span className={classNames("text-md text-text-light font-medium")}>
            Logout
          </span>
        )}
      </div>
    </div>
  );
};

const Sidebar2: React.FC<unknown> = ({ children }) => {
  const [showSidebar, setShowSidebar] = React.useState(false);

  return (
    <div className={"flex"}>
      {showSidebar ? (
        <button
          className={
            "fixed left-5 top-6 z-50 flex cursor-pointer items-center text-4xl text-black"
          }
          onClick={() => setShowSidebar(!showSidebar)}>
          x
        </button>
      ) : (
        <HamburgerSvg />
      )}

      <div
        className={`top-0 left-0 z-40  h-full w-[15vw] duration-300 ease-in-out ${
          showSidebar ? "-translate-x-0 " : "-translate-x-full"
        }`}>
        <h3
          className={"mt-20 overflow-y-auto text-4xl font-semibold text-white"}>
          {children}
        </h3>
      </div>
    </div>
  );
};

const ComponentsView: React.FC<unknown> = () => {
  return (
    <div className={"flex flex-grow justify-start"}>
      <ComponentsSidebarView />
      <div className={"w-full overflow-auto"}>
        <div className={"bg-gray-100 p-4 text-black"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export const ComponentsRoute = () => {
  const routes = componentsRouteMap.map((routeElement) => {
    const { name, component: Component } = routeElement;
    return (
      <Route
        key={`component-${name}-key`}
        path={`${name}`}
        element={<Component />}
      />
    );
  });

  return (
    <Route path={"/components"} element={<ComponentsView />}>
      {routes}
    </Route>
  );
};
