import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LABELS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { UserProfileView } from "./UserProfileView";

export const UserView: React.FC<unknown> = () => {
  return (
    <PageLayout title={`${LABELS.features.user} ${LABELS.features.settings}`}>
      <div className={"tabs my-8"}>
        <CustomLink to={ROUTE_NAMES.user.profile}>
          {LABELS.features.profile}
        </CustomLink>
      </div>

      <Routes>
        <Route
          index
          element={
            <Navigate to={`./${ROUTE_NAMES.user.profile}`} replace={true} />
          }
        />
        <Route path={ROUTE_NAMES.user.profile} element={<Outlet />}>
          <Route index element={<UserProfileView />} />
          {/*<Route path={"edit"} element={<EditOrgDetails />} />*/}
        </Route>
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
