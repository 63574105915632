export const ACCEPTED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg"];
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const validateFile = (file: File): boolean => {
  if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
    alert("Only JPG, JPEG, and PNG files are allowed.");
    return false;
  }
  if (file.size > MAX_FILE_SIZE) {
    alert(`File size should not exceed ${MAX_FILE_SIZE / 1024 / 1024}MB.`);
    return false;
  }
  return true;
};
