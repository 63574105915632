import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const OrganizationSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <>
      <img width={75} height={75} className={"img-center-align"} src={"/assets/images/organization.png"} />
    </>
  );
};
