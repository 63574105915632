export const getFormattedCurrency = (amount: number, currency = "CAD") => {
  // Format price details and detect zero decimal currencies
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (const part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  const newAmount = zeroDecimalCurrency ? amount : amount / 100;
  return numberFormat.format(newAmount);
};
