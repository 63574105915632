import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { TemplateModel } from "../factories/templates/models/template.model";

export const templatesSlice = createApi({
  reducerPath: "templatesSlice",
  tagTypes: ["Template"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getTemplates: build.query<
      TemplateModel[],
      {
        orgId: string;
      }
    >({
      query: (params) => ({
        url: "api/work-template/get-all",
        params,
        method: "GET",
      }),
      providesTags: ["Template"],
    }),
  }),
});

export const { useGetTemplatesQuery } = templatesSlice;
