import React from "react";

import { VisualTable } from "../../components/table/VisualTable";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const TablesDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Tables"}>
      <ComponentSectionLayout title={"Table - visual elements"}>
        <VisualTable />
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
