import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import {
  IContact,
  IOrganizationContact,
  IUser,
  IWorkType,
} from "../types/types";

export const getWorkFlow = async (orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/work-type-status/get-all?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    return response.json();
  }
};
export const getDashboard = async (orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/dashboard/get?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    return response.json();
  }
};
export const getWorkTypes = async (orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/services/get-all?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    const data: IWorkType[] = await response.json();
    return data;
  }
  return [];
};
export const getHiddenWorkTypes = async (orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/services/get-hidden-services?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    return response.json();
  }
};
export const getWorkType = async (id?: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/services/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    return response.json();
  }
};
export const getUsers = async (orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/users/search?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    const data: IUser[] = await response.json();
    return data;
  }
  return [];
};
export const getIndividualContacts = async (active: boolean, orgId: string) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/contacts/search?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    const data: IContact[] = await response.json();
    const activeList = data.filter(
      (item) => item.statusType && item.statusType.statusType === "Active",
    );
    const inactiveList = data.filter(
      (item) => !item.statusType || item?.statusType?.statusType === "Unknown",
    );
    return active ? activeList : inactiveList;
  } else {
    return [];
  }
};
export const getOrganizationContacts = async (
  active: boolean,
  orgId: string,
) => {
  const response = await fetch(
    `${devConfig.getServerBaseUrlForEnv()}/api/business-contacts/get-all?orgId=${orgId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: getAuthHeader().Authorization,
      },
      method: "GET",
    },
  );
  if (response.ok) {
    const data: IOrganizationContact[] = await response.json();
    const activeList: IOrganizationContact[] = data.filter(
      (item) => item.statusType && item.statusType.statusType === "Active",
    );
    const inactiveList: IOrganizationContact[] = data.filter(
      (item) => !item.statusType || item?.statusType?.statusType === "Unknown",
    );
    return active ? activeList : inactiveList;
  } else {
    return [];
  }
};
