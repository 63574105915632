import React from "react";

const ReassignedSvg: React.FC<{ size: string }> = ({ size }) => {
  return (
    <svg
      version={"1.0"}
      xmlns={"http://www.w3.org/2000/svg"}
      width={size}
      height={size}
      viewBox={"0 0 512.000000 512.000000"}
      preserveAspectRatio={"xMidYMid meet"}>
      <g
        transform={"translate(0.000000,512.000000) scale(0.100000,-0.100000)"}
        fill={"currentColor"}
        stroke={"none"}>
        <path
          d={
            "M2480 5097 c-73 -38 -109 -135 -80 -213 6 -14 40 -57 77 -95 36 -38 62 -69 56 -69 -61 0 -289 -25 -372 -41 -347 -66 -693 -226 -966 -447 -179 -146 -414 -417 -429 -495 -16 -83 42 -175 123 -197 84 -22 144 7 223 113 243 322 597 556 996 661 115 30 286 56 377 56 l59 0 -66 -67 c-37 -38 -72 -81 -77 -96 -50 -133 72 -264 209 -227 36 10 76 44 252 218 115 113 222 226 238 251 41 62 42 129 1 192 -44 67 -429 445 -473 463 -52 22 -98 20 -148 -7z"
          }
        />
        <path
          d={
            "M3800 4410 c-264 -68 -471 -279 -541 -551 -19 -74 -17 -267 4 -345 61 -226 224 -417 427 -500 l55 -22 -45 -12 c-315 -81 -542 -265 -657 -531 -54 -125 -74 -218 -80 -374 -5 -135 -4 -141 19 -175 36 -53 80 -78 151 -85 34 -3 440 -5 902 -3 l840 3 41 24 c43 24 77 73 88 126 10 45 -11 249 -33 335 -43 160 -112 288 -216 399 -120 127 -250 208 -412 257 -48 14 -95 28 -103 31 -9 2 19 21 65 44 153 75 272 197 345 354 54 115 65 171 64 320 0 111 -4 141 -26 207 -85 254 -292 446 -545 502 -98 22 -248 20 -343 -4z"
          }
        />
        <path
          d={
            "M985 3384 c-38 -8 -117 -38 -174 -65 -87 -43 -116 -63 -186 -132 -141 -141 -212 -294 -222 -482 -17 -314 161 -604 445 -725 l54 -23 -70 -18 c-342 -87 -596 -336 -681 -669 -27 -103 -44 -296 -32 -350 12 -54 47 -99 95 -121 39 -18 85 -19 925 -19 878 0 885 0 928 21 30 14 52 35 70 66 25 44 26 47 20 176 -9 226 -70 403 -194 565 -122 159 -294 271 -501 326 l-87 23 49 21 c27 12 50 22 52 22 11 0 116 73 156 109 100 89 186 231 224 371 27 102 25 284 -5 380 -77 251 -258 433 -506 510 -94 29 -263 36 -360 14z"
          }
        />
        <path
          d={
            "M4120 1572 c-19 -9 -54 -42 -78 -72 -176 -221 -294 -334 -477 -456 -135 -90 -283 -161 -441 -213 -129 -42 -309 -81 -377 -81 l-41 0 66 68 c79 80 88 97 88 158 0 66 -37 126 -93 153 -53 26 -90 26 -142 2 -49 -23 -479 -453 -495 -494 -14 -39 -13 -92 4 -130 8 -18 119 -136 247 -264 201 -198 239 -232 272 -238 112 -21 207 57 207 170 0 60 -9 77 -88 158 -37 37 -60 67 -51 67 8 0 53 5 99 11 477 59 924 276 1265 615 100 100 211 233 252 302 41 69 30 166 -23 213 -57 50 -133 62 -194 31z"
          }
        />
      </g>
    </svg>
  );
};

export default ReassignedSvg;
