import classNames from "classnames";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction } from "react";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { getInitialsFromFullName } from "../../../../../../../../constants/constants";
import { IOrganizationContact } from "../../../../../../../../types/types";

type Props = {
  organization: IOrganizationContact;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const OrganizationInfo: FC<Props> = ({ organization, setIsEdit }) => {
  const address = `${organization?.address?.line1 || ""} ${
    organization?.address?.city || ""
  } ${organization?.address?.region || ""} ${
    organization?.address?.postalCode || ""
  }`;

  return (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button
            label={"Edit"}
            size={"custom"}
            extraClasses={"btn-sm"}
            onClick={() => setIsEdit(true)}
          />
        </div>
        <div className={"grid-rows-[repeat(11, max-content)] grid gap-6"}>
          <div>
            <div className={"mb-2 text-base text-gray-400"}>LEGAL NAME</div>
            <div className={"flex items-center gap-2"}>
              <AvatarPlaceholder
                size={"extra-small"}
                type={"purple"}
                label={getInitialsFromFullName(organization?.companyName || "")}
              />
              <div className={"text-sm font-semibold"}>
                {organization?.companyName}
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>BUSINESS NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {organization?.businessNumber || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>INCORPORATION DATE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.incorporationDate
                ? moment(organization?.incorporationDate).format("MMM DD, YYYY")
                : "-"}
            </div>
          </div>
          <div className={"grid-cols-[(repeat(2, max-content))] grid gap-4"}>
            <div>
              <div className={"text-base text-gray-400"}>YEAR END</div>
              <div className={"text-sm font-semibold"}>
                {organization?.yearEndMonth || "-"}{" "}
                {organization?.yearEndDay || "-"}
              </div>
            </div>
          </div>
          <div>
            <div className={"flex justify-between py-2"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  GST/HST NUMBER
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.gstHstNumber}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  REPORTING PERIOD
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.gstHstReportingPeroid}
                </div>
              </div>
            </div>
            <div className={"flex justify-between py-2"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>PST NUMBER</div>
                <div className={"text-sm font-semibold"}>
                  {organization.pstNumber}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  REPORTING PERIOD
                </div>
                <div className={"text-sm font-semibold"}>
                  {organization.pstReportingPeroid}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>SALES TAX</div>
            <div className={"text-sm font-semibold"}>
              {organization?.salesTaxType?.salesTaxType || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ORGANIZATION TYPE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.organizationType?.organizationType || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>PHONE NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {organization?.phoneNumbers?.[0]?.number || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>EMAIL</div>
            <div>
              <a
                className={classNames(
                  "link-primary text-sm font-semibold",
                  organization?.email
                    ? "hover:underline"
                    : "pointer-events-none",
                )}
                href={
                  organization?.email ? `mailto:${organization?.email}` : ""
                }>
                {organization?.email || "-"}
              </a>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>CONTACT TYPE</div>
            <div className={"text-sm font-semibold"}>
              {organization?.contactType?.contactType}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDRESS</div>
            <div className={"text-sm font-semibold"}>
              {address?.trim() ? address : "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDITIONAL INFO</div>
            <div className={"text-sm font-semibold"}>
              {organization?.additionalInfo || "-"}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
