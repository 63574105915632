import * as Xstate from "@xstate/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getWorkFlow, getWorkType } from "../../../api/api";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { FormSection } from "../../../support/FormSection";
import { ISubStatus, IWorkflow, IWorkType } from "../../../types/types";
import * as devConfig from "../../../app/configs/devConfig";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";

const EditWorkType = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const navigate = useNavigate();
  const { id } = useParams();
  const [workflow, setWorkflow] = useState<IWorkflow[]>([]);
  const [workType, setWorkType] = useState<IWorkType | null>(null);
  const [error, setError] = useState("");
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllCheckedReady, setIsAllCheckedReady] = useState(false);
  const [isAllSelectedReady, setIsAllSelectedReady] = useState(false);
  const [isAllCheckedInProgress, setIsAllCheckedInProgress] = useState(false);
  const [isAllSelectedInProgress, setIsAllSelectedInProgress] = useState(false);
  const [isAllCheckedWaiting, setIsAllCheckedWaiting] = useState(false);
  const [isAllSelectedWaiting, setIsAllSelectedWaiting] = useState(false);
  const [isAllCheckedCompleted, setIsAllCheckedCompleted] = useState(false);
  const [isAllSelectedCompleted, setIsAllSelectedCompleted] = useState(false);
  const [isUpdateName, setIsUpdateName] = useState(false);
  const [name, setName] = useState(workType?.name || "");

  const getAllData = async () => {
    const flow: IWorkflow[] = await getWorkFlow(`${getLinkedFirm()?.orgId}`);
    const type: IWorkType = await getWorkType(id);
    const data = flow.map((item) => ({
      ...item,
      workTypeSubStatuses: item.workTypeSubStatuses.map((sub) => ({
        ...sub,
        check: Boolean(
          type.serviceStatuses
            .find((el) => el.workTypeStatusId === item.id)
            ?.serviceSubStatuses?.some(
              (el) => sub.id === el.worktypeSubStatusId,
            ),
        ),
      })),
    }));
    setWorkType(type);
    setWorkflow(data);
  };
  useEffect(() => {
    getAllData().then();
  }, []);
  const onCheck = (check: boolean, subStatus: ISubStatus) => {
    const data = workflow.map((item) =>
      item.id === subStatus.workTypeStatusId
        ? {
            ...item,
            workTypeSubStatuses: item.workTypeSubStatuses.map((sub) =>
              sub.id === subStatus.id
                ? {
                    ...sub,
                    check: check,
                  }
                : sub,
            ),
          }
        : item,
    );
    setWorkflow(data);
  };
  const onSelectAll = (status?: string) => {
    let data: IWorkflow[] = [];

    if (status) {
      data = workflow.map((item) =>
        item.name.toLowerCase() === status
          ? {
              ...item,
              workTypeSubStatuses: item.workTypeSubStatuses.map((sub) => ({
                ...sub,
                check: isSelectAllByStatus(status),
              })),
            }
          : item,
      );
    } else {
      data = workflow.map((item) => ({
        ...item,
        workTypeSubStatuses: item.workTypeSubStatuses.map((sub) => ({
          ...sub,
          check: isAllSelected,
        })),
      }));
    }

    setWorkflow(data);
  };
  useEffect(() => {
    onSelectAll();
  }, [isAllSelected]);
  useEffect(() => {
    onSelectAll("ready");
  }, [isAllSelectedReady]);
  useEffect(() => {
    onSelectAll("in progress");
  }, [isAllSelectedInProgress]);
  useEffect(() => {
    onSelectAll("waiting");
  }, [isAllSelectedWaiting]);
  useEffect(() => {
    onSelectAll("completed");
  }, [isAllSelectedCompleted]);

  useEffect(() => {
    const isEveryChecked = workflow.every((item) =>
      item.workTypeSubStatuses.every((sub) => sub.check),
    );
    const isEveryCheckedReady = workflow
      .filter((item) => item.name.toLowerCase() === "ready")
      .every((item) => item.workTypeSubStatuses.every((sub) => sub.check));
    const isEveryCheckedInProgress = workflow
      .filter((item) => item.name.toLowerCase() === "in progress")
      .every((item) => item.workTypeSubStatuses.every((sub) => sub.check));
    const isEveryCheckedWaiting = workflow
      .filter((item) => item.name.toLowerCase() === "waiting")
      .every((item) => item.workTypeSubStatuses.every((sub) => sub.check));
    const isEveryCheckedCompleted = workflow
      .filter((item) => item.name.toLowerCase() === "completed")
      .every((item) => item.workTypeSubStatuses.every((sub) => sub.check));
    setIsAllChecked(isEveryChecked);
    setIsAllCheckedReady(isEveryCheckedReady);
    setIsAllCheckedInProgress(isEveryCheckedInProgress);
    setIsAllCheckedWaiting(isEveryCheckedWaiting);
    setIsAllCheckedCompleted(isEveryCheckedCompleted);
  }, [workflow]);

  const selectAllByStatus = (check: boolean, status: string) => {
    const statusName = status.toLowerCase();
    switch (statusName) {
      case "ready": {
        setIsAllSelectedReady(check);
        setIsAllCheckedReady(check);
        break;
      }
      case "in progress": {
        setIsAllSelectedInProgress(check);
        setIsAllCheckedInProgress(check);
        break;
      }
      case "waiting": {
        setIsAllSelectedWaiting(check);
        setIsAllCheckedWaiting(check);
        break;
      }
      case "completed": {
        setIsAllSelectedCompleted(check);
        setIsAllCheckedCompleted(check);
        break;
      }
      default:
        break;
    }
  };
  const isCheckedAllByStatus = (status: string) => {
    const statusName = status.toLowerCase();
    switch (statusName) {
      case "ready": {
        return isAllCheckedReady;
      }
      case "in progress": {
        return isAllCheckedInProgress;
      }
      case "waiting": {
        return isAllCheckedWaiting;
      }
      case "completed": {
        return isAllCheckedCompleted;
      }
      default:
        return false;
    }
  };
  const isSelectAllByStatus = (status: string) => {
    const statusName = status.toLowerCase();
    switch (statusName) {
      case "ready": {
        return isAllSelectedReady;
      }
      case "in progress": {
        return isAllSelectedInProgress;
      }
      case "waiting": {
        return isAllSelectedWaiting;
      }
      case "completed": {
        return isAllSelectedCompleted;
      }
      default:
        return false;
    }
  };
  const update = async () => {
    const data = {
      workTypeId: workType?.id,
      statuses: workflow.map((item) => ({
        workTypeStatusId: item.id,
        workTypeSubStatusIds: item.workTypeSubStatuses
          .map((sub) => sub.check && sub.id)
          .filter((sub) => sub),
      })),
    };

    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/services/add-service-statuses`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      navigate("../");
    } else {
      setError("The request failed");
    }
  };
  const updateName = async () => {
    const data = {
      id: workType?.id,
      name: name,
    };

    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/services/update-service`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      const type: IWorkType = await getWorkType(id);
      setWorkType(type);
    } else {
      setError("The request failed");
    }
  };

  useEffect(() => {
    if (workType) {
      setName(workType?.name || "");
    }
  }, [workType]);

  if (workflow.length === 0 || !workType) {
    return <LottieLoading />;
  }

  return (
    <>
      <FormSection name={""} showHeading={false}>
        <div className={"text-lg font-bold"}>
          {!isUpdateName ? (
            <div
              onClick={() => setIsUpdateName(true)}
              className={"cursor-pointer"}>
              {workType.name}
            </div>
          ) : (
            <input
              className={"input input-bordered"}
              value={name}
              autoFocus
              onChange={(e) => setName(e.target.value)}
              onBlur={() => {
                setIsUpdateName(false);
                if (name) {
                  updateName();
                }
              }}
            />
          )}{" "}
          | Edit Work Type
        </div>

        <div className={"mb-6 flex items-center"}>
          <div>
            <input
              type={"checkbox"}
              className={"mr-4"}
              checked={isAllChecked}
              onChange={(event) => {
                setIsAllSelected(event.target.checked);
                setIsAllChecked(event.target.checked);
              }}
            />
          </div>
          <div className={"link-secondary text-[14px]"}>Select all</div>
        </div>
        <div className={"text-[14px]"}>
          {workflow.map((item) => (
            <div key={item.id} className={"mb-8"}>
              <div
                className={
                  "mb-4 flex w-full items-center gap-4 border-b-[1px] border-blue-700 pb-2"
                }>
                <div>
                  <input
                    type={"checkbox"}
                    checked={isCheckedAllByStatus(item.name)}
                    onChange={(event) => {
                      selectAllByStatus(event.target.checked, item.name);
                    }}
                  />
                </div>
                <div
                  style={{ background: item.color }}
                  className={"h-[15px] w-[15px]  rounded-full"}></div>
                <div className={"text-[14px] font-bold"}>{item.name}</div>
              </div>
              <div>
                {item.workTypeSubStatuses.map((subStatus) => (
                  <div
                    key={subStatus.id}
                    className={
                      "mb-4 flex border-b-[1px] border-gray-300  pb-2"
                    }>
                    <div>
                      <input
                        type={"checkbox"}
                        className={"mr-4"}
                        checked={subStatus.check}
                        onChange={(event) =>
                          onCheck(event.target.checked, subStatus)
                        }
                      />
                    </div>
                    <div className={"text-[14px]"}>{subStatus.subStatus}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={"flex gap-8"}>
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
            extraClasses={"normal-case"}
          />
          <Button
            onClick={() => update()}
            buttonType={"button"}
            label={"Update"}
            extraClasses={"normal-case"}
          />
        </div>
        {error && (
          <div className={"mt-4 text-[14px] text-red-600"}>{error}</div>
        )}
      </FormSection>
    </>
  );
};

export default EditWorkType;
