import React from "react";

import { isEmptyStr } from "../../app/commonOps/CommonStringOps";
import { LABELS } from "../../app/constants/TextConstants";
import {
  GetCustomerById_customerById_contact_phoneNumbers,
  GetJobById_jobById_customer_contact_email,
} from "../../generated/operation-result-types";

export type ContactInfoPanelProps = {
  id: string;
  fullName: string;
  avatarInitials: string;
  email: GetJobById_jobById_customer_contact_email | null;
  phoneNumbers?: GetCustomerById_customerById_contact_phoneNumbers[] | null;
};

type Props = {
  contact: ContactInfoPanelProps;
};

export const ContactInfoPanelView: React.FC<Props> = (props) => {
  const { fullName, phoneNumbers, email } = props.contact;

  const contactEmailRows = () => {
    if (email === null || email === undefined) return "Missing email";
    const { id, emailAddress } = email;
    return (
      <div className={"grid grid-cols-1 xl:grid-cols-2"} key={id}>
        <div className={"text-sm font-semibold"}>Email</div>
        <a
          href={`mailto:${emailAddress}`}
          className={"link link-primary text-sm"}>
          {emailAddress}
        </a>
      </div>
    );
  };

  const contactPhoneRows = phoneNumbers?.map((phoneNode) => {
    const { id, number } = phoneNode;
    return (
      <div className={"grid grid-cols-1 xl:grid-cols-2"} key={id}>
        <div className={"text-sm font-semibold"}>Phone</div>
        <div className={"text-sm"}>{number}</div>
      </div>
    );
  });

  return (
    <>
      <div className={"text-md text-center font-semibold"}>
        {LABELS.features.contact} Info
      </div>
      {!isEmptyStr(fullName) && (
        <div className={"grid grid-cols-1 xl:grid-cols-2"}>
          <div className={"text-sm font-semibold"}>Name</div>
          <div className={"text-sm"}>{fullName}</div>
        </div>
      )}

      {contactEmailRows()}

      {contactPhoneRows}
    </>
  );
};
