import { FC, useState } from "react";
import { OrganizationCollapsedNote } from "./OrganizationCollapsedNote";
import { OrganizationNote } from "./OrganizationNote";
import { IOrganizationContactNote } from "../../../../../../../../types/types";

type Props = {
  note: IOrganizationContactNote;
  setCurrentNote: (value: IOrganizationContactNote) => void;
  setIsOpenAdd: (value: boolean) => void;
  deleteContactNote: (value: IOrganizationContactNote) => void;
};

export const OrganizationNoteView: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return isCollapsed ? (
    <OrganizationCollapsedNote note={note} setIsCollapsed={setIsCollapsed} />
  ) : (
    <OrganizationNote
      note={note}
      setIsCollapsed={setIsCollapsed}
      setCurrentNote={setCurrentNote}
      setIsOpenAdd={setIsOpenAdd}
      deleteContactNote={deleteContactNote}
    />
  );
};
