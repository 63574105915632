import { ListOverrides } from "baseui/dnd-list";

const baseWebListOverrides: ListOverrides = {
  Root: {
    style: {
      width: "full-width",
    },
  },
};

export const BW_STYLES = {
  listOverrides: baseWebListOverrides,
};

export const BW_VALUES = {
  toast: { autoHideMs: 1000 },
};

export const TW_SIZES = {
  hw: {
    xs: "h-4 w-4",
    sm: "h-4 w-4",
    md: "h-6 w-6",
    lg: "h-6 w-6",
    xl: "h-8 w-8",
  },
  mr: {
    xs: "mr-1",
    sm: "mr-2",
    md: "mr-3",
    lg: "mr-4",
  },
  ml: {
    xs: "ml-1",
    sm: "ml-2",
    md: "ml-3",
    lg: "ml-4",
  },
  colors: {
    textSuccess: "text-green-600",
    textError: "text-red-600",
  },
};
