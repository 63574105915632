import React from "react";
import { useForm } from "react-hook-form";

import { LABELS } from "../../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import { IUserInvitation } from "../../../../types/types";
import * as devConfig from "../../../../app/configs/devConfig";
interface IEditeInvitedUserProps {
  invitedUser: IUserInvitation;
  closeEdit: () => void;
  getInvitedUsers: () => void;
  setIsLoading: (isLoading: boolean) => void;
}
const EditInvitedUser: React.FC<IEditeInvitedUserProps> = ({
  invitedUser,
  closeEdit,
  getInvitedUsers,
  setIsLoading,
}) => {
  const editInvitedUser = async (data: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations/update-user-invitation`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      closeEdit();
      getInvitedUsers();
    } else {
      setIsLoading(false);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      firstName: invitedUser?.firstName,
      lastName: invitedUser?.lastName,
      email: invitedUser?.email,
    },
  });
  // local effects
  React.useEffect(() => {
    setFocus("firstName");
  }, []);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await editInvitedUser({
      ...invitedUser,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
  };
  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit invitation</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"form-control lg:col-span-5"}>
          <label className={"label"}>
            <span className={"label-text"}>First name</span>
          </label>
          <input
            type={"text"}
            {...register("firstName", {
              required: LABELS.required,
              ...ValidationConstants.orgRules.nameRule.valueLength,
            })}
            className={"input input-bordered"}
            placeholder={"First name"}
          />
          {errors?.firstName?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.firstName?.message}
            </span>
          )}
        </div>
        <div className={"form-control lg:col-span-5"}>
          <label className={"label"}>
            <span className={"label-text"}>Last name</span>
          </label>
          <input
            type={"text"}
            {...register("lastName", {
              required: LABELS.required,
              ...ValidationConstants.orgRules.nameRule.valueLength,
            })}
            className={"input input-bordered"}
            placeholder={"Last name"}
          />
          {errors?.lastName?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.lastName?.message}
            </span>
          )}
        </div>
        <div className={"form-control lg:col-span-5"}>
          <label className={"label"}>
            <span className={"label-text"}>Email</span>
          </label>
          <input
            type={"text"}
            {...register("email", {
              required: LABELS.required,
              pattern: {
                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                message: "Invalid email format",
              },
            })}
            className={"input input-bordered"}
            placeholder={"Email"}
          />
          {errors?.email?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.email?.message}
            </span>
          )}
        </div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button
            buttonType={"submit"}
            label={"Update"}
            colorType={"secondary"}
            extraClasses={"normal-case"}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={closeEdit}
            extraClasses={"normal-case"}
          />
        </div>
      </form>
    </>
  );
};

export default EditInvitedUser;
