import moment from "moment";

export const detectFileType = (fileName: string) => {
  return fileName.split(".").pop()?.toLowerCase();
};

export function formatTime(
  seconds: number,
  variant: { isHours: boolean; isMinutes: boolean; isSeconds: boolean },
): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  return `${
    variant.isHours
      ? formattedHours + `${variant.isSeconds || variant.isMinutes ? ":" : ""}`
      : ""
  }${
    variant.isMinutes
      ? formattedMinutes + `${variant.isSeconds ? ":" : ""}`
      : ""
  }${variant.isSeconds ? formattedSeconds : ""}`;
}

export const showStatus = (work: any, workTask?: any): string => {
  if (!work?.jobStatus && !workTask?.status) {
    return "No status";
  }

  if (workTask) {
    if (workTask?.subStatus) {
      if (typeof workTask?.subStatus === "object") {
        return workTask?.subStatus?.subStatus;
      }
      return workTask?.subStatus;
    } else {
      if (typeof workTask?.status === "object") {
        return workTask?.status?.name;
      }
      return workTask?.status;
    }
  }

  if (work?.jobSubStatus) {
    if (typeof work?.jobSubStatus === "object") {
      return work?.jobSubStatus?.name;
    }
    return work?.jobSubStatus;
  } else {
    if (typeof work?.jobStatus === "object") {
      return work?.jobStatus?.name;
    }
    return work?.jobStatus;
  }
};

export const getInitialsFromFullName = (name: string) => {
  if (!name?.trim()) {
    return "N/A";
  }
  return name
    .split(" ")
    .map((i) => i.substring(0, 1))
    .filter((item) => item !== " " && item !== "")
    .filter((_, index) => index < 2)
    .join("");
};

export const getInitials = (name: string, lastName: string) => {
  if (!name?.trim()) {
    return "N/A";
  }
  return name?.trim().substring(0, 1) + lastName?.trim().substring(0, 1);
};

export const filterTypes = [
  { id: "0", name: "Status" },
  { id: "1", name: "Work type" },
  { id: "2", name: "Assignee" },
  { id: "3", name: "Due date" },
];
export const inactiveStatusId = "9075cde5-08cc-4c90-a7a7-c97c411af4aa";

export const getDaysBetweenDates = (date1: string, date2: string): number => {
  const oneDay = 86400000;
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const diffInMs = Math.abs(secondDate.getTime() - firstDate.getTime());
  const diffInDays = Math.floor(diffInMs / oneDay);

  return diffInDays;
};
export const timeFormatter = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}h ${formattedMinutes}m`;
};
export const convertTimeToSeconds = (time: { h: string; m: string }) => {
  return moment.duration(`${time.h || 0}:${time.m || 0}`, "hours").asSeconds();
};

export function validatePhoneFormat(phoneNumber: string) {
  if (!phoneNumber) {
    return true;
  }
  // Regular expression for a basic phone number format (e.g., +1 123-456-7890)
  const phoneRegex = /^[+]?\d{0,3}[-\s.]?\d{3}[-\s.]?\d{3}[-\s.]?\d{4}$/;

  return phoneRegex.test(phoneNumber);
}
