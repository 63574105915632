import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import DropPopup from "../../../components/DropPopup";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { ITaskTemplate } from "../../../types/types";
import { AddTaskTemplateModal } from "./AddTaskTemplate";
import EditTaskTemplate from "./EditTaskTemplate";
import HideTemplatePopup from "./HideTemplatePopup";
import * as devConfig from "../../../app/configs/devConfig";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";

export const TaskTemplates: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [isUpdateTaskTemplate, setIsUpdateTaskTemplate] = useState(false);
  const [isHideTemplate, setIsHideTemplate] = useState(false);
  const [isAddTaskTemplate, setIsAddTaskTemplate] = useState(false);
  const [taskTemplate, setTaskTemplate] = useState({});
  const [taskTemplates, setTaskTemplates] = useState<ITaskTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getTaskTemplates = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work-template/get-all?orgId=${
        getLinkedFirm()?.orgId
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTaskTemplates(data);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    setIsLoading(true);
    getTaskTemplates().then();
  });

  const deleteTaskTemplate = async (task: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work-template`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(task),
      },
    );
    if (response.ok) {
      getTaskTemplates().then();
    } else {
      setIsLoading(false);
      setTaskTemplate(task);

      setIsHideTemplate(true);
    }
  };
  const hideTaskTemplate = async (task: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work-template/hide-template`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(task),
      },
    );
    if (response.ok) {
      getTaskTemplates().then();
    } else {
      setIsLoading(false);
      setIsHideTemplate(true);
    }
  };
  const importTaskTemplate = async (formData: any) => {
    try {
      const response = await fetch(
        `${devConfig.getServerBaseUrlForEnv()}/api/work-template/import-templates?orgId=${
          getLinkedFirm()?.orgId
        }`,
        {
          headers: {
            Authorization: getAuthHeader().Authorization,
          },
          method: "POST",
          body: formData,
        },
      );

      if (response.ok) {
        const data = await response.json();
        // Handle success if needed
        setErrorMessage("");
      } else {
        // Handle the error and set the error message state
        const errorData = await response.text();
        setErrorMessage(errorData || "An error occurred.");
      }
    } catch (error) {
      setErrorMessage("A network error occurred.");
    }

    getTaskTemplates().then();
  };

  const handleImport = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append("formFile", file);
      //setIsLoading(true);
      importTaskTemplate(formData).then();
    }
  };
  console.log(errorMessage);
  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <FormSection name={""} extraCx={"overflow-visible"} showHeading={false}>
        <div className={"mb-4 flex items-start justify-between"}>
          <div>
            <div className={"tabs mb-4"}>
              <CustomLink to={"./"}>Active</CustomLink>
              <CustomLink to={"../inactive"}>Inactive</CustomLink>
            </div>
            <div className={"text-lg font-bold"}>Details</div>
          </div>
          <div className={"flex flex-col items-end"}>
            <Button
              icon={
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className={"text-[16px] text-white"}
                />
              }
              buttonType={"button"}
              onClick={() => setIsOpenImport(true)}
              label={"Import Template"}
              extraClasses={"normal-case"}
            />
            {errorMessage && (
              <div className={"mt-2 text-xs text-red-500"}>{errorMessage}</div>
            )}
          </div>
        </div>
        <div className={"text-[14px]"}>
          {taskTemplates.map((task) => (
            <div
              className={
                "mb-4 flex w-full  justify-between border-b-[1px] border-gray-300 pb-2 text-[14px]"
              }
              key={task.id}>
              <div>
                <Link
                  to={`../${task.id}`}
                  className={"link-primary text-sm font-[500] hover:underline"}>
                  {task.templateName}
                </Link>
              </div>
              <EditDots>
                <div className={""}>
                  <div
                    onClick={() => {
                      setTaskTemplate(task);
                      setIsUpdateTaskTemplate(true);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Edit
                  </div>
                  <div
                    onClick={() => {
                      setIsLoading(true);
                      hideTaskTemplate(task);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Hide
                  </div>
                  <div
                    onClick={() => {
                      setIsLoading(true);
                      deleteTaskTemplate(task);
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Delete
                  </div>
                </div>
              </EditDots>
            </div>
          ))}
        </div>
        <div>
          <Button
            buttonType={"button"}
            onClick={() => setIsAddTaskTemplate(true)}
            label={"Add Template"}
            extraClasses={"normal-case"}
          />
        </div>
      </FormSection>
      {isAddTaskTemplate && (
        <AddTaskTemplateModal
          setIsLoading={setIsLoading}
          setActive={setIsAddTaskTemplate}
          getTaskTemplates={getTaskTemplates}
        />
      )}
      {isUpdateTaskTemplate && (
        <EditTaskTemplate
          setIsLoading={setIsLoading}
          setActive={setIsUpdateTaskTemplate}
          taskTemplate={taskTemplate}
          getTaskTemplates={getTaskTemplates}
        />
      )}
      {isHideTemplate && (
        <HideTemplatePopup
          isOpen={isHideTemplate}
          closePopup={() => setIsHideTemplate(false)}
          confirmHideTemplate={() => {
            setIsLoading(true);
            setIsHideTemplate(false);
            hideTaskTemplate(taskTemplate);
          }}
        />
      )}
      {isOpenImport && (
        <DropPopup
          message={
            "If a task column has different names, it will be split into different templates"
          }
          title={"Import template"}
          close={() => setIsOpenImport(false)}
          onFileDrop={(file) => handleImport(file)}
        />
      )}
    </>
  );
};
