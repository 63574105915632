import React, { FC, useState } from "react";
import { HiEllipsisVertical, HiPlus } from "react-icons/hi2";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { inactiveStatusId } from "../../../../../../../../constants/constants";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import {
  IContactProfile,
  IOrganizationContact,
  SelectOptionType,
} from "../../../../../../../../types/types";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import {
  useAddCustomerTagsMutation,
  useDeleteCustomerTagMutation,
  useGetCustomerTagsQuery,
  useGetTagsQuery,
} from "../../../../../../../../slices/ContactTagsSlice";
import CustomSelect from "../../../../../../../../components/select/Select";
import { SlTag } from "react-icons/sl";
import { Dropdown } from "../../../../../../../../components/dropdown/new-dropdown/Dropdown";

type Props = {
  organization: IOrganizationContact;
};

export const OrganizationTagsWidget: FC<Props> = ({ organization }) => {
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [tagsValue, setTagsValue] = useState<SelectOptionType[]>([]);
  const [isError, setIsError] = useState(false);

  const { data: allTags } = useGetTagsQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: tags, isFetching } = useGetCustomerTagsQuery({
    customerId: organization.customerId,
  });
  const [addTags, { isSuccess }] = useAddCustomerTagsMutation();
  const [deleteCustomerTag] = useDeleteCustomerTagMutation();

  const isActive = organization?.contactTypeEnumId !== inactiveStatusId;
  const tagOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (allTags) {
      allTags.forEach((tag) => {
        options.push({
          value: tag.id,
          label: tag?.tagName,
        });
      });
    }

    return options;
  };
  function haveCommonElements(arr1: string[], arr2: string[]): boolean {
    const set1 = new Set(arr1);
    for (const element of arr2) {
      if (set1.has(element)) {
        return true;
      }
    }
    return false;
  }
  const errorAddTag = () => {
    if (tags) {
      const tagsNames: string[] = tags.map((tag) => tag.tags.id);
      const tagsValues: string[] = tagsValue.map((tag) => tag.value);
      if (haveCommonElements(tagsNames, tagsValues)) {
        setIsError(true);
      } else {
        addTags({
          customerId: organization?.customerId,
          tagIds: tagsValue.map((tag) => tag.value) || [],
        });
        setIsOpenAdd(false);
        setTagsValue([]);
      }
    }
  };

  return (
    <>
      <Card
        extraClasses={
          "shadow-box rounded-[6px] min-h-[310px] relative overflow-visible"
        }>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Tags</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={() => setIsOpenAdd(true)}
            />
          </div>
          <div>
            {isFetching ? (
              <LottieLoading />
            ) : !tags?.length ? (
              <div className={"px-6 pb-6 pt-0"}>
                <NoItems
                  icon={<SlTag size={"30px"} className={"text-purple"} />}
                  message={"There are no tag items"}
                  addButtonMessage={"Add Tag"}
                  openAdd={() =>
                    !isActive ? setIsOpenError(true) : setIsOpenAdd(true)
                  }
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-y-auto"}>
                {tags?.map((tag) => (
                  <div
                    className={
                      "flex items-center justify-between border-b border-gray-300 py-2 pl-7 pr-5 text-base font-semibold"
                    }
                    key={tag.tags.id}>
                    {tag.tags.tagName}
                    <Dropdown
                      triggerElement={
                        <Button
                          label={""}
                          colorType={"ghost"}
                          size={"custom"}
                          icon={<HiEllipsisVertical size={25} />}
                          extraClasses={"min-h-fit h-fit w-fit p-1"}
                        />
                      }>
                      <div
                        className={
                          "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                        }>
                        <div
                          className={
                            "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                          }
                          onClick={() => {
                            deleteCustomerTag({
                              tagIds: tags
                                .filter((item) => item.tags.id !== tag.tags.id)
                                .map((item) => item.tags.id),
                              customerId: organization.customerId,
                            });
                          }}>
                          Delete
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>

      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
      {isOpenAdd && (
        <div className={`modal ${isOpenAdd ? "modal-open" : "modal-close"}`}>
          <div
            className={
              "modal-box flex h-[500px] w-[550px] flex-wrap rounded-md bg-white px-3 py-2"
            }>
            <div className={"h-[60px] w-full pb-2"}>
              <CustomSelect
                options={tagOptions()}
                value={tagsValue}
                fullWidth
                onChange={(newValue) => {
                  const value = newValue as SelectOptionType[];
                  setTagsValue(value);
                  setIsError(false);
                }}
                inputValue={tagValue}
                onChangeInput={setTagValue}
                isMulti
                isLoading={isFetching}
                height={"h-[170px]"}
                autoFocus
              />
            </div>
            {isError && (
              <div className={"w-full text-sm font-bold text-error"}>
                This tag is already exist
              </div>
            )}
            <div
              className={
                "mt-4 flex h-[350px] w-full flex-wrap gap-0 overflow-y-auto px-2"
              }>
              <div className={"h-fit w-full"}>
                {tagsValue.map((i) => (
                  <div
                    className={
                      "flex h-[30px] w-[100%] items-center justify-between border-b border-gray-200 px-2 py-2"
                    }>
                    {i.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={"flex h-[10%] w-full items-end justify-end gap-2"}>
              <Button
                label={"Add"}
                extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
                onClick={() => {
                  errorAddTag();
                }}
              />
              <Button
                label={"Cancel"}
                colorType={"outline"}
                customSize={"px-1 py-1"}
                extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
                onClick={() => {
                  setIsOpenAdd(false);
                  setTagsValue([]);
                  setIsError(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
