import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const ArrowChevronDownSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          d={
            "M3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L12 14.5858L19.2929 7.29289C19.6834 6.90237 20.3166 6.90237 20.7071 7.29289C21.0976 7.68342 21.0976 8.31658 20.7071 8.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"1"}></path>
      </svg>
    </>
  );
};
