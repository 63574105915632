import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";
import homeSvg from "./home.png";

export const HomeSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md}` } = props;

  return (
    <>
      <img
        src={homeSvg}
        alt="home"
        className={`inline-block ${sizeCx} h-[24px] w-[24px] fill-current stroke-current opacity-70 `}
      />
    </>
  );
};
