import React from "react";

const ContactFileSvg = () => {
  return (
    <svg
      width={"35"}
      height={"35"}
      viewBox={"0 0 35 35"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M4.375 27.7083V7.29167C4.375 6.51812 4.68229 5.77625 5.22927 5.22927C5.77625 4.68229 6.51812 4.375 7.29167 4.375H23.5842C24.3577 4.37517 25.0994 4.68256 25.6463 5.22958L29.7704 9.35375C30.3174 9.9006 30.6248 10.6423 30.625 11.4158V27.7083C30.625 28.4819 30.3177 29.2237 29.7707 29.7707C29.2237 30.3177 28.4819 30.625 27.7083 30.625H7.29167C6.51812 30.625 5.77625 30.3177 5.22927 29.7707C4.68229 29.2237 4.375 28.4819 4.375 27.7083Z"
        }
        stroke={"#7C66F0"}
        strokeWidth={"2.1875"}
      />
      <path
        d={
          "M12.5417 13.125H22.4583C22.6904 13.125 22.913 13.0328 23.0771 12.8687C23.2411 12.7046 23.3333 12.4821 23.3333 12.25V5.25C23.3333 5.01794 23.2411 4.79538 23.0771 4.63128C22.913 4.46719 22.6904 4.375 22.4583 4.375H12.5417C12.3096 4.375 12.087 4.46719 11.9229 4.63128C11.7589 4.79538 11.6667 5.01794 11.6667 5.25V12.25C11.6667 12.4821 11.7589 12.7046 11.9229 12.8687C12.087 13.0328 12.3096 13.125 12.5417 13.125ZM8.75 19.8333V30.625H26.25V19.8333C26.25 19.6013 26.1578 19.3787 25.9937 19.2146C25.8296 19.0505 25.6071 18.9583 25.375 18.9583H9.625C9.39294 18.9583 9.17038 19.0505 9.00628 19.2146C8.84219 19.3787 8.75 19.6013 8.75 19.8333Z"
        }
        stroke={"#7C66F0"}
        strokeWidth={"2.1875"}
      />
    </svg>
  );
};

export default ContactFileSvg;
