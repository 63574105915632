import React from "react";

import { GetBankDocById_bankDocById } from "../../../generated/operation-result-types";
import { BankDocInfoPanelView } from "../../InfoPanels/BankDocInfoPanelView";
import { InfoPanelsView } from "../../InfoPanels/InfoPanelsView";
import { BankDocOutputLoaderView } from "./BankDocOutputLoaderView";
import { BankDocStatusMessageView } from "./BankDocStatusMessageView";

export type BankDocDetailsOutputViewProp = {
  locationId: string;
  bankDocById: GetBankDocById_bankDocById;
  onRefreshStatus: () => void;
};

export const BankDocDetailsOutputView: React.FC<
  BankDocDetailsOutputViewProp
> = ({ bankDocById, locationId, onRefreshStatus }) => {
  const { bankDocStatusEnum, blobUri, resultTokenId } = bankDocById;

  const statusValue = bankDocStatusEnum?.statusType.toLowerCase();
  if (!statusValue) {
    throw new Error("Missing bank document status");
  }

  const isResultNotReady =
    !statusValue.startsWith("success") || !blobUri || !resultTokenId;
  const resultView = isResultNotReady ? (
    <div className={"py-10"}>
      <BankDocStatusMessageView
        statusType={statusValue}
        onRefreshStatus={onRefreshStatus}
      />
    </div>
  ) : (
    <BankDocOutputLoaderView
      locationId={locationId}
      bankDocById={bankDocById}
    />
  );

  return (
    <>
      <div
        className={
          "collapse-arrow collapse rounded-box w-full border border-base-300 bg-white"
        }>
        <input type={"checkbox"} defaultChecked={true} />
        <div className={"collapse-title ml-8 text-lg font-medium"}>
          Customer Details
        </div>
        <div className={"collapse-content"}>
          <InfoPanelsView
            customer={bankDocById.customer}
            contact={bankDocById.customer?.contact}>
            <BankDocInfoPanelView bankDoc={bankDocById} />
          </InfoPanelsView>
        </div>
      </div>
      {resultView}
    </>
  );
};
