import moment from "moment";
import React, { FC } from "react";
import { IWorkNote } from "../../../../../../types/types";
import { getInitials } from "../../../../../../constants/constants";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { parseNote } from "../../../../../contacts/utils/parseNote";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import Button from "../../../../../../components/button/Button";
import { HiEllipsisVertical } from "react-icons/hi2";

type Props = {
  note: IWorkNote;
  setCurrentNote: (value: IWorkNote) => void;
  setIsOpenAdd: (isOpenAdd: boolean) => void;
  deleteContactNote: (value: IWorkNote) => void;
};

export const WorkNote: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  return (
    <div className={"border-b border-gray-200 px-5 py-5"}>
      <div className={"flex justify-between"}>
        <div className={"flex flex-wrap"}>
          <div className={"flex w-full flex-wrap text-base font-semibold"}>
            {note?.title || "No title"}
          </div>
          <div className={"flex w-full flex-wrap text-base text-[#9FA1AD]"}>
            {parseNote(note.note)}
          </div>
        </div>
        <Dropdown
          triggerElement={
            <Button
              label={""}
              colorType={"ghost"}
              size={"custom"}
              icon={<HiEllipsisVertical size={24} color={"black"} />}
              extraClasses={"min-h-fit h-fit w-fit p-1"}
            />
          }>
          <div
            className={
              "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
            }>
            <div
              onClick={() => {
                setCurrentNote(note);
                setIsOpenAdd(true);
              }}
              className={
                "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
              }>
              Edit
            </div>
            <div
              onMouseDown={() => deleteContactNote(note)}
              className={
                "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
              }>
              Delete
            </div>
          </div>
        </Dropdown>
      </div>

      <div className={"flex items-center justify-between pt-3"}>
        <div className={"flex items-center gap-2"}>
          <div>
            <AvatarPlaceholder
              size={"super-small"}
              label={getInitials(
                note?.createdByUser?.userProfile?.firstName,
                note?.createdByUser?.userProfile?.lastName,
              )}
            />
          </div>
          <div
            className={
              "w-full max-w-[180px] break-words text-base font-semibold"
            }>
            {note?.createdByUser?.userProfile?.firstName}{" "}
            {note?.createdByUser?.userProfile?.lastName}
          </div>
        </div>
        <div className={"flex justify-between text-base text-[#9FA1AD]"}>
          {moment(note.createdAt).format("MMM DD, YYYY")}
        </div>
      </div>
    </div>
  );
};
