import * as Xstate from "@xstate/react";
import { ChevronDown, ChevronUp } from "baseui/icon";
import React, { useEffect, useRef, useState } from "react";

import { getWorkTypes } from "../../../../api/api";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { IUser, IWorkType } from "../../../../types/types";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";

interface ISelectFilterWorkTypeProps {
  values: IWorkType[];
  setArrayFilter: (array: IWorkType[]) => void;
  variant?: "one" | "multi";
  page?: "Works" | "Dashboard";
}

const SelectFilterWorkType: React.FC<ISelectFilterWorkTypeProps> = ({
  values,
  setArrayFilter,
  variant = "multi",
  page = "Works",
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const selectFilterStatus = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [workTypes, setWorkTypes] = useState<IWorkType[]>([]);
  const [filterByWorkType, setFilterByWorkType] = useState<IWorkType[]>(values);

  useEffect(() => {
    setFilterByWorkType(values);
  }, [values]);

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      getWorkTypes(`${getLinkedFirm()?.orgId}`).then((res) =>
        setWorkTypes(res),
      );
    }
  }, [getLinkedFirm()?.orgId]);

  useOnClickOutside(selectFilterStatus, () => setIsOpen(false));
  const onChangeWorkType = (workType: IWorkType, checked: boolean) => {
    switch (page) {
      case "Works": {
        if (checked) {
          if (variant === "one") {
            setIsOpen(false);
            setFilterByWorkType([workType]);
          } else {
            setFilterByWorkType((prev) => [...prev, workType]);
          }
        } else {
          setFilterByWorkType((prev) =>
            prev.filter((item) => item.id !== workType.id),
          );
        }
        break;
      }
      case "Dashboard": {
        if (checked) {
          if (variant === "one") {
            setIsOpen(false);
            setArrayFilter([workType]);
          } else {
            setArrayFilter([...values, workType]);
          }
        } else {
          setArrayFilter(values.filter((item) => item.id !== workType.id));
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (page === "Works") {
      setArrayFilter(filterByWorkType);
    }
  }, [filterByWorkType, page]);

  const activeWorkType = () => {
    if (filterByWorkType.length > 0) {
      return (
        <div>
          {filterByWorkType[0].name}
          {filterByWorkType.length > 1 && "..."}
        </div>
      );
    } else {
      return <div>All</div>;
    }
  };

  return (
    <div>
      <div className={"mb-0.5 text-base"}>Work type</div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`relative h-fit w-[200px] cursor-pointer rounded-[8px] border-[1px] bg-white px-5 py-2 ${
          isOpen ? "border-purple-700" : "border-gray-300"
        }`}
        ref={selectFilterStatus}>
        <div className={"flex w-full items-center justify-between"}>
          <div className={"text-sm"}>{activeWorkType()}</div>
          {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </div>
        {isOpen && (
          <div
            className={
              "absolute left-[0px] z-[1] mt-[12px] max-h-[300px] w-full overflow-y-auto rounded-[8px] bg-white py-4 shadow-filter "
            }>
            {workTypes.map((workType) => (
              <div key={workType.id}>
                <div
                  className={
                    "mb-4 flex cursor-pointer px-4 last:mb-0 hover:bg-gray-100"
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    onChangeWorkType(
                      workType,
                      !Boolean(
                        filterByWorkType.some(
                          (item) => item.id === workType.id,
                        ),
                      ),
                    );
                  }}>
                  {variant === "multi" && (
                    <div className={"mr-4"}>
                      <input
                        className={"checkbox checkbox-accent cursor-pointer"}
                        type={"checkbox"}
                        checked={Boolean(
                          filterByWorkType.some(
                            (item) => item.id === workType.id,
                          ),
                        )}
                        onChange={() => null}
                      />
                    </div>
                  )}
                  <div className={"text-sm font-semibold"}>{workType.name}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFilterWorkType;
