import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AddContact from "../../../components/modal/addContact";
import Search from "../../../components/select/Search";
import {
  useGetActiveContactsQuery,
  useGetActiveOrganizationsQuery,
  useGetSearchCustomersQuery,
} from "../../../slices/ContactsSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../../types/types";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { useDebounce } from "usehooks-ts";
import { getInitialsFromFullName } from "../../../constants/constants";
import classNames from "classnames";
import { useAppSelector } from "../../../redux/redux";

interface Props {
  setType: (type: "contacts" | "works") => void;
}

const SearchContacts: React.FC<Props> = ({ setType }) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);

  const [isFocus, setIsFocus] = useState(false);
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 300);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: debounceSearch || "a",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const getContacts = () => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact.businessContactId
            ? contact.businessContactId
            : contact?.id,
          label: contact.businessContactId
            ? contact.businessContact?.companyName || ""
            : `${contact?.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const navigateToContacts = (contact: SelectOptionType) => {
    navigate(
      navigateToContact({
        type: contact.avatarColor === "purple" ? "organization" : "contact",
        customerId: contact.value || "",
        id: contact.value || "",
      }),
    );
  };

  return (
    <>
      <Search
        value={null}
        onChange={(item) => {
          const value = item as SelectOptionType;
          navigateToContacts(value);
        }}
        options={getContacts()}
        inputValue={search}
        onChangeInput={(value) => setSearch(value)}
        setType={setType}
        isLoading={isLoadingContacts}
        placeholder={isFocus ? "" : "Search contacts"}
        noOptionsMessage={"No results"}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        type={"contacts"}
        setIsOpenAddContact={setIsOpenAddContact}
      />
      {isOpenAddContact && (
        <AddContact
          isIdLikeContactId={false}
          isOpen={isOpenAddContact}
          closeModal={() => setIsOpenAddContact(false)}
          setContactsForSort={(newContact) => {
            const newData: SelectOptionType = {
              label: newContact?.name || "",
              value: newContact?.isBusinessContact
                ? newContact?.id || ""
                : newContact?.customerId || "",
              avatarColor: newContact?.isBusinessContact ? "purple" : "blue",
            };
            setIsOpenAddContact(false);
            navigateToContacts(newData);
          }}
        />
      )}
    </>
  );
};

export default SearchContacts;
