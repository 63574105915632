import React, { FC } from "react";
import { OutlineButton } from "../../components/button/OutlineButton";
import Button from "../../components/button/Button";

interface Props {
  isActive: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeletePopup: FC<Props> = ({ isActive, onDelete, onClose }) => {
  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-fit max-w-[90%]"}>
        <div className={"mb-4 text-base font-semibold text-gray-800"}>
          Delete this invoice?
        </div>
        <div className={"mb-4 text-sm text-gray-800"}>
          This will delete the invoice permanently and cannot be undone.
        </div>
        <div className={"mb-4 text-sm text-gray-800"}>
          Any payments made to this invoice will not be deleted.
        </div>
        <div className={"flex w-full justify-end gap-4"}>
          <OutlineButton
            colorType={"secondary"}
            label={"Cancel"}
            onClick={onClose}
            extraClasses={"normal-case"}
          />
          <Button
            colorType={"secondary"}
            label={"Delete"}
            onClick={onDelete}
            extraClasses={"normal-case"}
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
