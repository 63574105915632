import React, { MouseEventHandler } from "react";
import { match } from "ts-pattern";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import classNames from "classnames";
interface IButtonProps {
  id?: string;
  buttonType?: "button" | "submit";
  position?: "center" | "start" | "end";
  label: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  extraClasses?: string;
  size?: "custom" | "normal" | "small";
  customSize?: string;
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  radius?: "full" | "left" | "right";
  colorType?:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "neutral"
    | "warning"
    | "error"
    | "outline";
  isLoading?: boolean;
  fullWidth?: boolean;
}
const Button: React.FC<IButtonProps> = ({
  id,
  colorType = "secondary",
  buttonType,
  disabled,
  onClick,
  label,
  extraClasses,
  size = "normal",
  customSize,
  icon,
  children,
  position = "center",
  radius = "full",
  iconPosition = "left",
  isLoading,
  fullWidth,
}) => {
  const btnActiveClass: string = match(colorType)
    .with("primary", () => "btn-primary")
    .with("secondary", () => "bg-purple border-none")
    .with("error", () => "bg-[#F15252] border-none")
    .with("accent", () => "btn-accent")
    .with("ghost", () => "btn-ghost")
    .with("link", () => "btn-link")
    .with("warning", () => "btn-warning")
    .with("outline", () => "btn-outline")
    .with("neutral", () => "")
    .exhaustive();

  const buttonSize = match(size)
    .with("small", () => "px-[14px] py-[8px]")
    .with("normal", () => "px-4 py-[14px]")
    .with("custom", () => customSize)
    .exhaustive();

  const buttonContentPosition = match(position)
    .with("center", () => "justify-center")
    .with("start", () => "justify-start")
    .with("end", () => "justify-end")
    .exhaustive();

  const buttonRadius = match(radius)
    .with("full", () => "rounded-[8px]")
    .with("left", () => "rounded-l-[8px] rounded-r-[0px]")
    .with("right", () => "rounded-l-[0px] rounded-r-[8px]")
    .exhaustive();

  return (
    <button
      id={id}
      type={buttonType}
      className={classNames(
        "btn flex items-center gap-2 !font-notoSans text-sm font-medium hover:cursor-pointer",
        buttonContentPosition,
        buttonSize,
        buttonRadius,
        btnActiveClass,
        extraClasses ? extraClasses : "uppercase",
        fullWidth ? "w-full" : "w-fit",
      )}
      disabled={disabled}
      onClick={onClick}>
      {icon && iconPosition === "left" && <div className={"h-fit"}>{icon}</div>}
      {label && (
        <div className={"h-fit !font-notoSans normal-case"}>{label}</div>
      )}
      {children}
      {icon && iconPosition === "right" && (
        <div className={"h-fit"}>{icon}</div>
      )}
      {isLoading && (
        <div
          className={classNames(
            "h-5 w-5 animate-spin",
            colorType === "outline" ? "text-purple" : "text-white",
          )}>
          <AiOutlineLoading3Quarters size={20} />
        </div>
      )}
    </button>
  );
};

export default Button;
