import React, { FC, useContext, useEffect } from "react";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import {
  useAddOrganizationNoteMutation,
  useUpdateOrganizationNoteMutation,
} from "../../../slices/OrganizationNotesSlice";
import { IOrganizationContactNote } from "../../../types/types";
import { useParams } from "react-router-dom";

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: IOrganizationContactNote;
};

type FormData = {
  title: string;
  note: string;
};

export const OrganizationNoteModal: FC<Props> = ({
  isActive,
  onClose,
  defaultValues,
}) => {
  const { userInfoService } = useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { organizationId } = useParams();

  const [
    addOrganizationNote,
    {
      isLoading: isLoadingOrganizationAdd,
      isSuccess: isSuccessOrganizationAdd,
    },
  ] = useAddOrganizationNoteMutation();
  const [
    updateOrganizationNote,
    {
      isLoading: isLoadingOrganizationUpdate,
      isSuccess: isSuccessOrganizationUpdate,
    },
  ] = useUpdateOrganizationNoteMutation();

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: defaultValues,
  });

  const onSubmit = (data: FormData) => {
    if (
      (data.note || data.title) &&
      !isLoadingOrganizationAdd &&
      !isLoadingOrganizationUpdate
    ) {
      if (defaultValues) {
        updateOrganizationNote({
          ...defaultValues,
          note: data.note,
          title: data.title,
        });
      } else {
        addOrganizationNote({
          title: data.title,
          note: data.note,
          createdByUserRelayId: `${userInfoByEmail?.id}`,
          businessContactId: organizationId || "",
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccessOrganizationAdd || isSuccessOrganizationUpdate) {
      onClose();
    }
  }, [isSuccessOrganizationAdd, isSuccessOrganizationUpdate]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box p-0"}>
        <div
          className={
            "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
          }>
          {defaultValues ? "Update" : "Add"} note
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={"px-5 py-4"}>
          <div className={"mb-4"}>
            <input
              type={"text"}
              {...register("title", {})}
              className={"input input-bordered w-full"}
              placeholder={"Title"}
            />
          </div>
          <div>
            <textarea
              {...register("note", {})}
              className={"input input-bordered h-[200px] w-full"}
              placeholder={"Add a note"}
            />
          </div>
          <div className={"mt-4 flex justify-end gap-4"}>
            <Button
              label={defaultValues ? "Update" : "Add"}
              buttonType={"submit"}
              disabled={isLoadingOrganizationAdd || isLoadingOrganizationUpdate}
            />
            <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
          </div>
        </form>
      </div>
    </div>
  );
};
