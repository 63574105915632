import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button/Button";
import { LottieLoading } from "../../components/graphics/LottieLoading";

import {
  useDeleteNotificationMutation,
  useMarkAsReadNotificationMutation,
  useMarkAsUnreadNotificationMutation,
} from "../../slices/UserSlice";

import { INotification } from "../../types/types";
import { NotificationMessage } from "./NotificationMessage";
import { Card } from "../../components/card/Card";

type Props = {
  notifications: INotification[];
  isLoading: boolean;
  notificationType: "unread" | "read";
};

export const Notifications: React.FC<Props> = ({
  notifications,
  isLoading,
  notificationType,
}) => {
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState("");
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>(
    [],
  );

  const [markAsRead] = useMarkAsReadNotificationMutation();
  const [markAsUnread] = useMarkAsUnreadNotificationMutation();

  const [deleteNotification] = useDeleteNotificationMutation();

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedNotifications(
        notifications?.map((notification) => notification.id),
      );
    } else {
      setSelectedNotifications([]);
    }
  };

  const isSelectAll = () => {
    return notifications?.length
      ? selectedNotifications.length === notifications?.length
      : false;
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedNotifications((prev) => [...prev, id]);
    } else {
      setSelectedNotifications((prev) => prev.filter((item) => item !== id));
    }
  };

  return (
    <Card extraClasses={"overflow-visible"}>
      {isLoading ? (
        <div className={"py-10"}>
          <LottieLoading />
        </div>
      ) : (
        <div className={"flex flex-col"}>
          <div className={"flex justify-between"}>
            <div className={"px-4 py-2 font-semibold"}>
              {notificationType === "unread"
                ? "Unread notifications"
                : "Read notifications"}
            </div>
          </div>

          {!!notifications?.length && (
            <div className={"flex items-center gap-4 px-4 py-2"}>
              <div className={"h-5"}>
                <input
                  checked={isSelectAll()}
                  type={"checkbox"}
                  className={"checkbox checkbox-accent checkbox-sm"}
                  onChange={handleSelectAll}
                />
              </div>
              <div className={"cursor-default text-sm"}>Select all</div>

              <div className={"flex items-center gap-2"}>
                {selectedNotifications.length > 0 && (
                  <>
                    <div>
                      <Button
                        label={
                          notificationType === "unread"
                            ? "Mark as read"
                            : "Mark as unread"
                        }
                        onClick={() => {
                          if (notificationType === "unread") {
                            markAsRead(selectedNotifications);
                          } else {
                            markAsUnread(selectedNotifications);
                          }
                          setSelectedNotifications([]);
                        }}
                        size={"small"}
                        extraClasses={"normal-case max-h-[40px] min-h-[40px]"}
                      />
                    </div>
                    <div>
                      <Button
                        label={""}
                        icon={
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className={"text-base text-white"}
                          />
                        }
                        onClick={() => {
                          deleteNotification(selectedNotifications);
                          setSelectedNotifications([]);
                        }}
                        colorType={"error"}
                        size={"small"}
                        extraClasses={"normal-case max-h-[40px] min-h-[40px]"}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {!!notifications?.length ? (
            notifications.map((notification: INotification) => (
              <div
                key={notification.id}
                onClick={() => {
                  (notification?.jobTask?.jobId || notification?.jobId) &&
                    navigate(
                      `/work/${
                        notification?.jobId
                          ? notification?.jobId
                          : notification?.jobTask?.jobId
                      }`,
                    );
                  markAsRead([notification.id]);
                  setSelectedNotifications([]);
                }}
                onMouseEnter={() => setShowButton(notification.id)}
                onMouseLeave={() => setShowButton("")}
                className={
                  "flex cursor-pointer items-center justify-between gap-2 border-t-[1px] border-gray-300 px-4 py-2 hover:bg-gray-200"
                }>
                <NotificationMessage
                  notification={notification}
                  isChecked={selectedNotifications?.includes(notification?.id)}
                  onCheck={handleSelect}
                />
                <div className={"flex items-center gap-2"}>
                  <div className={"whitespace-nowrap text-sm text-gray-500"}>
                    {moment(notification.createdAt).format("MMM DD, YYYY")}
                  </div>
                  {showButton === notification.id && (
                    <>
                      <div>
                        <Button
                          label={
                            notificationType === "unread"
                              ? "Mark as read"
                              : "Mark as unread"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            notificationType === "unread"
                              ? markAsRead([notification.id])
                              : markAsUnread([notification.id]);
                          }}
                          size={"small"}
                          extraClasses={
                            "whitespace-nowrap max-h-[40px] min-h-[40px]"
                          }
                        />
                      </div>
                      <div>
                        <Button
                          label={""}
                          icon={
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className={"text-base text-white"}
                            />
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteNotification([notification.id]);
                          }}
                          colorType={"error"}
                          size={"small"}
                          extraClasses={"normal-case max-h-[40px] min-h-[40px]"}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={"py-10 text-center text-xl font-semibold"}>
              You don't have any notifications
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
