import classNames from "classnames";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import {
  NotificationTitle,
  NotificationTitleValue,
} from "../../../factories/notifications/notification-title.enum";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type Props = {
  title: NotificationTitle;
  count: number;
  value: NotificationTitleValue;
  isLoading: boolean;
};

export const NotificationsFilterItem: FC<Props> = ({
  title,
  count,
  value,
  isLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.get("filter") === value;

  return (
    <div
      onClick={() => setSearchParams({ filter: value })}
      className={classNames(
        "flex w-[215px] cursor-pointer items-center justify-between px-4 py-2 text-gray-500 hover:bg-gray-100",
        isActive && "bg-gray-100",
      )}>
      <div>{title}</div>
      {isLoading ? (
        <div className={"animate-spin"}>
          <AiOutlineLoading3Quarters size={24} />
        </div>
      ) : (
        !!count && (
          <div
            className={
              "flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs text-white"
            }>
            {count < 100 ? count : "99+"}
          </div>
        )
      )}
    </div>
  );
};
