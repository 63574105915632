import React, { FC } from "react";

type Props = {
  duration: string;
};

export const WorkTimeLog: FC<Props> = ({ duration }) => {
  return (
    <div>
      <div className={"flex h-8 items-center justify-end text-sm font-medium"}>
        {duration}
      </div>
    </div>
  );
};
