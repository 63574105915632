import React, { FC, useEffect, useRef, useState } from "react";
import { HiOutlineBars3, HiOutlineTrash } from "react-icons/hi2";
import { useDebounce } from "usehooks-ts";

import { IconButton } from "../../../components/button/IconButton";
import { BillingStatusType } from "../../../services/BillingService";

type Props = {
  handleUpdate: (status: BillingStatusType) => void;
  handleDelete: (status: BillingStatusType) => void;
  setIsButtonDisabled: (value: boolean) => void;
  isUpdating: boolean;
  status: BillingStatusType;
};

const BillingStatus: FC<Props> = ({
  status,
  isUpdating,
  handleUpdate,
  handleDelete,
  setIsButtonDisabled,
}) => {
  const colorRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState(status?.name || "");
  const [color, setColor] = useState(status?.color || "");

  const debouncedColor = useDebounce(color, 500);

  useEffect(() => {
    if (debouncedColor && debouncedColor !== status.color) {
      handleUpdate({ ...status, color: debouncedColor });
    }
  }, [debouncedColor]);

  return (
    <div className={"flex items-center gap-4"}>
      <HiOutlineBars3 className={"text-purple h-6 w-6"} />
      <div className={"flex items-center gap-4"}>
        <div className={"flex items-center"}>
          <div
            className={"h-6 w-6 cursor-pointer rounded-full"}
            style={{ backgroundColor: color }}
            onClick={() => {
              if (!isUpdating) {
                colorRef.current?.click();
              }
            }}
          />
          <input
            ref={colorRef}
            type={"color"}
            className={"h-0 w-0 opacity-0"}
            value={color}
            disabled={isUpdating}
            onChange={(e) => setColor(e.target.value)}
          />
        </div>
        <input
          type={"text"}
          className={"input input-bordered"}
          value={name}
          disabled={isUpdating}
          onFocus={() => setIsButtonDisabled(true)}
          onBlur={() => {
            setIsButtonDisabled(false);
            if (name !== status.name) {
              handleUpdate({ ...status, name });
            }
          }}
          onChange={(e) => setName(e.target.value)}
        />
        <IconButton
          colorType={"secondary"}
          IconSvg={HiOutlineTrash}
          size={"small"}
          onClick={() => handleDelete(status)}
        />
      </div>
    </div>
  );
};

export default BillingStatus;
