import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const DropdownsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"dropdowns"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"dropdown"}>
          <div tabIndex={0} className={"btn btn-primary m-1"}>
            Dropdown
          </div>
          <ul
            tabIndex={0}
            className={
              "dropdown-content menu rounded-box w-52 bg-base-100 p-2 shadow"
            }>
            <li>
              <a>Item 1</a>
            </li>
            <li>
              <a>Item 2</a>
            </li>
            <li>
              <a>Item 3</a>
            </li>
          </ul>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"end"}>
        <div className={"dropdown-end dropdown"}>
          <div tabIndex={0} className={"btn btn-primary m-1"}>
            Dropdown
          </div>
          <ul
            tabIndex={0}
            className={
              "dropdown-content menu rounded-box w-52 bg-base-100 p-2 shadow"
            }>
            <li>
              <a>Item 1</a>
            </li>
            <li>
              <a>Item 2</a>
            </li>
            <li>
              <a>Item 3</a>
            </li>
          </ul>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with nav"}>
        <div className={"navbar rounded-box mb-2 bg-base-300"}>
          <div className={"flex-1 px-2 lg:flex-none"}>
            <a className={"text-lg font-bold"}>ProCharted Inc.</a>
          </div>
          <div className={"flex flex-1 justify-end px-2"}>
            <div className={"flex items-stretch"}>
              <a className={"btn btn-ghost rounded-btn"}>Button</a>
              <div className={"dropdown-end dropdown"}>
                <div tabIndex={0} className={"btn btn-ghost rounded-btn"}>
                  Dropdown
                </div>
                <ul
                  tabIndex={0}
                  className={
                    "dropdown-content menu rounded-box w-52 bg-base-100 p-2 shadow"
                  }>
                  <li>
                    <a>Item 1</a>
                  </li>
                  <li>
                    <a>Item 2</a>
                  </li>
                  <li>
                    <a>Item 3</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with icon"}>
        A normal text and a helper dropdown
        <div className={"dropdown-end dropdown"}>
          <div
            tabIndex={0}
            className={"btn btn-circle btn-ghost btn-xs text-info"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              className={"inline h-5 w-5 stroke-current"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}
              />
            </svg>
          </div>
          <div
            tabIndex={0}
            className={
              "card dropdown-content compact rounded-box w-52 bg-base-100 shadow"
            }>
            <div className={"card-body"}>
              <h2 className={"card-title"}>You needed more info?</h2>
              <p>Here is more info you wanted. You are welcome!</p>
            </div>
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
