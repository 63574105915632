import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import AddContact from "../../../components/modal/addContact";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useActivateDeactivateMutation,
  useGetActiveCustomersQuery,
  useImportContactsMutation,
  useImportOrganizationsMutation,
} from "../../../slices/ContactsSlice";
import { FormSection } from "../../../support/FormSection";
import ImportContacts from "../modals/ImportContacts";
import { navigateToContact } from "../utils/navigateToContacts";
import { ComposeEmailModal } from "../modals/ComposeEmailModal";
import { CustomerModel } from "../../../factories/customers/models/customerModel";
import { IoMailOutline } from "react-icons/io5";
import { CiImport } from "react-icons/ci";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ExcelExport } from "../../../utils/ExportData";
import { Dropdown } from "../../../components/dropdown/new-dropdown/Dropdown";
import { HiEllipsisVertical } from "react-icons/hi2";
import { BsArchive } from "react-icons/bs";
import { useGetTagsQuery } from "../../../slices/ContactTagsSlice";
import CustomSelect from "../../../components/select/Select";
import { SelectOptionType } from "../../../types/types";

export type Filters = {
  tagsValues: SelectOptionType[];
};
export const ActiveContacts: FC = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);

  const { data: tags, isFetching } = useGetTagsQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });

  const [importContacts, { isLoading: isLoadingImportContacts }] =
    useImportContactsMutation();
  const [importOrganizations, { isLoading: isLoadingImportOrganizations }] =
    useImportOrganizationsMutation();
  const [deactivateContacts] = useActivateDeactivateMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCompose, setIsOpenCompose] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [customers, setCustomers] = useState<CustomerModel[]>([]);
  const [tagValue, setTagValue] = useState("");
  const [filters, setFilters] = useState<Filters>({
    tagsValues: [],
  });

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetActiveCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      isActive: true,
      userId: user.id,
      body: filters.tagsValues.map((tag) => tag.value) || [],
    });
  const contactsCount: number = contacts?.length || 0;
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {}, [contacts?.map((contact) => contact.customerTag)]);

  const handleImport = (file: File, variant: "individual" | "organization") => {
    if (file) {
      const formData = new FormData();
      formData.append("formFile", file);

      if (variant === "individual") {
        importContacts({
          body: formData,
          locationId: user?.org?.locations[0]?.id || "",
        });
      }

      if (variant === "organization") {
        importOrganizations({
          body: formData,
          locationId: user?.org?.locations[0]?.id || "",
        });
      }
    }
  };

  const tagOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (tags) {
      tags.forEach((tag) => {
        options.push({
          value: tag.id,
          label: tag?.tagName,
        });
      });
    }

    return options;
  };

  if (isLoadingContacts) {
    return <LottieLoading />;
  }

  return (
    <>
      <FormSection name={""} showHeading={false}>
        <div className={"flex justify-between"}>
          <div className={"flex items-center gap-10  pt-[10px]"}>
            <div className={"text-lg font-bold"}>Active ({contactsCount})</div>
            <Button
              buttonType={"button"}
              onClick={openModal}
              label={"Add Contact"}
              size={"small"}
            />
            <div className={"mt-[-25px] w-[200px]"}>
              <div className={"pb-1 text-base"}>Tags</div>
              <CustomSelect
                options={tagOptions()}
                value={filters.tagsValues}
                onChange={(newValue) => {
                  const value = newValue as SelectOptionType[];
                  setFilters((prev) => ({ ...prev, tagsValues: value }));
                }}
                inputValue={tagValue}
                onChangeInput={setTagValue}
                isMulti
                isLoading={isFetching}
              />
            </div>
          </div>
          <div className={"flex gap-4"}>
            <div className={"flex items-center"}>
              <IoMailOutline
                data-tooltip-id="tooltip-compose"
                onClick={() => {
                  setIsOpenCompose(true);
                }}
                color={"#7c66f0"}
                size={"25px"}
                className={"flex cursor-pointer items-center focus:outline-0"}
              />
            </div>
            <ReactTooltip
              id="tooltip-compose"
              place="bottom"
              content="Compose email"
              className={"!rounded-[8px] !p-2 !text-sm"}
            />
            <div className={"flex items-center"}>
              <ExcelExport
                data={
                  customers.length
                    ? customers.map((customer) => ({
                        Contact: customer.name,
                        "Phone Number": customer.phoneNumber || "",
                        "Email Address": customer.email || "",
                        "Contact Type": customer.contactType,
                        "Open Work": customer.openWorkCounts,
                      }))
                    : contacts?.map((contact) => ({
                        Contact: contact.name,
                        "Phone Number": contact.phoneNumber || "",
                        "Email Address": contact.email || "",
                        "Contact Type": contact.contactType,
                        "Open Work": contact.openWorkCounts,
                      })) || []
                }
                fileName={"Contacts"}
              />
            </div>
            <div className={"ml-[-8px] flex items-center"}>
              <Dropdown
                triggerElement={
                  <div>
                    <Button
                      label={""}
                      colorType={"ghost"}
                      size={"custom"}
                      icon={<HiEllipsisVertical size={24} />}
                      extraClasses={
                        "min-h-fit h-fit w-fit p-1 flex items-center"
                      }
                    />
                  </div>
                }>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    className={
                      "flex cursor-pointer items-center gap-1 rounded-[5px] bg-white p-2 text-sm hover:bg-gray-300"
                    }
                    onClick={() => {
                      if (
                        !isLoadingImportContacts &&
                        !isLoadingImportOrganizations
                      ) {
                        setIsOpenImport(true);
                      }
                    }}>
                    <CiImport
                      color={"#7c66f0"}
                      size={"20px"}
                      strokeWidth={0.5}
                      className={
                        "flex cursor-pointer items-center focus:outline-0"
                      }
                    />{" "}
                    Import
                  </div>
                  <div
                    className={
                      "flex cursor-pointer items-center gap-1 rounded-[5px] bg-white p-2 text-sm hover:bg-gray-300"
                    }
                    onClick={() =>
                      deactivateContacts({
                        activateCustomer: false,
                        body: customers.map((item) => item.id),
                      })
                    }>
                    <BsArchive
                      data-tooltip-id="tooltip-archive"
                      color={"#7c66f0"}
                      size={"20px"}
                      className={
                        "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                      }
                    />{" "}
                    Archive
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <AddContact isOpen={isOpen} closeModal={closeModal} isSearch={false} />
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                <input
                  className={
                    "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                  }
                  onChange={(e) =>
                    e.target.checked
                      ? setCustomers(contacts || [])
                      : setCustomers([])
                  }
                  checked={(contacts?.length || 0) === customers.length}
                  type="checkbox"
                />
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Contact
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Phone Number
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Email Address
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Contact type
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Open work
              </th>
              <th
                className={"p-4 text-start text-sm normal-case text-gray-400"}>
                Tags
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts?.map((item) => (
              <tr
                key={item.id}
                className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
                <td className={"w-[30px] pl-4"}>
                  <input
                    className={
                      "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                    }
                    onChange={(e) =>
                      !e.target.checked
                        ? setCustomers((prev) =>
                            prev.filter((i) => i.id !== item.id),
                          )
                        : setCustomers((prev) => [...prev, item])
                    }
                    type="checkbox"
                    checked={customers.some((i) => i.id === item.id)}
                  />
                </td>
                <td className={"max-w-[200px] px-4 py-2"}>
                  <div className={"flex items-center gap-3 text-sm"}>
                    <AvatarPlaceholder
                      type={item?.avatarColor}
                      size={"extra-small"}
                      label={item?.initials || ""}
                    />
                    <div
                      onClick={() =>
                        navigate(
                          navigateToContact({
                            type: item.type,
                            id: item?.businessContactId || "",
                            customerId: item?.id || "",
                          }),
                        )
                      }
                      className={
                        "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                      }>
                      {item.name}
                    </div>
                  </div>
                </td>
                <td className={"px-4 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item.phoneNumber}
                  </div>
                </td>
                <td className={"max-w-[200px] px-4 py-2"}>
                  <div
                    className={
                      "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                    }>
                    <a href={`mailto:${item.email}`}>{item.email}</a>
                  </div>
                </td>
                <td className={"px-4 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item.contactType}
                  </div>
                </td>
                <td className={"px-4 py-2"}>
                  <div
                    className={
                      "text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.openWorkCounts}
                  </div>
                </td>
                <td className={"px-4 py-2"}>
                  <div
                    className={
                      "w-[200px] text-sm font-semibold normal-case text-gray-800"
                    }>
                    {item?.customerTag.join(", ")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </FormSection>
      {isOpenCompose && (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
          bulk={true}
          customersEmail={customers.map((customer) => ({
            email: customer.email,
          }))}
        />
      )}
      {isOpenImport && (
        <ImportContacts
          handleImport={(file, variant) => handleImport(file, variant)}
          isOpen={isOpenImport}
          closeModal={() => setIsOpenImport(false)}
        />
      )}
    </>
  );
};
