import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import {
  ContactNoteModel,
  CreateContactNoteModel,
} from "../factories/contacts/models/contact-note.models";

export const contactNotesSlice = createApi({
  reducerPath: "contactNotesSlice",
  tagTypes: ["ContactNote"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getContactNotes: build.query<
      ContactNoteModel[],
      { contactId: string; orgId: string }
    >({
      query: ({ contactId, orgId }) => ({
        url: "api/contact-notes/" + contactId,
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: ContactNoteModel[]) => {
        const sortedData = res?.sort(
          (a, b) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
        );

        const pinnedNotes = sortedData.filter((note) => note.isPinned);
        const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

        return [...pinnedNotes, ...unpinnedNotes];
      },
      providesTags: () => ["ContactNote"],
    }),
    addContactNote: build.mutation<unknown, CreateContactNoteModel>({
      query: (body) => ({
        url: "api/contact-notes/add-contact-note",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ContactNote"],
    }),
    updateContactNote: build.mutation<unknown, ContactNoteModel>({
      query: (body) => ({
        url: "api/contact-notes/update-contact-note",
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["ContactNote"],
    }),
    pinContactNote: build.mutation<unknown, { id: string; isPinned: boolean }>({
      query: ({ id, isPinned }) => ({
        url: "api/contact-notes/mark-pinned",
        method: "PUT",
        params: { contactNoteId: id, isPinned: isPinned },
      }),
      invalidatesTags: () => ["ContactNote"],
    }),
    deleteContactNote: build.mutation<unknown, ContactNoteModel>({
      query: (body) => ({
        url: "api/contact-notes",
        method: "DELETE",
        body,
      }),
      invalidatesTags: () => ["ContactNote"],
    }),
  }),
});
export const {
  useGetContactNotesQuery,
  useAddContactNoteMutation,
  useUpdateContactNoteMutation,
  usePinContactNoteMutation,
  useDeleteContactNoteMutation,
} = contactNotesSlice;
