import React, { ReactNode, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";

interface EditDotsProps {
  variant?: "props" | "default";
  extraPosition?: string;
  children:
    | ReactNode
    | ((props: { setIsOpen: (isOpen: boolean) => void }) => ReactNode);
}

const EditDots: React.FC<EditDotsProps> = ({
  children,
  variant = "default",
  extraPosition,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const editDotsRef = useRef<HTMLDivElement>(null);
  const clickOutside = () => {
    setIsOpen(false);
  };
  useOnClickOutside(editDotsRef, clickOutside);

  const childProps = {
    setIsOpen: setIsOpen,
  };

  return (
    <div className={"relative flex items-end"} ref={editDotsRef}>
      <div
        className={
          "flex h-fit cursor-pointer flex-col justify-between rounded-[8px] px-1 py-[5px] hover:bg-gray-200"
        }
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(!isOpen);
        }}>
        <BsThreeDotsVertical className={"h-6"} />
      </div>
      {isOpen && (
        <div
          className={classNames(
            "absolute  z-[2] h-fit rounded-[5px] border-[1px] border-[#E8E9EA] bg-white",
            extraPosition ? extraPosition : "right-full top-0",
          )}>
          {variant === "props" && typeof children === "function"
            ? children(childProps)
            : children}
        </div>
      )}
    </div>
  );
};

export default EditDots;
