import React from "react";

import { AddStackSvg } from "../../components/svg/AddStackSvg";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const BreadcrumbsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"breadcrumbs"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"breadcrumbs text-sm"}>
          <ul>
            <li>
              <a>Home</a>
            </li>
            <li>
              <a>Documents</a>
            </li>
            <li>Add Document</li>
          </ul>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with icon"}>
        <div className={"breadcrumbs text-sm"}>
          <ul>
            <li>
              <a>
                <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
                Home
              </a>
            </li>
            <li>
              <a>
                <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
                Documents
              </a>
            </li>
            <li>
              <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
              Add Document
            </li>
          </ul>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"max width mobile"}>
        <div className={"breadcrumbs max-w-xs text-sm"}>
          <ul>
            <li>If you set max-width</li>
            <li>or the list</li>
            <li>gets larger than</li>
            <li>the container</li>
            <li>it will scroll</li>
          </ul>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
