import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { CreateOrganizationNoteModel } from "../factories/contacts/models/contact-note.models";
import { IOrganizationContactNote } from "../types/types";

export const organizationNotesSlice = createApi({
  reducerPath: "organizationNotesSlice",
  tagTypes: ["OrganizationNote"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getOrganizationNotes: build.query<
      IOrganizationContactNote[],
      { organizationId: string; orgId: string }
    >({
      query: ({ organizationId, orgId }) => ({
        url:
          "api/business-contact-notes/get-by-business-contact-id/" +
          organizationId,
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: IOrganizationContactNote[]) => {
        const sortedData = res?.sort(
          (a, b) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
        );

        const pinnedNotes = sortedData.filter((note) => note.isPinned);
        const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

        return [...pinnedNotes, ...unpinnedNotes];
      },
      providesTags: () => ["OrganizationNote"],
    }),
    addOrganizationNote: build.mutation<unknown, CreateOrganizationNoteModel>({
      query: (body) => ({
        url: "api/business-contact-notes/add-business-contact-note",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["OrganizationNote"],
    }),
    updateOrganizationNote: build.mutation<unknown, IOrganizationContactNote>({
      query: (body) => ({
        url: "api/business-contact-notes/update-business-contact-note",
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["OrganizationNote"],
    }),
    pinOrganizationNote: build.mutation<
      unknown,
      { id: string; isPinned: boolean }
    >({
      query: ({ id, isPinned }) => ({
        url: "api/business-contact-notes/mark-pinned",
        method: "PUT",
        params: { businessContactNoteId: id, isPinned: isPinned },
      }),
      invalidatesTags: () => ["OrganizationNote"],
    }),
    deleteOrganizationNote: build.mutation<unknown, IOrganizationContactNote>({
      query: (body) => ({
        url: "api/business-contact-notes",
        method: "DELETE",
        body,
      }),
      invalidatesTags: () => ["OrganizationNote"],
    }),
  }),
});
export const {
  useGetOrganizationNotesQuery,
  useAddOrganizationNoteMutation,
  useUpdateOrganizationNoteMutation,
  usePinOrganizationNoteMutation,
  useDeleteOrganizationNoteMutation,
} = organizationNotesSlice;
