import { FC, useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Pages } from "../../app/constants/routes";
import { generateRoutePath } from "../../app/constants/routes/utils";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";

export const ContactsView: FC = () => {
  useEffect(() => {
    document.title = GetPageTitle(Pages.contacts.label);
  }, []);

  const { active, archived } = Pages.contacts.children;

  const ActiveContactsComponent = active.component;
  const ArchivedContactsComponent = archived.component;

  return (
    <PageLayout title={Pages.contacts.label}>
      <div className={"tabs my-4"}>
        <CustomLink to={generateRoutePath([Pages.contacts.path, active.path])}>
          {active.label}
        </CustomLink>
        <CustomLink
          to={generateRoutePath([Pages.contacts.path, archived.path])}>
          {archived.label}
        </CustomLink>
      </div>
      <Routes>
        <Route
          index
          element={<Navigate to={"./" + active.path} replace={true} />}
        />
        <Route path={active.path} element={<ActiveContactsComponent />} />
        <Route path={archived.path} element={<ArchivedContactsComponent />} />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>
      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
