import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Email } from "../../../../factories/emails/models/emails.models";
import { useAppSelector } from "../../../../redux/redux";
import { useGetWorkEmailQuery } from "../../../../slices/EmailsSlice";
import { AddEmailModal } from "../../../contacts/modals/AddEmailModal";
import { ComposeEmailModal } from "../../../contacts/modals/ComposeEmailModal";
import { EmailsListView } from "../../../emails/EmailsListView";

export const WorkEmails: FC = () => {
  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenCompose, setIsOpenCompose] = useState(false);
  const [selectedMail, setSelectedMail] = useState<Email | null>(null);

  const { data: emails, isFetching: isLoading } = useGetWorkEmailQuery({
    userId: user?.id || "",
    workId: workId || "",
  });

  return (
    <>
      <EmailsListView
        emails={emails?.data || []}
        isLoading={isLoading}
        hidePages
        selectedMail={selectedMail}
        setSelectedMail={setSelectedMail}
        work={true}
      />
      {isOpenAdd && (
        <AddEmailModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={user?.id || ""}
          selectedMail={selectedMail}
        />
      )}
      {isOpenCompose && (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
        />
      )}
    </>
  );
};
