import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const ProgressBarsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Progress bars"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"phone artboard space-y-2 p-6"}>
          <progress
            className={"progress progress-primary"}
            value={"0"}
            max={"100"}
          />
          <progress
            className={"progress progress-primary"}
            value={"10"}
            max={"100"}
          />
          <progress
            className={"progress progress-primary"}
            value={"40"}
            max={"100"}
          />
          <progress
            className={"progress progress-primary"}
            value={"70"}
            max={"100"}
          />
          <progress
            className={"progress progress-primary"}
            value={"100"}
            max={"100"}
          />
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
