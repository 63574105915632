import React, { FC, useState } from "react";
import { HiPlus } from "react-icons/hi2";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import ContactNoteSvg from "../../../../../../../../components/svg/ContactNoteSvg";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import {
  useDeleteContactNoteMutation,
  useGetContactNotesQuery,
} from "../../../../../../../../slices/ContactNotesSlice";
import { ContactNoteView } from "./ContactNoteView";
import { IContactProfile } from "../../../../../../../../types/types";
import { inactiveStatusId } from "../../../../../../../../constants/constants";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { ContactNoteModal } from "../../../../../../modals/ContactNoteModal";
import { ContactNoteModel } from "../../../../../../../../factories/contacts/models/contact-note.models";

type Props = {
  contact: IContactProfile;
};

export const ContactNotesWidget: FC<Props> = ({ contact }) => {
  const { data: contactNotes, isFetching } = useGetContactNotesQuery({
    orgId: getLinkedFirm()?.orgId,
    contactId: contact?.contactId || "",
  });
  const [deleteContactNote] = useDeleteContactNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [currentNote, setCurrentNote] = useState<ContactNoteModel | null>(null);
  const isActive = contact?.contactStatusTypeEnumId !== inactiveStatusId;

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px] overflow-visible"}>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Notes</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={handleOpenAddModal}
            />
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !contactNotes?.length ? (
              <div className={"p-6 pt-0"}>
                <NoItems
                  icon={<ContactNoteSvg />}
                  message={"There are no note items"}
                  addButtonMessage={"Add Note"}
                  openAdd={() =>
                    !isActive ? setIsOpenError(true) : handleOpenAddModal()
                  }
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-y-auto"}>
                {contactNotes?.map((note) => (
                  <ContactNoteView
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteContactNote={deleteContactNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <ContactNoteModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={contact?.contactId || ""}
          defaultValues={currentNote || undefined}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
