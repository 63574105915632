import React, { FC } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { CiExport } from "react-icons/ci";
import { Tooltip as ReactTooltip } from "react-tooltip";

type Props = {
  data: any[];
  fileName: string;
};
export const ExcelExport: FC<Props> = ({ data, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <div onClick={exportToExcel}>
      <div className={"flex items-center"}>
        <CiExport
          data-tooltip-id="tooltip-export"
          className={"flex cursor-pointer items-center focus:outline-0"}
          color={"#7c66f0"}
          size={"25px"}
          strokeWidth={0.5}
        />
      </div>
      <ReactTooltip
        id="tooltip-export"
        place="bottom"
        content="Export"
        className={"z-10 !rounded-[8px] !p-2 !text-sm"}
      />
    </div>
  );
};
