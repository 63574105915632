import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import Button from "../../../components/button/Button";
import { FormSection } from "../../../support/FormSection";
import BillingLogo from "./BillingLogo";
import InvoicesStatuses from "./InvoicesStatuses";
import PaymentMethods from "./PaymentMethods";
import PaymentsStatuses from "./PaymentsStatuses";
import Services from "./Services";
import TaxRate from "./TaxRate";

enum Tabs {
  DEFAULT = "Default",

  SERVICES = "Services",
  PAYMENT_METHOD = "Payment method",
  TAX_RATE = "Tax rate",
  INVOICE_STATUSES = "Invoice labels",
  PAYMENT_STATUSES = "Payment labels",
  LOGO = "Logo",
}

const options = [
  Tabs.SERVICES,
  Tabs.PAYMENT_METHOD,
  Tabs.TAX_RATE,
  Tabs.INVOICE_STATUSES,
  Tabs.PAYMENT_STATUSES,
  Tabs.LOGO,
];

const BillingView = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.DEFAULT);

  const renderContent = () => {
    switch (activeTab) {
      case Tabs.SERVICES:
        return <Services />;
      case Tabs.PAYMENT_METHOD:
        return <PaymentMethods />;
      case Tabs.TAX_RATE:
        return <TaxRate />;
      case Tabs.INVOICE_STATUSES:
        return <InvoicesStatuses />;
      case Tabs.PAYMENT_STATUSES:
        return <PaymentsStatuses />;
      case Tabs.LOGO:
        return <BillingLogo onClose={() => setActiveTab(Tabs.DEFAULT)} />;
      default:
        return options?.map((option) => (
          <div
            key={option}
            className={
              "link-primary w-fit cursor-pointer text-sm font-semibold hover:underline"
            }
            onClick={() => setActiveTab(option)}>
            {option}
          </div>
        ));
    }
  };

  const renderTitle = () => {
    if (activeTab === Tabs.DEFAULT) {
      return "";
    } else {
      return (
        <div className={"flex items-center gap-2"}>
          <Button
            label={""}
            onClick={() => setActiveTab(Tabs.DEFAULT)}
            size={"custom"}
            icon={
              <FontAwesomeIcon icon={faChevronLeft} className={"text-[16px]"} />
            }
            colorType={"ghost"}
            extraClasses={"h-full min-h-fit px-2 py-2"}
          />
          <div className={"text-lg font-bold"}>{activeTab}</div>
        </div>
      );
    }
  };

  return (
    <FormSection name={""} showHeading={false}>
      {renderTitle()}
      {renderContent()}
    </FormSection>
  );
};

export default BillingView;
