import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const NotificationSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          fill={"none"}
          d={
            "M10 18H14C14.31 18.41 14.5 18.94 14.5 19.5C14.5 20.88 13.38 22 12 22C10.62 22 9.5 20.88 9.5 19.5C9.5 18.94 9.69 18.41 10 18Z"
          }
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          fill={"none"}
          d={
            "M10.414 5.21245C10.9193 5.07396 11.4512 5 12 5C12.5488 5 13.0807 5.07396 13.586 5.21245C13.8522 4.87379 14 4.4566 14 4C14 2.9 13.1 2 12 2C10.9 2 10 2.9 10 4C10 4.4566 10.1478 4.87379 10.414 5.21245Z"
          }
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          fill={"none"}
          d={
            "M6 14L4 18H20L18 14V11C18 7.69 15.31 5 12 5C8.69 5 6 7.69 6 11V14Z"
          }
        />
      </svg>
      {/*<svg*/}
      {/*  xmlns={"http://www.w3.org/2000/svg"}*/}
      {/*  fill={"none"}*/}
      {/*  viewBox={"0 0 24 24"}*/}
      {/*  className={`inline-block ${sizeClassNames} fill-none stroke-current`}>*/}
      {/*  <path*/}
      {/*    strokeLinecap={"round"}*/}
      {/*    strokeLinejoin={"round"}*/}
      {/*    strokeWidth={"2"}*/}
      {/*    d={*/}
      {/*      "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"*/}
      {/*    }*/}
      {/*  />*/}
      {/*</svg>*/}
    </>
  );
};
