import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const SearchSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      className={`inline-block ${sizeCx} fill-none stroke-current`}>
      <path
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={"2"}
        d={"M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"}
      />
    </svg>
  );
};
