import React from "react";

interface Props {
  close: () => void;
}

const Menu: React.FC<Props> = ({ close, children }) => {
  return (
    <div
      style={{ marginTop: 0, marginBottom: 0 }}
      className={
        "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-black/20"
      }
      onClick={close}>
      <div
        className={"w-[400px] rounded-[16px] bg-white p-8"}
        onClick={(event) => event.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Menu;
