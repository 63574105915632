import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice, updateTimer } from "../../services/TimerService";
import AddTimer from "./AddTimer";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import moment from "moment/moment";
import { formatTime } from "../../constants/constants";

const Timer = () => {
  const today = moment().toISOString();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { timer } = useAppSelector((state) => state.timerReducer);
  const { setStatusTimer, timerCalculate } = timerSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      timer.timerStatus === "started" && dispatch(timerCalculate(1));
    }, 1000);
    return () => clearInterval(interval);
  }, [timer.timerStatus]);

  const [isOpenAddTimer, setIsOpenAddTimer] = useState(false);
  const closeAddTimer = () => {
    setIsOpenAddTimer(false);
  };

  const onClickPauseTimer = () => {
    dispatch(
      updateTimer({
        ...timer,
        assignedUserId: null,
        timerStatus: "paused",
        assignedUserRelayId: userInfoByEmail!.id,
      }),
    );
    dispatch(setStatusTimer({ timerStatus: "paused" }));
  };
  const onClickStopTimer = () => {
    dispatch(
      updateTimer({
        ...timer,
        assignedUserId: null,
        timerStatus: "paused",
        assignedUserRelayId: userInfoByEmail!.id,
      }),
    );
    dispatch(setStatusTimer({ timerStatus: "paused" }));
    setIsOpenAddTimer(true);
  };

  return (
    <>
      <div className={"mr-[32px]  w-[140px] "}>
        <div className={"flex items-center justify-between"}>
          <div className={"text-white"}>
            {formatTime(timer.duration, {
              isHours: true,
              isMinutes: true,
              isSeconds: true,
            })}
          </div>
          <div
            onClick={() =>
              timer.timerStatus === "paused"
                ? dispatch(setStatusTimer({ timerStatus: "started" }))
                : onClickPauseTimer()
            }
            className={
              "flex h-[25px] w-[25px] items-center justify-center rounded-full bg-blue-500 text-[14px] text-white"
            }>
            {timer.timerStatus === "paused" ? (
              <FontAwesomeIcon icon={faPlay} />
            ) : (
              <FontAwesomeIcon icon={faPause} />
            )}
          </div>
          <div
            onClick={onClickStopTimer}
            className={
              "flex h-[25px] w-[25px] items-center justify-center rounded-full bg-red-500 text-[14px] text-white"
            }>
            <FontAwesomeIcon icon={faStop} />
          </div>
        </div>
      </div>
      {isOpenAddTimer && (
        <AddTimer isOpen={isOpenAddTimer} closeModal={closeAddTimer} />
      )}
    </>
  );
};

export default Timer;
