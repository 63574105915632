import * as Xstate from "@xstate/react";
import React from "react";
import { useForm, useWatch } from "react-hook-form";

import {
  getCurrency,
  getRounded,
  validateDecimalNumber,
} from "../../../app/commonOps/CommonNumberOps";
import { TW_SIZES } from "../../../app/constants/CssConstants";
import { LABELS } from "../../../app/constants/TextConstants";
import { PdfTotalsDataT } from "../../../app/stateMachines/BankDocDetailsMachine";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { AlertNotice } from "../../../components/alerts/AlertNotice";
import { FormSection } from "../../../support/FormSection";

const getMatchClass = (match: boolean) =>
  match ? TW_SIZES.colors.textSuccess : TW_SIZES.colors.textError;

const CX = {
  currencyLabel: "text-lg font-normal capitalize",
  currencyValue: "text-right text-lg font-semibold",
};

type CurrencyValueColumnProps = {
  value: number;
  classNames?: string;
};

const CurrencyValueColumnView: React.FC<CurrencyValueColumnProps> = (props) => {
  const { value, classNames } = props;

  return (
    <div className={"flex items-center justify-end space-x-2"}>
      <div className={`${CX.currencyValue} ${classNames}`}>
        {getCurrency(value)}
      </div>
    </div>
  );
};

const ExtractedTotalsReviewInfoPanelView: React.FC<unknown> = () => {
  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const [bankDocDetailsState] = Xstate.useActor(bankDocDetailsService);
  const { matcher, extractedTotalsData, isCreditCard } =
    bankDocDetailsState.context;

  if (!extractedTotalsData) return null;
  const { credits, debits } = extractedTotalsData;

  const creditsLabel = isCreditCard ? "charges" : "payments";
  const debitsLabel = isCreditCard ? "payments" : "deposits";
  return (
    <>
      <div className={"grid grid-cols-2 items-center"}>
        <div className={CX.currencyLabel}>{debitsLabel}</div>
        <CurrencyValueColumnView
          classNames={getMatchClass(matcher.endingEqual)}
          value={debits}
        />
      </div>
      <div className={"grid grid-cols-2 items-center"}>
        <div className={CX.currencyLabel}>{creditsLabel}</div>
        <CurrencyValueColumnView
          classNames={getMatchClass(matcher.endingEqual)}
          value={credits}
        />
      </div>
    </>
  );
};

const PdfBalancesReviewInfoPanelView: React.FC<unknown> = () => {
  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const [bankDocDetailsState] = Xstate.useActor(bankDocDetailsService);
  const { pdfTotalsData } = bankDocDetailsState.context;

  // RHF - pdf totals
  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm<PdfTotalsDataT>({
    defaultValues: pdfTotalsData,
    mode: "onChange",
  });

  const startingWatch = useWatch({
    control,
    name: "starting",
  });
  const endingWatch = useWatch({
    control,
    name: "ending",
  });

  React.useEffect(() => {
    const data = getValues();
    bankDocDetailsService.send("updatePdfTotals", {
      pdfTotalsData: data,
    });
  }, [startingWatch, endingWatch, getValues, bankDocDetailsService]);

  return (
    <>
      <div className={"grid grid-cols-2 items-center"}>
        <div className={CX.currencyLabel}>Starting Balance</div>
        <div className={"flex flex-col"}>
          <input
            className={"input input-bordered input-sm"}
            {...register("starting", {
              required: LABELS.required,
              validate: {
                positive: validateDecimalNumber,
              },
              setValueAs: (v) => getRounded(v),
            })}
          />
          {errors?.starting?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.starting?.message}
            </span>
          )}
        </div>
      </div>
      <div className={"grid grid-cols-2 items-center"}>
        <div className={CX.currencyLabel}>Ending Balance</div>
        <div className={"flex flex-col"}>
          <input
            className={"input input-bordered input-sm"}
            {...register("ending", {
              required: LABELS.required,
              validate: {
                positive: validateDecimalNumber,
              },
              setValueAs: (v) => getRounded(v),
            })}
          />
          {errors?.ending?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.ending?.message}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const DifferenceReviewInfoPanelView: React.FC<unknown> = () => {
  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const [bankDocDetailsState] = Xstate.useActor(bankDocDetailsService);
  const { difference, matcher } = bankDocDetailsState.context;

  return (
    <>
      <div className={"grid grid-cols-2 items-center"}>
        <div className={CX.currencyLabel}>Cleared balance</div>
        <CurrencyValueColumnView
          classNames={getMatchClass(matcher.endingEqual)}
          value={difference.calcEnding}
        />
      </div>

      {matcher.endingEqual ? (
        <div className={"grid-col-1 flex grid items-center justify-center"}>
          <AlertNotice label={"matched!"} />
        </div>
      ) : (
        <div className={"grid grid-cols-2 items-center"}>
          <div className={CX.currencyLabel}>Difference</div>
          <CurrencyValueColumnView
            classNames={getMatchClass(matcher.endingEqual)}
            value={difference.endingDiff}
          />
        </div>
      )}
    </>
  );
};

export const BankDocOutputReviewInfoPanelsView: React.FC<unknown> = ({
  children,
}) => {
  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const [state] = Xstate.useActor(bankDocDetailsService);
  const { pdfTotalsData } = state.context;

  if (!pdfTotalsData) return null;

  const panelContainerBaseCx =
    "col-span-1 px-4 border-b-2 xl:border-b-0 xl:border-r-2";
  const panelContainerEndCx = `${panelContainerBaseCx} border-b-0 xl:border-r-0`;
  const panelBaseCx = "mx-auto grid grid-cols-1 gap-4 xl:max-w-md";

  return (
    <div className={"z-30 grid grid-cols-1 border-b-2 xl:sticky xl:top-0.5"}>
      <FormSection name={"Summary"} viewFormat={"compact"} showHeading={false}>
        <div className={"grid grid-cols-1 gap-4 xl:grid-cols-4"}>
          <div className={panelContainerBaseCx}>
            <div className={panelBaseCx}>
              <PdfBalancesReviewInfoPanelView />
            </div>
          </div>

          <div className={panelContainerBaseCx}>
            <div className={panelBaseCx}>
              <ExtractedTotalsReviewInfoPanelView />
            </div>
          </div>

          <div className={panelContainerBaseCx}>
            <div className={panelBaseCx}>
              <DifferenceReviewInfoPanelView />
            </div>
          </div>

          <div className={`${panelContainerEndCx}`}>
            <div className={`${panelBaseCx} xl:max-w-xl`}>{children}</div>
          </div>
        </div>
      </FormSection>
    </div>
  );
};
