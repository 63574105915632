import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

import DropPopup from "../../../components/DropPopup";
import { IndividualSvg } from "../../../components/svg/IndividualSvg";
import { OrganizationSvg } from "../../../components/svg/OrganizationSvg";

interface IImportContactsProps {
  isOpen: boolean;
  closeModal: () => void;
  handleImport: (file: File, variant: "individual" | "organization") => void;
}
const ImportContacts: React.FC<IImportContactsProps> = ({
  isOpen,
  closeModal,
  handleImport,
}) => {
  const [isImportIndividual, setIsImportIndividual] = useState(false);
  const [isImportOrganization, setIsImportOrganization] = useState(false);
  const closeImportIndividualPopup = () => {
    setIsImportIndividual(false);
  };

  const openImportIndividualPopup = () => {
    setIsImportIndividual(true);
  };
  const closeImportOrganizationPopup = () => {
    setIsImportOrganization(false);
  };

  const openImportOrganizationPopup = () => {
    setIsImportOrganization(true);
  };
  const importIndividualPopup = (
    <>
      <div
        onClick={openImportIndividualPopup}
        className={
          "height-190 card card-body card-bordered my-6 cursor-pointer border-blue-500 bg-base-100 py-6 text-center"
        }>
        <h2>
          <b>Individual</b>
        </h2>
        <IndividualSvg width={75} height={75} className={"img-center-align"} />
      </div>
      <Transition appear show={isImportIndividual} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeImportIndividualPopup}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-[548px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                  }>
                  <DropPopup
                    title={"Import contacts"}
                    withTemplate={true}
                    close={closeImportIndividualPopup}
                    onFileDrop={(file) => handleImport(file, "individual")}
                    variant={"individual"}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  const importOrganizationPopup = (
    <>
      <div
        onClick={openImportOrganizationPopup}
        className={
          "height-190 card card-body card-bordered my-6 cursor-pointer border-blue-500 bg-base-100 py-6 text-center"
        }>
        <h2>
          <b>Organization</b>
        </h2>
        <OrganizationSvg />
      </div>
      <Transition appear show={isImportOrganization} as={Fragment}>
        <Dialog
          as={"div"}
          className={"relative z-10"}
          onClose={closeImportOrganizationPopup}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-index-100 w-full max-w-[548px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                  }>
                  <DropPopup
                    title={"Import organization contacts"}
                    withTemplate={true}
                    close={closeImportOrganizationPopup}
                    onFileDrop={(file) => handleImport(file, "organization")}
                    variant={"organization"}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-index-100 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <div className={"py-8"}>
                  <div className={"row mt-5 text-center"}>
                    <h3>
                      <b>Select one:</b>
                    </h3>
                  </div>
                  <div className={"row mt-5"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-6"}>
                      <div className={"lg:col-span-3"}>
                        {importIndividualPopup}
                      </div>
                      <div className={"ml-2 lg:col-span-3"}>
                        {importOrganizationPopup}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImportContacts;
