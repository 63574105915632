import React, { useState } from "react";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { ISubStatus } from "../../../types/types";
import * as devConfig from "../../../app/configs/devConfig";

interface IAddStatus {
  setActive: (active: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  getWorkFlowFetch: () => void;
  item: ISubStatus;
}

const UpdateStatus: React.FC<IAddStatus> = ({
  setActive,
  item,
  getWorkFlowFetch,
  setIsLoading,
}) => {
  const [name, setName] = useState(item.subStatus);
  const [error, setError] = useState("");

  const onClickUpdateStatus = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work-type-status/update-work-type-sub-status`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify({ ...item, subStatus: name }),
      },
    );
    if (response.ok) {
      setActive(false);
      getWorkFlowFetch();
    } else {
      const message: string = await response.json();
      setError(message);
      setIsLoading(false);
    }
  };
  return (
    <div
      className={
        "fixed top-0 left-0 flex h-screen w-screen items-center justify-center bg-black/20"
      }
      onClick={() => setActive(false)}>
      <div
        className={"w-[400px] rounded-[16px] bg-white p-8"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-8 text-center text-[18px] font-bold"}>
          Update sub status
        </div>
        <div>
          <div className={"mb-2"}>Name</div>
          <div>
            <input
              maxLength={25}
              type={"text"}
              className={"input input-bordered w-full"}
              value={name}
              onChange={(event) => {
                if (!event.target.value) {
                  setError("This field cannot be empty");
                } else {
                  setError("");
                }
                setName(event.target.value);
              }}
            />
            {error && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {error}
              </span>
            )}
          </div>
        </div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button
            buttonType={"submit"}
            label={"Update"}
            extraClasses={"normal-case"}
            onClick={() => {
              if (!error && name) {
                //setIsLoading(true);
                onClickUpdateStatus();
              } else {
                setError("This field cannot be empty");
              }
            }}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => setActive(false)}
            extraClasses={"normal-case"}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateStatus;
