import React from "react";
import { useForm } from "react-hook-form";

import { LABELS } from "../../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import * as devConfig from "../../../../app/configs/devConfig";

interface EditTaskProps {
  setActive: (active: boolean) => void;
  getTaskTemplate: () => void;
  task: any;
}
const EditTask: React.FC<EditTaskProps> = ({
  setActive,
  task,
  getTaskTemplate,
}) => {
  const updateTask = async (data: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/template-task/update-template-task`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      setActive(false);
      getTaskTemplate();
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      taskName: task.name,
      description: task.description,
    },
  });
  const onSubmit = async (data: any) => {
    await updateTask({
      ...task,
      name: data.taskName,
      description: data.description,
    });
  };

  const { nameRule } = ValidationConstants.customerRules;

  return (
    <div className={"fixed bottom-1/4 left-0 w-screen bg-transparent"}>
      <div className={"wrapper flex h-full items-end justify-center"}>
        <div className={"holder max-w-572 w-100 w-full"}>
          <div
            className={
              "text-18 mt-6 rounded-[15px] border-[1px] border-solid border-[#E8E9EA] bg-white p-10 shadow-[0px_4px_30px_rgba(34,34,34,0.15)]"
            }>
            <form
              className={"popup-form c-form "}
              onSubmit={handleSubmit(onSubmit)}>
              <div className={"mb-8 flex items-center justify-between"}>
                <p className={"mb-0"}>Edit Subtask</p>
                <div
                  className={"close-popup cursor-pointer"}
                  onClick={() => setActive(false)}>
                  <img src={"/img/closse.png"} alt={""} />
                </div>
              </div>
              <div className={"mb-4"}>
                <input
                  type={"text"}
                  placeholder={"Subtask name"}
                  className={"input"}
                  {...register("taskName", {
                    required: LABELS.required,
                    maxLength: {
                      value: 100,
                      message: "Name cannot be more than 100 characters",
                    },
                  })}
                />
                {errors?.taskName?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.taskName?.message}
                  </span>
                )}
              </div>
              <div className={"mb-4"}>
                <textarea
                  placeholder={"Description"}
                  className={"input"}
                  {...register("description", {
                    maxLength: {
                      value: 500,
                      message: "Description cannot be more than 500 characters",
                    },
                  })}
                />
                {errors?.description?.message && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.description?.message}
                  </div>
                )}
              </div>
              <div className={"mb-8 flex items-center justify-end gap-2"}>
                <OutlineButton
                  colorType={"neutral"}
                  label={"Cancel"}
                  onClick={() => setActive(false)}
                  extraClasses={"normal-case"}
                />
                <Button
                  buttonType={"submit"}
                  label={"Save"}
                  extraClasses={"normal-case"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
