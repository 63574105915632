import React, { FC, useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { useParams } from "react-router-dom";

import Button from "../../../../components/button/Button";
import { Card } from "../../../../components/card/Card";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import {
  useDeleteParticipantsMutation,
  useDeleteWorkNoteMutation,
  useGetParticipantsQuery,
  useGetWorkNotesQuery,
} from "../../../../slices/WorkNotesSlice";
import { IUser, IWorkNote } from "../../../../types/types";
import { WorkNoteModal } from "../../modals/WorkNoteModal";
import { WorkNote } from "./WorkNote";

export const WorkNotes: FC = () => {
  const { workId } = useParams();

  const { data: workNotes, isLoading } = useGetWorkNotesQuery({
    workId: workId || "",
  });

  const { data: participants, isLoading: isLoadingParticipants } =
    useGetParticipantsQuery({
      workId: workId || "",
    });

  const [
    deleteParticipant,
    { isSuccess, isLoading: isLoadingDeleteParticipant },
  ] = useDeleteParticipantsMutation();
  const [deleteContactNote] = useDeleteWorkNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [currentNote, setCurrentNote] = useState<IWorkNote | null>(null);
  const [confirmDeleteParticipant, setConfirmDeleteParticipant] = useState<{
    id: string;
    userId: string;
    user: IUser;
  } | null>(null);

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  useEffect(() => {
    if (isSuccess) {
      setConfirmDeleteParticipant(null);
    }
  }, [isSuccess]);

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px] overflow-visible bg-white"}>
        <div className={"max-w-full px-[24px] py-4"}>
          <div className={"mb-4 flex w-full justify-between"}>
            <div className={"text-lg font-bold"}>Notes</div>
          </div>
          <div className={"flex gap-2"}>
            <Button
              label={""}
              icon={
                <CiCirclePlus
                  size={25}
                  color={"#8773f1"}
                  className={"stroke-1"}
                />
              }
              fullWidth
              size={"custom"}
              colorType={"outline"}
              extraClasses={
                "btn-sm mb-4 normal-case px-2 w-[100%] h-[55px] bg-white border-[1px] border-[#8773f1] text-[#8773f1] flex items-center justify-center hover:bg-white hover:border-[#8773f1] hover:text-[#8773f1]"
              }
              onClick={handleOpenAddModal}>
              Add Note
            </Button>
          </div>
          <div>
            {isLoading ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : (
              <div className={"max-h-[500px] overflow-y-auto"}>
                {workNotes?.map((note) => (
                  <WorkNote
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteContactNote={deleteContactNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {confirmDeleteParticipant && (
        <ConfirmModal
          confirm={() =>
            deleteParticipant({
              workId: workId || "",
              id: confirmDeleteParticipant?.id || "",
            })
          }
          close={() => setConfirmDeleteParticipant(null)}
          title={""}
          message={
            <div className={"text-base text-gray-800"}>
              This user will no longer receive notifications regarding this
              conversation. Notifications can be resumed by using the @mention
              feature.
            </div>
          }
          isLoading={isLoadingDeleteParticipant}
          buttonsClasses={"flex-row-reverse gap-4"}
        />
      )}
      {isOpenAdd && (
        <WorkNoteModal
          workId={workId || ""}
          participants={participants || []}
          onClose={() => {
            setIsOpenAdd(false);
            setCurrentNote(null);
          }}
          isActive={isOpenAdd}
          defaultValues={currentNote || undefined}
        />
      )}
    </>
  );
};
