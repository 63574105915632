import React, { FC, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useGetOrgQuery } from "../../../services/OrgService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { CustomLink } from "../../../support/CustomLink";
import { GetPageTitle } from "../../../support/ScrollToTop";
import HomeTeamWork from "../HomeTeamWork";
import { HomeFirmWorks } from "./HomeFirmWorks";
import { HomeMyWorks } from "./HomeMyWorks";
import { HomeUserWorks } from "./HomeUserWorks";
import { useAppDispatch, useAppSelector } from "../../../redux/redux";
import { getUser } from "../../../services/AppService";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";

export const HomeView: FC<unknown> = () => {
  const { data: org, isLoading: isLoadingOrg } = useGetOrgQuery(
    getLinkedFirm()?.orgId || "",
  );
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { user, isLoadingUser } = useAppSelector((state) => state.appReducer);
  const viewFirmWorkPermission = "4ef8ede7-80d5-43a4-9ad9-a9e08dbdb7c0";

  useEffectOnce(() => {
    document.title = GetPageTitle("Home");
  });

  useEffect(() => {
    dispatch(
      getUser({
        userId: `${userInfoByEmail?.id}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
  }, []);

  if (isLoadingOrg || isLoadingUser) {
    return <LottieLoading />;
  }

  if (!org) {
    return null;
  }

  return (
    <div>
      <div className={"mb-6 flex"}>
        <CustomLink to={"/home/my-work"}>My work</CustomLink>
        <CustomLink to={"/home/firm-work"}>{"Firm's work"}</CustomLink>
        <CustomLink to={"/home/team-work"}>{"Team's work"}</CustomLink>
      </div>
      <div className={"mb-6 text-xl font-semibold"}>{org?.legalName}</div>
      <Routes>
        <Route path={""} element={<Outlet />}>
          <Route
            index
            element={<Navigate to={"/home/my-work"} replace={true} />}
          />
          <Route path={"/my-work"} element={<HomeMyWorks />} />
          <Route
            path={"/firm-work"}
            element={
              (user.permissionIds &&
                user.permissionIds.includes(viewFirmWorkPermission)) ||
              user?.userRoleEnum?.userRole === "Admin" ? (
                <HomeFirmWorks />
              ) : (
                <div
                  className={
                    "flex h-[75vh] items-center justify-center text-[42px] font-bold text-gray-400"
                  }>
                  You don`t have access to this page
                </div>
              )
            }
          />

          <Route path={"/team-work"} element={<HomeTeamWork />} />
          <Route path={"/user-work/:userId"} element={<HomeUserWorks />} />
        </Route>
      </Routes>
    </div>
  );
};
