import moment from "moment";
import React, { FC } from "react";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { getInitials } from "../../../../../../../../constants/constants";
import { ContactNoteModel } from "../../../../../../../../factories/contacts/models/contact-note.models";
import { parseNote } from "../../../../../../utils/parseNote";

type Props = {
  note: ContactNoteModel;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const ContactCollapsedNote: FC<Props> = ({ note, setIsCollapsed }) => {
  return (
    <div
      className={
        "mx-4 my-2 grid cursor-pointer grid-cols-[max-content_1fr] gap-3 rounded-[12px] border bg-gray-100 p-2"
      }>
      <div>
        <AvatarPlaceholder
          size={"super-small"}
          label={getInitials(
            note?.createdByUser?.userProfile?.firstName,
            note?.createdByUser?.userProfile?.lastName,
          )}
        />
      </div>
      <div className={"grid grid-rows-[repeat(2,max-content)] text-sm"}>
        <div
          className={
            "grid grid-cols-[repeat(2,max-content)] justify-between gap-2 text-gray-400"
          }>
          <div className={"w-full truncate"}>
            {note?.createdByUser?.userProfile?.firstName}{" "}
            {note?.createdByUser?.userProfile?.lastName}
          </div>
          <div>{moment(note.createdAt).format("MMM DD, YYYY")}</div>
        </div>
        <div className={"grid grid-cols-[1fr_32px] justify-between gap-2"}>
          <div className={"grid grid-rows-[repeat(2,max-content)]"}>
            <div className={"w-full break-all font-semibold"}>
              {note?.title || "No title"}
            </div>
            <div className={"w-full break-all text-gray-400"}>
              {parseNote(note.note)}
            </div>
          </div>
          <div className={"flex items-end"}>
            <Button
              label={"Ok"}
              onClick={() => setIsCollapsed(false)}
              size={"custom"}
              extraClasses={"btn-sm px-2"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
