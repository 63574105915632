import { FC } from "react";
import { Card } from "../../../components/card/Card";
import { NotificationsFilterItem } from "./NotificationsFilterItem";
import { notificationTitles } from "./options";
import { useGetCountsNotificationsQuery } from "../../../slices/UserSlice";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";

type Props = {
  isRead?: boolean;
};

export const NotificationsFilter: FC<Props> = ({ isRead }) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const { data, isLoading } = useGetCountsNotificationsQuery({
    userId: user?.id,
    orgId: getLinkedFirm()?.orgId || "",
    isRead: !!isRead,
  });

  return (
    <div className={"h-fit"}>
      <Card>
        <div
          className={
            "grid-rows-[repeat(5, max-content)] grid gap-1 py-2 text-base font-semibold"
          }>
          <div className={"mb-4 px-4 text-lg font-bold"}>Notifications</div>
          {notificationTitles.map((option) => (
            <NotificationsFilterItem
              key={option.title}
              {...option}
              count={data?.[option.count] || 0}
              isLoading={isLoading}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};
