import React from "react";
import { match } from "ts-pattern";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "../svg/SvgIconProps";

interface ButtonProps {
  buttonType?: "button" | "submit";
  colorType:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "neutral"
    | "warning";
  size?: "small" | "medium" | "large" | "extra-small";
  label: string;
  disabled?: boolean;
  wide?: boolean;
  onClick?: () => void;
  SvgIconLeft?: React.FunctionComponent<SvgIconProps>;
  SvgIconRight?: React.FunctionComponent<SvgIconProps>;
}

export const BrandButton: React.FC<ButtonProps> = (props) => {
  const {
    buttonType = "button",
    colorType = "primary",
    label,
    onClick,
    disabled = false,
    size = "medium",
    wide = false,
    SvgIconLeft,
    SvgIconRight,
  } = props;

  const btnActiveClass: string = match(colorType)
    .with("primary", () => "btn-primary")
    .with("secondary", () => "bg-purple border-none")
    .with("accent", () => "btn-accent")
    .with("ghost", () => "btn-ghost")
    .with("link", () => "btn-link")
    .with("warning", () => "btn-warning")
    .with("neutral", () => "")
    .exhaustive();

  const btnSizeClass: string = match(size)
    .with("small", () => "btn-sm")
    .with("extra-small", () => "btn-xs")
    .with("medium", () => "")
    .with("large", () => "btn-lg")
    .exhaustive();

  const btnWideClass = wide ? "btn-wide" : "";

  const svgIconMarginClass = match(size)
    .with("extra-small", () => (SvgIconLeft ? TW_SIZES.mr.xs : TW_SIZES.ml.xs))
    .with("small", () => (SvgIconLeft ? TW_SIZES.mr.sm : TW_SIZES.ml.sm))
    .with("medium", () => (SvgIconLeft ? TW_SIZES.mr.md : TW_SIZES.ml.md))
    .with("large", () => (SvgIconLeft ? TW_SIZES.mr.lg : TW_SIZES.ml.lg))
    .exhaustive();

  const svgIconSizeClass = match(size)
    .with("extra-small", () => TW_SIZES.hw.xs)
    .with("small", () => TW_SIZES.hw.sm)
    .with("medium", () => TW_SIZES.hw.md)
    .with("large", () => TW_SIZES.hw.lg)
    .exhaustive();

  const svgIconClassNames = `${svgIconSizeClass} ${svgIconMarginClass}`;

  return (
    <button
      type={buttonType}
      className={`btn ${btnActiveClass} ${btnSizeClass} ${btnWideClass}`}
      disabled={disabled}
      onClick={onClick}>
      {SvgIconLeft ? <SvgIconLeft sizeCx={svgIconClassNames} /> : null}
      {label}
      {SvgIconRight ? <SvgIconRight sizeCx={svgIconClassNames} /> : null}
    </button>
  );
};
