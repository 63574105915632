import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const TimeSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md}`, extraCx } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} ${extraCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          fill={"none"}
          d={
            "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          }
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          fill={"none"}
          d={"M9 10L12 13L17 8"}
        />
        <path strokeLinejoin={"round"} strokeLinecap={"round"} d={"M4 12H2"} />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          d={"M22 12H20"}
        />
        <path strokeLinejoin={"round"} strokeLinecap={"round"} d={"M12 4V2"} />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          d={"M12 22V20"}
        />
      </svg>
    </>
  );
};
