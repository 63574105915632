import React from "react";
import { useForm } from "react-hook-form";

import { LABELS } from "../../../app/constants/TextConstants";
import { BrandButton } from "../../../components/button/BrandButton";

export const StipeAddCustomer: React.FC<unknown> = () => {
  // RHF
  type FormState = {
    productId: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: {},
  });

  // todo: get free trial plan info - price + description + product id
  const freePlanDetails = {
    productId: "PP_FREE_TRIAL_01",
    name: "Free Trial",
    price: "0",
    currency: "USD",
    interval: "monthly",
    description:
      "Convert 100 pages of bank statements and credit card statements.",
  };

  // display radio button
  const freePlanInput: JSX.Element = (
    <div className={"form-control"}>
      <label className={"label cursor-pointer"}>
        <input
          type={"radio"}
          {...register("productId", { required: LABELS.required })}
          className={"radio checked:bg-primary"}
        />
        <div className={"m-4 flex max-w-sm flex-col"}>
          <span className={"capitalize"}>{freePlanDetails.name}</span>
          <span className={"capitalize"}>
            {freePlanDetails.price} {freePlanDetails.currency}
          </span>
          <span className={"label-text normal-case"}>
            {freePlanDetails.description}
          </span>
        </div>
      </label>
      {errors?.productId?.message && (
        <span className={"pt-2 text-sm font-bold text-error"}>
          {errors?.productId?.message}
        </span>
      )}
    </div>
  );

  // post create customer
  const onSubmit = async (data: FormState) => {
    console.log("StipeAddCustomer | onSubmit", { data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {freePlanInput}
      <BrandButton
        colorType={"primary"}
        buttonType={"submit"}
        label={"Start trial"}
      />
    </form>
  );
};
