export const getStatusColor = (name: string) => {
  if (name === "Ready") {
    return "#93AAFD";
  }
  if (name === "In Progress") {
    return "#FFD80B";
  }
  if (name === "Waiting") {
    return "#F15252";
  }
  if (name === "Completed") {
    return "#47DE96";
  }
  return "#000";
};
