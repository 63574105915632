import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const StatCardsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Stat Cards"}>
      <ComponentSectionLayout title={"row"}>
        <div className={"stats w-full shadow"}>
          <div className={"stat"}>
            <div className={"stat-figure text-secondary"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-8 w-8 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  }
                />
              </svg>
            </div>
            <div className={"stat-title"}>Downloads</div>
            <div className={"stat-value"}>310M</div>
            <div className={"stat-desc"}>Jan 1st - Feb 1st</div>
          </div>
          <div className={"stat"}>
            <div className={"stat-figure text-secondary"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-8 w-8 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  }
                />
              </svg>
            </div>
            <div className={"stat-title"}>New Users</div>
            <div className={"stat-value"}>4,200</div>
            <div className={"stat-desc text-success"}>↗︎ 400 (22%)</div>
          </div>
          <div className={"stat"}>
            <div className={"stat-figure text-secondary"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-8 w-8 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                  }
                />
              </svg>
            </div>
            <div className={"stat-title"}>New Registers</div>
            <div className={"stat-value"}>1,200</div>
            <div className={"stat-desc text-error"}>↘︎ 90 (14%)</div>
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
