import Lottie from "lottie-react";
import React from "react";

import * as procSecureAnimation from "../../media/lottieFiles/proc-secure-01.json";

export const LottieSessionExpired: React.FC<unknown> = () => {
  return (
    <div className={"flex flex-col items-center justify-center"}>
      <div className={"h-1/3 w-1/3"}>
        <Lottie animationData={procSecureAnimation} loop={true} />
      </div>
      <div className={"font-extrabold opacity-30"}>
        For your security, we have expired this session due to inactivity.
        Please refresh to continue.
      </div>
    </div>
  );
};
