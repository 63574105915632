import { getUtcFromLocal } from "../../../app/commonOps/CommonDateOps";
import { getRounded } from "../../../app/commonOps/CommonNumberOps";
import { ExtractedTotalsDataT } from "../../../app/stateMachines/BankDocDetailsMachine";
import {
  BankDocOutputRowExistingInput,
  GetBankDocOutputById_bankDocOutputById,
  GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows_bankDocOutputAmount,
  P5AmountTypeEnum,
} from "../../../generated/operation-result-types";
import {
  BankDocOutputDataRowT,
  BankDocOutputDataT,
} from "./BankDocOutputTypes";

const TransformBankDocAmount = (
  outputAmount: GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows_bankDocOutputAmount,
) => {
  const isCredit = outputAmount.typeEnum.toLowerCase() === "credit";
  const value = getRounded(outputAmount.value);
  return {
    credit: isCredit ? value : 0,
    debit: !isCredit ? value : 0,
  };
};

const TransformByIdToDataT = (
  bankDocOutputById: GetBankDocOutputById_bankDocOutputById,
): BankDocOutputDataT => {
  const extractedRows =
    bankDocOutputById.bankDocOutputTable.bankDocOutputRows.map(
      (outputRow): BankDocOutputDataRowT => {
        const amount = TransformBankDocAmount(outputRow.bankDocOutputAmount);
        return {
          id: outputRow.id,
          date: getUtcFromLocal(new Date(outputRow.rowDate)),
          credit: amount.credit,
          debit: amount.debit,
          isSelected: outputRow.isSelected,
          description: outputRow.description,
          memo: outputRow.memo,
          isNew: false,
        };
      },
    );

  return {
    extractedRows: extractedRows,
    pdfTotals: {
      starting: bankDocOutputById.bankDocOutputBalances.startingBalance,
      ending: bankDocOutputById.bankDocOutputBalances.endingBalance,
    },
  };
};

const TransformDataRowToRowInput = (
  dataRows: BankDocOutputDataRowT[],
): BankDocOutputRowExistingInput[] => {
  return dataRows.map((x): BankDocOutputRowExistingInput => {
    return {
      id: x.id,
      description: x.description,
      memo: x.memo,
      isSelected: x.isSelected,
      rowDate: x.date.toUTCString(),
      bankDocOutputAmountInput: {
        value: x.credit === 0 ? x.debit : x.credit,
        typeEnum:
          x.credit === 0 ? P5AmountTypeEnum.DEBIT : P5AmountTypeEnum.CREDIT,
      },
    };
  });
};

type AllExtractedDataRowsT = Array<BankDocOutputDataRowT | undefined>;
const InitialExtractedTotalsData: ExtractedTotalsDataT = {
  credits: 0,
  debits: 0,
};
const GetExtractedTotals = (allDataRows: AllExtractedDataRowsT) => {
  return allDataRows
    .filter((x) => x !== undefined)
    .filter((x) => x!.isSelected)
    .reduce<ExtractedTotalsDataT>((prev, curr) => {
      const newDebits: number = prev.debits + (curr?.debit ?? 0);
      const newCredits: number = prev.credits + (curr?.credit ?? 0);
      return {
        ...prev,
        credits: getRounded(newCredits),
        debits: getRounded(newDebits),
      };
    }, InitialExtractedTotalsData);
};

export const BankDocStatics = {
  TransformDataRowToRowInput,
  TransformByIdToDataT,
  GetExtractedTotals,
  InitialExtractedTotalsData,
};
