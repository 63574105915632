import * as Xstate from "@xstate/react";
import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

import { ROUTE_NAMES } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { LottieSessionExpired } from "../../components/graphics/LottieSessionExpired";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  handleDeleteLinkFirm,
  setIsLinkedFirm,
} from "../../services/AppService";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import MainViewPages from "./MainViewPages";
function clearAllCookies() {
  const Cookies = document.cookie.split(";");
  // set past expiry to all cookies
  for (let i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
  }
}
export const MainView: React.FC<unknown> = () => {
  // xstate
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userAuthService, userInfoService } = React.useContext(GlobalContext);
  const [userAuthState] = Xstate.useActor(userAuthService);
  const { userAuthInfo } = userAuthState.context;
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  const userEmail = userAuthInfo?.email;
  const locationId = currentLocation?.id;

  const [isSessionExpired, setIsSessionExpired] = React.useState(false);
  const { user } = useAppSelector((state) => state.appReducer);

  const logout = () => {
    if (getLinkedFirm()) {
      dispatch(
        handleDeleteLinkFirm({ userId: user?.id, orgId: getLinkedFirm()?.id }),
      );
    }
    sessionStorage.clear();
    dispatch(setIsLinkedFirm(false));
    clearAllCookies();
    navigate(ROUTE_NAMES.signOut);
  };

  useIdleTimer({
    crossTab: false,
    timeout: 1000 * 60 * 30,
    debounce: 1000,
    stopOnIdle: true,
    onIdle: () => {
      logout();
      // setIsSessionExpired(true);
    },
  });

  if (isSessionExpired) {
    return (
      <div>
        <LottieSessionExpired />
      </div>
    );
  }

  if (!userEmail) {
    return <LottieLoading />;
  }

  return <MainViewPages locationId={locationId} />;
};
