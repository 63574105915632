import { useMutation } from "@apollo/client";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import * as Xstate from "@xstate/react";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import {
  GetBankDocById_bankDocById,
  UpdateBankDocDetails,
  UpdateBankDocDetailsVariables,
} from "../../../generated/operation-result-types";
import {
  GET_BANK_DOC_BY_ID_GQL,
  UPDATE_BANK_DOC_DETAILS_GQL,
} from "../../../queries/BankDocQueries.gql";
import { FormSection } from "../../../support/FormSection";

type BankDocEditViewProps = {
  bankDocById: GetBankDocById_bankDocById;
};

type BankDocGqlApiInput = {
  name: string;
  customer: {
    id: string;
  };
  docType: "CREDIT_CARD" | "BANK_STATEMENT";
};

export const BankDocEditView: React.FC<BankDocEditViewProps> = ({
  bankDocById,
}) => {
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  // RHF
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BankDocGqlApiInput>({
    defaultValues: {
      customer: { id: bankDocById.customer?.id },
      docType: bankDocById.isCreditCard ? "CREDIT_CARD" : "BANK_STATEMENT",
      name: bankDocById.name,
    },
  });

  // refetch queries
  const refetchQueries: InternalRefetchQueryDescriptor[] = [
    {
      query: GET_BANK_DOC_BY_ID_GQL,
      variables: { bankDocId: bankDocById.id },
    },
  ];

  // mutation
  const [
    updateBankDocDetails,
    { loading: loadingM, error: errorM, reset: resetM },
  ] = useMutation<UpdateBankDocDetails, UpdateBankDocDetailsVariables>(
    UPDATE_BANK_DOC_DETAILS_GQL,
    { refetchQueries: refetchQueries },
  );

  if (loadingM) {
    return <LottieLoading />;
  }

  if (errorM) {
    console.error("BankDocEditView | BankDocEditView", {
      errorM,
    });
    throw new Error("Error getting BankDocEditView");
  }

  // customer dropdown view rows
  const customer = bankDocById.customer;
  const singleCustomerFormControl = (
    <div className={"flex flex-col items-start"}>
      <div>{customer?.name}</div>
    </div>
  );
  const customerFormControl = (
    <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
      <label className={"label"}>
        <span className={"label-text"}>{LABELS.features.customer} Name</span>
      </label>
      <div className={"w-full"}>
        {singleCustomerFormControl}
        {errors?.customer?.id?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.customer?.id?.message}
          </span>
        )}
      </div>
    </div>
  );

  const editSection = (
    <FormSection name={"Edit bank statement details"}>
      {customerFormControl}

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>File Name</span>
        </label>
        <div className={"w-full"}>
          <input
            type={"text"}
            {...register("name", {
              required: LABELS.required,
              ...ValidationConstants.bankDocAddEditRules.nameRule.valueLength,
            })}
            className={"input input-bordered w-full"}
          />
          {errors?.name?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.name?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Document Type</span>
        </label>
        <div className={"w-full"}>
          <Controller
            control={control}
            name={"docType"}
            rules={{ required: LABELS.required }}
            render={({ field: { onChange, value } }) => {
              const bankStatementValue = "bank-statement";
              const creditCardStatementValue = "credit-card-statement";
              const isBankStatementChecked = value === "BANK_STATEMENT";
              const isCreditCardChecked = value === "CREDIT_CARD";
              return (
                <div className={"flex flex-row space-x-4"}>
                  <label className={"label cursor-pointer space-x-2"}>
                    <input
                      type={"radio"}
                      name={"document-type"}
                      className={"radio radio-primary"}
                      value={bankStatementValue}
                      defaultChecked={isBankStatementChecked}
                      onChange={() => onChange("BANK_STATEMENT")}
                    />
                    <span className={"label-text"}>Bank Statement</span>
                  </label>
                  <label className={"label cursor-pointer space-x-2"}>
                    <input
                      type={"radio"}
                      name={"document-type"}
                      className={"radio radio-primary"}
                      value={creditCardStatementValue}
                      defaultChecked={isCreditCardChecked}
                      onChange={() => onChange("CREDIT_CARD")}
                    />
                    <span className={"label-text"}>Credit Card Statement</span>
                  </label>
                </div>
              );
            }}
          />
          {errors?.docType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.docType?.message}
            </span>
          )}
        </div>
      </div>
    </FormSection>
  );

  const onSubmit: SubmitHandler<BankDocGqlApiInput> = async (data) => {
    resetM();
    await updateBankDocDetails({
      variables: {
        updateBankDocDetailsInput: {
          bankDocId: bankDocById.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          orgId: userInfoByEmail!.org!.id,
          fileName: data.name,
          isCreditCard: data.docType === "CREDIT_CARD",
        },
      },
    });

    if (!errorM) {
      navigate("../");
    }
  };

  return (
    <>
      {/*<DevTool control={control} placement={"bottom-right"} />*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={false}>
          <>{editSection}</>
        </fieldset>

        {/*{errorMessage && <Alert type={"error"} label={LABELS.errors.default} />}*/}

        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"submit"}
            colorType={"primary"}
            label={"Update"}
            disabled={loadingM}
            SvgIconLeft={AddStackSvg}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
