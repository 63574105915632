import React, { FC } from "react";

type Props = {
  value: { h: string; m: string };
  onChange: (value: { h: string; m: string }) => void;
};

const InputTime: FC<Props> = ({ value, onChange }) => {
  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHour = e.target.value;
    if (/^\d*$/.test(newHour)) {
      // Allow only numbers
      onChange({ ...value, h: newHour });
    }
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinute = e.target.value;
    if (
      /^\d*$/.test(newMinute) &&
      (newMinute === "" || parseInt(newMinute, 10) < 60)
    ) {
      // Allow only numbers and less than 60
      onChange({ ...value, m: newMinute });
    }
  };

  return (
    <div className={"flex items-center gap-4"}>
      <div className={"flex items-center gap-2"}>
        <input
          className={"input input-bordered w-16"}
          type={"text"}
          value={value.h}
          onChange={handleHourChange}
          onFocus={(e) => e.target.select()}
        />
        <div className={"text-sm"}>hr</div>
      </div>
      <div className={"flex items-center gap-2"}>
        <input
          className={"input input-bordered w-16"}
          type={"text"}
          value={value.m}
          onChange={handleMinuteChange}
          maxLength={2}
          onFocus={(e) => e.target.select()}
        />
        <div className={"text-sm"}>min</div>
      </div>
    </div>
  );
};

export default InputTime;
