import _ from "lodash";

// constants
const CURRENCY_PRECISION = 2;

// functions
export const validateDecimalPositive = (v: unknown): boolean | string => {
  const mustBeAPositiveNumber = "Must be a positive number";
  const mustBeANumber = "Must be a number";
  const reg = new RegExp("^-?[0-9]+[.][0-9]{2}$");

  if (_.isNumber(v))
    return (reg.test(v.toFixed(2)) && v >= 0) || mustBeAPositiveNumber;
  if (_.isString(v)) return validateDecimalPositive(_.toNumber(v));
  else return mustBeANumber;
};

export const validateDecimalNumber = (v: unknown): boolean | string => {
  const mustBeANumber = "Must be a number";
  const reg = new RegExp("^-?[0-9]+[.][0-9]{2}$");

  if (_.isNumber(v)) return reg.test(v.toFixed(2)) || mustBeANumber;
  if (_.isString(v)) return validateDecimalNumber(_.toNumber(v));
  else return mustBeANumber;
};

const getRoundedStr = (value: string): number => {
  return _.chain(value)
    .thru((x) => _.toNumber(x))
    .round(CURRENCY_PRECISION)
    .value();
};

const getRoundedNum = (value: number): number => {
  return _.round(value, CURRENCY_PRECISION);
};

export const getRounded = (value: unknown): number => {
  if (_.isNumber(value)) return getRoundedNum(value);
  if (_.isString(value)) return getRoundedStr(value);
  throw new Error(`Unknown value ${value} of type ${typeof value}`);
};

export const getCurrency = (value: number): string => {
  if (isNaN(value)) return "INVALID";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(value);
};

export const toPositive = (value: number) => {
  return Math.abs(value);
};
