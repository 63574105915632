import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { Navigate, Route } from "react-router-dom";

import { b2cPolicies } from "../../app/configs/appConfig";
import { AuthenticatedMainView } from "../../features/main/AuthenticatedMainView";
import { invisibleRouteMap, sideNavRouteMap } from "./SidenavRouteMaps";
import UserInvite from "../../features/UserInvite/UserInvite";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";

export const MainRoute = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance: msalInstance } = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a React component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  React.useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error instanceof AuthError &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          msalInstance.loginRedirect({
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [],
          });
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          const policyTypeKey = "tfp";

          // @ts-ignore
          const policy = (event.payload as AuthenticationResult).idTokenClaims[
            policyTypeKey
          ];
          console.info("MainView | policy", { policy });

          // if (policy === b2cPolicies.names.forgotPassword) {
          //   window.alert(
          //     "Password has been reset successfully. \nPlease sign-in with your new password.",
          //   );
          //   return msalInstance.logout();
          // } else if (policy === b2cPolicies.names.editProfile) {
          //   window.alert(
          //     "Profile has been edited successfully. \nPlease sign-in again.",
          //   );
          //   return msalInstance.logout();
          // }
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance]);

  const sidebarRoutes = sideNavRouteMap.map((routeElement) => {
    const { name, path, component: Component } = routeElement;
    return (
      <Route
        key={`component-${path}-key`}
        path={`${path}/*`}
        element={<Component />}
      />
    );
  });

  const invisibleRoutes = invisibleRouteMap.map((routeElement) => {
    const { name, component: Component } = routeElement;
    return (
      <Route
        key={`component-${name}-key`}
        path={`${name}/*`}
        element={<Component />}
      />
    );
  });

  return (
    <>
      <Route element={<AuthenticatedMainView />}>
        <Route index element={<Navigate to={"./home"} replace={true} />} />
        {sidebarRoutes}
        {invisibleRoutes}
      </Route>
      <Route path={"/user-invitations/:id"} element={<UserInvite />} />
    </>
  );
};
