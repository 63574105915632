import React, { FC } from "react";

import { OrganizationInfoView } from "./widgets/organization-info/OrganizationInfoView";
import { OrganizationInvoicesWidget } from "./widgets/organization-invoices/OrganizsationInvoicesWidget";
import { OrganizationWorksWidget } from "./widgets/organization-work/OrganizationWorksWidgets";
import { OrganizationPaymentsWidget } from "./widgets/organization-payments/OrganizationPaymentsWidgets";
import { OrganizationLinkedProfilesWidget } from "./widgets/organization-linked-profiles/OrganizationLinkedProfilesWidget";
import { OrganizationFilesWidget } from "./widgets/organization-files/OrganizationFilesWidget";
import { OrganizationNotesWidget } from "./widgets/organization-notes/OrganizationNotesWidget";
import { IOrganizationContact } from "../../../../../../types/types";
import { OrganizationTagsWidget } from "./widgets/organization-tags/OrganizationTagsWidget";

type Props = {
  organization: IOrganizationContact;
};

export const OrganizationProfile: FC<Props> = ({ organization }) => {
  return (
    <div className={"grid grid-cols-3 gap-6"}>
      <div className={"flex h-fit flex-col gap-6"}>
        <OrganizationInfoView />
        <OrganizationLinkedProfilesWidget />
        <OrganizationTagsWidget organization={organization} />
      </div>
      <div className={"flex h-fit flex-col gap-6"}>
        <OrganizationWorksWidget organization={organization} />
        <OrganizationInvoicesWidget
          customerId={organization?.customerId || ""}
        />
        <OrganizationPaymentsWidget
          customerId={organization?.customerId || ""}
        />
      </div>
      <div className={"flex h-fit flex-col gap-6"}>
        <OrganizationNotesWidget />
        <OrganizationFilesWidget organization={organization} />
      </div>
    </div>
  );
};
