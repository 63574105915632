import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

export const SearchInput: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || "",
  );

  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    setSearchParams(
      debouncedSearchValue ? { search: debouncedSearchValue || "" } : undefined,
    );
  }, [debouncedSearchValue, setSearchParams]);

  return (
    <input
      className={"input input-bordered w-full"}
      value={searchValue}
      placeholder={"Search"}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
};
