import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { IWork } from "../types/types";

interface IInitialState {
  works: IWork[];
  isLoading: boolean;
  error: string;
}
const initialState: IInitialState = {
  works: [],
  isLoading: false,
  error: "",
};

export const getWorkByContact = createAsyncThunk(
  "timer/getWorkByContact",
  async (
    { contactId, orgId }: { contactId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/get-by-customer-id/${contactId}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWork[] = await response.json();
      return data?.filter((work) => work.customer);
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getWorkByOrganizationContact = createAsyncThunk(
  "timer/getWorkByOrganizationContact",
  async ({ id, orgId }: { id: string; orgId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/get-by-business-Contact/${id}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const worksSlice = createSlice({
  name: "works",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkByContact.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getWorkByContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.works = action.payload;
      })
      .addCase(getWorkByOrganizationContact.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getWorkByOrganizationContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.works = action.payload;
      });
  },
});

export default worksSlice.reducer;
