import React, { useState } from "react";
import EditDots from "../../../../components/EditDots/EditDots";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import EditTask from "./EditTask";
import * as devConfig from "../../../../app/configs/devConfig";
interface ITaskProps {
  task: any;
  getTaskTemplate: () => void;
}

const Task: React.FC<ITaskProps> = ({ task, getTaskTemplate }) => {
  const [isUpdateTask, setIsUpdateTask] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const deleteTaskTemplate = async (item: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/template-task`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(item),
      },
    );
    if (response.ok) {
      const data = await response.json();
      getTaskTemplate();
    }
  };
  return (
    <>
      <div
        className={
          "mb-4 flex w-full  justify-between border-b-[1px] border-gray-300 pb-2 text-[14px]"
        }>
        <div className={"flex items-center gap-[10px]"}>
          <img src={"/img/drag.png"} className={""} alt={""} />
          <div>
            <div>{task.name}</div>
            {task.description && (
              <div
                className={`mt-[2px] cursor-pointer text-[14px] text-gray-400 ${
                  !isCollapsed
                    ? "...  overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[300px] xl:max-w-[800px]"
                    : " whitespace-pre-line sm:max-w-[300px] xl:max-w-[800px]"
                }`}
                onClick={() => setIsCollapsed(!isCollapsed)}>
                {task.description}
              </div>
            )}
          </div>
        </div>
        <EditDots>
          <div className={""}>
            <div
              onClick={() => {
                setIsUpdateTask(true);
              }}
              className={"cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"}>
              Edit
            </div>
            <div
              onClick={() => deleteTaskTemplate(task)}
              className={"cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"}>
              Delete
            </div>
          </div>
        </EditDots>
      </div>
      {isUpdateTask && (
        <EditTask
          setActive={setIsUpdateTask}
          task={task}
          getTaskTemplate={getTaskTemplate}
        />
      )}
    </>
  );
};

export default Task;
