import classNames from "classnames";
import { Dispatch, FC, SetStateAction } from "react";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { getInitials } from "../../../../../../../../constants/constants";
import { IContactProfile } from "../../../../../../../../types/types";

type Props = {
  contact: IContactProfile;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const ContactInfo: FC<Props> = ({ contact, setIsEdit }) => {
  const address = `${contact?.address?.line1 || ""} ${
    contact?.address?.city || ""
  } ${contact?.address?.region || ""} ${contact?.address?.postalCode || ""}`;

  return (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button
            label={"Edit"}
            size={"custom"}
            extraClasses={"btn-sm"}
            onClick={() => setIsEdit(true)}
          />
        </div>
        <div className={"grid-rows-[repeat(7, max-content)] grid gap-6"}>
          <div>
            <div className={"mb-2 text-base text-gray-400"}>FULL NAME</div>
            <div className={"flex items-center gap-2"}>
              <AvatarPlaceholder
                size={"extra-small"}
                label={getInitials(
                  contact?.firstName || "",
                  contact?.lastName || "",
                )}
              />
              <div className={"text-sm font-semibold"}>
                {`${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`}
              </div>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>SIN NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {contact?.socialIdentificationNumber || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>EMAIL</div>
            <div>
              <a
                className={classNames(
                  "link-primary text-sm font-semibold",
                  contact?.email ? "hover:underline" : "pointer-events-none",
                )}
                href={contact?.email ? `mailto:${contact?.email}` : ""}>
                {contact?.email || "-"}
              </a>
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>PHONE NUMBER</div>
            <div className={"text-sm font-semibold"}>
              {contact?.phoneNumber || "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>CONTACT TYPE</div>
            <div className={"text-sm font-semibold"}>
              {contact?.contactType}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDRESS</div>
            <div className={"text-sm font-semibold"}>
              {address?.trim() ? address : "-"}
            </div>
          </div>
          <div>
            <div className={"text-base text-gray-400"}>ADDITIONAL INFO</div>
            <div className={"text-sm font-semibold"}>
              {contact?.additionalInfo || "-"}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
