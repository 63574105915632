import React, { useEffect, useState } from "react";

import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  IPaymentMethod,
  IService,
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useUpdatePaymentMethodMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import Menu from "./Menu";

const PaymentMethods = () => {
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsQuery(
    getLinkedFirm()?.orgId,
  );

  const [addPaymentMethod, { isSuccess: isSuccessAdd }] =
    useAddPaymentMethodMutation();
  const [updatePaymentMethod, { isSuccess: isSuccessUpdate }] =
    useUpdatePaymentMethodMutation();
  const [deletePaymentMethod, { isSuccess: isSuccessDelete }] =
    useDeletePaymentMethodMutation();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [newName, setNewName] = useState("");
  const [activeMethod, setActiveMethod] = useState<IPaymentMethod | null>(null);

  useEffect(() => {
    if (isSuccessAdd) {
      setNewName("");
      setIsOpenAdd(false);
    }
  }, [isSuccessAdd]);

  useEffect(() => {
    if (isSuccessUpdate || isSuccessDelete) {
      closeActiveMethod();
    }
  }, [isSuccessUpdate, isSuccessDelete]);

  if (isLoading) {
    return <LottieLoading />;
  }

  const closeActiveMethod = () => {
    setActiveMethod(null);
    setNewName("");
  };
  const openActiveMethod = (method: IPaymentMethod) => {
    setActiveMethod(method);
    setNewName(method.paymentMethod || "");
  };

  const renderAdd = () => {
    return (
      <Menu close={() => setIsOpenAdd(false)}>
        <>
          <div className={"mb-8 text-center text-[18px] font-bold"}>
            Add payment method
          </div>
          <div>
            <div className={"mb-2"}>Name</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newName}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewName(event.target.value);
                }}
              />
              {/*{error && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {error}
                </span>
              )}*/}
            </div>
          </div>
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button
              buttonType={"submit"}
              label={"Add"}
              extraClasses={"normal-case"}
              onClick={() => {
                if (newName) {
                  addPaymentMethod({
                    paymentMethod: newName,
                    orgId: getLinkedFirm()?.orgId,
                  });
                } else {
                  //setError("This field cannot be empty");
                }
              }}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={() => setIsOpenAdd(false)}
              extraClasses={"normal-case"}
            />
          </div>
        </>
      </Menu>
    );
  };
  const renderActiveMethod = () => {
    return (
      <Menu close={closeActiveMethod}>
        <>
          <div className={"mb-8 text-center text-[18px] font-bold"}>
            Edit payment method
          </div>
          <div>
            <div className={"mb-2"}>Name</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newName}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewName(event.target.value);
                }}
              />
              {/*{error && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {error}
                </span>
              )}*/}
            </div>
          </div>
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button
              buttonType={"button"}
              label={"Edit"}
              extraClasses={"normal-case"}
              onClick={() => {
                if (newName) {
                  updatePaymentMethod({
                    id: activeMethod?.id || "",
                    paymentMethod: newName,
                  });
                } else {
                  //setError("This field cannot be empty");
                }
              }}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeActiveMethod}
              extraClasses={"normal-case"}
            />
          </div>
        </>
      </Menu>
    );
  };

  return (
    <>
      <div className={"mb-4 flex items-start justify-between"}>
        <Button
          buttonType={"button"}
          onClick={() => {
            setNewName("");
            setIsOpenAdd(true);
          }}
          label={"Add payment method"}
          extraClasses={"normal-case"}
        />
      </div>
      <div>
        {paymentMethods?.map((item) => (
          <div key={item.id} className={"mb-8"}>
            <div
              className={
                "mb-4 flex w-full  justify-between border-b-[1px] border-gray-300 pb-2"
              }>
              <div className={"text-sm font-semibold text-gray-800"}>
                {item?.paymentMethod}
              </div>
              <EditDots>
                <div className={""}>
                  <div
                    onClick={() => openActiveMethod(item)}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Edit
                  </div>

                  <div
                    onClick={() => deletePaymentMethod(item?.id || "")}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Delete
                  </div>
                </div>
              </EditDots>
            </div>
          </div>
        ))}
      </div>
      {isOpenAdd && renderAdd()}
      {activeMethod && renderActiveMethod()}
    </>
  );
};

export default PaymentMethods;
