import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

import Search from "../../../components/select/Search";
import { useGetSearchWorksQuery } from "../../../slices/WorkSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../../types/types";

type Props = {
  setType: (type: "contacts" | "works") => void;
};

const SearchWorks: FC<Props> = ({ setType }) => {
  const navigate = useNavigate();
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState("");

  const {
    data: works,
    isFetching,
    refetch,
  } = useGetSearchWorksQuery({
    query: search,
    orgId: getLinkedFirm()?.orgId || "",
  });

  const debounceValue = useDebounce(search, 500);

  useEffect(() => {
    refetch();
  }, [debounceValue]);

  return (
    <Search
      value={null}
      onChange={(item) => {
        const value = item as SelectOptionType;
        navigate(`/work/${value?.value}`);
      }}
      options={works || []}
      inputValue={search}
      onChangeInput={(value) => setSearch(value)}
      setType={setType}
      isLoading={isFetching}
      placeholder={isFocus ? "" : "Search work items"}
      noOptionsMessage={"No results"}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      type={"works"}
    />
  );
};

export default SearchWorks;
