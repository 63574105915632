import React from "react";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { Card } from "../../../components/card/Card";

export const EmptyDetails = () => {
  return (
    <Card extraClasses={"h-full"}>
      <div className={"grid h-full place-items-center py-4"}>
        <div className={"text-center"}>
          <div className={"flex justify-center text-gray-300"}>
            <HiOutlineEnvelope size={60} />
          </div>
          <div className={"text-lg font-semibold"}>
            <span className={"text-gray-800"}>Select Email to read or</span>{" "}
            <span className={"text-purple cursor-pointer hover:underline"}>
              Create new email
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
