import React, { FC, useState } from "react";
import { IContactProfile } from "../../../../../../types/types";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteContactNoteMutation,
  useGetContactNotesQuery,
} from "../../../../../../slices/ContactNotesSlice";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { ContactNoteModel } from "../../../../../../factories/contacts/models/contact-note.models";
import { Card } from "../../../../../../components/card/Card";
import Button from "../../../../../../components/button/Button";
import { ContactNoteModal } from "../../../../modals/ContactNoteModal";
import ErrorPopup from "../../../../modals/ErrorPopup";
import { CiCirclePlus } from "react-icons/ci";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import { ContactNote } from "./ContactNote";

type Props = {
  contact: IContactProfile;
};

export const ContactNotes: FC<Props> = ({ contact }) => {
  const [searchParams] = useSearchParams();

  const { data: contactNotes, isFetching } = useGetContactNotesQuery({
    orgId: getLinkedFirm()?.orgId,
    contactId: contact?.contactId,
  });
  const [deleteContactNote] = useDeleteContactNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [currentNote, setCurrentNote] = useState<ContactNoteModel | null>(null);

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px] overflow-visible bg-white"}>
        <div className={"max-w-full px-[24px] py-4"}>
          <div className={"mb-4 flex w-full justify-between"}>
            <div className={" text-lg font-bold"}>Notes</div>
          </div>
          <div className={"flex gap-2"}>
            <Button
              label={""}
              colorType={"outline"}
              icon={
                <CiCirclePlus
                  size={25}
                  color={"#8773f1"}
                  className={"stroke-1"}
                />
              }
              fullWidth
              size={"custom"}
              extraClasses={
                "btn-sm mb-4 normal-case px-2 w-[100%] h-[55px] bg-white border-[1px] border-[#8773f1] text-[#8773f1] flex items-center justify-center hover:bg-white hover:border-[#8773f1] hover:text-[#8773f1]"
              }
              onClick={handleOpenAddModal}>
              Add Note
            </Button>
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : (
              <div className={"flex flex-col gap-4"}>
                {contactNotes?.map((note) => (
                  <ContactNote
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteContactNote={deleteContactNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <ContactNoteModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={contact?.contactId || ""}
          defaultValues={currentNote || undefined}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
