import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

import Button from "./button/Button";

interface DropPopupProps {
  onFileDrop: (file: File) => void;
  close: () => void;
  title: string;
  message?: string;
  withTemplate?: boolean;
  variant?: "individual" | "organization";
}

const DropPopup: React.FC<DropPopupProps> = ({
  onFileDrop,
  close,
  title,
  message,
  withTemplate,
  variant,
}) => {
  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const file = event.dataTransfer?.files[0];
      if (file) {
        onFileDrop(file);
      }
      close();
    },
    [onFileDrop],
  );

  const handleButtonClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx, .csv"; // Adjust the accepted file types as needed

    fileInput.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        onFileDrop(file);
      }
      close();
    };

    fileInput.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDownload = () => {
    switch (variant) {
      case "individual": {
        const fileDataUri = process.env.PUBLIC_URL + "/ImportContacts.csv";
        const downloadLink = document.createElement("a");
        downloadLink.href = fileDataUri;
        downloadLink.download = "ImportContacts.csv";
        downloadLink.click();
        break;
      }
      case "organization": {
        const fileDataUri =
          process.env.PUBLIC_URL + "/ImportBusinessContacts.csv";

        const downloadLink = document.createElement("a");
        downloadLink.href = fileDataUri;
        downloadLink.download = "ImportBusinessContacts.csv";

        downloadLink.click();
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      onClick={variant ? () => null : close}
      className={
        variant
          ? ""
          : "fixed top-0 left-0 z-[101] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-25"
      }>
      <div
        className={"rounded-2xl bg-white p-6"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-4"}>
          <div className={"text-lg font-medium"}>{title}</div>
          {message && (
            <div className={"mt-1 text-sm text-gray-500"}>{message}</div>
          )}
        </div>

        <div
          className={
            "mb-4 flex min-h-[300px] min-w-[500px] flex-col items-center justify-center gap-4 rounded-2xl border-[1px] border-dotted border-gray-400 p-4"
          }
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}>
          <div className={"text-lg font-medium text-blue-500"}>Drag & Drop</div>
          <div>
            <FontAwesomeIcon
              icon={faCloudArrowUp}
              className={"text-[100px] text-blue-500"}
            />
          </div>
          <div className={"text-gray-500"}>
            <span>your file or </span>
            <span
              onClick={handleButtonClick}
              className={"cursor-pointer font-bold"}>
              Click here
            </span>
          </div>
        </div>
        {withTemplate && (
          <div className={"flex justify-center"}>
            <Button
              label={"Download template"}
              colorType={"secondary"}
              onClick={handleDownload}
              extraClasses={"normal-case"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DropPopup;
