import "./index.css";
import "./features/work-charts/work-chart.css";
import "react-toastify/dist/ReactToastify.css";

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { PublicClientApplication } from "@azure/msal-browser";
import { registerLicense } from "@syncfusion/ej2-base";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { App } from "./App";
import {
  getServerBaseUrlForEnv,
  msalConfig,
  PROC_VERSION,
} from "./app/configs/appConfig";
import { getAuthHeader } from "./app/globalState/GlobalAuthState";
import { setupStore } from "./redux/store";

/**
 * MSAL should be instantiated outside the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

const baseGraphQLUrl = getServerBaseUrlForEnv();
console.info("index | baseGraphQLUrl", {
  baseGraphQLUrl,
  version: PROC_VERSION,
});

const httpLink = createHttpLink({
  uri: `${baseGraphQLUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...getAuthHeader(),
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          contacts: relayStylePagination(),
          customers: relayStylePagination(),
          jobs: relayStylePagination(),
          services: relayStylePagination(),
          bankDocsByLocation: relayStylePagination(),
        },
      },
    },
  }),
  connectToDevTools: false,
});

// noinspection SpellCheckingInspection
const sfLicenseKey =
  "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJiXn5XcnNXQGlVWUw=";
registerLicense(sfLicenseKey);
const store = setupStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App msalInstance={msalInstance} apolloClient={apolloClient} />
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// React 18 doesn't work with SF charts
// const container = document.getElementById("root");
// if (container) {
//   const root = createRoot(container);
//   root.render(
//     // <React.StrictMode>
//     <App msalInstance={msalInstance} apolloClient={apolloClient} />,
//     // </React.StrictMode>,
//   );
// }
