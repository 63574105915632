import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const PaginationsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Wip - Pagination"}>
      <ComponentSectionLayout title={"default"}></ComponentSectionLayout>
    </ComponentLayout>
  );
};
