import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getInitialsFromFullName } from "../../../constants/constants";
import {
  useDeletePaymentMutation,
  useGetPaymentByIdQuery,
  useGetPaymentStatusesQuery,
  useUpdatePaymentMutation,
} from "../../../services/BillingService";
import { FormSection } from "../../../support/FormSection";
import DropStatus from "../DropStatus";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";

interface Props {
  setView: React.Dispatch<React.SetStateAction<"details" | "edit">>;
}

const PaymentDetails: React.FC<Props> = ({ setView }) => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const { data: payment, isLoading: isLoadingPayment } = useGetPaymentByIdQuery(
    typeof id === "string" && id !== "create" ? id : "",
  );

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetPaymentStatusesQuery(getLinkedFirm()?.orgId || "");

  const [deletePayment, { isSuccess: isSuccessDelete }] =
    useDeletePaymentMutation();

  const [updatePayment, { isLoading: isLoadingUpdate }] =
    useUpdatePaymentMutation();

  const updateStatus = (statusId: string) => {
    updatePayment({
      id: payment?.id || "",
      paymentStatusId: statusId || null,
      paymentDate: payment?.paymentDate,
      amount: payment?.amount,
      notes: payment?.notes,
      paymentMethodId: payment?.paymentMethodId,
      customerId: payment?.customerId,
      paymentItem: payment?.paymentItem,
    });
  };

  const total = () => {
    let value = 0;
    payment?.paymentItem?.forEach((item) => (value += item?.amountPaid));
    return value;
  };

  useEffect(() => {
    if (isSuccessDelete) {
      navigate("/billing/payments");
    }
  }, [isSuccessDelete]);

  if (isLoadingPayment || isLoadingStatuses) {
    return <LottieLoading />;
  }

  if (!payment) {
    return null;
  }

  const tableRows =
    payment &&
    payment?.paymentItem?.map((item, index) => {
      return (
        <tr
          key={index}
          onClick={() => navigate(`/billing/invoices/${item?.invoiceId}`)}
          className={
            "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
          }>
          <td
            className={
              "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
            }>
            {item?.invoiceNo}
          </td>
          <td
            className={
              "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
            }>
            {moment(item?.invoiceDueDate).format("MM/DD/YYYY")}
          </td>
          <td
            className={
              "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
            }>
            $
            {(item?.invoiceTotal || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          <td
            className={
              "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
            }>
            $
            {(item.invoiceBalance || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          <td
            className={
              "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
            }>
            {(item?.amountPaid || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
      );
    });

  return (
    <FormSection
      name={"Record payment"}
      classForTitle={"p-0"}
      RightSideComponent={
        <div className={"flex items-center gap-4"}>
          <DropStatus
            invoiceStatus={null}
            isLoading={isLoadingUpdate || isLoadingPayment}
            paymentStatus={payment?.paymentStatus || null}
            statuses={statuses || []}
            selectStatus={(status) => {
              updateStatus(status?.id || "");
            }}
          />
          <div
            className={
              "border-purple relative flex items-center justify-center rounded-[8px] border-[1px] transition-all duration-200"
            }>
            <EditDots extraPosition={"top-[calc(100%+4px)] right-0"}>
              <div className={""}>
                <div
                  onClick={() => setView("edit")}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Edit
                </div>
                <div
                  onClick={() => {
                    if (payment) {
                      deletePayment(payment);
                    }
                  }}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Delete
                </div>
              </div>
            </EditDots>
          </div>
        </div>
      }>
      <div>
        <div className={"mb-4"}>
          <div className={"mb-2 text-base font-semibold text-gray-800"}>
            Client
          </div>
          <div className={"min-w-[200px]"}>
            <div className={"flex  items-center"}>
              <AvatarPlaceholder
                size={"extra-small"}
                type={payment?.customer?.businessContactId ? "purple" : "blue"}
                label={getInitialsFromFullName(payment?.customer?.name || "")}
              />
              <div
                onClick={() =>
                  navigate(
                    navigateToContact({
                      type: payment?.customer?.businessContactId
                        ? "organization"
                        : "contact",
                      customerId: payment?.customer?.id || "",
                      id: payment?.customer?.businessContactId || "",
                    }),
                  )
                }
                className={
                  "link link-primary max-w-[300px] cursor-pointer pl-2 text-base font-semibold hover:underline"
                }>
                {payment?.customer?.name}
              </div>
            </div>
          </div>
        </div>
        <div className={"flex gap-4"}>
          <div>
            <div className={"mb-2 text-base font-semibold text-gray-800"}>
              Payment date
            </div>
            <div>{moment(payment?.paymentDate).format("MM/DD/YYYY")}</div>
          </div>
          <div>
            <div className={"mb-2 text-base font-semibold text-gray-800"}>
              Payment method
            </div>
            <div>{payment?.paymentMethod?.paymentMethod}</div>
          </div>
        </div>
      </div>
      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Invoice #
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Due date
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Original amount
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Balance
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case"}>
                Amount paid
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={"text-base"}>{tableRows}</tbody>
      </table>
      <div className={"flex w-full justify-between"}>
        <div>
          <div className={"mb-2 text-base font-semibold text-gray-800"}>
            Notes
          </div>
          <div
            className={
              "textarea textarea-bordered min-h-[75px] min-w-[300px] max-w-[500px] break-all"
            }>
            {payment?.notes}
          </div>
        </div>
        <div className={"text-base font-semibold text-gray-800"}>
          Total: $
          {(total() || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    </FormSection>
  );
};

export default PaymentDetails;
