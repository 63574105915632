import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const FormTogglesDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Wip - Form Toggles"}>
      <ComponentSectionLayout title={"default"}></ComponentSectionLayout>
    </ComponentLayout>
  );
};
