import React, { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { IUser, IWorkNote } from "../../../types/types";
import {
  useAddWorkNoteMutation,
  useUpdateWorkNoteMutation,
} from "../../../slices/WorkNotesSlice";
import { Mention, MentionsInput } from "react-mentions";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import {
  useGetUsersQuery,
  useMentionNotificationMutation,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { getInitials } from "../../../constants/constants";
import { useAppSelector } from "../../../redux/redux";

const mentionStyles = (hasMentions?: boolean) => {
  return {
    control: {
      backgroundColor: "#fff",
      fontSize: 16,
    },

    "&multiLine": {
      control: {
        fontFamily: "inherit",
        minHeight: 60,
      },
      highlighter: {
        padding: "10px 20px",
        fontSize: 14,
        fontWeight: 400,
      },
      input: {
        padding: "10px 20px",
        border: "1px solid #D2D4D7",
        outline: "none",
        borderRadius: 7,
        fontFamily: "inherit",
        fontSize: 14,
        fontWeight: 400,
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "1px solid #D2D4D7",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
        borderRadius: 7,
        maxHeight: 200,
        overflowY: "auto",
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        cursor: hasMentions ? "pointer" : "not-allowed",
        "&focused": {
          backgroundColor: hasMentions ? "#cee4e5" : "",
        },
      },
    },
  };
};

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: IWorkNote;
  workId?: string;
  participants: { id: string; userId: string; user: IUser }[];
};

export const WorkNoteModal: FC<Props> = ({
  isActive,
  onClose,
  defaultValues,
  workId,
  participants,
}) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const { userInfoService } = useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [mentions, setMentions] = useState<{ id: string; name: string }[]>([]);
  const [formState, setFormState] = useState({
    title: defaultValues?.title || "",
    note: defaultValues?.note || "",
  });

  const { data: users } = useGetUsersQuery(getLinkedFirm()?.orgId || "");

  const userOptions = () => {
    return (
      users?.map((user) => ({
        id: `${user.id}`,
        display: `${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`,
        avatar: getInitials(
          user?.userProfile?.firstName,
          user?.userProfile?.lastName,
        ),
      })) || []
    );
  };

  const [
    addWorkNote,
    { isLoading: isLoadingWorkAdd, isSuccess: isSuccessWorkAdd },
  ] = useAddWorkNoteMutation();
  const [
    updateWorkNote,
    { isLoading: isLoadingWorkUpdate, isSuccess: isSuccessWorkUpdate },
  ] = useUpdateWorkNoteMutation();
  const [sendNotifications] = useMentionNotificationMutation();

  const onSubmit = () => {
    if (
      (formState.note || formState.title) &&
      !isLoadingWorkAdd &&
      !isLoadingWorkUpdate
    ) {
      if (defaultValues) {
        updateWorkNote({
          ...defaultValues,
          note: formState.note,
          title: formState.title,
        });
      } else {
        addWorkNote({
          title: formState.title,
          note: formState.note,
          createdByUserRelayId: `${userInfoByEmail?.id}`,
          workId: workId || "",
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccessWorkAdd || isSuccessWorkUpdate) {
      onClose();
      const users = [
        ...new Set([
          ...mentions?.map((user) => user.id),
          ...participants?.map((participant) => participant.userId),
        ]),
      ];
      sendNotifications({
        workId: `${workId}`,
        userId: `${user?.id}`,
        userIds: users,
      });
    }
  }, [isSuccessWorkAdd, isSuccessWorkUpdate]);

  const customSuggestion = (props: any) => {
    return (
      <div
        {...props}
        className={
          "flex max-w-[200px] items-center gap-2 text-sm text-gray-500"
        }>
        <AvatarPlaceholder size={"2xs"} label={props?.avatar} />
        <div>{props?.display}</div>
      </div>
    );
  };

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box p-0"}>
        <div
          className={
            "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
          }>
          {defaultValues ? "Update" : "Add"} note
        </div>
        <div className={"px-5 py-4"}>
          <div className={"mb-4"}>
            <input
              type={"text"}
              value={formState.title}
              onChange={(e) =>
                setFormState((prev) => ({ ...prev, title: e.target.value }))
              }
              className={"input input-bordered w-full"}
              placeholder={"Title"}
            />
          </div>
          <div>
            <MentionsInput
              a11ySuggestionsListLabel={"Suggested mentions"}
              placeholder={"Add a note"}
              style={mentionStyles(true)}
              value={formState.note}
              className={"min-h-[150px]"}
              onChange={(e) => {
                setFormState({ ...formState, note: e.target.value });
              }}>
              <Mention
                trigger={"@"}
                onAdd={(id, display) => {
                  setMentions([...mentions, { id: `${id}`, name: display }]);
                }}
                data={userOptions()}
                markup={"@[__display__]"}
                style={{
                  backgroundColor: "#cee4e5",
                }}
                renderSuggestion={customSuggestion}
              />
            </MentionsInput>
          </div>
          <div className={"mt-4 flex justify-end gap-4"}>
            <Button
              label={defaultValues ? "Update" : "Add"}
              onClick={onSubmit}
              disabled={isLoadingWorkAdd || isLoadingWorkUpdate}
            />
            <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
          </div>
        </div>
      </div>
    </div>
  );
};
