import * as Xstate from "@xstate/react";
import React from "react";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { OnboardingView } from "../onboarding/OnboardingView";
import { MainView } from "./MainView";
import { UserAuthLoadingMainView } from "./UserAuthLoadingMainView";
import { UserInfoLoadingMainView } from "./UserInfoLoadingMainView";

export const LoadingMainView: React.FC<unknown> = () => {
  const { userAuthService, userInfoService, onboardingInfoService } =
    React.useContext(GlobalContext);
  const isUserAuthEmptyState = Xstate.useSelector(
    userAuthService,
    (state) => state.context.userAuthInfo === undefined,
  );
  const isUserInfoEmptyState = Xstate.useSelector(
    userInfoService,
    (state) => state.context.userInfoByEmail === undefined,
  );
  const isOnboardingIncompleteState = Xstate.useSelector(
    onboardingInfoService,
    (state) => {
      const { onboardingStatus } = state.context;
      return (
        onboardingStatus === undefined ||
        !onboardingStatus.orgIsOnboarded ||
        !onboardingStatus.userIsOnboarded
      );
    },
  );

  if (isUserAuthEmptyState) {
    return (
      <div>
        <UserAuthLoadingMainView />
        <LottieLoading />
      </div>
    );
  }

  if (!isUserAuthEmptyState && isUserInfoEmptyState) {
    return (
      <div>
        <UserInfoLoadingMainView />
        <LottieLoading />
      </div>
    );
  }

  if (!isUserInfoEmptyState && isOnboardingIncompleteState) {
    return (
      <div>
        <OnboardingView />
      </div>
    );
  }

  return (
    <>
      <UserAuthLoadingMainView />
      <MainView />
    </>
  );
};
