import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const NavbarsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Navbar"}>
      <ComponentSectionLayout title={"default"}>
        <div
          className={
            "navbar rounded-box mb-2 bg-secondary text-neutral-content shadow-lg"
          }>
          <div className={"hidden flex-none lg:flex"}>
            <button className={"btn btn-ghost btn-square"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-6 w-6 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={"M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
          <div className={"mx-2 hidden flex-1 px-2 lg:flex"}>
            <span className={"text-lg font-bold"}>daisyUI</span>
          </div>
          <div className={"flex-1 lg:flex-none"}>
            <div className={"form-control"}>
              <input
                type={"text"}
                placeholder={"Search"}
                className={"input input-ghost"}
              />
            </div>
          </div>
          <div className={"flex-none"}>
            <button className={"btn btn-ghost btn-square"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-6 w-6 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={"M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"}
                />
              </svg>
            </button>
          </div>
          <div className={"flex-none"}>
            <button className={"btn btn-ghost btn-square"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"inline-block h-6 w-6 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  }
                />
              </svg>
            </button>
          </div>
          <div className={"flex-none"}>
            <div className={"avatar"}>
              <div className={"m-1 h-10 w-10 rounded-full"}>
                <img alt={"foo"} src={"https://i.pravatar.cc/500?img=32"} />
              </div>
            </div>
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
