import React from "react";

const IndividualContactSvg = () => {
  return (
    <svg
      width={"39"}
      height={"39"}
      viewBox={"0 0 39 39"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M8.125 32.5V30.875C8.125 27.8582 9.32343 24.9649 11.4567 22.8317C13.5899 20.6984 16.4832 19.5 19.5 19.5M19.5 19.5C22.5168 19.5 25.4101 20.6984 27.5433 22.8317C29.6766 24.9649 30.875 27.8582 30.875 30.875V32.5M19.5 19.5C21.2239 19.5 22.8772 18.8152 24.0962 17.5962C25.3152 16.3772 26 14.7239 26 13C26 11.2761 25.3152 9.62279 24.0962 8.40381C22.8772 7.18482 21.2239 6.5 19.5 6.5C17.7761 6.5 16.1228 7.18482 14.9038 8.40381C13.6848 9.62279 13 11.2761 13 13C13 14.7239 13.6848 16.3772 14.9038 17.5962C16.1228 18.8152 17.7761 19.5 19.5 19.5Z"
        }
        stroke={"white"}
        strokeWidth={"2.875"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  );
};

export default IndividualContactSvg;
