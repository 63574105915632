import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import { BaseProvider, LightTheme } from "baseui";
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import { reactPlugin } from "./app/insights/AppInsightsPlugin";
import { bankDocDetailsMachine } from "./app/stateMachines/BankDocDetailsMachine";
import { GlobalContext } from "./app/stateMachines/GlobalContext";
import { OnboardingInfoMachine } from "./app/stateMachines/OnboardingInfoMachine";
import { userAuthMachine } from "./app/stateMachines/UserAuthMachine";
import { UserInfoMachine } from "./app/stateMachines/UserInfoMachine";
import { ComponentsRoute } from "./pages/components/ComponentsRoute";
import { MainRoute } from "./pages/main/MainRoute";
import { ScrollToTop } from "./support/ScrollToTop";
import "../src/features/contacts/modals/ComposeEmailModal.css";
import { createSignalRContext } from "react-signalr";

type Props = {
  msalInstance: PublicClientApplication;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

// import { inspect as xstateInspect } from "@xstate/inspect";
// xstateInspect({
//   // options
//   // url: 'https://statecharts.io/inspect', // (default)
//   iframe: false, // open in new window
// });

const engine = new Styletron();
export const SignalRContext = createSignalRContext();

export const App: React.FC<Props> = (props) => {
  const { msalInstance, apolloClient } = props;
  const userAuthService = Xstate.useInterpret(userAuthMachine, {
    devTools: false,
  });
  const userInfoService = Xstate.useInterpret(UserInfoMachine, {
    devTools: false,
  });
  const onboardingInfoService = Xstate.useInterpret(OnboardingInfoMachine, {
    devTools: false,
  });
  const bankDocDetailsService = Xstate.useInterpret(bankDocDetailsMachine, {
    devTools: false,
  });
  const globalContextValues = {
    userAuthService,
    userInfoService,
    onboardingInfoService,
    bankDocDetailsService,
  };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <GlobalContext.Provider value={globalContextValues}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <ApolloProvider client={apolloClient}>
              <BrowserRouter>
                <ScrollToTop />
                <MsalProvider instance={msalInstance}>
                  <Routes>
                    {MainRoute()}
                    {ComponentsRoute()}
                  </Routes>
                </MsalProvider>
              </BrowserRouter>
            </ApolloProvider>
          </BaseProvider>
        </StyletronProvider>
      </GlobalContext.Provider>
    </AppInsightsContext.Provider>
  );
};
