import { addDays, format, formatRelative, isAfter, subYears } from "date-fns";

// constants
const TODAY_EPOCH = new Date().setHours(0, 0, 0, 0);
const TODAY = new Date(TODAY_EPOCH);

export const TODAY_UTC = () => new Date(TODAY.toUTCString());

export const NOW_UTC = () => new Date(new Date().toUTCString());

export const DATES = {
  on1980: new Date(1980, 0, 1), // month 0 to 11
  today: NOW_UTC,
  minus5Years: subYears(NOW_UTC(), 6),
  tomorrow: addDays(NOW_UTC(), 1),
};

export const DATE_FORMATS = {
  dateOnly: "yyyy/MM/dd",
  // QBO recommended format
  // https://quickbooks.intuit.com/learn-support/en-ca/help-article/bank-transactions/format-csv-files-excel-get-bank-transactions/L4BjLWckq_CA_en_CA
  bankDateOnly: "dd/MM/yyyy",
};

// functions
export const getDateOnlyStr = (
  dateStr: string,
  dateFormat: string = DATE_FORMATS.dateOnly,
): string => {
  return getDateOnly(new Date(dateStr), dateFormat);
};

export const getDateOnly = (
  date: Date,
  dateFormat: string = DATE_FORMATS.dateOnly,
): string => {
  return format(date, dateFormat);
};

export const getRelativeDateStr = (dateStr: string) => {
  return getRelativeDate(new Date(dateStr));
};

export const getRelativeDate = (date: Date) => {
  return formatRelative(date, new Date());
};

export const getIsoDateStr = (dateStr: string) =>
  new Date(dateStr).toISOString();

export const isDateInFuture = (date: Date) => {
  return isAfter(date, NOW_UTC());
};

export const getUtcFromLocal = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const getLocalFromUtc = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getLocalFromUtcs = (date: Date[]) => {
  return date.map(getLocalFromUtc);
};
