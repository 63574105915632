import { IPayment } from "../../services/BillingService";
import { CommonFactoryInterface } from "../common-factory-type";
import { UpdatePaymentStatusDto } from "./factory-dtos";

class UpdatePaymentStatusFactory
  implements
    CommonFactoryInterface<
      {
        payment: IPayment;
        status: string | null;
      },
      UpdatePaymentStatusDto
    >
{
  createModelFromDto = (dto: {
    payment: IPayment;
    status: string | null;
  }): UpdatePaymentStatusDto => {
    return {
      id: dto?.payment?.id || "",
      paymentStatusId: dto?.status || null,
      paymentDate: dto?.payment?.paymentDate,
      amount: dto?.payment?.amount,
      notes: dto?.payment?.notes || "",
      paymentMethodId: dto?.payment?.paymentMethodId,
      customerId: dto?.payment?.customerId,
      paymentItem: dto?.payment?.paymentItem,
    };
  };
}

export const updatePaymentStatusFactory = new UpdatePaymentStatusFactory();
