import { gql } from "@apollo/client";

export const GET_ONBOARDING_STATUS_GQL = gql`
  query GetOnboardingStatus($orgId: ID!, $userId: ID!) {
    orgIsOnboarded(id: $orgId)
    userIsOnboarded(id: $userId)
  }
`;

export const ONBOARD_USER_GQL = gql`
  mutation OnboardUser($onboardUserInput: OnboardUserInput!) {
    onboardUser(input: $onboardUserInput) {
      user {
        id
      }
    }
  }
`;

export const ONBOARD_ORG_GQL = gql`
  mutation OnboardOrg($onboardOrgInput: OnboardOrgInput!) {
    onboardOrg(input: $onboardOrgInput) {
      org {
        id
      }
    }
  }
`;
