import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const AccordionDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"breadcrumbs"}>
      <ComponentSectionLayout title={"default"}>
        <div
          tabIndex={0}
          className={"collapse rounded-box w-96 border border-base-300"}>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={"collapse rounded-box w-96 border border-base-300"}>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={"collapse rounded-box w-96 border border-base-300"}>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with arrow"}>
        <div
          tabIndex={0}
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with plus minus"}>
        <div
          tabIndex={0}
          className={
            "collapse collapse-plus rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            "collapse collapse-plus rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          tabIndex={0}
          className={
            "collapse collapse-plus rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I open with focus
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with checkbox"}>
        <div
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <input type={"checkbox"} />
          <div className={"collapse-title text-xl font-medium"}>
            I open/close with click
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <input type={"checkbox"} />
          <div className={"collapse-title text-xl font-medium"}>
            I open/close with click
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <input type={"checkbox"} />
          <div className={"collapse-title text-xl font-medium"}>
            I open/close with click
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"force open"}>
        <div
          className={
            "collapse-arrow collapse collapse-open rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always open
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-arrow collapse collapse-open rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always open
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-arrow collapse collapse-open rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always open
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"force close"}>
        <div
          className={
            "collapse-close collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always close
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-close collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always close
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
        <div
          className={
            "collapse-close collapse-arrow collapse rounded-box w-96 border border-base-300"
          }>
          <div className={"collapse-title text-xl font-medium"}>
            I'm always close
          </div>
          <div className={"collapse-content"}>
            <p>
              Collapse content reveals with focus. If you add a checkbox, you
              can control it using checkbox instead of focus. Or you can
              force-open/force-close using
              <span className={"badge badge-outline"}>collapse-open</span> and
              <span className={"badge badge-outline"}>collapse-close</span>{" "}
              classes.
            </p>
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
