import classNames from "classnames";
import { FC } from "react";
import { match } from "ts-pattern";

type Props = {
  title?: string;
  extraClasses?: string;
  fullWidth?: boolean;
  type?: "filled" | "outlined";
  color?: { borderColor: string; bgColor: string };
  children?: JSX.Element;
};

export const Card: FC<Props> = ({
  title,
  extraClasses,
  fullWidth = true,
  type = "outlined",
  color = { borderColor: "border-base-300", bgColor: "bg-base-100" },
  children,
}) => {
  const typeClasses = match(type)
    .with("filled", () => `border ${color?.borderColor} ${color?.bgColor}`)
    .with("outlined", () => `border bg-base-100 ${color?.borderColor}`)
    .exhaustive();

  return (
    <div
      className={classNames(
        "overflow-hidden rounded-[10px]",
        typeClasses,
        fullWidth ? "w-full" : "w-fit",
        extraClasses,
      )}>
      {title && <div className={"text-lg font-bold"}>{title}</div>}
      {children}
    </div>
  );
};
