import { ContactPages } from "./contact-routes";
import { ContactsPages } from "./contacts-routes";
import { DashboardPages } from "./dashboard-routes";
import { HomePages } from "./home-routes";
import { OrganizationPages } from "./organization-routes";
import { WorkPages } from "./work-routes";

export const Pages = {
  //Home
  home: HomePages,
  //Dashboard
  dashboard: DashboardPages,
  //Contacts
  contacts: ContactsPages,
  contact: ContactPages,
  organization: OrganizationPages,
  //Works

  //Work
  work: WorkPages,
};
