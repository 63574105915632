import React, { FC, useEffect } from "react";

import Button from "../../../components/button/Button";
import { useDeleteUserMutation } from "../../../slices/UserSlice";
import { IUser } from "../../../types/types";

type Props = {
  user: IUser;
  onClose: () => void;
  isActive: boolean;
};

const DeleteUserModal: FC<Props> = ({ user, onClose, isActive }) => {
  const [deleteUser, { isSuccess, isError }] = useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-fit max-w-[90%]"}>
        <div className={"mb-4 text-base font-semibold text-gray-800"}>
          {user?.userProfile?.firstName} {user?.userProfile?.lastName}
        </div>
        <div className={"mb-4 max-w-[300px] text-sm text-gray-500"}>
          Deleting this user{" "}
          <span className={"font-bold"}>
            will permanently delete all the work items
          </span>{" "}
          associated with them. Are you sure you want to proceed?
        </div>
        {isError && (
          <div className={"text-md mb-2 text-red-500"}>
            Something went wrong
          </div>
        )}
        <div className={"flex w-full justify-end gap-4"}>
          <Button
            colorType={"secondary"}
            label={"Confirm"}
            onClick={() => deleteUser(user)}
            extraClasses={"normal-case"}
          />
          <Button
            colorType={"outline"}
            label={"Cancel"}
            onClick={onClose}
            extraClasses={"normal-case"}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
