import React from "react";

import { BrandButton } from "../../components/button/BrandButton";
import { IconButton } from "../../components/button/IconButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import { ArrowChevronDownSvg } from "../../components/svg/ArrowChevronDownSvg";
import { ArrowChevronRightSvg } from "../../components/svg/ArrowChevronRightSvg";
import { ChevronDoubleLeftSvg } from "../../components/svg/ChevronDoubleLeftSvg";
import { ContactsSvg } from "../../components/svg/ContactsSvg";
import { CustomersSvg } from "../../components/svg/CustomersSvg";
import { DocumentSvg } from "../../components/svg/DocumentSvg";
import { EditSvg } from "../../components/svg/EditSvg";
import { HamburgerSvg } from "../../components/svg/HamburgerSvg";
import { HomeSvg } from "../../components/svg/HomeSvg";
import { InfoSvg } from "../../components/svg/InfoSvg";
import { InvoicesSvg } from "../../components/svg/InvoicesSvg";
import { JobsSvg } from "../../components/svg/JobsSvg";
import { NotificationSvg } from "../../components/svg/NotificationSvg";
import { SearchSvg } from "../../components/svg/SearchSvg";
import { ServicesSvg } from "../../components/svg/ServicesSvg";
import { SettingsSvg } from "../../components/svg/SettingsSvg";
import { SignOutSvg } from "../../components/svg/SignOutSvg";
import { SuccessCheckSvg } from "../../components/svg/SuccessCheckSvg";
import { TimeSvg } from "../../components/svg/TimeSvg";
import { WarningExclaimSvg } from "../../components/svg/WarningExclaimSvg";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const ButtonsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Buttons"}>
      {/* Brand buttons */}
      <ComponentSectionLayout title={"Brand Buttons - active"}>
        <BrandButton colorType={"primary"} label={"Primary"} />
        <BrandButton colorType={"secondary"} label={"Secondary"} />
        <BrandButton colorType={"accent"} label={"Accent"} />
        <BrandButton colorType={"ghost"} label={"Ghost"} />
        <BrandButton colorType={"link"} label={"Link"} />
        <BrandButton colorType={"neutral"} label={"Neutral"} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Brand Buttons - sizes"}>
        <BrandButton colorType={"primary"} label={"large"} size={"large"} />
        <BrandButton colorType={"secondary"} label={"medium"} />
        <BrandButton colorType={"accent"} label={"small"} size={"small"} />
        <BrandButton
          colorType={"neutral"}
          label={"x-small"}
          size={"extra-small"}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Brand Buttons - disabled"}>
        <BrandButton colorType={"primary"} label={"Primary"} disabled={true} />
        <BrandButton
          colorType={"secondary"}
          label={"Secondary"}
          disabled={true}
        />
        <BrandButton colorType={"accent"} label={"Accent"} disabled={true} />
        <BrandButton colorType={"ghost"} label={"Ghost"} disabled={true} />
        <BrandButton colorType={"link"} label={"Link"} disabled={true} />
        <BrandButton colorType={"neutral"} label={"Neutral"} disabled={true} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Brand Buttons - width"}>
        <BrandButton
          colorType={"primary"}
          label={"Primary"}
          wide={true}
          size={"extra-small"}
        />
        <BrandButton
          colorType={"secondary"}
          label={"Secondary"}
          wide={true}
          size={"small"}
        />
        <BrandButton
          colorType={"accent"}
          label={"Accent"}
          wide={true}
          size={"medium"}
        />
        <BrandButton
          colorType={"ghost"}
          label={"Ghost"}
          wide={true}
          size={"large"}
        />
        <BrandButton colorType={"link"} label={"Link"} wide={true} />
        <BrandButton colorType={"neutral"} label={"Neutral"} wide={true} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Brand Buttons - icon left"}>
        <BrandButton
          colorType={"primary"}
          label={"Primary"}
          size={"large"}
          SvgIconLeft={AddStackSvg}
        />
        <BrandButton
          colorType={"secondary"}
          label={"Secondary"}
          size={"medium"}
          SvgIconLeft={AddStackSvg}
        />
        <BrandButton
          colorType={"accent"}
          label={"accent"}
          size={"small"}
          SvgIconLeft={AddStackSvg}
        />
        <BrandButton
          colorType={"link"}
          label={"link"}
          size={"extra-small"}
          SvgIconLeft={AddStackSvg}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Brand Buttons - icon right"}>
        <BrandButton
          colorType={"primary"}
          label={"Primary"}
          size={"large"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <BrandButton
          colorType={"secondary"}
          label={"Secondary"}
          size={"medium"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <BrandButton
          colorType={"accent"}
          label={"accent"}
          size={"small"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <BrandButton
          colorType={"link"}
          label={"link"}
          size={"extra-small"}
          SvgIconRight={ArrowChevronRightSvg}
        />
      </ComponentSectionLayout>

      {/* Outline buttons */}
      <ComponentSectionLayout title={"Outline buttons - active"}>
        <OutlineButton colorType={"primary"} label={"primary"} />
        <OutlineButton colorType={"secondary"} label={"secondary"} />
        <OutlineButton colorType={"accent"} label={"accent"} />
        <OutlineButton colorType={"neutral"} label={"neutral"} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline buttons - disabled"}>
        <OutlineButton
          colorType={"primary"}
          label={"primary"}
          disabled={true}
        />
        <OutlineButton
          colorType={"secondary"}
          label={"secondary"}
          disabled={true}
        />
        <OutlineButton colorType={"accent"} label={"accent"} disabled={true} />
        <OutlineButton
          colorType={"neutral"}
          label={"neutral"}
          disabled={true}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline buttons - sizes"}>
        <OutlineButton colorType={"primary"} label={"large"} size={"large"} />
        <OutlineButton colorType={"secondary"} label={"medium"} />
        <OutlineButton colorType={"accent"} label={"small"} size={"small"} />
        <OutlineButton
          colorType={"neutral"}
          label={"x-small"}
          size={"extra-small"}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline buttons - width"}>
        <OutlineButton
          colorType={"primary"}
          label={"large"}
          size={"large"}
          wide={true}
        />
        <OutlineButton colorType={"secondary"} label={"medium"} wide={true} />
        <OutlineButton
          colorType={"accent"}
          label={"small"}
          size={"small"}
          wide={true}
        />
        <OutlineButton
          colorType={"neutral"}
          label={"x-small"}
          size={"extra-small"}
          wide={true}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline Buttons - icon left"}>
        <OutlineButton
          colorType={"primary"}
          label={"Primary"}
          size={"large"}
          SvgIconLeft={AddStackSvg}
        />
        <OutlineButton
          colorType={"secondary"}
          label={"Secondary"}
          size={"medium"}
          SvgIconLeft={AddStackSvg}
        />
        <OutlineButton
          colorType={"accent"}
          label={"accent"}
          size={"small"}
          SvgIconLeft={AddStackSvg}
        />
        <OutlineButton
          colorType={"neutral"}
          label={"neutral"}
          size={"extra-small"}
          SvgIconLeft={AddStackSvg}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline Buttons - icon right"}>
        <OutlineButton
          colorType={"primary"}
          label={"Primary"}
          size={"large"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <OutlineButton
          colorType={"secondary"}
          label={"Secondary"}
          size={"medium"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <OutlineButton
          colorType={"accent"}
          label={"accent"}
          size={"small"}
          SvgIconRight={ArrowChevronRightSvg}
        />
        <OutlineButton
          colorType={"neutral"}
          label={"neutral"}
          size={"extra-small"}
          SvgIconRight={ArrowChevronRightSvg}
        />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Outline Icon Buttons"}>
        {[
          HomeSvg,
          CustomersSvg,
          DocumentSvg,
          SettingsSvg,
          AddStackSvg,
          ArrowChevronRightSvg,
          ArrowChevronDownSvg,
          ContactsSvg,
          EditSvg,
          HamburgerSvg,
          InvoicesSvg,
          JobsSvg,
          NotificationSvg,
          SearchSvg,
          ServicesSvg,
          SignOutSvg,
          SuccessCheckSvg,
          TimeSvg,
          WarningExclaimSvg,
          ChevronDoubleLeftSvg,
          InfoSvg,
        ].map((IconSvg, index) => {
          return (
            <div key={index} className={"flex space-x-4"}>
              <IconButton
                colorType={"primary"}
                size={"large"}
                IconSvg={IconSvg}
              />
              <IconButton
                colorType={"primary"}
                size={"medium"}
                IconSvg={IconSvg}
              />
              <IconButton
                colorType={"primary"}
                size={"small"}
                IconSvg={IconSvg}
              />
              <IconButton
                colorType={"primary"}
                size={"extra-small"}
                IconSvg={IconSvg}
              />
            </div>
          );
        })}
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
