import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const FormSelectsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Form Selects"}>
      <ComponentSectionLayout title={"default"}>
        <select className={"select select-bordered w-full max-w-xs"}>
          <option disabled selected>
            Choose your superpower
          </option>
          <option>telekinesis</option>
          <option>time travel</option>
          <option>invisibility</option>
        </select>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
