import * as Xstate from "@xstate/react";
import { ChevronDown, ChevronUp } from "baseui/icon";
import React, { useEffect, useRef, useState } from "react";

import { getWorkFlow } from "../../../../api/api";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { ISubStatus, IWorkflow } from "../../../../types/types";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";

export interface ISubStatusWithColor extends ISubStatus {
  color: string;
}

interface ISelectFilterStatusProps {
  values: (IWorkflow | ISubStatusWithColor)[];
  setArrayFilter: (array: (IWorkflow | ISubStatusWithColor)[]) => void;
  page?: "Works" | "Dashboard";
}

const SelectFilterStatus: React.FC<ISelectFilterStatusProps> = ({
  values,
  setArrayFilter,
  page = "Works",
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const selectFilterStatus = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [statuses, setStatuses] = useState<IWorkflow[]>([]);
  const [filterByStatus, setFilterByStatus] =
    useState<(IWorkflow | ISubStatusWithColor)[]>(values);

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      getWorkFlow(`${getLinkedFirm()?.orgId}`).then((res) =>
        setStatuses(res.filter((item: IWorkflow) => item.name !== "trial")),
      );
    }
  }, [getLinkedFirm()?.orgId]);

  useEffect(() => {
    setFilterByStatus(values);
  }, [values]);

  useOnClickOutside(selectFilterStatus, () => setIsOpen(false));

  const onChangeStatus = (
    status: IWorkflow | ISubStatusWithColor,
    checked: boolean,
  ) => {
    switch (page) {
      case "Works": {
        if (checked) {
          setFilterByStatus((prev) => [...prev, status]);
        } else {
          setFilterByStatus((prev) =>
            prev.filter((item) => {
              if ("name" in status) {
                return "name" in item ? item.name !== status.name : item;
              } else {
                return "subStatus" in item
                  ? item.subStatus !== status.subStatus
                  : item;
              }
            }),
          );
        }
        break;
      }
      case "Dashboard": {
        if (checked) {
          setArrayFilter([...values, status]);
        } else {
          setArrayFilter(
            values.filter((item) => {
              if ("name" in status) {
                return "name" in item ? item.name !== status.name : item;
              } else {
                return "subStatus" in item
                  ? item.subStatus !== status.subStatus
                  : item;
              }
            }),
          );
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (page === "Works") {
      setArrayFilter(filterByStatus);
    }
  }, [filterByStatus, page]);

  const findActiveItem = () => {
    if (filterByStatus.length > 0) {
      return (
        <div className={"flex items-center gap-2"}>
          <div className={"flex h-[22px] items-center"}>
            <div
              style={{ background: `${filterByStatus[0]?.color}` }}
              className={"h-[10px] w-[10px] rounded-full"}
            />
          </div>
          <div
            className={
              "max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap text-sm"
            }>
            {"name" in filterByStatus[0]
              ? filterByStatus[0]?.name
              : filterByStatus[0]?.subStatus}
            {filterByStatus.length > 1 && "..."}
          </div>
        </div>
      );
    } else {
      return <div>All</div>;
    }
  };

  return (
    <div>
      <div className={"mb-0.5 text-base"}>Status</div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`relative z-[1] h-fit w-[200px] cursor-pointer rounded-[8px] border-[1px] bg-white px-5 py-2 ${
          isOpen ? "border-purple-700" : "border-gray-300"
        }`}
        ref={selectFilterStatus}>
        <div className={"flex  w-full items-center justify-between"}>
          <div className={"text-sm"}>{findActiveItem()}</div>
          {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </div>
        {isOpen && (
          <div
            className={
              "absolute left-[0px] z-[1] mt-[12px] max-h-[300px] w-full overflow-y-auto rounded-[8px] bg-white py-4 shadow-filter "
            }>
            {statuses.map((status) => (
              <div key={status.id}>
                <div
                  className={
                    "mb-2 flex cursor-pointer items-start justify-between gap-4 px-4 font-semibold hover:bg-gray-100"
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    onChangeStatus(
                      status,
                      !filterByStatus.some(
                        (item) => "name" in item && item.name === status.name,
                      ),
                    );
                  }}>
                  <div className={"flex gap-2"}>
                    <div className={"flex h-[21px] items-center"}>
                      <input
                        type={"checkbox"}
                        className={"checkbox checkbox-accent"}
                        checked={filterByStatus.some(
                          (item) => "name" in item && item.name === status.name,
                        )}
                        onChange={() => null}
                      />
                    </div>
                    <div className={"flex h-[22px] items-center"}>
                      <div
                        style={{ background: `${status.color}` }}
                        className={"h-[10px] w-[10px] rounded-full"}
                      />
                    </div>
                    <div className={"text-sm font-bold"}>{status.name}</div>
                  </div>
                </div>
                {status.workTypeSubStatuses.map((sub) => (
                  <div
                    key={sub.id}
                    className={
                      "mb-2 flex cursor-pointer items-start justify-between gap-4 px-4 hover:bg-gray-100"
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      onChangeStatus(
                        { ...sub, color: status.color },
                        !filterByStatus.some(
                          (item) =>
                            "subStatus" in item &&
                            item.subStatus === sub.subStatus,
                        ),
                      );
                    }}>
                    <div className={"flex gap-2"}>
                      <div className={"flex h-[21px] items-center"}>
                        <input
                          className={"checkbox checkbox-accent cursor-pointer"}
                          type={"checkbox"}
                          checked={filterByStatus.some(
                            (item) =>
                              "subStatus" in item &&
                              item.subStatus === sub.subStatus,
                          )}
                          onChange={() => null}
                        />
                      </div>
                      <div className={"flex h-[22px] items-center"}>
                        <div
                          style={{ background: `${status.color}` }}
                          className={"h-[10px] w-[10px] rounded-full"}
                        />
                      </div>
                      <div className={"text-sm font-semibold"}>
                        {sub.subStatus}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFilterStatus;
