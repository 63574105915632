import React from "react";

import { LABELS } from "../../../app/constants/TextConstants";
import { AlertSimple } from "../../../components/alerts/AlertSimple";

export const BankDocStatusMessageView: React.FC<{
  statusType: string;
  blobUri?: string;
  resultTokenId?: string;
  onRefreshStatus: () => void;
}> = ({ statusType, blobUri, resultTokenId, onRefreshStatus }) => {
  const getPendingMessage = (): string | undefined => {
    if (!statusType.startsWith("pending")) return;

    if (statusType === "pending-processing") {
      return LABELS.messages.bankDocs.status.pendingProcessing;
    }

    if (statusType === "pending-ai-learning-too-long") {
      return LABELS.messages.bankDocs.status.pendingAiLearningTooLong;
    }

    return LABELS.messages.bankDocs.status.pendingNotUploaded;
  };
  const pendingMessage = getPendingMessage();

  if (pendingMessage) {
    return (
      <AlertSimple
        label={"Status: Pending"}
        type={"info"}
        actionButton1={{ name: "Refresh", onClick: onRefreshStatus }}>
        {pendingMessage}
      </AlertSimple>
    );
  }

  const getFailureMessage = (): string | undefined => {
    if (!statusType.startsWith("failure")) return;

    if (statusType === "failure-exceeded-file-size") {
      return LABELS.messages.bankDocs.status.failureExceededSize;
    }
    if (statusType === "failure-exceeded-page-count") {
      return LABELS.messages.bankDocs.status.failureExceededPageCount;
    }
    if (statusType === "failure-unsupported-bank") {
      return LABELS.messages.bankDocs.status.failureUnsupportedBank;
    }
    if (statusType === "failure-virus-infected") {
      return LABELS.messages.bankDocs.status.failureVirusInfected;
    }
    if (statusType === "failure-invalid-format") {
      return LABELS.messages.bankDocs.status.failureInvalidFormat;
    }

    return LABELS.messages.bankDocs.status.failureDefault;
  };

  const failureMessage = getFailureMessage();

  if (failureMessage) {
    return (
      <AlertSimple label={"Status: Failed"} type={"info"}>
        {failureMessage}
      </AlertSimple>
    );
  }

  if (blobUri === null || resultTokenId === null) {
    return (
      <AlertSimple label={"Status: Pending"} type={"info"}>
        Waiting for results ...
      </AlertSimple>
    );
  }

  return null;
};
