import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EmptyDetails } from "./components/EmptyDetails";
import { EmailDetails } from "./EmailDetails";
import { EmailList } from "./EmailsList";
import { Email } from "../../factories/emails/models/emails.models";
import { Card } from "../../components/card/Card";
import { Pagination } from "baseui/pagination";
import { SelectOptionType } from "../../types/types";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import CustomSelect from "../../components/select/Select";
import classNames from "classnames";
import { useAppSelector } from "../../redux/redux";

type Props = {
  emails: Email[];
  isLoading: boolean;
  hidePages?: boolean;
  page?: number;
  setPage?: (page: number) => void;
  setPages?: (pages: string[]) => void;
  folder?: SelectOptionType | null;
  setFolder?: (folder: SelectOptionType | null) => void;
  folderOptions?: () => SelectOptionType[];
  selectedMail: Email | null;
  setSelectedMail?: Dispatch<SetStateAction<Email | null>>;
  work?: boolean;
  contactPage?: boolean;
};

export const EmailsListView: React.FC<Props> = ({
  emails,
  isLoading,
  page,
  setPage,
  setPages,
  folder,
  setFolder,
  folderOptions,
  selectedMail,
  setSelectedMail,
  hidePages,
  work,
  contactPage,
}) => {
  const hideEmails = !emails?.length;
  const totalPages = Math.ceil((folder?.totalCount || 0) / 30) || 1;
  const { user } = useAppSelector((state) => state.appReducer);

  useEffect(() => {
    if (selectedMail) {
      const updatedEmail = emails?.find((item) => item.id === selectedMail.id);
      if (updatedEmail && setSelectedMail) {
        setSelectedMail(updatedEmail);
      }
    }
  }, [emails]);

  return (
    <div
      className={
        "grid h-[calc(100vh-230px)] grid-cols-[30fr_70fr] grid-rows-1 gap-4 "
      }>
      <Card>
        <div
          className={classNames(
            "grid h-full grid-cols-1 pt-2",
            folder ? "grid-rows-[49px_1fr_52px]" : "grid-rows-[1fr_52px] ",
          )}>
          {folder && folderOptions && setFolder && (
            <div className={"w-full border-b border-gray-300 "}>
              <div className={"w-full px-2"}>
                <CustomSelect
                  options={folderOptions()}
                  value={folder}
                  small={true}
                  mail={true}
                  onChange={(newValue) => {
                    const newFolder = newValue as SelectOptionType;
                    setPage && setPage(1);
                    setPages && setPages(["1"]);
                    setFolder(newFolder);
                  }}
                  fullWidth
                />
              </div>
            </div>
          )}
          {isLoading ? (
            <div className={"h-full"}>
              <LottieLoading />
            </div>
          ) : hideEmails || (!user.syncEmails && contactPage) ? (
            <div className={"flex h-full items-center justify-center pt-4"}>
              <div className={"text-base font-semibold text-gray-500"}>
                No new messages
              </div>
            </div>
          ) : (
            <EmailList
              emails={emails || []}
              setSelectedMail={setSelectedMail}
              selectedMail={selectedMail}
            />
          )}
          {contactPage && !user.syncEmails ? (
            <div></div>
          ) : (
            !!emails?.length &&
            !hidePages && (
              <div className={"items-end"}>
                <Pagination
                  numPages={totalPages}
                  currentPage={page || 1}
                  overrides={{
                    MaxLabel: {
                      style: {
                        marginRight: "auto",
                        fontFamily:
                          '"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
                      },
                    },
                    DropdownContainer: {
                      component: () => (
                        <div className={"ml-auto !font-notoSans"}>{page}</div>
                      ),
                    },
                  }}
                  onNextClick={() => {
                    if (!isLoading && setPage && page) {
                      setPage(page + 1);
                    }
                  }}
                  onPrevClick={() => {
                    if (!isLoading && setPage && page) {
                      setPage(page - 1);
                    }
                  }}
                />
              </div>
            )
          )}
        </div>
      </Card>

      <div className={"h-full"}>
        {!selectedMail ? (
          <EmptyDetails />
        ) : (
          <EmailDetails
            email={selectedMail}
            setSelectedMail={setSelectedMail}
            selectedMail={selectedMail}
            emails={emails || []}
            work={work}
          />
        )}
      </div>
    </div>
  );
};
