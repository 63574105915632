import React from "react";

import { LottieLoading } from "../../components/graphics/LottieLoading";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const GraphicsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Graphics"}>
      <ComponentSectionLayout title={"Loading 01"}>
        <LottieLoading />
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
