import React, { Dispatch, SetStateAction } from "react";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import CustomSelect from "../../../components/select/Select";
import { IUser, SelectOptionType } from "../../../types/types";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { CustomerModel } from "../../../factories/customers/models/customerModel";
import { FaTrashCan } from "react-icons/fa6";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";

type Props = {
  contacts: CustomerModel[] | undefined;
  isActive: boolean;
  user: IUser;
  isLoadingContacts: boolean;
  assignedContacts: SelectOptionType[];
  setAssignedContacts: Dispatch<SetStateAction<SelectOptionType[]>>;
  userValue: string;
  setUserValue: Dispatch<SetStateAction<string>>;
  closeEdit: () => void;
};
export const ViewContactList: React.FC<Props> = ({
  contacts,
  isActive,
  user,
  isLoadingContacts,
  assignedContacts,
  setAssignedContacts,
  userValue,
  setUserValue,
  closeEdit,
}) => {
  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-[35%] max-w-[90%] p-0"}>
        <div className={"my-2 mr-4 flex justify-center pt-2 text-lg font-bold"}>
          Assign User To Clients
        </div>
        <div className={"mb-3 h-[130px] w-full flex-col items-center gap-4 "}>
          <div className={"mb-2 flex gap-3 px-3"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={getInitials(
                user?.userProfile?.firstName || "",
                user?.userProfile?.lastName || "",
              )}
            />
            <div
              className={"flex items-center text-lg font-semibold capitalize"}>
              {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
            </div>
          </div>

          <div
            className={
              "h-[82px] w-full border-b border-t border-gray-300 px-3 py-4"
            }>
            <CustomSelect
              options={clientOptions()}
              value={assignedContacts}
              onChange={(newValue) => {
                const value = newValue as SelectOptionType[];
                setAssignedContacts(value);
              }}
              inputValue={userValue}
              onChangeInput={setUserValue}
              isMulti
              isLoading={isLoadingContacts}
              fullWidth
            />
          </div>
        </div>
        <div className={""}>
          <div className={"mb-3 px-3 font-semibold"}>Clients assigned:</div>
          <div className={"h-[350px] max-h-[320px] overflow-y-auto"}>
            {assignedContacts?.map((item) => (
              <div
                className={
                  "flex items-center justify-between border-b border-gray-300 px-4 py-2"
                }>
                <div className={"flex items-center gap-3"}>
                  <AvatarPlaceholder
                    size={"extra-small"}
                    type={item.avatarColor}
                    label={getInitialsFromFullName(item.label || "")}
                  />
                  <div
                    className={"text-purple truncate text-base font-semibold"}>
                    {item.label}
                  </div>
                </div>

                <div
                  className={"cursor-pointer"}
                  onClick={() =>
                    setAssignedContacts(
                      assignedContacts.filter((i) => i.value !== item.value),
                    )
                  }>
                  <FaTrashCan size={"16px"} color={"#7c66f0"} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={"my-3 flex h-[50px] justify-start gap-4 px-3"}>
            <Button
              onClick={closeEdit}
              label={"Update"}
              colorType={"secondary"}
              extraClasses={"normal-case"}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeEdit}
              extraClasses={"normal-case"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
