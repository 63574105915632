export const parseNote = (note: string) => {
  const parts = note.split(/(\@\[.*?\])/);

  return parts.map((part, index) => {
    if (part.startsWith("@[")) {
      const matchResult = part.match(/\@\[(.*?)\]/);
      if (matchResult) {
        const name = matchResult[1];
        return (
          <span className={"font-semibold"} key={index}>
            @{name}
          </span>
        );
      }
    }
    return <span key={index}>{part}</span>;
  });
};
