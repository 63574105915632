import React from "react";

import { LABELS } from "../../app/constants/TextConstants";
import { GetCustomerById_customerById_customerTaxIds } from "../../generated/operation-result-types";

export type CustomerInfoPanelProps = {
  id: string;
  name: string;
  customerTaxIds?: GetCustomerById_customerById_customerTaxIds[] | null;
};

type Props = {
  customer: CustomerInfoPanelProps;
};

export const CustomerInfoPanelView: React.FC<Props> = (props) => {
  const { name, customerTaxIds } = props.customer;

  const taxIdRows = customerTaxIds?.map((taxIdNode) => {
    const { id, taxIdType, taxIdValue } = taxIdNode;
    return (
      <div className={"grid grid-cols-1 xl:grid-cols-2"} key={id}>
        <div className={"text-sm font-semibold"}>{taxIdType}</div>
        <div className={"text-sm"}>{taxIdValue}</div>
      </div>
    );
  });

  return (
    <>
      <div className={"text-md text-center font-semibold"}>
        {LABELS.features.customer} Info
      </div>
      <div className={"grid grid-cols-1 xl:grid-cols-2"}>
        <div className={"text-sm font-semibold"}>Name</div>
        <div className={"text-sm"}>{name}</div>
      </div>
      {taxIdRows}
    </>
  );
};
