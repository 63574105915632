import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  useGetArchivedUsersQuery,
  useUnarchiveUserMutation,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser } from "../../../types/types";
import DeleteUserModal from "./DeleteUserModal";
import { EditUserView } from "./EditUserView";
import { getInitials } from "../../../constants/constants";

const UsersArchivedList = () => {
  const navigate = useNavigate();
  const { data: users, isLoading } = useGetArchivedUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const [unarchiveUser, { isError }] = useUnarchiveUserMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Firm users");
  });

  const [isEdit, setIsEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const openEditPopup = (user: IUser) => {
    setCurrentUser(user);
    setIsEdit(true);
  };

  const openDeletePopup = (user: IUser) => {
    setCurrentUser(user);
    setIsOpenDelete(true);
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  const userRows = users?.map((user) => {
    return (
      <tr key={user?.id}>
        <td>
          <div className={"flex items-center gap-2"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={getInitials(
                user?.userProfile?.firstName || "",
                user?.userProfile?.lastName || "",
              )}
            />
            <div
              className={
                "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
              }>
              {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
            </div>
          </div>
        </td>
        <td>
          <div className={"text-[14px]"}>{user?.userProfile?.email}</div>
        </td>
        <td>
          <div className={"text-[14px]"}>
            {user?.nylasToken?.email
              ? user?.nylasToken?.email
              : "Not connected"}
          </div>
        </td>
        <td>
          <div className={"flex items-center"}>
            <div className={"badge badge-outline text-[14px] capitalize"}>
              {user?.userRoleEnum?.userRole}
            </div>
          </div>
        </td>
        <td>
          <div className={"flex justify-end"}>
            <EditDots>
              <div className={""}>
                <div
                  onClick={() => openEditPopup(user)}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Edit
                </div>
                <div
                  onClick={() => unarchiveUser(user)}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Unarchive
                </div>
                <div
                  onClick={() => openDeletePopup(user)}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Delete
                </div>
              </div>
            </EditDots>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit text-[16px]"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"p-6"}>
          <div className={"mb-4"}>
            <div className={"tabs mb-4"}>
              <CustomLink to={"../active"}>Active</CustomLink>
              <CustomLink to={"./"}>Archived</CustomLink>
            </div>
            <div className={"text-lg font-bold "}>Users</div>
          </div>
          <div>
            <button
              onClick={() => navigate("/settings/users/active/add")}
              className={
                "bg-purple mb-6 flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white hover:bg-purple-800"
              }>
              Add User
            </button>
          </div>
          {isError && (
            <div className={"text-[14px] text-red-600"}>
              Something went wrong. Try again or contact support
            </div>
          )}
          <div className={"flex"}>
            <table
              className={
                "table table-compact w-full overflow-x-auto  md:table-normal"
              }>
              <thead>
                <tr>
                  <th>
                    <div className={"text-[14px]"}>Name</div>
                  </th>
                  <th>
                    <div className={"text-[14px]"}>Email</div>
                  </th>
                  <th>
                    <div className={"text-[14px]"}>Connected email</div>
                  </th>
                  <th>
                    <div className={"text-[14px]"}>Role</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          </div>
        </div>
      </FormSection>
      {currentUser && (
        <DeleteUserModal
          user={currentUser}
          onClose={() => {
            setIsOpenDelete(false);
            setCurrentUser(null);
          }}
          isActive={isOpenDelete}
        />
      )}
      {currentUser && (
        <EditUserView
          isActive={isEdit}
          closeEdit={() => {
            setIsEdit(false);
            setCurrentUser(null);
          }}
          user={currentUser}
        />
      )}
    </>
  );
};

export default UsersArchivedList;
