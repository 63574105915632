import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
interface ITimer {
  assignedUserId: string;
  contactId: string | null;
  businessContactId: string | null;
  contactName: string;
  duration: number;
  workId: string;
  id: string;
  work: { name: string };
  notes: "";
  timerStatus: "started" | "paused" | "ended";
}
interface IInitialState {
  timer: ITimer;
  isLoading: boolean;
  error: string;
}
const initialState: IInitialState = {
  timer: {
    id: "",
    notes: "",
    timerStatus: "ended",
    assignedUserId: "",
    duration: 0,
    workId: "",
    work: { name: "" },
    businessContactId: null,
    contactId: null,
    contactName: "",
  },
  isLoading: false,
  error: "",
};

export const getTimer = createAsyncThunk(
  "timer/getTimer",
  async (
    { userId, orgId }: { userId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/get-by-userId/${userId}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const updateTimer = createAsyncThunk(
  "timer/updateTimer",
  async (timer: any, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/upload`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify(timer),
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const updateStatusTimer = createAsyncThunk(
  "timer/updateStatusTimer",
  async (
    { status, workId }: { status: string; workId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/timer-status?status=${status}&workId=${workId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setStatusTimer(
      state,
      action: PayloadAction<{
        workId?: string;
        timerStatus: "ended" | "started" | "paused";
        work?: { name: string };
        contactId?: string | null;
        businessContactId?: string | null;
        contactName?: string;
      }>,
    ) {
      state.timer.timerStatus = action.payload.timerStatus;
      state.timer.workId = action.payload.workId || state.timer.workId;
      state.timer.work.name =
        action.payload.work?.name || state.timer.work.name;

      state.timer.contactId = action.payload.contactId || state.timer.contactId;
      state.timer.businessContactId =
        action.payload.businessContactId || state.timer.businessContactId;

      state.timer.contactName =
        action.payload.contactName || state.timer.contactName;
    },
    timerCalculate(state, action: PayloadAction<number>) {
      state.timer.duration += action.payload;
    },
    clearTimer(state) {
      state.timer = {
        id: "",
        notes: "",
        timerStatus: "ended",
        assignedUserId: "",
        duration: 0,
        workId: "",
        work: { name: "" },
        contactId: null,
        businessContactId: null,
        contactName: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimer.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getTimer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timer =
          action.payload.timerStatus === "ended" ||
          action.payload.timerStatus === null
            ? {
                id: "",
                notes: "",
                timerStatus: "ended",
                assignedUserId: "",
                duration: 0,
                workId: "",
                work: { name: "" },
                businessContactId: null,
                contactId: null,
                contactName: "",
              }
            : action.payload.timerStatus === "paused"
            ? {
                ...action.payload,
                contactName:
                  action.payload.businessContact?.companyName ||
                  `${action.payload.contact.firstName} ${action.payload.contact.lastName}`,
              }
            : {
                ...action.payload,
                contactName:
                  action.payload.businessContact?.companyName ||
                  `${action.payload.contact.firstName} ${action.payload.contact.lastName}`,
                duration:
                  action.payload.duration +
                  moment().utc().unix() -
                  moment(action.payload.date).unix(),
              };
      })
      .addCase(updateStatusTimer.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(updateStatusTimer.fulfilled, (state) => {
        state.isLoading = false;
        state.timer = {
          id: "",
          timerStatus: "ended",
          assignedUserId: "",
          duration: 0,
          workId: "",
          work: { name: "" },
          notes: "",
          businessContactId: null,
          contactId: null,
          contactName: "",
        };
      })
      .addCase(updateTimer.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(updateTimer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timer = {
          ...state.timer,
          id: action.payload.id,
          assignedUserId: action.payload.assignedUserId,
        };
      });
  },
});

export default timerSlice.reducer;
