import React from "react";

export const OnboardingLayout: React.FC<unknown> = ({ children }) => {
  return (
    <div className={"mx-auto flex w-full max-w-4xl flex-col p-4"}>
      <div className={"pb-4 text-center text-2xl font-semibold text-primary"}>
        Welcome to ProCharted
      </div>

      {children}
    </div>
  );
};
