import React, { FC } from "react";
import { parseNote } from "../../../../utils/parseNote";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { getInitials } from "../../../../../../constants/constants";
import moment from "moment";
import Button from "../../../../../../components/button/Button";
import { IOrganizationContactNote } from "../../../../../../types/types";
import { usePinOrganizationNoteMutation } from "../../../../../../slices/OrganizationNotesSlice";

type Props = {
  note: IOrganizationContactNote;
  setCurrentNote: (value: IOrganizationContactNote | null) => void;
  setIsOpenAdd: (isOpenAdd: boolean) => void;
  deleteOrganizationNote: (value: IOrganizationContactNote) => void;
};

export const OrganizationNote: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteOrganizationNote,
}) => {
  const [pinNote, { isLoading: isLoadingPin }] =
    usePinOrganizationNoteMutation();

  return (
    <div
      className={
        "rounded-[7px]  border-[1px] border-[#AFAFAF] bg-[#F9FAFB] px-[20px] py-[15px]"
      }>
      <div className={"flex justify-between"}>
        <div className={"text-14 font-bold "}>{note.title}</div>
        <div className={"text-[14px] font-bold"}>
          {note.isPinned && "Pinned"}
        </div>
      </div>
      <div className={"mb-4 text-[14px] text-[#6A7584]"}>
        {parseNote(note.note)}
      </div>
      <div className={"flex justify-between"}>
        <div className={"flex items-center space-x-3 text-[14px]"}>
          <AvatarPlaceholder
            size={"extra-small"}
            label={getInitials(
              note.createdByUser?.userProfile?.firstName || "",
              note.createdByUser?.userProfile?.lastName || "",
            )}
          />
          <div className={"text-[12px] text-gray-400"}>
            {`${note.createdByUser?.userProfile?.firstName} ${note.createdByUser?.userProfile?.lastName}`}
          </div>
        </div>
        <div className={"flex"}>
          <div className={"mr-4 flex items-end text-[12px] text-gray-400"}>
            {moment(note.createdAt).format("MMM DD, YYYY")}
          </div>
          <Button
            buttonType={"button"}
            label={"Edit"}
            onClick={() => {
              setCurrentNote(note);
              setIsOpenAdd(true);
            }}
            extraClasses={"normal-case mr-4"}
          />
          <div
            className={
              "mr-4 cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
            }
            onClick={() => {
              if (!isLoadingPin) {
                pinNote({
                  id: note?.id || "",
                  isPinned: !note?.isPinned,
                });
              }
            }}>
            <img src={"/img/pin.png"} alt={"pin"} className={"w-[20px] "} />
          </div>
          <div
            onClick={() => {
              deleteOrganizationNote(note);
            }}
            className={
              "cursor-pointer rounded-[5px] p-[5px] hover:bg-gray-200"
            }>
            <img
              src={"/img/trash.png"}
              alt={"delete"}
              className={"w-[20px] "}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
