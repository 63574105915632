import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import SearchContacts from "./SearchContacts";
import SearchWorks from "./SearchWorks";

const HeaderSearch = () => {
  const [type, setType] = useState<"contacts" | "works">("contacts");
  const location = useLocation();

  useEffect(() => {
    setType("contacts");
  }, [location?.pathname]);

  return (
    <div className={"w-[300px]"}>
      {type === "contacts" && <SearchContacts setType={setType} />}
      {type === "works" && <SearchWorks setType={setType} />}
    </div>
  );
};

export default HeaderSearch;
