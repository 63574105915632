import * as Xstate from "@xstate/react";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";

import { GlobalContext } from "../../../../../../../../app/stateMachines/GlobalContext";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import PreviewFile from "../../../../../../../../components/modal/PreviewFile";
import NoItems from "../../../../../../../../components/NoItems";
import ContactFileSvg from "../../../../../../../../components/svg/ContactFileSvg";
import fileIcon from "../../../../../../../../components/svg/file.svg";
import { inactiveStatusId } from "../../../../../../../../constants/constants";
import {
  useAddContactFileMutation,
  useDeleteContactFileMutation,
  useGetContactFilesQuery,
  useLazyGetPreviewContactFileQuery,
} from "../../../../../../../../slices/FilesSlice";
import { IContactProfile } from "../../../../../../../../types/types";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { InputFile } from "../../../../../../components/InputFile";
import { Dropdown } from "../../../../../../../../components/dropdown/new-dropdown/Dropdown";
import { Tooltip as ReactTooltip } from "react-tooltip";

type Props = {
  contact: IContactProfile;
};

export const ContactFilesWidget: FC<Props> = ({ contact }) => {
  const contactId = contact?.contactId || "";

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { data: contactFiles, isFetching } = useGetContactFilesQuery(contactId);
  const [getPreviewFile, { data: previewFile, isLoading: isLoadingPreview }] =
    useLazyGetPreviewContactFileQuery();

  const [addFile, { isLoading: isLoadingAdd, isError }] =
    useAddContactFileMutation();
  const [deleteFile] = useDeleteContactFileMutation();

  const addFileRef = useRef<HTMLInputElement>(null);

  const [activeFileName, setActiveFileName] = useState("");

  const [error, setError] = useState("");
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const isActive = contact?.contactStatusTypeEnumId !== inactiveStatusId;

  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("contactId", `${contactId}`);
    formData.append("assignedUserRelayId", `${userInfoByEmail?.id}`);
    formData.append("notes", "");

    addFile(formData);
  };

  useEffect(() => {
    if (isError) {
      setError("Something went wrong");
    }
  }, [isError]);

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full  pt-4"}>
          <div className={"w-full border-b border-gray-300 px-6 pb-4"}>
            <div className={"flex w-full justify-between"}>
              <div className={"text-lg font-bold"}>Files</div>
              <InputFile
                setFile={handleFileChange}
                setError={setError}
                inputRef={addFileRef}
                isLoading={isLoadingAdd}
              />
            </div>
            {error && (
              <div className={"text-sm font-semibold text-red-500"}>
                {error}
              </div>
            )}
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !contactFiles?.length ? (
              <div className={"p-6"}>
                <NoItems
                  icon={<ContactFileSvg />}
                  message={"There are no files"}
                  addButtonMessage={"Add File"}
                  openAdd={() =>
                    !isActive
                      ? setIsOpenError(true)
                      : addFileRef?.current?.click()
                  }
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-y-auto"}>
                {contactFiles?.map((file) => (
                  <div
                    key={file?.id}
                    className={
                      "border-b border-gray-300 px-6 hover:bg-gray-100"
                    }>
                    <div
                      onClick={() => {
                        getPreviewFile(file?.id);
                        setIsOpenPreview(true);
                        setActiveFileName(file?.originalFileName);
                      }}
                      className={
                        "flex cursor-pointer items-center justify-between py-4 "
                      }
                      data-tooltip-id={file.id}>
                      <div
                        className={"flex items-center space-x-3 text-[14px]"}>
                        <div className={"h-[32px] w-[32px]"}>
                          <img src={fileIcon} alt={"file"} />
                        </div>
                        <div>
                          <div
                            className={
                              "... overflow-hidden text-ellipsis  whitespace-nowrap  text-[14px] font-[500] xl:max-w-[180px] 2xl:max-w-[220px] 3xl:max-w-[270px] 4xl:max-w-[320px]"
                            }>
                            {file?.originalFileName}
                          </div>
                          <div className={"text-[12px] text-gray-400"}>
                            {moment(file?.createdDate).format("MMM DD, YYYY")}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Dropdown
                          triggerElement={
                            <Button
                              label={""}
                              colorType={"ghost"}
                              size={"custom"}
                              icon={<HiEllipsisVertical size={24} />}
                              extraClasses={"min-h-fit h-fit w-fit p-1"}
                            />
                          }>
                          <div
                            className={
                              "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                            }>
                            <div
                              onClick={() => {
                                deleteFile(file?.id);
                              }}
                              className={
                                "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                              }>
                              Delete
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                    <ReactTooltip
                      id={file.id}
                      place="bottom"
                      content={file.originalFileName}
                      className={"!rounded-[8px] !p-2 !text-sm"}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenPreview && (
        <PreviewFile
          close={() => setIsOpenPreview(false)}
          file={previewFile?.documentUrl || ""}
          fileName={activeFileName}
          isLoading={isLoadingPreview}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
