import { FC } from "react";

import { Card } from "../../../components/card/Card";

type Props = {
  allWorks: number;
  completedWorks: number;
};

export const WorksStats: FC<Props> = ({ allWorks, completedWorks }) => {
  return (
    <Card fullWidth={false}>
      <div className={"grid grid-cols-[repeat(2,max-content)] py-6"}>
        <div className={"min-w-[300px] border-r border-gray-300 px-5"}>
          <div className={"text-base text-gray-400"}>All work</div>
          <div className={"text-[40px] font-bold"}>{allWorks}</div>
        </div>
        <div className={"min-w-[300px] px-5"}>
          <div className={"text-base text-gray-400"}>Completed</div>
          <div className={"text-[40px] font-bold"}>{completedWorks}</div>
        </div>
      </div>
    </Card>
  );
};
