import React from "react";

import { getDateOnlyStr } from "../../app/commonOps/CommonDateOps";
import { isEmptyStr } from "../../app/commonOps/CommonStringOps";
import { LABELS } from "../../app/constants/TextConstants";
import { GetBankDocById_bankDocById_uploadedByUser } from "../../generated/operation-result-types";

export type BankDocInfoPanelProps = {
  id: string;
  uploadedByUser: GetBankDocById_bankDocById_uploadedByUser | null;
  isCreditCard: boolean;
  createdAt: string;
};

type Props = {
  bankDoc: BankDocInfoPanelProps;
};

export const BankDocInfoPanelView: React.FC<Props> = (props) => {
  const { isCreditCard, createdAt, uploadedByUser } = props.bankDoc;
  const fullName = uploadedByUser?.userProfile?.fullName ?? "";
  const derivedFullName = isEmptyStr(fullName)
    ? LABELS.empty.default
    : fullName;
  const derivedDocType = isCreditCard ? "Credit card" : "Bank statement";

  return (
    <>
      <div className={"text-md text-center font-semibold"}>
        {LABELS.features.bankDoc} Info
      </div>
      <div className={"grid grid-cols-1 xl:grid-cols-2"}>
        <div className={"text-sm font-semibold"}>Uploaded by</div>
        <div className={"text-sm"}>{derivedFullName}</div>
      </div>
      <div className={"grid grid-cols-1 xl:grid-cols-2"}>
        <div className={"text-sm font-semibold"}>Document Type</div>
        <div className={"text-sm"}>{derivedDocType}</div>
      </div>
      <div className={"grid grid-cols-1 xl:grid-cols-2"}>
        <div className={"text-sm font-semibold"}>Uploaded at</div>
        <div className={"text-sm"}>{getDateOnlyStr(createdAt)}</div>
      </div>
    </>
  );
};
