import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { AllWorks } from "./pages/AllWorks";
import { MyWorks } from "./pages/MyWorks";
import { ArchivedWorks } from "./pages/ArchivedWorks";

export const WorksView = () => {
  return (
    <PageLayout title={"Work"}>
      <div className={"tabs py-8"}>
        <CustomLink to={"/works/all"}>All Work</CustomLink>
        <CustomLink to={"/works/my"}>My Work</CustomLink>
        <CustomLink to={"/works/archived"}>Archived</CustomLink>
      </div>
      <Routes>
        <Route index element={<Navigate to={"./all"} replace={true} />} />
        <Route path={"all"} element={<AllWorks />} />
        <Route path={"my"} element={<MyWorks />} />
        <Route path={"archived"} element={<ArchivedWorks />} />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>
    </PageLayout>
  );
};
