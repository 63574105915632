import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CustomSelect from "../../../../../../components/select/Select";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { useGetTemplatesQuery } from "../../../../../../slices/TemplatesSlice";
import { useUpdateWorkTemplateMutation } from "../../../../../../slices/WorkSlice";
import { SelectOptionType } from "../../../../../../types/types";
import { useAppDispatch } from "../../../../../../redux/redux";
import { workTasksSlice } from "../../../../../../slices/WorkTasksSlice";

type Props = {
  work: WorkDetailsModel;
};

export const WorkEditTemplate: FC<Props> = ({ work }) => {
  const { workId } = useParams();
  const dispatch = useAppDispatch();

  const { data: templates, isLoading } = useGetTemplatesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [updateTemplate, { data: updatedWork, isSuccess, isError }] =
    useUpdateWorkTemplateMutation();

  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectOptionType | null>(null);

  const template: SelectOptionType = {
    value: work?.templateId,
    label: work?.template?.templateName,
  };

  const templateOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [{ value: "", label: "None" }];
    if (templates) {
      templates?.forEach((item) => {
        options.push({
          value: item?.id,
          label: item?.templateName,
        });
      });
    }
    return options;
  };

  const showTemplate = (): string => {
    if (updatedWork?.templateId === work?.templateId) {
      return work?.template?.templateName || "None";
    }
    if (selectedTemplate) {
      return selectedTemplate.label;
    }
    if (updatedWork) {
      return updatedWork?.template?.templateName || "None";
    }
    if (!selectedTemplate && !work?.templateId) {
      return "None";
    }
    return work?.template?.templateName || "None";
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedTemplate(null);
      dispatch(workTasksSlice.util.invalidateTags(["WorkTask"]));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setSelectedTemplate(null);
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  return (
    <div className={"flex items-center gap-2"}>
      {isEditTemplate ? (
        <div className={"absolute left-0 w-[200px]"}>
          <CustomSelect
            options={templateOptions()}
            fullWidth
            autoFocus
            value={template}
            onChange={(value) => {
              const newValue = value as SelectOptionType;
              updateTemplate({
                templateId: newValue?.value,
                workId: workId || "",
              });
              setSelectedTemplate(newValue);
              setIsEditTemplate(false);
            }}
            onBlur={() => setIsEditTemplate(false)}
            isLoading={isLoading}
            menuIsOpen
          />
        </div>
      ) : (
        <div
          onClick={() => setIsEditTemplate(true)}
          className={
            "w-full cursor-pointer text-base font-medium hover:underline"
          }>
          {showTemplate()}
        </div>
      )}
    </div>
  );
};
