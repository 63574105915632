import React, { FC, useState } from "react";
import Button from "../../../../components/button/Button";
import { WorkList } from "../../../works/components/WorkList";
import { useGetRepeatsWorkQuery } from "../../../../slices/WorkSlice";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { useParams } from "react-router-dom";
import { AddWorkModal } from "../../../works/modals/AddWork.modal";
import { FilterWorksModal } from "../../../works/modals/FilterWorks.modal";
import { Filters } from "../../../works/pages/AllWorks";

export const WorkRepeats: FC = () => {
  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { workId } = useParams();
  const { data: works, isFetching: isLoadingWorks } = useGetRepeatsWorkQuery({
    orgId: getLinkedFirm()?.orgId || "",
    workId: workId || "",
  });

  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });

  return (
    <div>
      <WorkList works={works || []} isLoading={isLoadingWorks}>
        <div
          className={
            "flex w-full items-center justify-between px-[32px] py-[24px]  font-bold"
          }>
          <div>All Work ({works?.[0]?.totalRecords || 0})</div>
          <Button
            buttonType={"button"}
            label={"Add work"}
            onClick={() => setIsAddWork(true)}
          />
        </div>
      </WorkList>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      {isOpenFilters && (
        <FilterWorksModal
          isOpen={isOpenFilters}
          onClose={() => setIsOpenFilters(false)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </div>
  );
};
