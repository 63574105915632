import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const SignOutSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          fill={"current"}
          d={
            "M5.71286 4.15868C6.10018 4.55239 6.09501 5.18553 5.7013 5.57285C4.03343 7.21368 3 9.49035 3 12C3 16.9677 7.03228 21 12 21C16.9677 21 21 16.9677 21 12C21 9.49035 19.9666 7.21368 18.2987 5.57285C17.905 5.18553 17.8998 4.55239 18.2871 4.15868C18.6745 3.76498 19.3076 3.7598 19.7013 4.14712C21.7334 6.14629 23 8.92962 23 12C23 18.0723 18.0723 23 12 23C5.92772 23 1 18.0723 1 12C1 8.92962 2.26657 6.14629 4.2987 4.14712C4.6924 3.7598 5.32554 3.76498 5.71286 4.15868Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"1"}></path>
        <path
          fill={"current"}
          d={
            "M12 1C12.5523 1 13 1.44772 13 2V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V2C11 1.44772 11.4477 1 12 1Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"1"}></path>
      </svg>
    </>
  );
};
