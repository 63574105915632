import React, { FC, useState } from "react";
import { Card } from "../../../../../../components/card/Card";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { WorkEditTemplate } from "./WorkEditTemplate";
import { WorkTasksList } from "./WorkTasksList";
import { WorkTaskModal } from "../../../../modals/WorkTaskModel";
import { useGetUsersQuery } from "../../../../../../slices/UserSlice";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useMarkDoneAllWorkTasksMutation } from "../../../../../../slices/WorkTasksSlice";
import { useParams } from "react-router-dom";
import Button from "../../../../../../components/button/Button";

type Props = {
  work: WorkDetailsModel;
};

export const WorkTasks: FC<Props> = ({ work }) => {
  const { workId } = useParams();
  const [isOpenAdd, setIsOpenAdd] = useState(false);

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId,
  );
  const [markDoneAllWorkTasks, { isLoading }] =
    useMarkDoneAllWorkTasksMutation();

  return (
    <Card extraClasses={"shadow-box max-w-full"}>
      <div className={"pt-2"}>
        <div className={"flex justify-between px-6 pb-2"}>
          <div className={"flex h-12 items-center gap-2"}>
            <div className={"text-[20px] font-semibold text-[#2B333C]"}>
              Subtasks
            </div>
            <div
              className={
                "relative flex cursor-pointer items-center gap-2 rounded-[12px] border border-[#EAEDF3A3] bg-[#F8F9FB] px-2 py-[6px]"
              }>
              <WorkEditTemplate work={work} />
              <MdOutlineKeyboardArrowDown
                width={"7px"}
                className={"text-[#2B333C]"}
              />
            </div>
          </div>
          <div className={"flex items-center gap-4"}>
            <Button
              label={"Mark all as completed"}
              onClick={() => markDoneAllWorkTasks({ workId: workId || "" })}
              size={"custom"}
              isLoading={isLoading}
              disabled={isLoading}
              extraClasses={"btn-sm px-4"}
            />
            <div
              className={
                "flex cursor-pointer items-center text-base text-[#7B66F0]"
              }
              onClick={() => setIsOpenAdd(true)}>
              Add Subtask
            </div>
          </div>
        </div>
        <WorkTasksList
          work={work}
          users={users}
          isOpenAdd={isOpenAdd}
          setIsOpenAdd={setIsOpenAdd}
        />
        {isOpenAdd && (
          <WorkTaskModal
            isActive={isOpenAdd}
            onClose={() => setIsOpenAdd(false)}
            users={users}
          />
        )}
      </div>
    </Card>
  );
};
