import { FC, useState } from "react";

import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { EditWorkDetails } from "./EditWorkDetails";
import { WorkDetailsInfo } from "./WorkDetailsInfo";

type Props = {
  work: WorkDetailsModel;
};

export const WorkDetails: FC<Props> = ({ work }) => {
  const [isEdit, setIsEdit] = useState(false);

  return isEdit ? (
    <EditWorkDetails work={work} setIsEdit={setIsEdit} />
  ) : (
    <WorkDetailsInfo work={work} setIsEdit={setIsEdit} />
  );
};
