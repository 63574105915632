import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appReducer from "../services/AppService";
import { billingAPI } from "../services/BillingService";
import { orgAPI } from "../services/OrgService";
import timerReducer from "../services/TimerService";
import worksReducer from "../services/WorkReducer";
import { contactNotesSlice } from "../slices/ContactNotesSlice";
import { contactsSlice } from "../slices/ContactsSlice";
import { emailsSlice } from "../slices/EmailsSlice";
import { filesSlice } from "../slices/FilesSlice";
import { linkedProfilesSlice } from "../slices/LinkedProfilesSlice";
import { organizationNotesSlice } from "../slices/OrganizationNotesSlice";
import { statusesSlice } from "../slices/StatusesSlice";
import { templatesSlice } from "../slices/TemplatesSlice";
import { userSlice } from "../slices/UserSlice";
import { workSlice } from "../slices/WorkSlice";
import { workTypeSlice } from "../slices/WorkTypeSlice";
import { workNotesSlice } from "../slices/WorkNotesSlice";
import { workTasksSlice } from "../slices/WorkTasksSlice";
import { contactTagsSlice } from "../slices/ContactTagsSlice";

const rootReducer = combineReducers({
  [emailsSlice.reducerPath]: emailsSlice.reducer,
  [contactsSlice.reducerPath]: contactsSlice.reducer,
  [linkedProfilesSlice.reducerPath]: linkedProfilesSlice.reducer,
  [contactNotesSlice.reducerPath]: contactNotesSlice.reducer,
  [organizationNotesSlice.reducerPath]: organizationNotesSlice.reducer,
  [filesSlice.reducerPath]: filesSlice.reducer,
  [billingAPI.reducerPath]: billingAPI.reducer,
  [workSlice.reducerPath]: workSlice.reducer,
  [statusesSlice.reducerPath]: statusesSlice.reducer,
  [orgAPI.reducerPath]: orgAPI.reducer,
  [userSlice.reducerPath]: userSlice.reducer,
  [workTypeSlice.reducerPath]: workTypeSlice.reducer,
  [templatesSlice.reducerPath]: templatesSlice.reducer,
  [workNotesSlice.reducerPath]: workNotesSlice.reducer,
  [workTasksSlice.reducerPath]: workTasksSlice.reducer,
  [contactTagsSlice.reducerPath]: contactTagsSlice.reducer,
  timerReducer,
  worksReducer,
  appReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        emailsSlice.middleware,
        contactsSlice.middleware,
        linkedProfilesSlice.middleware,
        contactNotesSlice.middleware,
        organizationNotesSlice.middleware,
        userSlice.middleware,
        billingAPI.middleware,
        workSlice.middleware,
        orgAPI.middleware,
        statusesSlice.middleware,
        workTypeSlice.middleware,
        templatesSlice.middleware,
        filesSlice.middleware,
        workNotesSlice.middleware,
        workTasksSlice.middleware,
        contactTagsSlice.middleware,
      ),
  });
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
