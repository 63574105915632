import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React from "react";

import { loginRequestScopes } from "../../app/configs/appConfig";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { LoadingMainView } from "./LoadingMainView";

export const AuthenticatedMainView: React.FC<unknown> = () => {
  const authRequest = {
    ...loginRequestScopes,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={LottieLoading}>
      <LoadingMainView />
    </MsalAuthenticationTemplate>
  );
};
