import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { AddLinkedProfileModel } from "../factories/contacts/models/add-linked-profile.model";
import { UpdateLinkedProfileModel } from "../factories/contacts/models/update-linked-profile.model";
import { ILinkedProfile, ILinkedProfileOrgContact } from "../types/types";

export const linkedProfilesSlice = createApi({
  reducerPath: "linkedProfilesSlice",
  tagTypes: ["LinkedProfile"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getContactLinkedProfiles: build.query<
      ILinkedProfile[],
      { contactId: string; orgId: string }
    >({
      query: ({ contactId, orgId }) => ({
        url: "api/contacts/linked-profiles/get-by-contact-id/" + contactId,
        params: { orgId },
      }),
      providesTags: () => ["LinkedProfile"],
    }),
    getOrganizationLinkedProfiles: build.query<
      ILinkedProfileOrgContact[],
      { organizationId: string; orgId: string }
    >({
      query: ({ organizationId, orgId }) => ({
        url:
          "api/business-contacts/linked-profiles/get-by-business-contact-id/" +
          organizationId,
        params: { orgId },
      }),
      providesTags: () => ["LinkedProfile"],
    }),
    addContactLinkedProfile: build.mutation<unknown, AddLinkedProfileModel>({
      query: (body) => ({
        url: "api/contacts/linked-profiles/add",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
    updateContactLinkedProfile: build.mutation<
      unknown,
      UpdateLinkedProfileModel
    >({
      query: (body) => ({
        url: "api/contacts/linked-profiles/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
    addOrganizationLinkedProfile: build.mutation<
      unknown,
      AddLinkedProfileModel
    >({
      query: (body) => ({
        url: "api/business-contacts/linked-profiles/add",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
    updateOrganizationLinkedProfile: build.mutation<
      unknown,
      UpdateLinkedProfileModel
    >({
      query: (body) => ({
        url: "api/business-contacts/linked-profiles/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
    deleteContactLinkedProfile: build.mutation<unknown, ILinkedProfile>({
      query: (body) => ({
        url: "api/contacts/linked-profiles",
        method: "DELETE",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
    deleteOrganizationLinkedProfile: build.mutation<
      unknown,
      ILinkedProfileOrgContact
    >({
      query: (body) => ({
        url: "api/business-contacts/linked-profiles",
        method: "DELETE",
        body,
      }),
      invalidatesTags: () => ["LinkedProfile"],
    }),
  }),
});
export const {
  useGetContactLinkedProfilesQuery,
  useGetOrganizationLinkedProfilesQuery,
  useAddContactLinkedProfileMutation,
  useAddOrganizationLinkedProfileMutation,
  useUpdateContactLinkedProfileMutation,
  useUpdateOrganizationLinkedProfileMutation,
  useDeleteContactLinkedProfileMutation,
  useDeleteOrganizationLinkedProfileMutation,
} = linkedProfilesSlice;
