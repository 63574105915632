import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";

import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../components/button/Button";
import CustomSelect from "../../../../components/select/Select";
import { getInitialsFromFullName } from "../../../../constants/constants";
import {
  useGetActiveContactsQuery,
  useGetActiveOrganizationsQuery,
  useMergedContactsMutation,
} from "../../../../slices/ContactsSlice";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../../../types/types";

type Props = {
  customerId: string;
  name: string;
  isActive: boolean;
  onClose: () => void;
  type: "contact" | "organization";
};

export const MergeContactModal: FC<Props> = ({
  customerId,
  name,
  isActive,
  onClose,
  type,
}) => {
  const { data: contacts, isLoading: isLoadingContacts } =
    useGetActiveContactsQuery(`${getLinkedFirm()?.orgId}`);
  const { data: organizations, isLoading: isLoadingOrganizations } =
    useGetActiveOrganizationsQuery(`${getLinkedFirm()?.orgId}`);

  const [mergeContacts, { isLoading: isLoadingMerge, isSuccess }] =
    useMergedContactsMutation();

  const [selectedContact, setSelectedContact] =
    useState<SelectOptionType | null>(null);

  const getOptions = (): SelectOptionType[] => {
    if (type === "contact") {
      const data: SelectOptionType[] =
        contacts?.map((item) => ({
          label: `${item?.firstName} ${item?.lastName}`,
          value: item.customerId || "",
          avatar: `${item?.firstName?.[0]}${item?.lastName?.[0]}`,
        })) || [];
      return data?.filter((item) => item?.value !== customerId) || [];
    }
    if (type === "organization") {
      const data: SelectOptionType[] =
        organizations?.map((item) => ({
          label: `${item?.companyName}`,
          value: item.customerId || "",
          avatar: getInitialsFromFullName(item?.companyName || ""),
          avatarColor: "purple",
        })) || [];

      return data?.filter((item) => item?.value !== customerId) || [];
    }
    return [];
  };

  const handleMergeContacts = () => {
    if (selectedContact?.value) {
      mergeContacts({
        mergeCustomerId: selectedContact?.value || "",
        mergeToCustomerId: customerId,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box"}>
        <div className={"mb-4 text-2xl font-semibold"}>Merge contacts</div>
        <div className={"mb-4"}>
          <div className={"mb-2 text-sm font-semibold"}>Primary contact:</div>
          <div
            className={
              "flex items-center gap-4 rounded-[8px] border border-gray-300 p-2"
            }>
            <AvatarPlaceholder
              size={"super-small"}
              label={getInitialsFromFullName(name)}
              type={type === "contact" ? "blue" : "purple"}
            />
            <div className={"truncate text-sm font-semibold text-gray-800"}>
              {name}
            </div>
          </div>
        </div>
        <div className={"mb-4"}>
          <div className={"mb-2 text-sm font-semibold"}>
            Secondary contact (To be deleted)
          </div>
          <div>
            <CustomSelect
              options={getOptions()}
              onChange={(newValue) =>
                setSelectedContact(newValue as SelectOptionType)
              }
              fullWidth
              value={selectedContact}
              isLoading={isLoadingContacts || isLoadingOrganizations}
              withAvatar
            />
          </div>
        </div>
        <div className={"mb-2 text-sm font-semibold text-gray-500"}>
          <ul className={"list-disc pl-5"}>
            <li>
              The secondary contact's work items, notes, documents, invoices and
              payments will move to the primary contact.
            </li>
            <li>
              The primary "contact info" section (address, phone number etc.)
              will be kept.
            </li>
            <li>The secondary contact will be permanently deleted.</li>
          </ul>
        </div>
        <div className={"mb-4 text-sm font-bold text-gray-800"}>
          This cannot be undone. Are you sure you want to continue?
        </div>
        <div className={"flex justify-end gap-4"}>
          <Button
            label={isLoadingMerge ? "Loading" : "Continue"}
            onClick={handleMergeContacts}
            disabled={isLoadingMerge}
          />
          <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
        </div>
      </div>
    </div>
  );
};
