import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import React, { Fragment, useEffect, useState } from "react";

import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import { FormSection } from "../../../../support/FormSection";
import { IUserInvitation } from "../../../../types/types";
import EditInvitedUser from "./EditInvitedUser";
import * as devConfig from "../../../../app/configs/devConfig";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { getInitials } from "../../../../constants/constants";

const InvitedUsers = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [invitedUsers, setInvitedUsers] = useState<IUserInvitation[]>([]);
  const [editUser, setEditUser] = useState<IUserInvitation>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const openEdit = () => {
    setIsEdit(true);
  };
  const closeEdit = () => {
    setIsEdit(false);
  };
  const getInvitedUsers = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations/get-all?orgId=${
        getLinkedFirm()?.orgId
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setInvitedUsers(data);
    }
    setIsLoading(false);
  };
  const deleteInvitedUser = async (data: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-invitations`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      getInvitedUsers();
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getInvitedUsers();
  }, []);
  if (isLoading) {
    return <LottieLoading />;
  }
  const editUserInvite = (
    <Transition appear show={isEdit} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeEdit}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <div className={"py-8"}>
                  <EditInvitedUser
                    setIsLoading={setIsLoading}
                    getInvitedUsers={getInvitedUsers}
                    invitedUser={editUser}
                    closeEdit={closeEdit}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
  return (
    <>
      <FormSection name={"Invited users"} extraCx={"overflow-visible"}>
        <div className={"flex"}>
          <table className={"table w-full"}>
            <thead>
              <tr>
                <th>
                  <div className={"text-[14px]"}>Name</div>
                </th>
                <th>
                  <div className={"text-[14px]"}>Email</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitedUsers.map((user) => (
                <tr key={user.id}>
                  <td className={"text-[14px]"}>
                    <div className={"flex items-center gap-2"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        label={getInitials(
                          user?.firstName || "",
                          user?.lastName || "",
                        )}
                      />
                      <div
                        className={
                          "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
                        }>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                    </div>
                  </td>
                  <td className={"text-[14px]"}>
                    <div
                      className={
                        "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm normal-case text-gray-800"
                      }>
                      {user.email}
                    </div>
                  </td>
                  <td>
                    <div className={"flex justify-end"}>
                      <EditDots>
                        <div className={""}>
                          <div
                            onClick={() => {
                              setEditUser(user);
                              openEdit();
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Edit
                          </div>
                          <div
                            onClick={() => {
                              setIsLoading(true);
                              deleteInvitedUser(user);
                            }}
                            className={
                              "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                            }>
                            Delete
                          </div>
                        </div>
                      </EditDots>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </FormSection>
      {editUserInvite}
    </>
  );
};

export default InvitedUsers;
