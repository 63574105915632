import React from "react";

const CalendarSvg = () => {
  return (
    <svg
      width={"29"}
      height={"29"}
      viewBox={"0 0 31 31"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <g id={"ion:calendar-clear-outline"}>
        <path
          id={"Vector"}
          d={
            "M24.7548 5.31934H6.24522C4.71184 5.31934 3.46878 6.56239 3.46878 8.09578V24.7544C3.46878 26.2878 4.71184 27.5309 6.24522 27.5309H24.7548C26.2882 27.5309 27.5313 26.2878 27.5313 24.7544V8.09578C27.5313 6.56239 26.2882 5.31934 24.7548 5.31934Z"
          }
          stroke={"currentColor"}
          strokeWidth={"1.5"}
          strokeLinejoin={"round"}
        />
        <path
          id={"Vector_2"}
          d={
            "M8.09618 3.46875V5.31971M22.9039 3.46875V5.31971M27.5313 9.94712H3.46878"
          }
          stroke={"currentColor"}
          strokeWidth={"1.5"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
      </g>
    </svg>
  );
};

export default CalendarSvg;
