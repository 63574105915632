import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { Email } from "../../factories/emails/models/emails.models";
import { getInitialsFromFullName } from "../../constants/constants";
import moment from "moment";
import classNames from "classnames";
import { useStarredEmailMutation } from "../../slices/EmailsSlice";
import { useAppSelector } from "../../redux/redux";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

type Props = {
  emails: Email[];
  setSelectedMail?: Dispatch<SetStateAction<Email | null>>;
  selectedMail?: Email | null;
};

export const EmailList: FC<Props> = ({
  emails,
  setSelectedMail,
  selectedMail,
}) => {
  const [updateEmail, { data, isLoading: isLoadingUpdate, isSuccess }] =
    useStarredEmailMutation();
  const { user } = useAppSelector((state) => state.appReducer);
  const [emailUnread, setEmailUnread] = useState(selectedMail?.unread);

  const handleClickOnMail = (email: Email) => {
    if (email.unread) {
      setSelectedMail && setSelectedMail(email);
      updateEmail({
        messageId: email.id,
        userId: user.id,
        body: {
          unread: !email.unread,
          starred: email.starred,
          folders: email.folders,
        },
      });
    } else {
      setSelectedMail && setSelectedMail(email);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setEmailUnread(false);
    }
  }, []);

  const starredEmail = (email: Email) => {
    if (!isLoadingUpdate) {
      updateEmail({
        messageId: email.id,
        userId: user.id,
        body: {
          starred: !email.starred,
          unread: email.unread,
          folders: email.folders,
        },
      });
    }
  };

  const checkIsUnreadEmail = (email: Email) => {
    if (selectedMail?.id === email.id) {
      return emailUnread;
    } else {
      return email.unread;
    }
  };

  return (
    <div className={"h-full overflow-y-auto"}>
      {emails.map((email) => (
        <div
          key={email.id}
          onClick={() => handleClickOnMail(email)}
          className={
            "grid cursor-pointer grid-cols-[40px_1fr] gap-1 border-b border-gray-200 px-4 pt-2 hover:bg-gray-100"
          }>
          <div className={"row-span-2"}>
            <AvatarPlaceholder
              size={"super-small"}
              label={
                email?.from?.[0]?.name
                  ? getInitialsFromFullName(email?.from?.[0]?.name || "")
                  : getInitialsFromFullName(
                      `${user.userProfile.firstName}  ${user.userProfile.lastName}` ||
                        "",
                    )
              }
            />
          </div>
          <div
            className={
              "grid grid-cols-[1fr_max-content] justify-between text-base"
            }>
            <div className={"grid grid-cols-[1fr] grid-rows-3 overflow-hidden"}>
              <div
                className={classNames(
                  "truncate !font-notoSans text-sm text-gray-800",
                  checkIsUnreadEmail(email) ? "font-bold" : "font-semibold",
                )}>
                {email.from?.[0]?.name
                  ? email.from?.[0]?.name
                  : `${user.userProfile.firstName}  ${user.userProfile.lastName}`}
              </div>
              <div
                className={classNames(
                  "truncate !font-notoSans text-sm text-gray-800",
                  checkIsUnreadEmail(email) ? "font-bold" : "",
                )}>
                {email.subject}
              </div>
              <div
                className={classNames(
                  "truncate !font-notoSans text-sm text-gray-500",
                  checkIsUnreadEmail(email) ? "font-bold" : "",
                )}>
                {email.snippet}
              </div>
            </div>
            <div className={"h-fit flex-col items-center gap-1"}>
              <div className={"flex items-center gap-1"}>
                {checkIsUnreadEmail(email) && (
                  <div className={"bg-purple h-3 w-3 rounded-full"} />
                )}
                <div
                  className={
                    "!font-notoSans text-sm font-semibold text-gray-500"
                  }>
                  {moment.unix(email.date).format("MMM DD")}
                </div>
              </div>
              <div className={"!font-notoSans text-sm text-gray-500"}>
                {moment.unix(email?.date).format("hh:mm a")}
              </div>
              <div
                className={
                  "flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] bg-transparent text-base font-semibold hover:bg-gray-200"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  starredEmail(email);
                }}>
                {email.starred ? (
                  <FaStar fontSize={"18px"} className={"text-yellow-400"} />
                ) : (
                  <CiStar fontSize={"18px"} className={"text-purple"} />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
