const getValueMessage = (value: number, type: "min" | "max") => {
  return {
    value: value,
    message:
      type === "min"
        ? `Must be at least ${value} characters`
        : `Must not be more than ${value} characters`,
  };
};

export const ValidationConstants = {
  userRules: {
    profileRules: {
      firstNameLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
      middleNameLength: {
        maxLength: getValueMessage(100, "max"),
      },
      lastNameLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
      emailLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
  },
  bankDocAddEditRules: {
    nameRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(30, "max"),
      },
    },
  },
  bankDocOutputReviewRules: {
    memoRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
  },
  bankDocDownloadRules: {
    accountNumberRule: {
      valueLength: {
        minLength: getValueMessage(6, "min"),
        maxLength: getValueMessage(32, "max"),
      },
    },
  },
  orgRules: {
    nameRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
  },
  customerRules: {
    nameRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
    contactNameRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(20, "max"),
      },
    },
    profileRules: {
      firstNameLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
      middleNameLength: {
        maxLength: getValueMessage(100, "max"),
      },
      lastNameLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
      emailLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
    phoneNumberRule: {
      valueLength: {
        minLength: getValueMessage(5, "min"),
        maxLength: getValueMessage(30, "max"),
      },
    },
    taxIdRule: {
      nameLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(20, "max"),
      },
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(20, "max"),
      },
    },
    addressRule: {
      line1Length: {
        maxLength: getValueMessage(100, "max"),
      },
      line2Length: {
        maxLength: getValueMessage(100, "max"),
      },
      cityLength: {
        maxLength: getValueMessage(50, "max"),
      },
      regionLength: {
        maxLength: getValueMessage(50, "max"),
      },
      countryLength: {
        maxLength: getValueMessage(100, "max"),
      },
      postalCodeLength: {
        maxLength: getValueMessage(20, "max"),
      },
    },
    spouseRule: {
      valueLength: {
        minLength: getValueMessage(2, "min"),
        maxLength: getValueMessage(100, "max"),
      },
    },
  },
};
