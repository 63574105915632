import { FC, useState } from "react";

import { WorkCollapsedNote } from "./WorkCollapsedNote";
import { WorkNote } from "./WorkNote";
import { IWorkNote } from "../../../../../../types/types";

type Props = {
  note: IWorkNote;
  setCurrentNote: (value: IWorkNote) => void;
  setIsOpenAdd: (value: boolean) => void;
  deleteContactNote: (value: IWorkNote) => void;
};

export const WorkNoteView: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  return (
    <WorkNote
      note={note}
      setCurrentNote={setCurrentNote}
      setIsOpenAdd={setIsOpenAdd}
      deleteContactNote={deleteContactNote}
    />
  );
};
