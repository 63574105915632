import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { IUser, IWorkNote } from "../types/types";

export const workNotesSlice = createApi({
  reducerPath: "workNotesSlice",
  tagTypes: ["Participants", "WorkNotes"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getParticipants: build.query<
      { id: string; userId: string; user: IUser }[],
      { workId: string }
    >({
      query: ({ workId }) => ({
        url: "api/user-notification/get-by-id/" + workId,
      }),
      providesTags: ["Participants"],
    }),
    deleteParticipants: build.mutation<unknown, { workId: string; id: string }>(
      {
        query: (body) => ({
          url: "api/user-notification",
          method: "DELETE",
          body: { jobId: body.workId, id: body.id },
        }),
        invalidatesTags: ["Participants"],
      },
    ),
    getWorkNotes: build.query<IWorkNote[], { workId: string }>({
      query: ({ workId }) => ({
        url: "api/work-notes/" + workId,
      }),
      transformResponse: (res: IWorkNote[]) => {
        const sortedData = res?.sort(
          (a, b) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
        );

        const pinnedNotes = sortedData.filter((note) => note.isPinned);
        const unpinnedNotes = sortedData.filter((note) => !note.isPinned);

        return [...pinnedNotes, ...unpinnedNotes];
      },
      providesTags: ["WorkNotes"],
    }),
    addWorkNote: build.mutation<
      unknown,
      {
        title: string;
        note: string;
        createdByUserRelayId: string;
        workId: string;
      }
    >({
      query: (body) => ({
        url: "api/work-notes/add-note",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkNotes"],
    }),
    updateWorkNote: build.mutation<unknown, IWorkNote>({
      query: (body) => ({
        url: "api/work-notes/update-note",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkNotes"],
    }),
    pinWorkNote: build.mutation<unknown, { id: string; isPinned: boolean }>({
      query: ({ id, isPinned }) => ({
        url: "api/work-notes/mark-pinned",
        method: "PUT",
        params: { workNoteId: id, isPinned: isPinned },
      }),
      invalidatesTags: () => ["WorkNotes"],
    }),
    deleteWorkNote: build.mutation<unknown, IWorkNote>({
      query: (body) => ({
        url: "api/work-notes",
        method: "DELETE",
        body: { jobId: body.workId, id: body.id },
      }),
      invalidatesTags: ["WorkNotes"],
    }),
  }),
});
export const {
  useGetParticipantsQuery,
  useDeleteParticipantsMutation,
  useGetWorkNotesQuery,
  useAddWorkNoteMutation,
  useUpdateWorkNoteMutation,
  usePinWorkNoteMutation,
  useDeleteWorkNoteMutation,
} = workNotesSlice;
