import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

type Props = {
  triggerElement: ReactNode;
  positionType?: "right" | "bottom";
  isCloseOnClick?: boolean;
  propsPosition?: boolean;
  notification?: boolean;
};

export const Dropdown: FC<Props> = ({
  triggerElement,
  positionType = "right",
  isCloseOnClick,
  children,
  propsPosition,
  notification,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [position, setPosition] = useState<CSSProperties | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      const target = event.target;

      if (
        menuRef.current &&
        target instanceof Node &&
        !triggerRef.current?.contains(target) &&
        !menuRef.current.contains(target)
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = () => {
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen && triggerRef.current && menuRef.current) {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const menuHeight = menuRef.current?.offsetHeight;

      if (positionType === "bottom") {
        setPosition({
          top: triggerRect.bottom + 4,
          left: triggerRect?.left,
        });
      } else {
        setPosition({
          top: triggerRect.bottom - menuHeight,
          left: triggerRect?.right - 110,
        });
      }

      setIsHide(false);
    }

    if (!isOpen) {
      setIsHide(true);
    }
  }, [isOpen, triggerRef, menuRef, positionType]);

  const handleTriggerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const portalElement = document.getElementById("portal-root");
  if (!portalElement) {
    return null;
  }

  return (
    <>
      <div onClick={handleTriggerClick} ref={triggerRef} className={"w-fit"}>
        {triggerElement}
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            style={
              propsPosition && notification
                ? {
                    position: "fixed",
                    opacity: isHide ? 0 : 1,
                    top: "9rem",
                    right: "5rem",
                    fontSize: "14px",
                  }
                : propsPosition
                ? {
                    position: "fixed",
                    opacity: isHide ? 0 : 1,
                    top: "8rem",
                    right: "5rem",
                    fontSize: "14px",
                  }
                : {
                    position: "fixed",
                    opacity: isHide ? 0 : 1,
                    ...position,
                    fontSize: "14px",
                  }
            }
            onClick={(e) => {
              e.stopPropagation();
              if (isCloseOnClick) {
                setIsOpen(false);
              }
            }}
            ref={menuRef}>
            {children}
          </div>,
          portalElement,
        )}
    </>
  );
};
