import React, { FC, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useDeleteOrganizationNoteMutation,
  useGetOrganizationNotesQuery,
} from "../../../../../../../../slices/OrganizationNotesSlice";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import { Card } from "../../../../../../../../components/card/Card";
import Button from "../../../../../../../../components/button/Button";
import { HiPlus } from "react-icons/hi2";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import ContactNoteSvg from "../../../../../../../../components/svg/ContactNoteSvg";
import { OrganizationNoteView } from "./OrganizationNoteView";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { IOrganizationContactNote } from "../../../../../../../../types/types";
import { OrganizationNoteModal } from "../../../../../../modals/OrganizationNoteModal";

export const OrganizationNotesWidget: FC = () => {
  const { organizationId } = useParams();

  const { data: notes, isFetching } = useGetOrganizationNotesQuery({
    orgId: getLinkedFirm()?.orgId,
    organizationId: organizationId || "",
  });
  const [deleteNote] = useDeleteOrganizationNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [currentNote, setCurrentNote] =
    useState<IOrganizationContactNote | null>(null);
  const isActive = true;

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px] overflow-visible"}>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Notes</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={handleOpenAddModal}
            />
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !notes?.length ? (
              <div className={"p-6 pt-0"}>
                <NoItems
                  icon={<ContactNoteSvg />}
                  message={"There are no note items"}
                  addButtonMessage={"Add Note"}
                  openAdd={() =>
                    !isActive ? setIsOpenError(true) : handleOpenAddModal()
                  }
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-y-auto"}>
                {notes?.map((note) => (
                  <OrganizationNoteView
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteContactNote={deleteNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <OrganizationNoteModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          defaultValues={currentNote || undefined}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
