type NavigateToOrganization = { type: "organization"; id: string };
type NavigateToContact = {
  type: "contact";
  customerId: string;
};

export const navigateToContact = (
  navigation: NavigateToOrganization | NavigateToContact,
) => {
  if (navigation.type === "organization") {
    return `/organization/${navigation.id}`;
  } else {
    return `/contact/${navigation.customerId}`;
  }
};
