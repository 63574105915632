import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../components/NoItems";
import ContactWorkSvg from "../../../../../../components/svg/ContactWorkSvg";
import {
  useGetWorkTasksQuery,
  useReorderWorkTasksMutation,
} from "../../../../../../slices/WorkTasksSlice";
import { WorkTaskModal } from "../../../../modals/WorkTaskModel";
import { WorkTask } from "./WorkTask";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { WorkTaskModel } from "../../../../../../factories/work/model/work-task.model";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { IUser } from "../../../../../../types/types";

const reorder = (
  list: WorkTaskModel[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
type Props = {
  work: WorkDetailsModel;
  users?: IUser[];
  setIsOpenAdd: Dispatch<SetStateAction<boolean>>;
  isOpenAdd: boolean;
};
export const WorkTasksList: FC<Props> = ({
  work,
  users,
  isOpenAdd,
  setIsOpenAdd,
}) => {
  const { workId } = useParams();

  const { data, isLoading } = useGetWorkTasksQuery({
    workId: workId || "",
  });
  const [reorderWorkTasks] = useReorderWorkTasksMutation();

  const [workTasks, setWorkTasks] = useState<WorkTaskModel[]>(data || []);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      workTasks,
      result.source.index,
      result.destination.index,
    );
    reorderWorkTasks(newItems.map((item) => item.id));
    setWorkTasks(newItems);
  };

  useEffect(() => {
    setWorkTasks(data || []);
  }, [data]);

  return (
    <>
      <div className={"w-full"}>
        {isLoading ? (
          <div>
            <LottieLoading />
          </div>
        ) : (
          <>
            {!workTasks?.length ? (
              <div className={"mt-8 px-6 pb-6"}>
                <NoItems
                  icon={<ContactWorkSvg />}
                  message={"There are no subtasks"}
                  addButtonMessage={"Add Subtask"}
                  openAdd={() => setIsOpenAdd(true)}
                />
              </div>
            ) : (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={"droppable"}>
                    {(provided: DroppableProvided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={"flex w-full max-w-full flex-col pb-3"}>
                        {workTasks?.map((workTask, index) => (
                          <Draggable
                            key={workTask.id}
                            draggableId={workTask.id}
                            index={index}>
                            {(provided: DraggableProvided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}>
                                <WorkTask
                                  workTask={workTask}
                                  users={users || []}
                                  work={work}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </>
        )}
      </div>
      {isOpenAdd && (
        <WorkTaskModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          users={users}
        />
      )}
    </>
  );
};
