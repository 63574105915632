type Props = {
  className?: string;
};

export const HomeIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 16H14M9.0177 1.76403L2.23539 7.03916C1.78202 7.39178 1.55534 7.56809 1.39203 7.78889C1.24737 7.98447 1.1396 8.20481 1.07403 8.43908C1 8.70355 1 8.99073 1 9.56508V16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56508C19 8.99073 19 8.70355 18.926 8.43908C18.8604 8.20481 18.7526 7.98447 18.608 7.78889C18.4447 7.56809 18.218 7.39178 17.7646 7.03916L10.9823 1.76403C10.631 1.49078 10.4553 1.35415 10.2613 1.30163C10.0902 1.25529 9.9098 1.25529 9.73865 1.30163C9.54468 1.35415 9.36902 1.49078 9.0177 1.76403Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DashboardIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.254 1.96875C6.24323 1.96872 5.40034 1.96869 4.73121 2.05865C4.02495 2.15361 3.3873 2.36249 2.87489 2.87489C2.36249 3.3873 2.15361 4.02495 2.05865 4.73121C1.96869 5.40034 1.96872 6.2432 1.96875 7.25397V7.371C1.96872 8.38178 1.96869 9.22467 2.05865 9.89379C2.15361 10.6001 2.36249 11.2377 2.87489 11.7501C3.3873 12.2625 4.02495 12.4714 4.73121 12.5664C5.40034 12.6563 6.2432 12.6563 7.25397 12.6563H7.371C8.38177 12.6563 9.22467 12.6563 9.89379 12.5664C10.6001 12.4714 11.2377 12.2625 11.7501 11.7501C12.2625 11.2377 12.4714 10.6001 12.5664 9.89379C12.6563 9.22467 12.6563 8.38181 12.6563 7.37103V7.25401C12.6563 6.24323 12.6563 5.40034 12.5664 4.73121C12.4714 4.02495 12.2625 3.3873 11.7501 2.87489C11.2377 2.36249 10.6001 2.15361 9.89379 2.05865C9.22467 1.96869 8.38181 1.96872 7.37103 1.96875H7.254ZM4.06813 4.06813C4.21474 3.92153 4.43693 3.8009 4.95607 3.73111C5.49949 3.65805 6.22799 3.65625 7.3125 3.65625C8.39702 3.65625 9.12552 3.65805 9.66894 3.73111C10.1881 3.8009 10.4103 3.92153 10.5569 4.06813C10.7035 4.21474 10.8241 4.43693 10.8939 4.95607C10.967 5.49949 10.9688 6.22799 10.9688 7.3125C10.9688 8.39702 10.967 9.12552 10.8939 9.66894C10.8241 10.1881 10.7035 10.4103 10.5569 10.5569C10.4103 10.7035 10.1881 10.8241 9.66894 10.8939C9.12552 10.967 8.39702 10.9688 7.3125 10.9688C6.22799 10.9688 5.49949 10.967 4.95607 10.8939C4.43693 10.8241 4.21474 10.7035 4.06813 10.5569C3.92153 10.4103 3.8009 10.1881 3.73111 9.66894C3.65805 9.12552 3.65625 8.39702 3.65625 7.3125C3.65625 6.22799 3.65805 5.49949 3.73111 4.95607C3.8009 4.43693 3.92153 4.21474 4.06813 4.06813Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.629 14.3438C18.6182 14.3437 17.7753 14.3437 17.1062 14.4337C16.3999 14.5286 15.7623 14.7375 15.2499 15.2499C14.7375 15.7623 14.5286 16.3999 14.4337 17.1062C14.3437 17.7753 14.3437 18.6182 14.3438 19.629V19.746C14.3437 20.7568 14.3437 21.5997 14.4337 22.2688C14.5286 22.9751 14.7375 23.6127 15.2499 24.1251C15.7623 24.6375 16.3999 24.8464 17.1062 24.9414C17.7753 25.0313 18.6182 25.0313 19.6289 25.0313H19.746C20.7567 25.0313 21.5997 25.0313 22.2688 24.9414C22.9751 24.8464 23.6127 24.6375 24.1251 24.1251C24.6375 23.6127 24.8464 22.9751 24.9414 22.2688C25.0313 21.5997 25.0313 20.7569 25.0313 19.7461V19.629C25.0313 18.6183 25.0313 17.7753 24.9414 17.1062C24.8464 16.3999 24.6375 15.7623 24.1251 15.2499C23.6127 14.7375 22.9751 14.5286 22.2688 14.4337C21.5997 14.3437 20.7568 14.3437 19.746 14.3438H19.629ZM16.4431 16.4431C16.5897 16.2965 16.8119 16.1759 17.3311 16.1061C17.8745 16.033 18.603 16.0313 19.6875 16.0313C20.772 16.0313 21.5005 16.033 22.0439 16.1061C22.5631 16.1759 22.7853 16.2965 22.9319 16.4431C23.0785 16.5897 23.1991 16.8119 23.2689 17.3311C23.342 17.8745 23.3438 18.603 23.3438 19.6875C23.3438 20.772 23.342 21.5005 23.2689 22.0439C23.1991 22.5631 23.0785 22.7853 22.9319 22.9319C22.7853 23.0785 22.5631 23.1991 22.0439 23.2689C21.5005 23.342 20.772 23.3438 19.6875 23.3438C18.603 23.3438 17.8745 23.342 17.3311 23.2689C16.8119 23.1991 16.5897 23.0785 16.4431 22.9319C16.2965 22.7853 16.1759 22.5631 16.1061 22.0439C16.033 21.5005 16.0313 20.772 16.0313 19.6875C16.0313 18.603 16.033 17.8745 16.1061 17.3311C16.1759 16.8119 16.2965 16.5897 16.4431 16.4431Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.254 14.3438H7.371C8.38179 14.3437 9.22466 14.3437 9.89379 14.4337C10.6001 14.5286 11.2377 14.7375 11.7501 15.2499C12.2625 15.7623 12.4714 16.3999 12.5664 17.1062C12.6563 17.7753 12.6563 18.6182 12.6563 19.629V19.746C12.6563 20.7568 12.6563 21.5997 12.5664 22.2688C12.4714 22.9751 12.2625 23.6127 11.7501 24.1251C11.2377 24.6375 10.6001 24.8464 9.89379 24.9414C9.22468 25.0313 8.38183 25.0313 7.37107 25.0313H7.25401C6.24325 25.0313 5.40033 25.0313 4.73121 24.9414C4.02495 24.8464 3.3873 24.6375 2.87489 24.1251C2.36249 23.6127 2.15361 22.9751 2.05865 22.2688C1.96869 21.5997 1.96872 20.7568 1.96875 19.746V19.629C1.96872 18.6182 1.96869 17.7753 2.05865 17.1062C2.15361 16.3999 2.36249 15.7623 2.87489 15.2499C3.3873 14.7375 4.02495 14.5286 4.73121 14.4337C5.40034 14.3437 6.24321 14.3437 7.254 14.3438ZM4.95607 16.1061C4.43693 16.1759 4.21474 16.2965 4.06813 16.4431C3.92153 16.5897 3.8009 16.8119 3.73111 17.3311C3.65805 17.8745 3.65625 18.603 3.65625 19.6875C3.65625 20.772 3.65805 21.5005 3.73111 22.0439C3.8009 22.5631 3.92153 22.7853 4.06813 22.9319C4.21474 23.0785 4.43693 23.1991 4.95607 23.2689C5.49949 23.342 6.22799 23.3438 7.3125 23.3438C8.39702 23.3438 9.12552 23.342 9.66894 23.2689C10.1881 23.1991 10.4103 23.0785 10.5569 22.9319C10.7035 22.7853 10.8241 22.5631 10.8939 22.0439C10.967 21.5005 10.9688 20.772 10.9688 19.6875C10.9688 18.603 10.967 17.8745 10.8939 17.3311C10.8241 16.8119 10.7035 16.5897 10.5569 16.4431C10.4103 16.2965 10.1881 16.1759 9.66894 16.1061C9.12552 16.033 8.39702 16.0313 7.3125 16.0313C6.22799 16.0313 5.49949 16.033 4.95607 16.1061Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.629 1.96875C18.6182 1.96872 17.7753 1.96869 17.1062 2.05865C16.3999 2.15361 15.7623 2.36249 15.2499 2.87489C14.7375 3.3873 14.5286 4.02495 14.4337 4.73121C14.3437 5.40034 14.3437 6.24321 14.3438 7.254V7.371C14.3437 8.38179 14.3437 9.22466 14.4337 9.89379C14.5286 10.6001 14.7375 11.2377 15.2499 11.7501C15.7623 12.2625 16.3999 12.4714 17.1062 12.5664C17.7753 12.6563 18.6182 12.6563 19.629 12.6563H19.746C20.7568 12.6563 21.5997 12.6563 22.2688 12.5664C22.9751 12.4714 23.6127 12.2625 24.1251 11.7501C24.6375 11.2377 24.8464 10.6001 24.9414 9.89379C25.0313 9.22467 25.0313 8.38181 25.0313 7.37103V7.25401C25.0313 6.24323 25.0313 5.40034 24.9414 4.73121C24.8464 4.02495 24.6375 3.3873 24.1251 2.87489C23.6127 2.36249 22.9751 2.15361 22.2688 2.05865C21.5997 1.96869 20.7568 1.96872 19.746 1.96875H19.629ZM16.4431 4.06813C16.5897 3.92153 16.8119 3.8009 17.3311 3.73111C17.8745 3.65805 18.603 3.65625 19.6875 3.65625C20.772 3.65625 21.5005 3.65805 22.0439 3.73111C22.5631 3.8009 22.7853 3.92153 22.9319 4.06813C23.0785 4.21474 23.1991 4.43693 23.2689 4.95607C23.342 5.49949 23.3438 6.22799 23.3438 7.3125C23.3438 8.39702 23.342 9.12552 23.2689 9.66894C23.1991 10.1881 23.0785 10.4103 22.9319 10.5569C22.7853 10.7035 22.5631 10.8241 22.0439 10.8939C21.5005 10.967 20.772 10.9688 19.6875 10.9688C18.603 10.9688 17.8745 10.967 17.3311 10.8939C16.8119 10.8241 16.5897 10.7035 16.4431 10.5569C16.2965 10.4103 16.1759 10.1881 16.1061 9.66894C16.033 9.12552 16.0313 8.39702 16.0313 7.3125C16.0313 6.22799 16.033 5.49949 16.1061 4.95607C16.1759 4.43693 16.2965 4.21474 16.4431 4.06813Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export const ContactsIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.375 24.1667C25.375 22.0622 23.3575 20.2719 20.5417 19.6084M18.125 24.1667C18.125 21.4974 14.8791 19.3334 10.875 19.3334C6.87094 19.3334 3.625 21.4974 3.625 24.1667M18.125 15.7084C20.7944 15.7084 22.9583 13.5445 22.9583 10.8751C22.9583 8.2057 20.7944 6.04175 18.125 6.04175M10.875 15.7084C8.20562 15.7084 6.04167 13.5445 6.04167 10.8751C6.04167 8.2057 8.20562 6.04175 10.875 6.04175C13.5444 6.04175 15.7083 8.2057 15.7083 10.8751C15.7083 13.5445 13.5444 15.7084 10.875 15.7084Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const WorksIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66667 9.66659H7.4919C6.13844 9.66659 5.46121 9.66659 4.94425 9.92999C4.48953 10.1617 4.12009 10.5311 3.8884 10.9858C3.625 11.5028 3.625 12.18 3.625 13.5335V20.3002C3.625 21.6536 3.625 22.33 3.8884 22.8469C4.12009 23.3017 4.48953 23.6717 4.94425 23.9034C5.4607 24.1666 6.13712 24.1666 7.48793 24.1666H21.5121C22.8629 24.1666 23.5383 24.1666 24.0548 23.9034C24.5095 23.6717 24.8802 23.3017 25.1119 22.8469C25.375 22.3305 25.375 21.6551 25.375 20.3042V13.5295C25.375 12.1787 25.375 11.5023 25.1119 10.9858C24.8802 10.5311 24.5095 10.1617 24.0548 9.92999C23.5378 9.66659 22.862 9.66659 21.5086 9.66659H19.3333M9.66667 9.66659H19.3333M9.66667 9.66659C9.66667 6.99721 11.8306 4.83325 14.5 4.83325C17.1694 4.83325 19.3333 6.99721 19.3333 9.66659"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MailsIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.83333 7.25002L12.2134 12.8232L12.2159 12.8252C13.0353 13.4262 13.4453 13.7268 13.8943 13.8429C14.2912 13.9456 14.7084 13.9456 15.1053 13.8429C15.5548 13.7267 15.966 13.4252 16.7869 12.8232C16.7869 12.8232 21.5205 9.19053 24.1667 7.25002M3.625 19.0919V9.90859C3.625 8.55513 3.625 7.87789 3.8884 7.36094C4.12009 6.90622 4.48953 6.53678 4.94425 6.30509C5.46121 6.04169 6.13844 6.04169 7.4919 6.04169H21.5086C22.862 6.04169 23.5378 6.04169 24.0548 6.30509C24.5095 6.53678 24.8802 6.90622 25.1119 7.36094C25.375 7.87739 25.375 8.5538 25.375 9.90462V19.096C25.375 20.4468 25.375 21.1223 25.1119 21.6387C24.8802 22.0934 24.5095 22.4635 24.0548 22.6952C23.5383 22.9584 22.8629 22.9584 21.5121 22.9584H7.48793C6.13712 22.9584 5.4607 22.9584 4.94425 22.6952C4.48953 22.4635 4.12009 22.0934 3.8884 21.6387C3.625 21.1218 3.625 20.4454 3.625 19.0919Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CalendarIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="ion:calendar-clear-outline">
        <path
          id="Vector"
          d="M24.7548 5.31934H6.24522C4.71184 5.31934 3.46878 6.56239 3.46878 8.09578V24.7544C3.46878 26.2878 4.71184 27.5309 6.24522 27.5309H24.7548C26.2882 27.5309 27.5313 26.2878 27.5313 24.7544V8.09578C27.5313 6.56239 26.2882 5.31934 24.7548 5.31934Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.09618 3.46875V5.31971M22.9039 3.46875V5.31971M27.5313 9.94712H3.46878"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export const TimeIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 8.45833V14.5H20.5417M14.5 25.375C8.4939 25.375 3.625 20.5061 3.625 14.5C3.625 8.4939 8.4939 3.625 14.5 3.625C20.5061 3.625 25.375 8.4939 25.375 14.5C25.375 20.5061 20.5061 25.375 14.5 25.375Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BillingIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M11 16H5M13 12H5M17 8.98822V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H9.01178C9.74555 1 10.1124 1 10.4577 1.08289C10.7638 1.15638 11.0564 1.27759 11.3249 1.44208C11.6276 1.6276 11.887 1.88703 12.4059 2.40589L15.5941 5.59411C16.113 6.11297 16.3724 6.3724 16.5579 6.67515C16.7224 6.94356 16.8436 7.2362 16.9171 7.5423C17 7.88757 17 8.25445 17 8.98822Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
