import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { WorkDetailsModel } from "../factories/work/model/work-details.model";
import { WorkTimeModel } from "../factories/work/model/work-time.model";
import { AddWorkDto } from "../factories/works/dtos/add-work.dto";
import { AddWorkResponseModel } from "../factories/works/models/add-work-response.model";
import { AllWorksStatsModel } from "../factories/works/models/all-works-stats.model";
import {
  HomeFirmStatsResponseModel,
  HomeStatsModel,
  HomeStatsResponseModel,
} from "../factories/works/models/home-stats.model";
import { SearchWorkModel } from "../factories/works/models/searchWorkModel";
import { WorkModel } from "../factories/works/models/work.model";
import { WorksStatsModel } from "../factories/works/models/works-stats.model";
import { WorkTableSort } from "../features/works/components/types/work-table";
import { SelectOptionType } from "../types/types";
import { contactsSlice } from "./ContactsSlice";
import { workTasksSlice } from "./WorkTasksSlice";
import { FirmsWorkCountsResponseModel } from "../factories/works/models/firms-work-counts.model";

export const workSlice = createApi({
  reducerPath: "workSlice",
  tagTypes: ["Work"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    /**Works Page*/
    getWorks: build.query<
      WorkModel[],
      {
        pageNumber: number;
        pageSize: number;

        customerIds?: string[];
        workName?: string[];
        userIds?: string[];
        workStatuses?: string[];

        worktypeIds?: string[];

        startDates?: string[];
        dueDates?: string[];
        fromDate?: Date | null;
        toDate?: Date | null;

        orgId: string;
        sortBy: WorkTableSort;
        sortOrder: "asc" | "desc";
        currentUserId?: string;
      }
    >({
      query: (body) => ({
        url: "api/work/find-work/" + body.orgId,
        params: {
          sortBy: body.sortBy,
          sortOrder: body.sortOrder,
        },
        body: {
          pageNumber: body.pageNumber,
          pageSize: body.pageSize.toString(),

          customerIds: body.customerIds || [],
          workName: body.workName || [],
          userIds: body.userIds || [],
          workStatuses: body.workStatuses || [],

          worktypeIds: body.worktypeIds || [],

          startDates: body.startDates || [],
          dueDates: body.dueDates || [],
          fromDate: body?.fromDate || null,
          toDate: body?.toDate || null,
          currentUserId: body?.currentUserId,
        },
        method: "POST",
      }),
      providesTags: ["Work"],
    }),
    getHomeWorks: build.query<
      WorkModel[],
      {
        pageNumber: number;
        pageSize: number;
        sortBy: WorkTableSort;
        sortOrder: "asc" | "desc";
        dueDates: string;
        status?: string;
        assignedUserId?: string;
        orgId: string;
        currentUserId?: string;
      }
    >({
      query: (body) => ({
        url: "api/work/firms-and-my-work/" + body.orgId,
        body: {
          pageNumber: body.pageNumber,
          pageSize: body.pageSize,
          assignedUserId: body.assignedUserId || undefined,
          dueDates: body.dueDates,
          status: body.status || null,
          sortBy: body.sortBy,
          sortOrder: body.sortOrder,
          currentUserId: body.currentUserId,
        },
        method: "POST",
      }),
      providesTags: ["Work"],
    }),
    exportWorks: build.query<
      WorkModel[],
      {
        pageNumber: number;
        pageSize: number;

        customerIds?: string[];
        workName?: string[];
        userIds?: string[];
        workStatuses?: string[];

        worktypeIds?: string[];

        startDates?: string[];
        dueDates?: string[];
        fromDate?: Date | null;
        toDate?: Date | null;

        orgId: string;
        sortBy: WorkTableSort;
        sortOrder: "asc" | "desc";
        currentUserId?: string;
      }
    >({
      query: (body) => ({
        url: "api/work/export-work/" + body.orgId,
        params: {
          sortBy: body.sortBy,
          sortOrder: body.sortOrder,
        },
        body: {
          pageNumber: body.pageNumber,
          pageSize: body.pageSize.toString(),

          customerIds: body.customerIds || [],
          workName: body.workName || [],
          userIds: body.userIds || [],
          workStatuses: body.workStatuses || [],

          worktypeIds: body.worktypeIds || [],

          startDates: body.startDates || [],
          dueDates: body.dueDates || [],
          fromDate: body?.fromDate || null,
          toDate: body?.toDate || null,
          currentUserId: body?.currentUserId,
        },
        method: "POST",
      }),
      providesTags: ["Work"],
    }),
    getAllWorkStats: build.query<AllWorksStatsModel, { orgId: string }>({
      query: (params) => ({
        url: "api/dashboard/get-all-works",
        params,
        method: "GET",
      }),
      providesTags: ["Work"],
    }),
    getFirmsWorksCounts: build.query<
      HomeStatsModel,
      { orgId: string; userId: string; isMyWork: boolean }
    >({
      query: (params) => ({
        url: "api/dashboard/firms-work-counts",
        params,
        method: "GET",
      }),
      transformResponse: (response: FirmsWorkCountsResponseModel) => {
        return {
          thisWeek: {
            total: response?.firmsWorkWeekCount,
            ready: response?.thisWeekReadyCount,
            inProgress: response?.thisWeekProgressCount,
            waiting: response?.thisWeekWaitingCount,
            overdue: response?.firmsWorkOverDueCount,
          },
          nextWeek: {
            total: response?.firmsWorkNextWeekCount,
            ready: response?.nextWeekReadyCount,
            inProgress: response?.nextWeekProgressCount,
            waiting: response?.nextWeekWaitingCount,
          },
          later: {
            total: response?.firmsWorkDueLaterCount,
            ready: response?.dueLaterReadyCount,
            inProgress: response?.dueLaterProgressCount,
            waiting: response?.dueLaterWaitingCount,
          },
          allOpen: {
            total: response?.firmAllOpenWorksCount,
            ready: response?.allOpenReadyCount,
            inProgress: response?.allOpenProgressCount,
            waiting: response?.allOpenWaitingCount,
          },
          completed: response?.firmsWorkCompletedCount,
        };
      },
      providesTags: ["Work"],
    }),
    getArchivedWorks: build.query<
      WorkModel[],
      { orgId: string; userId: string }
    >({
      query: (params) => ({
        url: "api/work/get-archives",
        params,
        method: "GET",
      }),
      providesTags: ["Work"],
    }),

    getMyWorkStats: build.query<
      AllWorksStatsModel,
      { userRelayId: string; orgId: string }
    >({
      query: ({ orgId, userRelayId }) => ({
        url: "api/dashboard/get-my-work/" + userRelayId,
        params: { orgId },
        method: "GET",
      }),
      transformResponse: (response: WorksStatsModel) => {
        return {
          allWorksCount:
            (response?.myWorkAllOpenCount || 0) +
            (response?.myWorkCompletedCount || 0),
          completedWorkCount: response?.myWorkCompletedCount || 0,
          openWorkCount: response?.myWorkAllOpenCount || 0,
          overDueWorkCount: response?.myWorkOverDueCount || 0,
        };
      },
      providesTags: ["Work"],
    }),
    //Home
    getHomeMyWorkStats: build.query<
      HomeStatsModel,
      { userId: string; orgId: string }
    >({
      query: ({ orgId, userId }) => ({
        url: "/api/dashboard/get-my-work/" + userId,
        params: { orgId },
        method: "GET",
      }),
      transformResponse: (response: HomeStatsResponseModel) => {
        return {
          thisWeek: {
            total: response?.myWorkWeekCount || 0,
            ready: response?.thisWeekReadyCount || 0,
            inProgress: response?.thisWeekProgressCount || 0,
            waiting: response?.thisWeekWaitingCount,
            overdue: response?.myWorkOverDueCount,
          },
          nextWeek: {
            total: response?.myWorkNextWeekCount || 0,
            ready: response?.nextWeekReadyCount || 0,
            inProgress: response?.nextWeekProgressCount || 0,
            waiting: response?.nextWeekWaitingCount,
          },
          later: {
            total: response?.myWorkDueLaterCount || 0,
            ready: response?.dueLaterReadyCount || 0,
            inProgress: response?.dueLaterProgressCount || 0,
            waiting: response?.dueLaterWaitingCount,
          },
          allOpen: {
            total: response?.myWorkAllOpenCount || 0,
            ready: response?.allOpenReadyCount || 0,
            inProgress: response?.allOpenProgressCount || 0,
            waiting: response?.allOpenWaitingCount,
          },
          completed: response?.myWorkCompletedCount || 0,
        };
      },
      providesTags: ["Work"],
    }),
    getHomeFirmWorkStats: build.query<HomeStatsModel, { orgId: string }>({
      query: ({ orgId }) => ({
        url: "/api/dashboard/get-firms-work",
        params: { orgId },
        method: "GET",
      }),
      transformResponse: (response: HomeFirmStatsResponseModel) => {
        return {
          thisWeek: {
            total:
              (response?.firmsWorkWeekCount || 0) +
              (response?.firmsWorkOverDueCount || 0),
            ready: response?.thisWeekReadyCount || 0,
            inProgress: response?.thisWeekProgressCount || 0,
            waiting: response?.thisWeekWaitingCount,
            overdue: response?.firmsWorkOverDueCount,
          },
          nextWeek: {
            total: response?.firmsWorkNextWeekCount || 0,
            ready: response?.nextWeekReadyCount || 0,
            inProgress: response?.nextWeekProgressCount || 0,
            waiting: response?.nextWeekWaitingCount,
          },
          later: {
            total: response?.firmsWorkDueLaterCount || 0,
            ready: response?.dueLaterReadyCount || 0,
            inProgress: response?.dueLaterProgressCount || 0,
            waiting: response?.dueLaterWaitingCount,
          },
          allOpen: {
            total: response?.firmAllOpenWorksCount || 0,
            ready: response?.allOpenReadyCount || 0,
            inProgress: response?.allOpenProgressCount || 0,
            waiting: response?.allOpenWaitingCount,
          },
          completed: response?.firmsWorkCompletedCount || 0,
        };
      },
      providesTags: ["Work"],
    }),

    /**Work*/

    getWork: build.query<WorkDetailsModel, { workId: string }>({
      query: ({ workId }) => ({
        url: "api/work/get-by-work-id/" + workId,
        method: "GET",
      }),
      providesTags: ["Work"],
    }),
    getRepeatsWork: build.query<WorkModel[], { workId: string; orgId: string }>(
      {
        query: ({ workId, orgId }) => ({
          url: `${devConfig.getServerBaseUrlForEnv()}/api/work/get-recurring-works?orgId=${orgId}&jobId=${workId}`,
          method: "GET",
        }),
        providesTags: ["Work"],
      },
    ),
    getTimesByWork: build.query<
      WorkTimeModel[],
      { workId: string; orgId: string }
    >({
      query: ({ workId, orgId }) => ({
        url: `${devConfig.getServerBaseUrlForEnv()}/api/time/get-by-workId/${workId}?orgId=${orgId}`,
        method: "GET",
      }),
      providesTags: ["Work"],
    }),
    updateWork: build.mutation<
      unknown,
      {
        workId: string;
        workName: string;
        serviceId: string;
        taskTemplateId: string;
        userId: string;
        customerId: string;
        businessContactId: string | null;
        isReassigned: boolean;
        assignedByUserId: string;
      }
    >({
      query: (body) => ({
        url: "api/work/update-details",
        body,
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
        });
      },
      invalidatesTags: ["Work"],
    }),
    updateWorkDates: build.mutation<
      {
        workId: string;
        startDate: Date;
        dueDate: Date;
      },
      {
        workId: string;
        startDate: Date;
        endDate: Date;
        createdAt: Date;
        relayId: string | null;
      }
    >({
      query: (body) => ({
        url: "api/work/update-due-date",
        body,
        method: "PUT",
      }),
      transformResponse: (response: {
        workId: string;
        startDate: Date;
        endDate: Date;
      }) => ({
        ...response,
        startDate: response.startDate,
        dueDate: response.endDate,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(workTasksSlice.util?.invalidateTags(["WorkTask"]));
        });
      },
      invalidatesTags: ["Work"],
    }),
    updateWorkTemplate: build.mutation<
      WorkDetailsModel,
      {
        workId: string;
        templateId: string;
      }
    >({
      query: (params) => ({
        url: "api/work/update-template",
        params,
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
          api.dispatch(workTasksSlice.util?.invalidateTags(["WorkTask"]));
        });
      },
      invalidatesTags: ["Work"],
    }),
    archiveOrUnarchive: build.mutation<
      unknown,
      {
        workId: string;
        archiveWork: boolean;
      }
    >({
      query: (params) => ({
        url: "api/work/archive-or-unarchive",
        params,
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
        });
        api.dispatch(workTasksSlice.util?.invalidateTags(["WorkTask"]));
      },
      invalidatesTags: ["Work"],
    }),
    deleteWork: build.mutation<unknown, WorkDetailsModel>({
      query: (work) => ({
        url: "api/work",
        method: "DELETE",
        body: work,
      }),
      invalidatesTags: ["Work"],
    }),
    updateWorkRepeats: build.mutation<
      unknown,
      {
        workId: string;
        repeatTypeEnumId: string;
        beforeDays: number;
        repeatOnId: string;
        repeatValue: string;
        repeatUntil: Date;
        dueDate: string;
        beforeStartDate: number;
        beforeStartDateValue: string;
      }
    >({
      query: (body) => ({
        url: "api/work/repeat-work",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Work"],
    }),

    /**Common*/

    getSearchWorks: build.query<
      SelectOptionType[],
      { query: string; orgId: string }
    >({
      query: (params) => ({
        url: "api/work/search-work",
        params,
        method: "GET",
      }),
      providesTags: ["Work"],
      transformResponse: (response: SearchWorkModel[]) => {
        const result: SelectOptionType[] = response.map((item) => ({
          value: item?.id,
          label: item?.name,
          caption: item?.customer?.name || "",
          contactId: item.customer.contactId || "",
          businessContactId: item.customer.businessContactId || "",
        }));
        return result;
      },
    }),
    getSearchCustomersWorks: build.query<
      SelectOptionType[],
      { customerId: string; orgId: string }
    >({
      query: ({ customerId, orgId }) => ({
        url: `api/work/get-by-customer-id/${customerId}?orgId=${orgId}`,
        method: "GET",
      }),
      providesTags: ["Work"],
      transformResponse: (response: SearchWorkModel[]) => {
        const result: SelectOptionType[] = response.map((item) => ({
          value: item?.id,
          label: item?.name,
          caption: item?.customer?.name || "",
          contactId: item.customer.contactId || "",
          businessContactId: item.customer.businessContactId || "",
        }));
        return result;
      },
    }),
    updateStatusWork: build.mutation<
      WorkModel,
      { workId: string; statusId: string | null; subStatusId: string | null }
    >({
      query: (params) => ({
        url: "api/work/update-status",
        params: {
          ...params,
          subStatusId: params?.subStatusId || undefined,
        },
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
        });
      },
      invalidatesTags: ["Work"],
    }),
    addWork: build.mutation<AddWorkResponseModel, AddWorkDto>({
      query: (body) => ({
        url: "api/work/add-work",
        body,
        method: "POST",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
        });
      },
      invalidatesTags: ["Work"],
    }),
  }),
});
export const {
  //Works Page
  useGetAllWorkStatsQuery,
  useGetMyWorkStatsQuery,
  useGetWorksQuery,
  useGetArchivedWorksQuery,
  useArchiveOrUnarchiveMutation,
  useGetFirmsWorksCountsQuery,

  //Home
  useGetHomeMyWorkStatsQuery,
  useGetHomeFirmWorkStatsQuery,
  useGetHomeWorksQuery,

  //Work
  useGetWorkQuery,
  useUpdateWorkMutation,
  useUpdateWorkDatesMutation,
  useUpdateWorkTemplateMutation,
  useGetTimesByWorkQuery,
  useGetRepeatsWorkQuery,
  useDeleteWorkMutation,
  useUpdateWorkRepeatsMutation,
  useGetSearchCustomersWorksQuery,
  useExportWorksQuery,

  //Common
  useGetSearchWorksQuery,
  useAddWorkMutation,
  useUpdateStatusWorkMutation,
} = workSlice;
