export enum NotificationTitle {
  All = "All",
  Comments = "Comments",
  AssignedToMe = "Assigned to me",
  CompletedSubtasks = "Completed Subtasks",
}

export enum NotificationTitleValue {
  All = "All",
  Comments = "Comments",
  AssignedToMe = "AssignedToMe",
  CompletedSubtasks = "CompletedTasks",
}

export enum NotificationFilterCount {
  allCounts = "allCounts",
  assignedToMeCounts = "assignedToMeCounts",
  commentCounts = "commentCounts",
  completedTasksCounts = "completedTasksCounts",
}
