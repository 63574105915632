import { useApolloClient } from "@apollo/client";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import fileDownload from "js-file-download";
import _ from "lodash";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import AsyncSelect from "react-select/async";

import { protectedResources } from "../../../app/configs/appConfig";
import { LABELS, LinkConstants } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import {
  GetBankDocById_bankDocById,
  GetSearchBankNameFid,
  GetSearchBankNameFidVariables,
} from "../../../generated/operation-result-types";
import { GET_SEARCH_BANK_NAME_FID_GQL } from "../../../queries/BankDocQueries.gql";
import { postApiWithToken } from "../../../support/FetchWithToken";
import { FormSection } from "../../../support/FormSection";
import { BankDocInfoPanelView } from "../../InfoPanels/BankDocInfoPanelView";
import { InfoPanelsView } from "../../InfoPanels/InfoPanelsView";
import {
  BankDocDownloadConstants,
  ReactSelectOptionT,
} from "./BankDocDownloadConstants";

type BankDocDownloadViewProps = {
  bankDocById: GetBankDocById_bankDocById;
};

type BankDocDownloadRestApiInput = {
  orgId: string;
  bankDocId: string;
  accEnum: string;
  currency: string;
  accountNumber: string;
  bankId: string;
};

export const BankDocDownloadView: React.FC<BankDocDownloadViewProps> = ({
  bankDocById,
}) => {
  // app insights
  const appInsights = useAppInsightsContext();
  const trackBankDocDownload = useTrackEvent(
    appInsights,
    "Bank doc output downloaded",
    {},
  );

  // react router
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accSwEnum =
    searchParams.get(LinkConstants.bankStatements.accEnumQueryParam) ??
    "unknown";
  const accSw = BankDocDownloadConstants.getAccSw(accSwEnum);

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;

  // gql
  const apolloClient = useApolloClient();

  // RHF
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BankDocDownloadRestApiInput>({
    defaultValues: {
      orgId: orgId,
      bankDocId: bankDocById.id,
      accEnum: accSwEnum,
      currency: BankDocDownloadConstants.firstCurrency.value,
      accountNumber: "123456",
    },
  });

  // local state
  const [isRestApiLoading, setIsRestApiLoading] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );

  const onSubmit: SubmitHandler<BankDocDownloadRestApiInput> = async (data) => {
    trackBankDocDownload({
      orgId,
      accountNumber: data.accountNumber,
      currency: data.currency,
      accEnum: data.accEnum,
    });
    setIsRestApiLoading(true);
    try {
      const response = await postApiWithToken(
        protectedResources.bankDocs.downloadEndpoint,
        data,
        {},
      );
      if (response.status === 200) {
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(";", 1)[0];
        fileDownload(response.data, fileName);
      } else {
        setErrorMessage(response.data);
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.toString());
      }
    }
    setIsRestApiLoading(false);
  };

  // async select - bank name search
  type SearchCallbackT = (x: ReactSelectOptionT[]) => void;
  const bankNameSearch = (inputValue: string, callback: SearchCallbackT) => {
    // async-await doesn't work with lodash.debounce
    // see https://stackoverflow.com/questions/55073545/react-select-debounced-async-call-not-displaying-suggestions
    apolloClient
      .query<GetSearchBankNameFid, GetSearchBankNameFidVariables>({
        query: GET_SEARCH_BANK_NAME_FID_GQL,
        variables: { searchTerm: inputValue },
      })
      .then(({ data }) => {
        if (!data) {
          callback([]);
          return;
        }

        const transformedList = data.searchBankNameFid.map(
          (x): ReactSelectOptionT => {
            return {
              value: x.fid,
              label: `${x.name} -- ${x.urlHost}`,
            };
          },
        );
        callback(transformedList);
      });
  };

  const debouncedBankNameSearch = _.debounce(bankNameSearch, 300);

  const currencySearch = async (): Promise<ReactSelectOptionT[]> => {
    return BankDocDownloadConstants.currenciesList;
  };

  const uploadSection = (
    <FormSection name={accSw.displayName} extraCx={"overflow-visible"}>
      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Bank Name</span>
        </label>
        <div className={"w-full"}>
          <Controller
            control={control}
            name={"bankId"}
            rules={{ required: LABELS.required }}
            render={({ field: { onChange } }) => {
              return (
                <AsyncSelect
                  cacheOptions={true}
                  isMulti={false}
                  isClearable={true}
                  isSearchable={true}
                  defaultOptions
                  loadOptions={debouncedBankNameSearch}
                  onChange={(option) => onChange(option?.value)}
                />
              );
            }}
          />
          {errors?.bankId?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.bankId?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Account Number</span>
        </label>
        <div className={"w-full"}>
          <input
            type={"text"}
            {...register("accountNumber", {
              required: LABELS.required,
              ...ValidationConstants.bankDocDownloadRules.accountNumberRule
                .valueLength,
            })}
            className={"input input-bordered w-full"}
          />
          <div className={"text-xs opacity-50"}>
            This is optional. If you have an account number associated in your
            desktop accounting software, enter it above.
          </div>
          {errors?.accountNumber?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.accountNumber?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Currency</span>
        </label>
        <div className={"w-full"}>
          <Controller
            control={control}
            name={"currency"}
            rules={{ required: LABELS.required }}
            render={({ field: { onChange, value } }) => {
              return (
                <AsyncSelect
                  cacheOptions={true}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  defaultOptions
                  loadOptions={currencySearch}
                  value={BankDocDownloadConstants.getCurrencyByValue(value)}
                  onChange={(option) => onChange(option?.value)}
                />
              );
            }}
          />
          {errors?.currency?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.currency?.message}
            </span>
          )}
        </div>
      </div>
    </FormSection>
  );

  const customerInfoPanel = (
    <div
      className={
        "collapse-arrow collapse rounded-box w-full border border-base-300 bg-white"
      }>
      <input type={"checkbox"} defaultChecked={true} />
      <div className={"collapse-title ml-8 text-lg font-medium"}>
        Customer Details
      </div>
      <div className={"collapse-content"}>
        <InfoPanelsView
          customer={bankDocById.customer}
          contact={bankDocById.customer?.contact}>
          <BankDocInfoPanelView bankDoc={bankDocById} />
        </InfoPanelsView>
      </div>
    </div>
  );

  return (
    <div>
      {customerInfoPanel}

      <FormSection name={"Download Transactions"} extraCx={"overflow-visible"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isRestApiLoading}>
            <>{uploadSection}</>
          </fieldset>
          {errorMessage && (
            <Alert type={"error"} label={LABELS.errors.default} />
          )}

          <div className={"flex justify-start space-x-4 pb-4"}>
            <BrandButton
              buttonType={"submit"}
              colorType={"primary"}
              label={"Download .QBO file"}
              disabled={isRestApiLoading}
              SvgIconLeft={AddStackSvg}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Back to statement"}
              onClick={() => navigate("../")}
            />
          </div>
        </form>
      </FormSection>
    </div>
  );
};
