import React, { useEffect, useState } from "react";

import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  IService,
  useAddServiceMutation,
  useDeleteServiceMutation,
  useGetServicesQuery,
  useUpdateServiceMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import Menu from "./Menu";

const Services = () => {
  const { data, isLoading } = useGetServicesQuery(getLinkedFirm()?.orgId);

  const [addService, { isSuccess: isSuccessAdd }] = useAddServiceMutation();
  const [updateService, { isSuccess: isSuccessUpdate }] =
    useUpdateServiceMutation();
  const [deleteService, { isSuccess: isSuccessDelete }] =
    useDeleteServiceMutation();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [activeService, setActiveService] = useState<IService | null>(null);
  const [newServiceData, setNewServiceData] = useState({
    serviceName: "",
    description: "",
    rate: 0,
  });

  useEffect(() => {
    if (isSuccessAdd) {
      setNewServiceData({
        serviceName: "",
        description: "",
        rate: 0,
      });
      setIsOpenAdd(false);
    }
  }, [isSuccessAdd]);

  useEffect(() => {
    if (isSuccessUpdate || isSuccessDelete) {
      closeActiveService();
    }
  }, [isSuccessUpdate, isSuccessDelete]);

  const closeActiveService = () => {
    setActiveService(null);
    setNewServiceData({
      serviceName: "",
      description: "",
      rate: 0,
    });
  };
  const openActiveService = (service: IService) => {
    setActiveService(service);
    setNewServiceData({
      serviceName: service.name || "",
      description: service.description || "",
      rate: service.rate || 0,
    });
  };

  const renderActiveService = () => {
    return (
      <Menu close={closeActiveService}>
        <>
          <div className={"mb-8 text-center text-[18px] font-bold"}>
            Add payment method
          </div>
          <div>
            <div className={"mb-2"}>Name</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newServiceData.serviceName}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewServiceData({
                    ...newServiceData,
                    serviceName: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className={"mb-2"}>Description</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newServiceData.description}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewServiceData({
                    ...newServiceData,
                    description: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className={"mb-2"}>Rate</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={"$" + newServiceData.rate}
                onChange={(e) => {
                  const value = /^\$?[0-9]*$/.test(e.target.value)
                    ? Number(e.target.value.slice(1))
                    : newServiceData.rate;
                  setNewServiceData({ ...newServiceData, rate: value });
                }}
              />
            </div>
          </div>
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button
              buttonType={"submit"}
              label={"Delete"}
              colorType={"error"}
              extraClasses={"normal-case"}
              onClick={() => deleteService(activeService?.id || "")}
            />
            <Button
              buttonType={"submit"}
              label={"Edit"}
              extraClasses={"normal-case"}
              onClick={() => {
                if (newServiceData.serviceName) {
                  updateService({
                    id: activeService?.id || "",
                    name: newServiceData.serviceName,
                    description: newServiceData.description,
                    rate: newServiceData.rate,
                    orgId: getLinkedFirm()?.orgId,
                  });
                } else {
                  //setError("This field cannot be empty");
                }
              }}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeActiveService}
              extraClasses={"normal-case"}
            />
          </div>
        </>
      </Menu>
    );
  };

  const renderAdd = () => {
    return (
      <Menu close={() => setIsOpenAdd(false)}>
        <>
          <div className={"mb-8 text-center text-[18px] font-bold"}>
            Add payment method
          </div>
          <div>
            <div className={"mb-2"}>Name</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newServiceData.serviceName}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewServiceData({
                    ...newServiceData,
                    serviceName: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className={"mb-2"}>Description</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={newServiceData.description}
                onChange={(event) => {
                  if (!event.target.value) {
                    //setError("This field cannot be empty");
                  } else {
                    //setError("");
                  }
                  setNewServiceData({
                    ...newServiceData,
                    description: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <div className={"mb-2"}>Rate</div>
            <div>
              <input
                maxLength={25}
                type={"text"}
                className={"input input-bordered w-full"}
                value={"$" + newServiceData.rate}
                onChange={(e) => {
                  const value = /^\$?[0-9]*$/.test(e.target.value)
                    ? Number(e.target.value.slice(1))
                    : newServiceData.rate;
                  setNewServiceData({ ...newServiceData, rate: value });
                }}
              />
            </div>
          </div>
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button
              buttonType={"submit"}
              label={"Add"}
              extraClasses={"normal-case"}
              onClick={() => {
                if (newServiceData.serviceName) {
                  addService({
                    name: newServiceData.serviceName,
                    description: newServiceData.description,
                    rate: newServiceData.rate,
                    orgId: getLinkedFirm()?.orgId,
                  });
                } else {
                  //setError("This field cannot be empty");
                }
              }}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={() => setIsOpenAdd(false)}
              extraClasses={"normal-case"}
            />
          </div>
        </>
      </Menu>
    );
  };

  if (isLoading) {
    return <LottieLoading />;
  }

  const rows = data?.map((item) => {
    return (
      <tr
        key={item?.id}
        className={
          "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
        }>
        <td className={" bg-transparent px-4 py-2 "}>
          <div
            onClick={() => openActiveService(item)}
            className={
              "link-primary w-fit cursor-pointer text-sm font-semibold hover:underline"
            }>
            {item?.name}
          </div>
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {item?.description}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {"$" +
            (item.rate.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) ||
              Number(0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }))}
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className={"mb-4 flex items-start justify-between"}>
        <Button
          buttonType={"button"}
          onClick={() => setIsOpenAdd(true)}
          label={"Add service"}
          extraClasses={"normal-case"}
        />
      </div>
      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Service
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Description
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Rate
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={"text-[14px]"}>
          {isLoading ? <LottieLoading /> : rows}
        </tbody>
      </table>
      {isOpenAdd && renderAdd()}
      {activeService && renderActiveService()}
    </>
  );
};

export default Services;
