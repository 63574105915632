import { assign, createMachine } from "xstate";

import { getRounded, toPositive } from "../commonOps/CommonNumberOps";

export type ExtractedTotalsDataT = {
  credits: number;
  debits: number;
};

export type PdfTotalsDataT = {
  starting: number;
  ending: number;
};

type MachineContext = {
  extractedTotalsData: ExtractedTotalsDataT;
  pdfTotalsData: PdfTotalsDataT;
  difference: {
    calcEnding: number;
    endingDiff: number;
  };
  isCreditCard: boolean;
  matcher: {
    endingEqual: boolean;
  };
};

type Events =
  | { type: "updatePdfTotals"; pdfTotalsData: PdfTotalsDataT }
  | { type: "updateIsCreditCard"; isCreditCard: boolean }
  | {
      type: "updateExtractedTotals";
      extractedTotalsData: ExtractedTotalsDataT;
    };

const initialMachineContext: MachineContext = {
  extractedTotalsData: {
    credits: 0,
    debits: 0,
  },
  pdfTotalsData: {
    starting: 0,
    ending: 0,
  },
  isCreditCard: false,
  difference: { calcEnding: 0, endingDiff: 0 },
  matcher: { endingEqual: false },
};

export const bankDocDetailsMachine = createMachine<MachineContext, Events>(
  {
    id: "bankDocDetails",
    context: initialMachineContext,
    states: {},
    on: {
      updatePdfTotals: {
        actions: [
          assign((ctx, event) => {
            return {
              ...ctx,
              pdfTotalsData: event.pdfTotalsData,
            };
          }),
          "updateMatcher",
        ],
      },
      updateIsCreditCard: {
        actions: [
          assign((ctx, event) => {
            return {
              ...ctx,
              isCreditCard: event.isCreditCard,
            };
          }),
          "updateMatcher",
        ],
      },
      updateExtractedTotals: {
        actions: [
          assign((ctx, event) => {
            return {
              ...ctx,
              extractedTotalsData: event.extractedTotalsData,
            };
          }),
          "updateMatcher",
        ],
      },
    },
  },
  {
    actions: {
      updateMatcher: (ctx) => {
        // guard
        if (!ctx.extractedTotalsData || !ctx.pdfTotalsData) return;

        // set formulas
        const bankStatementFormula =
          ctx.pdfTotalsData.starting +
          ctx.extractedTotalsData.credits -
          ctx.extractedTotalsData.debits;
        const creditCardStatementFormula =
          ctx.pdfTotalsData.starting -
          ctx.extractedTotalsData.credits +
          ctx.extractedTotalsData.debits;

        const calcEnding = getRounded(
          ctx.isCreditCard ? creditCardStatementFormula : bankStatementFormula,
        );
        const endingDiff = getRounded(
          toPositive(ctx.pdfTotalsData.ending - calcEnding),
        );

        // when user didn't select any txn, then do not match as zero
        const isCreditsDebitsZero =
          ctx.extractedTotalsData.credits === 0 &&
          ctx.extractedTotalsData.debits === 0;
        const isEndingDiffZero = endingDiff === 0;
        const isEndingEqual = !isCreditsDebitsZero && isEndingDiffZero;
        ctx.difference = {
          calcEnding: calcEnding,
          endingDiff: endingDiff,
        };
        ctx.matcher = {
          endingEqual: isEndingEqual,
        };
      },
    },
  },
);
