import * as Xstate from "@xstate/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { INTERNAL, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import AddContact from "../../components/modal/addContact";
import AddBillingSvg from "../../components/svg/AddBillingSvg";
import AddPeopleSvg from "../../components/svg/AddPeopleSvg";
import AddTimeSvg from "../../components/svg/AddTimeSvg";
import AddWorkSvg from "../../components/svg/AddWorkSvg";
import PlusSvg from "../../components/svg/PlusSvg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { sideNavRouteMap } from "../../pages/main/SidenavRouteMaps";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import StartTimer from "../time/StartTimer";
import { AddWorkModal } from "../works/modals/AddWork.modal";
import { LocationSelector } from "./LocationSelector";
import { TbHelpHexagon } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";

export const MainSidebarNav: React.FC<unknown> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [isOpenAdd, setIsOpenAdd] = React.useState(false);
  const [isAddContact, setIsAddContact] = React.useState(false);
  const [isAddWork, setIsAddWork] = React.useState(false);
  const [isAddTime, setIsAddTime] = React.useState(false);
  const [user, setUser] = React.useState<{
    userRoleEnum: { userRole: string };
    userProfile: { firstName: string; lastName: string };
  }>();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const openButtonRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(openButtonRef, () => setIsOpenAdd(false));
  // hide sidebar on bank doc details view
  const docPathMatch = useMatch({
    path: `${ROUTE_NAMES.documents.bankDoc.index}/:docId/*`,
  });
  const isDocPath = !!docPathMatch;

  React.useEffect(() => {
    if (isDocPath) {
      setIsCollapsed(true);
    }
  }, [setIsCollapsed, isDocPath]);
  React.useEffect(() => {
    setIsCollapsed(true);
  }, [document.URL]);

  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/users/${
        userInfoByEmail?.id
      }?orgId=${getLinkedFirm()?.orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setUser(data);
    }
  };
  const getNavItemClasses = () => {
    return classNames(
      "w-full rounded-md block flex items-center bg-transparent text-[#9FA1AD] my-2 py-2 px-4 overflow-hidden text-base cursor-pointer",
      "focus:bg-gray-100 focus:font-semibold focus:text-primary focus:text-gray-900 focus:outline-none focus:shadow-outline",
      "hover:bg-gray-100 hover:text-[#7C66F0]",
    );
  };

  const userRoleNavIcons = (name: string) => {
    if (user?.userRoleEnum?.userRole?.includes("Billing")) {
      if (
        location.pathname === "/billing/invoices" ||
        location.pathname === "/billing/payments"
      ) {
        navigate("/");
      }
      if (location.pathname.includes("settings")) {
        navigate("/");
      }
      return name !== "settings" && name !== "billing";
    }
    return true;
  };
  const getLocation = () => {
    if (location) {
      return location.pathname ? location.pathname.split("/")[1] : "";
    } else return "";
  };
  const sideNavRouteLinks = sideNavRouteMap.map((routeElement, index) => {
    const { name, icon: Icon, path } = routeElement;
    return (
      <React.Fragment key={index}>
        {userRoleNavIcons(name) && (
          <Link to={`/${path}`} className={"w-full"}>
            <div
              className={`${getNavItemClasses()} ${
                getLocation().includes(name) && "bg-gray-200 !text-[#7C66F0]"
              }`}
              data-tooltip-id={path}
              key={name}>
              <div className={"flex items-center gap-4"}>
                <Icon className={"h-6 w-6"} />
                <div className={"capitalize"}>{name}</div>
              </div>
            </div>
          </Link>
        )}
        {!isOpenMenu && (
          <ReactTooltip
            id={path}
            place="right"
            content={name}
            className={"z-10 !rounded-[8px] !p-2 !text-sm capitalize"}
          />
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <div
        className={classNames(
          isOpenMenu ? "w-[280px]" : "w-20",
          "flex max-h-full flex-col justify-between bg-white transition-all duration-300",
        )}
        // style={{ transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s" }}
        /*onMouseEnter={onMouseOver}
                    onMouseLeave={onMouseOver}*/
      >
        <div>
          <div className={"absolute flex items-center justify-between"}>
            <div className={"flex items-center pl-1 opacity-0"}>
              <span>
                <LocationSelector isHidden={isCollapsed} />
              </span>
            </div>
          </div>
          <div className={"flex flex-col justify-between"}>
            <div className={"flex flex-col items-center"}>
              <div
                className={
                  "relative mt-2 flex w-full border-b border-gray-200 px-2  "
                }
                ref={openButtonRef}>
                <div
                  className={
                    "mb-2 flex w-full cursor-pointer overflow-hidden py-2 hover:rounded-md hover:bg-gray-100"
                  }
                  onClick={() => setIsOpenAdd(!isOpenAdd)}>
                  <div className={"px-4"}>
                    <button
                      className={`${
                        isOpenAdd ? "bg-[#1F2937]" : "bg-purple"
                      } border-purple flex h-[30px] !w-[30px] items-center justify-center rounded-[50%] border-solid text-[20px] text-white duration-300`}>
                      <div
                        className={`${
                          isOpenAdd && "rotate-[-135deg]"
                        } fill-white duration-300`}>
                        <PlusSvg />
                      </div>
                    </button>
                  </div>
                  <div
                    className={
                      "flex items-center pl-1 text-base text-[#7C66F0]"
                    }>
                    Create
                  </div>
                </div>

                {isOpenAdd && (
                  <div
                    className={
                      "absolute left-[calc(100%+17px)] top-[-8px] z-10 flex gap-[16px]  bg-white p-4 shadow-dropdown"
                    }>
                    <div
                      onClick={() => {
                        setIsOpenAdd(false);
                        setIsAddContact(true);
                      }}
                      className={
                        "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                      }>
                      <div
                        className={
                          "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                        }>
                        <AddPeopleSvg />
                      </div>
                      <div
                        className={
                          "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                        }>
                        New Contact
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setIsOpenAdd(false);
                        setIsAddWork(true);
                      }}
                      className={
                        "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                      }>
                      <div
                        className={
                          "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                        }>
                        <AddWorkSvg />
                      </div>
                      <div
                        className={
                          "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                        }>
                        New Work
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setIsOpenAdd(false);
                        setIsAddTime(true);
                      }}
                      className={
                        "flex cursor-pointer flex-col items-center gap-2 overflow-y-auto rounded-[12px] p-2 hover:bg-gray-200"
                      }>
                      <div
                        className={
                          "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                        }>
                        <div className={"relative right-[-2px]"}>
                          <AddTimeSvg />
                        </div>
                      </div>
                      <div
                        className={
                          "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                        }>
                        New Time
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setIsOpenAdd(false);
                        navigate("/billing/invoices/add");
                      }}
                      className={
                        "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                      }>
                      <div
                        className={
                          "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                        }>
                        <div className={"relative stroke-white"}>
                          <AddBillingSvg />
                        </div>
                      </div>
                      <div
                        className={
                          "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                        }>
                        New Invoice
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setIsOpenAdd(false);
                        navigate("/billing/payments/add");
                      }}
                      className={
                        "flex cursor-pointer flex-col items-center gap-2 rounded-[12px] p-2 hover:bg-gray-200"
                      }>
                      <div
                        className={
                          "flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#7C66F0]"
                        }>
                        <div className={"relative stroke-white"}>
                          <AddBillingSvg />
                        </div>
                      </div>
                      <div
                        className={
                          "whitespace-nowrap text-[14px] font-[550] text-[#1F2937]"
                        }>
                        New Payment
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={"w-full px-3"}>
                {sideNavRouteLinks}
                <div className={"mt-6"} data-tooltip-id={"support"}>
                  <div className={`${getNavItemClasses()}`}>
                    <a
                      className={"h-[30px] w-full"}
                      href={`mailto:${INTERNAL.emails.support}?subject=Support%20request&body=ProCharted%20Support%2C%0A%0A`}>
                      <div className={"flex-start flex"}>
                        <div
                          className={
                            "flex h-[30px] items-center justify-center"
                          }>
                          <div className={"flex items-center gap-4"}>
                            <TbHelpHexagon
                              strokeWidth={1.5}
                              className={"h-6 w-6"}
                            />
                            Support
                          </div>
                        </div>
                      </div>
                      {!isOpenMenu && (
                        <ReactTooltip
                          id={"support"}
                          place="right"
                          content={"Support"}
                          className={
                            "z-10 !rounded-[8px] !p-2 !text-sm capitalize"
                          }
                        />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"flex flex-col gap-4 border-t border-gray-200 px-3 py-4"}>
          <div
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            className={
              "flex h-[40px] cursor-pointer items-center overflow-hidden px-4 hover:rounded-md hover:bg-gray-200"
            }
            data-tooltip-id={"menu"}>
            <div className={"flex items-center gap-4"}>
              {isOpenMenu ? (
                <LuArrowLeftToLine
                  className={"h-[24px] w-[24px] text-[#9FA1AD]"}
                />
              ) : (
                <>
                  <LuArrowRightToLine
                    className={"h-[24px] w-[24px] text-[#9FA1AD]"}
                  />
                  <ReactTooltip
                    id={"menu"}
                    place="right"
                    content={"Maximize menu"}
                    className={"z-10 !rounded-[8px] !p-2 !text-sm capitalize"}
                  />
                </>
              )}
              <div className={"w-[110px] text-base text-[#9FA1AD]"}>
                Minimize menu
              </div>
            </div>
          </div>
        </div>
      </div>

      {isAddWork && (
        <AddWorkModal onClose={() => setIsAddWork(false)} isOpen={isAddWork} />
      )}
      <AddContact
        isOpen={isAddContact}
        closeModal={() => setIsAddContact(false)}
        isSearch={false}
      />
      {isAddTime && (
        <StartTimer isOpen={isAddTime} closeModal={() => setIsAddTime(false)} />
      )}
    </>
  );
};
