import { FC } from "react";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useGetWorkTypeByIdQuery } from "../../../slices/WorkTypeSlice";
import { desiredOrder } from "../../dashboard/Dashboard";

type Props = {
  workTypeId: string;
  setIsOpen: (value: boolean) => void;
  updateStatusWork: (value: {
    statusId: string | null;
    subStatusId: string | null;
  }) => void;
};

export const StatusesList: FC<Props> = ({
  workTypeId,
  updateStatusWork,
  setIsOpen,
}) => {
  const { data, isLoading } = useGetWorkTypeByIdQuery({ id: workTypeId });
  const statuses =
    data?.serviceStatuses?.filter((status) => status?.name !== "trial") || [];

  return (
    <div
      className={
        "max-h-[300px] w-fit overflow-y-auto rounded-[8px] border border-black border-opacity-[0.2] bg-white"
      }>
      {isLoading ? (
        <div className={"w-[250px]"}>
          <LottieLoading />
        </div>
      ) : !statuses?.length ? (
        <div className={"px-4 py-2"}>No items</div>
      ) : (
        statuses
          ?.sort((a, b) => {
            return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
          })
          ?.map((status) => (
            <div key={status.id} className={"max-w-[250px] overflow-hidden"}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setIsOpen(false);
                  updateStatusWork({
                    statusId: status?.id || null,
                    subStatusId: null,
                  });
                }}
                className={
                  "mb-2 grid cursor-pointer grid-cols-[10px_1fr] items-center gap-2 px-4 py-2 font-[600] last:mb-0 last:mr-0 hover:bg-gray-200"
                }>
                <div
                  className={"h-[10px] w-[10px] rounded-full"}
                  style={{
                    background: `${status?.color}`,
                  }}
                />
                <div className={"truncate"}>{status?.name}</div>
              </div>
              {status?.serviceSubStatuses?.map((sub) => (
                <div
                  key={sub?.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(false);
                    updateStatusWork({
                      statusId: status?.id || "",
                      subStatusId: sub?.id || "",
                    });
                  }}
                  className={
                    "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 hover:bg-gray-200"
                  }>
                  <div>
                    <div
                      className={" mr-2 h-[10px] w-[10px] rounded-full"}
                      style={{
                        background: `${status?.color}`,
                      }}
                    />
                  </div>
                  <div className={"truncate"}>{sub?.subStatus}</div>
                </div>
              ))}
            </div>
          ))
      )}
    </div>
  );
};
