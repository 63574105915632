import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";

import { getServerBaseUrlForEnv } from "../app/configs/appConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";

export const getApiWithToken = async (
  apiEndpoint: string,
): Promise<AxiosResponse> => {
  const restApiUploadPath = getServerBaseUrlForEnv();
  const trimmedEndpoint = _.trim(apiEndpoint, "/");
  const fullUrl = `${restApiUploadPath}/${trimmedEndpoint}`;
  return axios.get(fullUrl, {
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const postApiWithToken = async (
  apiEndpoint: string,
  data: unknown,
  config: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  const restApiUploadPath = getServerBaseUrlForEnv();
  const trimmedEndpoint = _.trim(apiEndpoint, "/");
  const fullUrl = `${restApiUploadPath}/${trimmedEndpoint}`;
  return axios.post(fullUrl, data, {
    ...config,
    headers: {
      ...config.headers,
      ...getAuthHeader(),
    },
  });
};
