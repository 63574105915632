import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Search } from "baseui/icon";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { getInitialsFromFullName } from "../../constants/constants";
import { AddIndividualView } from "../../features/contacts/refactor/modals/AddIndividualView";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AddContact from "../modal/addContact";
import { AvatarPlaceholder } from "../avatar/AvatarPlaceholder";
import { LottieLoading } from "../graphics/LottieLoading";
import { navigateToContact } from "../../features/contacts/utils/navigateToContacts";

interface IContact {
  id: string;
  name: string;
  isBusinessContact: boolean;
  customerId?: string;
  contactId?: string;
}

interface ISearchContactProps {
  saveContact: (contact: IContact) => void;
  contacts: IContact[];
  defaultContact?: IContact;
  inputVariant?: "header";
  isIdLikeContactId?: boolean;
  isLinkedProfile?: boolean;
  isClearValue?: boolean;
  isLoading?: boolean;
}

const SearchContact: React.FC<ISearchContactProps> = ({
  isLinkedProfile,
  contacts,
  saveContact,
  defaultContact,
  inputVariant,
  isIdLikeContactId,
  isClearValue,
  isLoading,
}) => {
  const navigate = useNavigate();
  const contactRef = useRef<HTMLDivElement>(null);
  const [letter, setLetter] = useState(
    defaultContact ? defaultContact.name : "",
  );
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const [contactsSorted, setContactsSorted] = useState<IContact[]>([]);
  const closeStatusMenu = () => {
    setIsOpenContact(false);
    if (inputVariant === "header") {
      setLetter("");
    }
  };
  useOnClickOutside(contactRef, closeStatusMenu);

  const sortContacts = (e: ChangeEvent<HTMLInputElement>) => {
    const sortLetter = e.target.value.toLowerCase();
    setLetter(e.target.value);
    const filtered = contacts.filter((item) =>
      item.name?.toLowerCase().includes(sortLetter),
    );
    setContactsSorted(filtered);
  };

  const saveContactFunc = (contact: IContact) => {
    if (inputVariant === "header") {
      contact.isBusinessContact
        ? navigate(`/organization/${contact.id}`)
        : navigate(
            navigateToContact({
              type: "contact",
              customerId: contact.id || "",
            }),
          );
      setIsOpenContact(false);
    } else {
      saveContact(contact);
      setLetter(contact.name);
      setIsOpenContact(false);
    }
  };

  const currentContacts = () => {
    return letter ? contactsSorted : contacts;
  };

  useEffect(() => {
    if (isClearValue) {
      setLetter("");
    }
  }, [isClearValue]);

  const inputVariants = () => {
    switch (inputVariant) {
      case "header":
        return (
          <div
            className={
              "flex h-10 w-[300px] items-center rounded-[8px] bg-white px-[10px]"
            }>
            <div className={"w-full"}>
              <input
                onFocus={() => setIsOpenContact(true)}
                onChange={sortContacts}
                value={letter}
                type={"text"}
                placeholder={"Search contacts"}
                className={"w-full rounded-l-[8] text-base outline-none"}
              />
            </div>
            <div className={""}>
              <Search size={25} />
            </div>
          </div>
        );
      default:
        return (
          <input
            value={letter}
            type={"text"}
            onChange={sortContacts}
            onFocus={() => setIsOpenContact(true)}
            placeholder={"Search contacts"}
            className={
              "select select-bordered flex w-full items-center placeholder:font-normal"
            }
          />
        );
    }
  };

  return (
    <>
      <div className={"relative w-full"} ref={contactRef}>
        <div>{inputVariants()}</div>
        {isOpenContact && (
          <div
            className={
              "absolute left-0 top-[47px] z-[1] flex max-h-[200px] w-full flex-col space-y-[2px] overflow-hidden rounded-[10px] border-[1px] border-gray-300 bg-white text-[13px]"
            }>
            {isLoading ? (
              <LottieLoading />
            ) : (
              <div className={"overflow-auto"}>
                <div
                  className={
                    "mt-2 flex cursor-pointer items-center gap-2 px-[24px] py-[6px] text-[14px] text-blue-600 hover:bg-blue-400 hover:text-white"
                  }
                  onClick={() =>
                    setIsOpenAddContact && setIsOpenAddContact(true)
                  }>
                  <div
                    className={
                      "flex h-[16px] w-[16px] flex-nowrap items-center justify-center rounded-full border-[1px] border-blue-600"
                    }>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className={"text-[10px] text-blue-600"}
                    />
                  </div>
                  <div className={"whitespace-nowrap font-bold"}>
                    Create new contact
                  </div>
                </div>
                {currentContacts().map((contact, index) => (
                  <div
                    onClick={() => saveContactFunc(contact)}
                    className={
                      "mt-2 flex cursor-pointer items-center space-x-[4px] px-[16px] last-of-type:mb-2 hover:bg-blue-400 hover:text-white"
                    }
                    key={`${contact?.id} ${index}`}>
                    <AvatarPlaceholder
                      type={contact.isBusinessContact ? "purple" : "blue"}
                      size={"super-small"}
                      label={getInitialsFromFullName(contact?.name || "")}
                    />
                    <div
                      className={
                        "text-sm font-semibold"
                      }>{`${contact?.name}`}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {isLinkedProfile ? (
        <>
          <Transition appear show={isOpenAddContact} as={Fragment}>
            <Dialog
              as={"div"}
              className={"relative z-10"}
              onClose={() => setIsOpenContact(false)}>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>
                <div className={"fixed inset-0 bg-black bg-opacity-25"} />
              </Transition.Child>

              <div className={"fixed inset-0 overflow-y-auto"}>
                <div
                  className={
                    "flex min-h-full items-center justify-center p-4 text-center"
                  }>
                  <Transition.Child
                    as={Fragment}
                    enter={"ease-out duration-300"}
                    enterFrom={"opacity-0 scale-95"}
                    enterTo={"opacity-100 scale-100"}
                    leave={"ease-in duration-200"}
                    leaveFrom={"opacity-100 scale-100"}
                    leaveTo={"opacity-0 scale-95"}>
                    <Dialog.Panel
                      className={
                        "z-index-100 w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                      }>
                      <div>
                        <AddIndividualView
                          closeModal={() => setIsOpenAddContact(false)}
                          closeAddCustomerPopupModal={() =>
                            setIsOpenAddContact(false)
                          }
                          setContactsForSort={(newContact) => {
                            setIsOpenAddContact(false);
                            saveContactFunc(newContact);
                          }}
                          isSearch={true}
                          isIdLikeContactId={isIdLikeContactId}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      ) : (
        isOpenAddContact && (
          <AddContact
            isIdLikeContactId={isIdLikeContactId}
            isOpen={isOpenAddContact}
            closeModal={() => setIsOpenAddContact(false)}
            setContactsForSort={(newContact) => {
              setIsOpenAddContact(false);
              saveContactFunc(newContact);
            }}
            isSearch={Boolean(inputVariant !== "header")}
          />
        )
      )}
    </>
  );
};

export default SearchContact;
