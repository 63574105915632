import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { StatusModel } from "../factories/statuses/models/status.model";
import { StatusName } from "../factories/statuses/enums/status.enum";

export const statusesSlice = createApi({
  reducerPath: "statusesSlice",
  tagTypes: ["Status"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getStatuses: build.query<
      StatusModel[],
      {
        orgId: string;
      }
    >({
      query: (params) => ({
        url: "api/work-type-status/get-all",
        params,
        method: "GET",
      }),
      transformResponse: (res: StatusModel[]) => {
        return res?.filter((status) => status?.name !== StatusName.trial) || [];
      },
      providesTags: ["Status"],
    }),
    updateStatusWork: build.mutation<
      unknown,
      { workId: string; statusId: string; subStatusId: string }
    >({
      query: (params) => ({
        url: "api/work/update-status",
        params,
        method: "PUT",
      }),
      invalidatesTags: ["Status"],
    }),
  }),
});

export const { useGetStatusesQuery } = statusesSlice;
