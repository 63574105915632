import {
  NotificationFilterCount,
  NotificationTitle,
  NotificationTitleValue,
} from "../../../factories/notifications/notification-title.enum";

export const notificationTitles = [
  {
    title: NotificationTitle.All,
    count: NotificationFilterCount.allCounts,
    value: NotificationTitleValue.All,
  },
  {
    title: NotificationTitle.Comments,
    count: NotificationFilterCount.commentCounts,
    value: NotificationTitleValue.Comments,
  },
  {
    title: NotificationTitle.AssignedToMe,
    count: NotificationFilterCount.assignedToMeCounts,
    value: NotificationTitleValue.AssignedToMe,
  },
  {
    title: NotificationTitle.CompletedSubtasks,
    count: NotificationFilterCount.completedTasksCounts,
    value: NotificationTitleValue.CompletedSubtasks,
  },
];
