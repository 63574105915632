import { Datepicker } from "baseui/datepicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/button/Button";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useAppSelector } from "../../../redux/redux";
import {
  IAddPayment,
  Invoice,
  useAddPaymentMutation,
  useGetPaymentMethodsQuery,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { transformDateToUTC } from "../../../utils/transformDate";

interface Props {
  close: () => void;
  invoice: Invoice | undefined;
  refetch?: () => void;
}

const PayInvoiceModal: React.FC<Props> = ({ close, invoice, refetch }) => {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.appReducer);

  const [addPayment, { isSuccess: isSuccessAdd, error }] =
    useAddPaymentMutation();

  const { data: paymentMethods, isLoading: isLoadingMethods } =
    useGetPaymentMethodsQuery(getLinkedFirm()?.orgId);

  const [amountPaid, setAmountPaid] = useState("");

  const [paymentDate, setPaymentDate] = useState(new Date());
  const [method, setMethod] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      setMethod(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (isSuccessAdd) {
      close();
      navigate(`/billing/invoices`);
    }
  }, [isSuccessAdd]);

  const createPayment = () => {
    if (invoice?.customerId && paymentDate) {
      const body: IAddPayment = {
        paymentDate: paymentDate.toISOString(),
        customerId: invoice?.customerId,
        amount: parseFloat(amountPaid),
        balance: invoice?.balance,
        notes: notes,
        orgId: getLinkedFirm()?.orgId,
        paymentMethodId: method,
        userId: user?.id,
        paymentItem: [
          {
            invoiceId: invoice?.id,
            amountPaid: parseFloat(amountPaid),
          },
        ],
      };
      addPayment(body);
    }
  };

  const renderContent = () => {
    if (isLoadingMethods) {
      return <LottieLoading />;
    } else {
      return (
        <div>
          <div className={"flex justify-between px-8 pb-4 pt-2 text-sm"}>
            <div>
              <span className={"text-gray-500"}>Invoice</span>{" "}
              <span className={"font-semibold"}># {invoice?.invoiceNo}</span>
            </div>
            <div>
              <span className={"text-gray-500"}>Due date</span>{" "}
              <span className={"font-semibold"}>
                {moment(invoice?.dueDate).format("MM/DD/YYYY")}
              </span>
            </div>
          </div>
          <div
            className={"flex w-full flex-col gap-6 px-8 pb-4 pt-2 text-base"}>
            <div className={"flex items-center gap-4"}>
              <div className={"w-[100px] font-semibold text-gray-500"}>
                Date
              </div>
              <div>
                <Datepicker
                  value={paymentDate}
                  overrides={{
                    MonthYearSelectPopover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 10000 },
                          },
                        },
                      },
                    },
                    Input: {
                      props: {
                        mountNode: document.getElementById("pay-invoice-modal"),
                        overrides: {
                          Input: {
                            style: () => ({
                              backgroundColor: "#FFFFFF",
                            }),
                          },
                          Root: {
                            style: () => ({
                              borderTopWidth: "1px",
                              borderRightWidth: "1px",
                              borderBottomWidth: "1px",
                              borderLeftWidth: "1px",
                              borderTopColor: "#D4D6D9",
                              borderRightColor: "#D4D6D9",
                              borderBottomColor: "#D4D6D9",
                              borderLeftColor: "#D4D6D9",
                            }),
                          },
                        },
                      },
                    },
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: {
                              zIndex: 9999,
                            },
                          },
                        },
                      },
                    },
                  }}
                  onChange={({ date }) => {
                    if (date instanceof Date) {
                      setPaymentDate(transformDateToUTC(date));
                    }
                  }}
                  minDate={paymentDate}
                />
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              <div className={"w-[100px] font-semibold text-gray-500"}>
                Balance
              </div>
              <div>
                $
                {(invoice?.balance || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              <div className={"w-[100px] font-semibold text-gray-500"}>
                Amount paid
              </div>
              <div>
                <CustomCurrencyInput
                  value={amountPaid}
                  setValue={setAmountPaid}
                />
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              <div className={"w-[100px] font-semibold text-gray-500"}>
                Method
              </div>
              <div>
                <select
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                  className={"select select-bordered"}>
                  {paymentMethods?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item?.paymentMethod}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={"flex items-center"}>
              <div
                className={"min-w-[100px] gap-4 font-semibold text-gray-500"}>
                Notes
              </div>
              <div className={"w-full"}>
                <textarea
                  className={"textarea textarea-bordered w-full"}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
          {error && (
            <div className={"px-8 py-4 text-sm font-bold text-error"}>
              {parseFloat(amountPaid) > (invoice?.balance || 0)
                ? "Amount paid cannot be greater than balance"
                : "Something went wrong"}
            </div>
          )}
          <div className={"flex justify-end gap-4 px-8 pb-4"}>
            <Button
              label={"Cancel"}
              colorType={"ghost"}
              onClick={close}
              extraClasses={"normal-case"}
            />
            <Button
              label={"Confirm"}
              extraClasses={"normal-case"}
              onClick={createPayment}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div
      id={"pay-invoice-modal"}
      style={{ marginTop: 0, marginBottom: 0 }}
      className={
        "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-black/20"
      }
      onClick={close}>
      <div
        className={"w-[400px] rounded-[16px] bg-white"}
        onClick={(event) => event.stopPropagation()}>
        <div
          className={
            "border-b-[1px] border-base-300 px-8 py-4 text-center text-lg font-semibold"
          }>
          Record a payment for invoice
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default PayInvoiceModal;
