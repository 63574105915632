import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmailVariables,
} from "../../generated/operation-result-types";
import { GET_USER_INFO_BY_EMAIL_GQL } from "../../queries/UserQueries.gql";

export const UserInfoLoadingMainView: React.FC<unknown> = () => {
  const { userAuthService, userInfoService } = React.useContext(GlobalContext);
  const [userAuthInfoState] = Xstate.useActor(userAuthService);
  const { userAuthInfo } = userAuthInfoState.context;
  const { send: sendUserInfo } = userInfoService;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetUserInfoByEmail, GetUserInfoByEmailVariables>(
    GET_USER_INFO_BY_EMAIL_GQL,
    {
      fetchPolicy: "network-only",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { email: userAuthInfo!.email },
    },
  );

  React.useEffect(() => {
    const userNode = _.first(dataQ?.users?.edges)?.node;
    if (userNode) {
      sendUserInfo("SUCCESS", { userInfoByEmail: userNode });
    }
  }, [dataQ, sendUserInfo]);

  if (loadingQ) {
    sendUserInfo("FETCH");
    return null;
  }

  if (errorQ) {
    console.error("UserInfoLoadingMainView | UserInfoLoadingMainView", {
      errorQ,
    });
    sendUserInfo("FAILURE");
    throw new Error("Error getting UserInfoLoadingMainView");
  }

  return null;
};
