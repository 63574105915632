export interface ReactSelectOptionT {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
}

export class BankDocDownloadConstants {
  static currenciesList: ReactSelectOptionT[] = [
    { label: "CAD", value: "cad" },
    { label: "USD", value: "usd" },
  ];

  static firstCurrency = BankDocDownloadConstants.currenciesList[0];

  static getCurrencyByValue = (value: string): ReactSelectOptionT => {
    return (
      this.currenciesList.find((x) => x.value === value) ?? this.firstCurrency
    );
  };

  static accEnumMap: { [k: string]: { displayName: string } } = {
    QuickbooksDesktopWcf: { displayName: "QuickBooks Desktop (.QBO)" },
    QuickbooksOnlineWcf: { displayName: "QuickBooks Online (.QBO)" },
    SageCanadaOfx: { displayName: "Sage 50 Canada (.OFX)" },
    SageOfx: { displayName: "Sage 50 Cloud (.OFX)" },
    SageSimplyAso: { displayName: "Sage Simply Accounting (.ASO)" },
    XeroOfx: { displayName: "Xero Cloud (.OFX)" },
  };

  static accEnumList = Object.keys(this.accEnumMap);

  static getAccSw = (accSw: string) =>
    BankDocDownloadConstants.accEnumMap[accSw];
}
