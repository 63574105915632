import React from "react";
import { match } from "ts-pattern";

import { SuccessCheckSvg } from "../svg/SuccessCheckSvg";
import { WarningExclaimSvg } from "../svg/WarningExclaimSvg";

type AlertProps = {
  type: "warning" | "info" | "success" | "error";
  label: string;
};

export const Alert: React.FC<AlertProps> = (props) => {
  const { type, label, children } = props;

  const alertClassName = match(type)
    .with("error", () => "alert-error")
    .with("warning", () => "alert-warning")
    .with("info", () => "alert-info")
    .with("success", () => "alert-success")
    .exhaustive();

  const iconElement = match(type)
    .with("error", () => <WarningExclaimSvg />)
    .with("warning", () => <WarningExclaimSvg />)
    .with("info", () => <SuccessCheckSvg />)
    .with("success", () => <SuccessCheckSvg />)
    .exhaustive();

  return (
    <>
      <div className={`alert ${alertClassName} my-4 shadow-lg`}>
        <div>
          {iconElement}

          <span>{label}</span>
        </div>
        {children}
      </div>
    </>
  );
};
