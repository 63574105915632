import { assign, createMachine } from "xstate";

import {
  GetUserInfoByEmail_users_edges_node,
  GetUserInfoByEmail_users_edges_node_org_locations_edges_node,
} from "../../generated/operation-result-types";
import { setAppInsightsUserInfo } from "../insights/AppInsightsPlugin";

type UserInfoContext = {
  userInfoByEmail?: GetUserInfoByEmail_users_edges_node;
  currentLocation?: GetUserInfoByEmail_users_edges_node_org_locations_edges_node;
};

export const UserInfoMachine = createMachine<UserInfoContext>(
  {
    id: "userInfo",
    initial: "empty",
    context: {
      userInfoByEmail: undefined,
      currentLocation: undefined,
    },
    states: {
      empty: {
        on: { FETCH: "loading" },
      },
      loading: {
        on: {
          SUCCESS: {
            target: "valid",
            actions: [
              assign((ctx, event) => {
                return {
                  userInfoByEmail: event.userInfoByEmail,
                };
              }),
              "updateAppInsightsUserInfo",
            ],
          },
          FAILURE: "invalid",
        },
      },
      valid: {
        on: { RELOAD: "loading" },
      },
      invalid: {
        type: "final",
      },
    },
    on: {
      updateLocation: {
        actions: [
          assign((ctx, event) => {
            return {
              ...ctx,
              currentLocation: event.currentLocation,
            };
          }),
        ],
      },
      updateUserInfoByEmail: {
        actions: [
          assign((ctx, event) => {
            return {
              ...ctx,
              userInfoByEmail: event.userInfoByEmail,
            };
          }),
        ],
      },
    },
  },
  {
    actions: {
      updateAppInsightsUserInfo: (ctx): void => {
        if (!ctx.userInfoByEmail) return;
        const { id, userProfile, org } = ctx.userInfoByEmail;
        setAppInsightsUserInfo({
          userId: id,
          userEmail: userProfile?.email,
          orgId: org?.id,
        });
      },
    },
  },
);
