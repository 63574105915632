import React, { FC, useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";

type Props = {
  value: string;
  setValue: (value: string) => void;
  prefix?: string;
  className?: string;
};

const CustomCurrencyInput: FC<Props> = ({
  setValue,
  value,
  prefix = "$",
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocusPaid, setIsFocusPaid] = useState(false);

  useEffect(() => {
    if (isFocusPaid && inputRef?.current) {
      inputRef.current.select();
    }
  }, [isFocusPaid, inputRef]);

  return (
    <CurrencyInput
      decimalsLimit={2}
      value={value}
      ref={inputRef}
      prefix={isFocusPaid && value ? "" : prefix}
      decimalSeparator={"."}
      onFocus={() => {
        setIsFocusPaid(true);
      }}
      onBlur={() => {
        const data = value?.split(".")[0] || "";
        let decimalValue = value?.split(".")[1] || "";
        if (decimalValue?.length > 2) {
          decimalValue = decimalValue?.slice(0, 2);
        }
        if (decimalValue?.length > 0 && decimalValue?.length < 2) {
          decimalValue = decimalValue + "0";
        }
        if (decimalValue?.length === 0) {
          decimalValue = "00";
        }
        setValue(data + "." + decimalValue);
        setIsFocusPaid(false);
      }}
      allowDecimals={true}
      groupSeparator={","}
      fixedDecimalLength={2}
      className={"input input-bordered w-full " + className}
      onValueChange={(data) => {
        setValue(data || "0");
      }}
    />
  );
};

export default CustomCurrencyInput;
