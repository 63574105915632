import React from "react";

export type Props = {
  blobUri: string;
};
export const PdfViewerIframe: React.FC<Props> = (props) => {
  const { blobUri } = props;
  return (
    <>
      {/* 7.0 params https://www.mobilefish.com/download/pdf/PDFOpenParameters.pdf#zoom=100 */}
      {/* 8.0 params https://pdfobject.com/pdf/pdf_open_parameters_acro8.pdf */}
      <iframe
        src={`${blobUri}#page=1&view=fitH&pagemode=none`}
        title={"testPdf"}
        height={"100%"}
        width={"100%"}
      />
    </>
  );
};
