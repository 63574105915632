import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { GetPageTitle } from "../../support/ScrollToTop";
import { Dashboard } from "./Dashboard";
import { WorksByStatus } from "./WorksByStatus";
import { WorksByWorkType } from "./WorksByWorkType";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { getUser } from "../../services/AppService";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { LottieLoading } from "../../components/graphics/LottieLoading";

export const DashboardView = () => {
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { user, isLoadingUser } = useAppSelector((state) => state.appReducer);

  useEffect(() => {
    dispatch(
      getUser({
        userId: `${userInfoByEmail?.id}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
  }, []);

  useEffectOnce(() => {
    document.title = GetPageTitle("Dashboard");
  });

  const viewDashboardPermission = "b051ebbc-b5a8-46d3-ad6d-bafb82383682";

  if (isLoadingUser) {
    return <LottieLoading />;
  }

  return (
    <>
      {(user.permissionIds &&
        user.permissionIds.includes(viewDashboardPermission)) ||
      user?.userRoleEnum?.userRole === "Admin" ? (
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path={"/works"} element={<WorksByStatus />} />
          <Route path={"/works-by-work-type"} element={<WorksByWorkType />} />
        </Routes>
      ) : (
        <div
          className={
            "flex h-[75vh] items-center justify-center text-[42px] font-bold text-gray-400"
          }>
          You don`t have access to this page
        </div>
      )}
    </>
  );
};
