import { useMsal } from "@azure/msal-react";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { GetPageTitle } from "../../support/ScrollToTop";

export const SignOutView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Sign out");
  });

  const { instance: msalInstance } = useMsal();

  msalInstance.logoutRedirect({
    postLogoutRedirectUri: "/",
  });

  return null;
};
