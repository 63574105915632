import * as Xstate from "@xstate/react";
import React, { useState } from "react";

import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import * as devConfig from "../../../app/configs/devConfig";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { IWorkType } from "../../../types/types";

interface IAddWorkTypeProps {
  setActive: (active: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  getWorkTypesFetch: () => void;
  workTypes?: IWorkType[];
}

const AddWorkType: React.FC<IAddWorkTypeProps> = ({
  setActive,
  setIsLoading,
  getWorkTypesFetch,
  workTypes,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail, currentLocation } = userInfoState.context;
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isOpenError, setIsOpenError] = useState(false);
  const addWorkType = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/services/add-service`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          name: name,
          orgId: getLinkedFirm()?.orgId,
          locationRelayId: getLinkedFirm()?.locationId || null,
        }),
      },
    );
    if (response.ok) {
      setActive(false);
      getWorkTypesFetch();
    } else {
      setIsLoading(false);
    }
  };
  const nameError = workTypes?.filter((item) => item.name === name);
  return (
    <div
      className={
        "fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/20"
      }>
      <div
        className={"w-[400px] rounded-[16px] bg-white p-8"}
        onClick={(event) => event.stopPropagation()}>
        <div className={"mb-8 text-center text-[18px] font-bold"}>
          Add Work Type
        </div>
        <div>
          <div className={"mb-2"}>Name</div>
          <div>
            <input
              type={"text"}
              className={"input input-bordered w-full"}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            {error && (
              <div className={"pt-2 text-sm font-bold text-error"}>{error}</div>
            )}
          </div>
        </div>
        <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
          <Button
            buttonType={"submit"}
            label={"Add"}
            extraClasses={"normal-case"}
            disabled={isOpenError}
            onClick={() => {
              if (nameError?.length) {
                setIsOpenError(true);
              } else if (Boolean(name)) {
                setIsLoading(true);
                addWorkType();
              } else {
                setError("This field cannot be empty");
              }
            }}
          />
          <OutlineButton
            colorType={"neutral"}
            disabled={isOpenError}
            label={"Cancel"}
            onClick={() => setActive(false)}
            extraClasses={"normal-case"}
          />
        </div>
      </div>
      {isOpenError && (
        <div
          className={
            "fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/20"
          }>
          <div className={"modal-box absolute bg-white shadow-box "}>
            <div className={"pb-2 font-semibold"}>Duplicate item</div>
            <div>
              There is already a work type with this name.
              <br />
              Please choose a different name.
            </div>
            <div className={"flex w-full justify-end"}>
              <Button
                label={"Ok"}
                onClick={() => {
                  setIsOpenError(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddWorkType;
