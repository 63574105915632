import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment/moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IPayment,
  SortEnum,
  useGetPaymentsQuery,
  useGetPaymentStatusesQuery,
  useUpdatePaymentMutation,
} from "../../../../../../services/BillingService";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { getInitialsFromFullName } from "../../../../../../constants/constants";
import { navigateToContact } from "../../../../utils/navigateToContacts";
import DropStatus from "../../../../../billing/DropStatus";

interface Props {
  customerId: string;
}

const OrganizationPayments: FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<{ name: SortEnum; order: boolean }>({
    name: SortEnum.InvoiceDate,
    order: false,
  });

  const [activePaymentId, setActivePaymentId] = useState("");

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetPaymentStatusesQuery(getLinkedFirm()?.orgId || "");

  const [updatePayment, { isLoading: isLoadingUpdate }] =
    useUpdatePaymentMutation();

  const updateStatus = (statusId: string, payment: IPayment) => {
    updatePayment({
      id: payment?.id || "",
      paymentStatusId: statusId || null,
      paymentDate: payment?.paymentDate,
      amount: payment?.amount,
      notes: payment?.notes,
      paymentMethodId: payment?.paymentMethodId,
      customerId: payment?.customerId,
      paymentItem: payment?.paymentItem,
    });
  };

  const {
    data: payments,
    refetch,
    isLoading,
    isFetching,
  } = useGetPaymentsQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: [],
    sortBy: sort.name,
    sortOrder: sort.order ? "asc" : "desc",
    filterByStatus: [],
    filterByClient: [customerId],
    filterByAssignee: [],
    filterByLabel: [],
  });

  const handleSort = (name: SortEnum) => {
    if (name === sort.name) {
      setSort({ name, order: !sort.order });
    } else {
      setSort({ name, order: true });
    }
  };

  useEffect(() => {
    refetch();
  }, [sort]);

  if (isLoading || isLoadingStatuses) {
    return <LottieLoading />;
  }

  const rows = payments?.map((payment) => {
    return (
      <tr
        key={payment.id}
        className={
          "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
        }
        onClick={() => navigate("/billing/payments/" + payment?.id)}>
        <td className={"bg-transparent px-[16px] py-[8px]"}>
          <div className={"flex items-center"}>
            <div className={"flex items-center text-[14px]"}>
              <AvatarPlaceholder
                size={"extra-small"}
                type={payment?.customer?.businessContactId ? "purple" : "blue"}
                label={getInitialsFromFullName(payment?.customer?.name || "")}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    navigateToContact({
                      type: payment?.customer?.businessContactId
                        ? "organization"
                        : "contact",
                      customerId: payment?.customer?.id || "",
                      id: payment?.customer?.businessContactId || "",
                    }),
                  );
                }}
                className={
                  "link-primary cursor-pointer pl-2 text-sm font-semibold hover:underline"
                }>
                {payment?.customer?.name}
              </div>
            </div>
          </div>
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {moment(payment?.paymentDate).format("MM/DD/YYYY")}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {"$" +
            (payment?.amount || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {payment?.paymentMethod?.paymentMethod}
        </td>
        <td className={"bg-transparent px-4 py-2"}>
          <DropStatus
            invoiceStatus={null}
            isLoading={
              (isLoadingUpdate || isFetching) && payment?.id === activePaymentId
            }
            paymentStatus={payment?.paymentStatus || null}
            statuses={statuses || []}
            selectStatus={(status) => {
              setActivePaymentId(payment?.id);
              updateStatus(status?.id || "", payment);
            }}
          />
        </td>
        <td
          className={
            "max-w-[300px] truncate whitespace-nowrap bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {payment?.notes}
        </td>
      </tr>
    );
  });

  return (
    <table className={"w-full"}>
      <thead className={"border-b-[1px] border-gray-300"}>
        <tr>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Customer)}>
              <div>Contact</div>
              {sort?.name === SortEnum.Customer && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.PaymentDate)}>
              <div>Payment date</div>
              {sort?.name === SortEnum.PaymentDate && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Amount)}>
              <div>Amount</div>
              {sort?.name === SortEnum.Amount && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.PaymentMethod)}>
              <div>Method</div>
              {sort?.name === SortEnum.PaymentMethod && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.PaymentStatus)}>
              <div>Label</div>
              {sort?.name === SortEnum.PaymentStatus && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Notes)}>
              <div>Notes</div>
              {sort?.name === SortEnum.Notes && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody className={"text-sm"}>{rows}</tbody>
    </table>
  );
};

export default OrganizationPayments;
