import { assign, createMachine } from "xstate";

type UserAuthContext = { userAuthInfo?: { email: string; authToken: string } };

export const userAuthMachine = createMachine<UserAuthContext>({
  id: "userAuth",
  initial: "empty",
  context: { userAuthInfo: undefined },
  states: {
    empty: {
      on: { FETCH: "loading" },
    },
    loading: {
      on: {
        SUCCESS: {
          target: "valid",
          actions: [
            assign((ctx, event) => {
              return {
                userAuthInfo: event.userAuthInfo,
              };
            }),
          ],
        },
        FAILURE: "invalid",
      },
    },
    valid: {
      on: { RELOAD: "loading" },
    },
    invalid: {
      type: "final",
    },
  },
});
