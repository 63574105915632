import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { WorkTypeModel } from "../factories/work-type/models/work-type.model";

export const workTypeSlice = createApi({
  reducerPath: "workTypeSlice",
  tagTypes: ["WorkType"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getWorkTypes: build.query<
      WorkTypeModel[],
      {
        orgId: string;
      }
    >({
      query: (params) => ({
        url: "api/services/get-all",
        params,
        method: "GET",
      }),
      providesTags: ["WorkType"],
    }),
    getWorkType: build.query<
      WorkTypeModel,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: "api/services/" + id,
        method: "GET",
      }),
      providesTags: ["WorkType"],
    }),
    getWorkTypeById: build.query<
      WorkTypeModel,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: "api/services/get-by-id/" + id,
        method: "GET",
      }),
      providesTags: ["WorkType"],
    }),
  }),
});

export const {
  useGetWorkTypesQuery,
  useGetWorkTypeQuery,
  useGetWorkTypeByIdQuery,
} = workTypeSlice;
