import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../types/types";
import CustomSelect from "../select/Select";
import {
  useGetActiveContactsQuery,
  useGetActiveOrganizationsQuery,
} from "../../slices/ContactsSlice";
import { getInitialsFromFullName } from "../../constants/constants";
import { navigateToContact } from "../../features/contacts/utils/navigateToContacts";

type Props = {
  setValue: (data: string) => void;
  value: string;
  placeholder: string;
  close?: () => void;
  type: "contact" | "organization";
};

const SearchContacts: FC<Props> = ({
  setValue,
  value,
  placeholder,
  close,
  type,
}) => {
  const navigate = useNavigate();

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetActiveContactsQuery(`${getLinkedFirm()?.orgId}`);

  const { data: organizations, isLoading: isLoadingOrganizations } =
    useGetActiveOrganizationsQuery(`${getLinkedFirm()?.orgId}`);

  const getOptions = (): SelectOptionType[] => {
    if (type === "contact") {
      return (
        contacts?.map((item) => ({
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.customers?.[0]?.id || "",
          secondValue: item?.id || "",
          avatar: `${item?.firstName?.[0]}${item?.lastName?.[0]}`,
        })) || []
      );
    }
    if (type === "organization") {
      return (
        organizations?.map((item) => ({
          label: `${item?.companyName}`,
          value: item.businessCustomers?.[0].id || "",
          avatar: getInitialsFromFullName(item?.companyName || ""),
          avatarColor: "purple",
        })) || []
      );
    }
    return [];
  };

  return (
    <CustomSelect
      placeholder={placeholder}
      value={null}
      inputValue={value}
      options={getOptions()}
      onChangeInput={(newValue) => {
        setValue(newValue);
      }}
      isLoading={isLoadingContacts || isLoadingOrganizations}
      clearValueCloseMenu={false}
      fullWidth
      onChange={(newValue) => {
        close && close();
        const contact = newValue as SelectOptionType;
        const link =
          contact?.avatarColor === "purple"
            ? `/organization/${contact?.value}`
            : navigateToContact({
                type: "contact",
                customerId: contact.value || "",
              });
        navigate(link);
      }}
    />
  );
};

export default SearchContacts;
