import * as Apollo from "@apollo/client";
import { NetworkStatus, useMutation } from "@apollo/client";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import * as Xstate from "@xstate/react";
import React from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import {
  LABELS,
  PATHS,
  ROUTE_NAMES,
} from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { IconButton } from "../../../components/button/IconButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { PageLayout } from "../../../components/layouts/PageLayout";
import { ArrowChevronDownSvg } from "../../../components/svg/ArrowChevronDownSvg";
import {
  BankDocStateEnum,
  GetBankDocById,
  GetBankDocByIdVariables,
  UpdateBankDocState,
  UpdateBankDocStateVariables,
} from "../../../generated/operation-result-types";
import {
  GET_BANK_DOC_BY_ID_GQL,
  UPDATE_BANK_DOC_STATE_GQL,
} from "../../../queries/BankDocQueries.gql";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { BankDocDetailsOutputView } from "./BankDocDetailsOutputView";
import { BankDocDownloadView } from "./BankDocDownloadView";
import { BankDocEditView } from "./BankDocEditView";

export const BankDocDetailsView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Bank Statement Details");
  });

  const { bankDocId } = useParams<{ bankDocId: string }>();
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation, userInfoByEmail } = userInfoState.context;

  // queries
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    refetch,
    networkStatus,
  } = Apollo.useQuery<GetBankDocById, GetBankDocByIdVariables>(
    GET_BANK_DOC_BY_ID_GQL,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { bankDocId: bankDocId! },
    },
  );

  const isRefetchingQ = networkStatus === NetworkStatus.refetch;
  const isLoadingOrFetchingQ = (loadingQ && !dataQ) || isRefetchingQ;

  // refetch queries
  const refetchQueries: InternalRefetchQueryDescriptor[] = [
    {
      query: GET_BANK_DOC_BY_ID_GQL,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { bankDocId: bankDocId! },
    },
  ];

  // mutation
  const [
    updateBankDocState,
    { loading: loadingM, error: errorM, reset: resetM },
  ] = useMutation<UpdateBankDocState, UpdateBankDocStateVariables>(
    UPDATE_BANK_DOC_STATE_GQL,
    { refetchQueries: refetchQueries },
  );

  if (isLoadingOrFetchingQ || loadingM) {
    return <LottieLoading />;
  }

  if (errorQ || errorM) {
    console.error("BankDocDetailsView | BankDocDetailsView", {
      errorQ,
      errorM,
    });
    throw new Error("Error getting BankDocDetailsView");
  }

  const bankDocById = dataQ?.bankDocById;
  if (!bankDocById) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }

  const { name: bankDocName, deletedAt } = bankDocById;
  const isVisible = !deletedAt;

  if (bankDocId === undefined || currentLocation === undefined) {
    throw new Error("Error: missing bank document Id or location Id");
  }

  const onToggleState = async (bankDocStateEnum: BankDocStateEnum) => {
    resetM();
    await updateBankDocState({
      variables: {
        updateBankDocStateInput: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          orgId: userInfoByEmail!.org!.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationId: currentLocation!.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          bankDocId: bankDocId!,
          bankDocStateEnum,
        },
      },
    });
  };

  return (
    <PageLayout title={bankDocName}>
      <div className={"breadcrumbs text-sm"}>
        <ul>
          <li>
            <Link className={"link link-primary"} to={PATHS.customers()}>
              {LABELS.features.customers}
            </Link>
          </li>
          <li>
            <Link
              className={"link link-primary"}
              to={PATHS.customerById(bankDocById.customer?.id)}>
              {bankDocById.customer?.name}
            </Link>
          </li>
          <li>
            <Link className={"link link-primary"} to={PATHS.bankDocs()}>
              {LABELS.features.bankDocs}
            </Link>
          </li>
          <li>{bankDocName}</li>
        </ul>
      </div>

      {!isVisible && (
        <Alert type={"warning"} label={LABELS.warnings.documentInactive} />
      )}

      <div className={"flex items-center justify-between"}>
        <div className={"tabs my-8"}>
          {/* intentionally empty for spacing */}
        </div>
        <div className={"flex items-center space-x-2"}>
          <OutlineButton
            colorType={"secondary"}
            label={ROUTE_NAMES.documents.bankDoc.edit}
            onClick={() => navigate(`./${ROUTE_NAMES.documents.bankDoc.edit}`)}
          />
          <Dropdown
            ButtonComponent={
              <IconButton
                colorType={"secondary"}
                IconSvg={ArrowChevronDownSvg}
              />
            }
            ListComponent={
              <>
                <li>
                  <button
                    className={"link link-secondary no-underline"}
                    disabled={loadingM}
                    onClick={() =>
                      onToggleState(
                        isVisible
                          ? BankDocStateEnum.DEACTIVATE
                          : BankDocStateEnum.ACTIVATE,
                      )
                    }>
                    {isVisible ? "Deactivate" : "Activate"}
                  </button>
                </li>
              </>
            }
          />
        </div>
      </div>

      <Routes>
        <Route path={"*"} element={<Outlet />}>
          <Route index element={<Navigate to={"./output"} replace={true} />} />
          <Route
            path={ROUTE_NAMES.documents.bankDoc.edit}
            element={<BankDocEditView bankDocById={bankDocById} />}
          />
          <Route
            path={ROUTE_NAMES.documents.bankDoc.download}
            element={<BankDocDownloadView bankDocById={bankDocById} />}
          />
          <Route
            path={"output/*"}
            element={
              <BankDocDetailsOutputView
                bankDocById={bankDocById}
                locationId={currentLocation.id}
                onRefreshStatus={() => refetch()}
              />
            }
          />
        </Route>
      </Routes>
    </PageLayout>
  );
};
