import { ChevronDown, ChevronUp } from "baseui/icon";
import React, { useEffect, useRef, useState } from "react";

import { getUsers } from "../../../../api/api";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { IUser } from "../../../../types/types";
import { getInitials } from "../../../../constants/constants";

interface ISelectFilterUsersProps {
  type?: string;
  setArrayFilter: (array: IUser[]) => void;
  variant?: "one" | "multi";
  values: IUser[];
  page?: "Works" | "Dashboard" | "Billing";
}

const SelectFilterUsers: React.FC<ISelectFilterUsersProps> = ({
  values,
  setArrayFilter,
  variant = "multi",
  page = "Works",
}) => {
  const selectFilterStatus = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [filterByUser, setFilterByUser] = useState<IUser[]>(values);

  useEffect(() => {
    setFilterByUser(values);
  }, [values]);

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      getUsers(`${getLinkedFirm()?.orgId}`).then((res) => setUsers(res));
    }
  }, [getLinkedFirm()?.orgId]);

  useOnClickOutside(selectFilterStatus, () => setIsOpen(false));

  const onChangeUser = (user: IUser, checked: boolean) => {
    switch (page) {
      case "Works": {
        if (checked) {
          if (variant === "one") {
            setIsOpen(false);
            setFilterByUser([user]);
          } else {
            setFilterByUser((prev) => [...prev, user]);
          }
        } else {
          setFilterByUser((prev) => prev.filter((item) => item.id !== user.id));
        }
        break;
      }
      case "Billing": {
        if (checked) {
          if (variant === "one") {
            setIsOpen(false);
            setFilterByUser([user]);
          } else {
            setFilterByUser((prev) => [...prev, user]);
          }
        } else {
          setFilterByUser((prev) => prev.filter((item) => item.id !== user.id));
        }
        break;
      }
      case "Dashboard": {
        if (checked) {
          if (variant === "one") {
            setIsOpen(false);
            setArrayFilter([user]);
          } else {
            setArrayFilter([...values, user]);
          }
        } else {
          setArrayFilter(values.filter((item) => item.id !== user.id));
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (page === "Works") {
      setArrayFilter(filterByUser);
    }
  }, [filterByUser, page]);

  const activeUser = () => {
    if (filterByUser.length > 0) {
      return (
        <div>
          <div className={"flex items-center gap-1"}>
            <AvatarPlaceholder
              size={"2xs"}
              label={getInitials(
                filterByUser[0]?.userProfile?.firstName || "",
                filterByUser[0]?.userProfile?.lastName || "",
              )}
            />
            <div
              className={
                "max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap text-sm"
              }>
              {`${filterByUser[0].userProfile?.firstName} ${filterByUser[0].userProfile?.lastName}`}
              {filterByUser.length > 1 && "..."}
            </div>
          </div>
        </div>
      );
    } else {
      return <div>All</div>;
    }
  };

  return (
    <div>
      {page !== "Billing" && <div className={"mb-0.5 text-base"}>Assignee</div>}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`relative h-fit w-[200px] cursor-pointer rounded-[8px] border-[1px] bg-white px-5 py-2 ${
          isOpen ? "border-purple-700" : "border-gray-300"
        }
        ${page === "Billing" && "h-[48px]"}`}
        ref={selectFilterStatus}>
        <div className={"flex w-full items-center justify-between"}>
          <div className={"text-sm"}>{activeUser()}</div>
          {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </div>
        {isOpen && (
          <div
            className={
              "absolute left-[0px] z-[1] mt-[12px] max-h-[300px] w-full overflow-y-auto rounded-[8px] bg-white py-4 shadow-filter "
            }>
            <div className={"flex flex-col gap-1"}>
              {users.map((user) => (
                <div key={user.id}>
                  <div
                    className={"flex cursor-pointer px-4 hover:bg-gray-100"}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChangeUser(
                        user,
                        !filterByUser.some((item) => item.id === user.id),
                      );
                    }}>
                    {variant === "multi" && (
                      <div className={"mr-4"}>
                        <input
                          className={"checkbox checkbox-accent cursor-pointer"}
                          type={"checkbox"}
                          checked={filterByUser.some(
                            (item) => item.id === user.id,
                          )}
                          onChange={() => null}
                        />
                      </div>
                    )}
                    <div className={"flex items-center gap-1"} key={user?.id}>
                      <AvatarPlaceholder
                        size={"super-small"}
                        label={getInitials(
                          user?.userProfile?.firstName || "",
                          user?.userProfile?.lastName || "",
                        )}
                      />
                      <div
                        className={
                          "text-sm font-semibold"
                        }>{`${user.userProfile?.firstName} ${user.userProfile?.middleName} ${user.userProfile?.lastName}`}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFilterUsers;
