import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import classNames from "classnames";
import _ from "lodash";
import React from "react";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmail_users_edges_node_org_locations_edges_node,
  GetUserInfoByEmailVariables,
} from "../../generated/operation-result-types";
import { GET_USER_INFO_BY_EMAIL_GQL } from "../../queries/UserQueries.gql";

type Props = {
  isHidden: boolean;
};

export const LocationSelector: React.FC<Props> = ({ isHidden }) => {
  // xstate
  const { userAuthService, userInfoService } = React.useContext(GlobalContext);
  const [userAuthState] = Xstate.useActor(userAuthService);
  const { userAuthInfo } = userAuthState.context;
  const { send: sendUserInfo } = userInfoService;

  const [defaultSelectVal, setDefaultSelectVal] = React.useState<
    string | undefined
  >(undefined);

  const { data: dataQ } = Apollo.useQuery<
    GetUserInfoByEmail,
    GetUserInfoByEmailVariables
  >(GET_USER_INFO_BY_EMAIL_GQL, {
    fetchPolicy: "network-only",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { email: userAuthInfo!.email },
  });

  const handleSend = (jsonLocation: string) => {
    const selectedLocation: GetUserInfoByEmail_users_edges_node_org_locations_edges_node =
      JSON.parse(jsonLocation);
    sendUserInfo("updateLocation", { currentLocation: selectedLocation });
  };

  React.useEffect(() => {
    if (!!defaultSelectVal) handleSend(defaultSelectVal);
  }, [defaultSelectVal, sendUserInfo]);

  const userNode = _.first(dataQ?.users?.edges)?.node;

  React.useEffect(() => {
    if (userNode) {
      sendUserInfo("updateUserInfoByEmail", { userInfoByEmail: userNode });
    }
  }, [userNode, sendUserInfo]);

  if (!userNode) {
    return <div>Missing user info</div>;
  }

  const { org } = userNode;

  const orgLocations = org?.locations?.edges ?? [];
  if (orgLocations.length === 0) {
    return <div>Missing locations</div>;
  }

  const selectLocationOptions = orgLocations.map((locationEdge, index) => {
    const jsonLocation = JSON.stringify(locationEdge.node);
    if (index === 0 && !defaultSelectVal) {
      setDefaultSelectVal(jsonLocation);
    }
    return (
      <option key={jsonLocation} value={jsonLocation}>
        {locationEdge.node.locationAddress?.city}
      </option>
    );
  });

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    handleSend(e.target.value);

  return (
    <>
      <select
        defaultValue={defaultSelectVal}
        onChange={handleLocationChange}
        className={classNames(
          "select select-bordered select-md w-full max-w-xs",
          {
            hidden: isHidden,
          },
        )}>
        {selectLocationOptions}
      </select>
      {/* tick daisy-ui select padding to not jank when hidden */}
      <div
        className={classNames("py-6", {
          hidden: !isHidden,
        })}></div>
    </>
  );
};
