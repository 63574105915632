import { Invoice } from "../../services/BillingService";
import { CommonFactoryInterface } from "../common-factory-type";
import { UpdateInvoiceStatusDto } from "./factory-dtos";

class UpdateInvoiceStatusFactory
  implements
    CommonFactoryInterface<
      {
        invoice: Invoice;
        status: string | null;
      },
      UpdateInvoiceStatusDto
    >
{
  createModelFromDto = (dto: {
    invoice: Invoice;
    status: string | null;
  }): UpdateInvoiceStatusDto => {
    return {
      id: dto.invoice?.id || "",
      invoiceStatusId: dto.status || null,
      customerId: dto.invoice?.customerId,
      invoiceClientsIds: dto.invoice?.invoiceClientsIds,
      createdByUserId: dto.invoice?.createdByUserId,
      invoiceDate: dto.invoice?.invoiceDate,
      termId: dto.invoice?.termId,
      dueDate: dto.invoice?.dueDate,
      invoiceNo: dto.invoice?.invoiceNo,
      tax: dto.invoice?.tax,
      orgId: dto.invoice?.orgId,
      total: dto.invoice?.total,
      discount: dto.invoice?.discount,
      balance: dto.invoice?.balance,
      discountTypeId: dto.invoice?.discountTypeId,
      paymentRecieved: dto.invoice?.paymentRecieved,
      assignedToUserId: dto.invoice?.assignedToUserId,
      invoiceItem: dto.invoice?.invoiceItem,
    };
  };
}

export const updateInvoiceStatusFactory = new UpdateInvoiceStatusFactory();
