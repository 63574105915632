import React, { FC } from "react";
import { INotification } from "../../types/types";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { getInitials } from "../../constants/constants";

type Props = {
  notification: INotification;
  isChecked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};

export const NotificationMessage: FC<Props> = ({
  notification,
  isChecked,
  onCheck,
}) => {
  if (notification.message === "You have been mentioned in a comment by") {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}
              size={"extra-small"}
              label={getInitials(
                notification?.mentionByUser?.userProfile?.firstName || "",
                notification?.mentionByUser?.userProfile?.lastName || "",
              )}
            />
          </div>
        </div>
        <div>
          {notification?.message}{" "}
          <span
            className={
              "link-primary font-semibold"
            }>{`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}</span>{" "}
          {"regarding"}
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.customer?.name}`}</span>
        </div>
      </div>
    );
  }
  if (notification.message === "assigned you the Subtask ") {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}
              size={"extra-small"}
              label={getInitials(
                notification?.mentionByUser?.userProfile?.firstName || "",
                notification?.mentionByUser?.userProfile?.lastName || "",
              )}
            />
          </div>
        </div>
        <div>
          <span
            className={
              "link-primary font-semibold"
            }>{`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}</span>{" "}
          {notification?.message}
          <span className={"link-primary font-semibold"}>
            {notification?.jobTask?.name}
          </span>
          {", for"}
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.customer?.name}`}</span>
        </div>
      </div>
    );
  }
  if (notification.message === "has completed the Subtask") {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={`${notification?.user?.userProfile?.firstName} ${notification?.user.userProfile?.lastName}`}
              size={"extra-small"}
              label={getInitials(
                notification?.user?.userProfile?.firstName || "",
                notification?.user?.userProfile?.lastName || "",
              )}
            />
          </div>
        </div>
        <div>
          <span
            className={
              "link-primary font-semibold"
            }>{`${notification?.user?.userProfile?.firstName} ${notification?.user?.userProfile?.lastName}`}</span>{" "}
          {notification?.message}{" "}
          <span className={"link-primary font-semibold"}>
            {notification?.jobTask?.name}
          </span>
          {", for"}
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.customer?.name}`}</span>
        </div>
      </div>
    );
  }
  if (
    notification?.message === "has added a new comment regarding for work item"
  ) {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}
              size={"extra-small"}
              label={getInitials(
                notification?.mentionByUser?.userProfile?.firstName || "",
                notification?.mentionByUser?.userProfile?.lastName || "",
              )}
            />
          </div>
        </div>
        <div>
          <span
            className={
              "link-primary font-semibold"
            }>{`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}</span>{" "}
          {notification?.message.slice(0, notification?.message.length - 14)}{" "}
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.customer?.name}`}</span>{" "}
          for work item
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.job?.name}`}</span>
        </div>
      </div>
    );
  }
  if (notification?.message === "assigned you the Work ") {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}
              size={"extra-small"}
              label={getInitials(
                notification?.mentionByUser?.userProfile?.firstName,
                notification?.mentionByUser?.userProfile?.lastName,
              )}
            />
          </div>
        </div>
        <div>
          <span
            className={
              "link-primary font-semibold"
            }>{`${notification?.mentionByUser?.userProfile?.firstName} ${notification?.mentionByUser?.userProfile?.lastName}`}</span>{" "}
          assigned you the work
          <span
            className={
              "link-primary font-semibold"
            }>{` ${notification?.job?.name}`}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"flex items-center gap-4 text-sm text-gray-600"}>
        <div className={"h-5"}>
          <input
            onClick={(event) => {
              event.stopPropagation();
            }}
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={isChecked}
            onChange={(event) => onCheck(event, notification.id)}
          />
        </div>
        <div className={"relative"}>
          <div>
            <AvatarPlaceholder
              isShowUsername={true}
              username={"N/A"}
              size={"extra-small"}
              label={"N/A"}
            />
          </div>
        </div>
        <div>
          <span>Something went wrong</span>
        </div>
      </div>
    );
  }
};
