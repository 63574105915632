import moment from "moment/moment";
import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import {
  getInitials,
  getInitialsFromFullName,
  timeFormatter,
} from "../../../../constants/constants";
import { WorkTimeModel } from "../../../../factories/work/model/work-time.model";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { useGetTimesByWorkQuery } from "../../../../slices/WorkSlice";
import { FormSection } from "../../../../support/FormSection";
import { navigateToContact } from "../../../contacts/utils/navigateToContacts";
import EditTime from "../../refactor/EditTime";
import Button from "../../../../components/button/Button";
import { timerSlice, updateTimer } from "../../../../services/TimerService";
import { useAppDispatch, useAppSelector } from "../../../../redux/redux";
import * as Xstate from "@xstate/react";
import { GlobalContext } from "../../../../app/stateMachines/GlobalContext";

export const WorkTime: FC = () => {
  const { workId } = useParams();
  const { timer } = useAppSelector((state) => state.timerReducer);
  const [isEditTime, setIsEditTime] = useState(false);
  const [currentTime, setCurrentTime] = useState<WorkTimeModel | null>(null);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const [isOpenStart, setIsOpenStart] = useState(false);
  const dispatch = useAppDispatch();
  const { setStatusTimer } = timerSlice.actions;
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { data: timesByWork, isLoading } = useGetTimesByWorkQuery({
    workId: workId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });

  if (isLoading) {
    return <LottieLoading />;
  }
  const buttonTimerVariant = () => {
    if (timer.timerStatus === "ended" || timer.timerStatus === null) {
      return (
        <Button
          onClick={() => setIsOpenStart(true)}
          buttonType={"button"}
          label={"Start timer"}
          extraClasses={"normal-case"}
        />
      );
    } else {
      return (
        <Button
          onClick={() => {
            timer.timerStatus === "started" &&
              dispatch(
                updateTimer({
                  ...timer,
                  assignedUserId: null,
                  timerStatus: "paused",
                  assignedUserRelayId: `${userInfoByEmail?.id}`,
                }),
              );
            dispatch(
              setStatusTimer({
                timerStatus:
                  timer.timerStatus === "started" ? "paused" : "started",
              }),
            );
          }}
          buttonType={"button"}
          label={timer.timerStatus === "paused" ? "Continue" : "Pause"}
          extraClasses={"normal-case"}
        />
      );
    }
  };
  return (
    <>
      <FormSection name={""} showHeading={false}>
        <div>
          <div className={"mb-5 flex w-full items-center justify-between"}>
            <div className={"font-bold"}>Time</div>
            <div>{buttonTimerVariant()}</div>
          </div>
          <table className={"w-full"}>
            <thead className={"border-b-[1px] border-gray-300"}>
              <tr className={"py-[16px] pl-[16px] pr-[32px] last:pr-[16px]"}>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Contact
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work Type
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Total Time
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Date
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Assignee
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
              {!timesByWork?.length ? (
                <></>
              ) : (
                timesByWork?.map((time) => (
                  <tr
                    key={time?.id}
                    className={
                      "border-b-[1px] border-gray-300 hover:bg-gray-100"
                    }>
                    <td className={"px-[16px] py-[8px] align-top"}>
                      <div className={"flex h-[40px] items-center"}>
                        <div className={"max-w-[300px] whitespace-normal"}>
                          <Link
                            to={`/work/${time?.workId}`}
                            className={
                              "link-primary text-sm font-[500] hover:underline"
                            }>
                            {time?.work?.name}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top"}>
                      <div className={"flex items-center"}>
                        {time.contactId ? (
                          <div className={"flex items-center text-[14px]"}>
                            <AvatarPlaceholder
                              size={"extra-small"}
                              label={getInitials(
                                time?.contact?.firstName || "",
                                time?.contact?.lastName || "",
                              )}
                            />
                            <div className={"pl-2 text-[14px] normal-case"}>
                              <Link
                                to={
                                  time?.contact
                                    ? navigateToContact({
                                        type: "contact",
                                        customerId:
                                          time?.work?.customerId || "",
                                      })
                                    : ""
                                }
                                className={
                                  "link-primary text-sm font-[500] hover:underline"
                                }>
                                {time?.contact
                                  ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                                  : "Contact name"}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className={"flex items-center text-[14px]"}>
                            <AvatarPlaceholder
                              size={"extra-small"}
                              type={"purple"}
                              label={getInitialsFromFullName(
                                time?.businessContact?.companyName || "",
                              )}
                            />
                            <div className={"pl-2 text-[14px] normal-case"}>
                              <Link
                                to={
                                  time?.contact
                                    ? `/organization/${time?.businessContactId}`
                                    : ""
                                }
                                className={
                                  "link-primary text-sm font-[500] hover:underline"
                                }>
                                {time?.businessContact
                                  ? time?.businessContact.companyName
                                  : "Contact name"}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      <div className={"flex h-[40px] items-center"}>
                        {time?.work?.service?.name}
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        setCurrentTime(time);
                        setIsEditTime(true);
                      }}
                      className={"px-[16px] py-[8px] align-top "}>
                      <div
                        className={
                          "link-primary flex h-[40px] cursor-pointer items-center text-sm font-[500] hover:underline"
                        }>
                        {timeFormatter(time?.duration)}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      <div className={"flex h-[40px] items-center"}>
                        {moment(time?.date).format("YYYY-MM-DD")}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      <div className={"flex items-center"}>
                        <AvatarPlaceholder
                          size={"extra-small"}
                          label={getInitials(
                            time?.assignedUser?.userProfile?.firstName || "",
                            time?.assignedUser?.userProfile?.lastName || "",
                          )}
                        />
                        <div className={"pl-2 text-[14px] normal-case"}>
                          {time?.assignedUser?.userProfile
                            ? `${time?.assignedUser?.userProfile?.firstName} ${time.assignedUser.userProfile.lastName}`
                            : "User name"}
                        </div>
                      </div>
                    </td>
                    <td
                      className={"px-[16px] py-[8px] align-top text-[14px]"}
                      onClick={() => {
                        setCollapsedItems((prev) =>
                          collapsedItems.includes(time?.id)
                            ? prev.filter((item) => item !== time?.id)
                            : [...prev, time?.id],
                        );
                      }}>
                      <div
                        className={`${
                          !collapsedItems.includes(time?.id)
                            ? "...  max-w-[370px] overflow-hidden text-ellipsis whitespace-nowrap"
                            : " max-w-[370px] whitespace-pre-line"
                        }`}>
                        {time?.notes}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </FormSection>
      {isEditTime && currentTime && (
        <EditTime
          closeModal={() => {
            setIsEditTime(false);
            setCurrentTime(null);
          }}
          time={currentTime}
        />
      )}
    </>
  );
};
