import React, { FC, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import {
  useAddContactNoteMutation,
  useUpdateContactNoteMutation,
} from "../../../slices/ContactNotesSlice";
import { ContactNoteModel } from "../../../factories/contacts/models/contact-note.models";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: ContactNoteModel;
  contactId?: string;
  businessContactId?: string;
};

type FormData = {
  title: string;
  note: string;
};

export const ContactNoteModal: FC<Props> = ({
  isActive,
  onClose,
  defaultValues,
  contactId,
}) => {
  const { userInfoService } = useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [
    addContactNote,
    { isLoading: isLoadingContactAdd, isSuccess: isSuccessContactAdd },
  ] = useAddContactNoteMutation();
  const [
    updateContactNote,
    { isLoading: isLoadingContactUpdate, isSuccess: isSuccessContactUpdate },
  ] = useUpdateContactNoteMutation();

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: defaultValues,
  });

  const onSubmit = (data: FormData) => {
    if (
      (data.note || data.title) &&
      !isLoadingContactAdd &&
      !isLoadingContactUpdate
    ) {
      if (defaultValues) {
        updateContactNote({
          ...defaultValues,
          note: data.note,
          title: data.title,
        });
      } else {
        addContactNote({
          title: data.title,
          note: data.note,
          createdByUserRelayId: `${userInfoByEmail?.id}`,
          contactId: contactId || "",
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccessContactAdd || isSuccessContactUpdate) {
      onClose();
    }
  }, [isSuccessContactAdd, isSuccessContactUpdate]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box p-0"}>
        <div
          className={
            "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
          }>
          {defaultValues ? "Update" : "Add"} note
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={"px-5 py-4"}>
          <div className={"mb-4"}>
            <input
              type={"text"}
              {...register("title", {})}
              className={"input input-bordered w-full"}
              placeholder={"Title"}
            />
          </div>
          <div>
            <textarea
              {...register("note", {})}
              className={"input input-bordered h-[200px] w-full"}
              placeholder={"Add a note"}
            />
          </div>
          <div className={"mt-4 flex justify-end gap-4"}>
            <Button
              label={defaultValues ? "Update" : "Add"}
              buttonType={"submit"}
              disabled={isLoadingContactAdd || isLoadingContactUpdate}
            />
            <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
          </div>
        </form>
      </div>
    </div>
  );
};
