import * as Apollo from "@apollo/client";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { LABELS, LinkConstants } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmailVariables,
  OnboardUser,
  OnboardUserInput,
  OnboardUserVariables,
} from "../../generated/operation-result-types";
import { ONBOARD_USER_GQL } from "../../queries/OnboardingQueries.gql";
import { GET_USER_INFO_BY_EMAIL_GQL } from "../../queries/UserQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";
import { OnboardingLayout } from "./OnboardingLayout";

type Props = {
  orgId: string;
  userId: string;
  userEmail: string;
  onDone: () => void;
};

export const UserOnboardingView: React.FC<Props> = (props) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Onboarding user");
  });

  const { orgId, userId, onDone, userEmail } = props;

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const { send: sendUserInfo } = userInfoService;

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardUserInput>({
    defaultValues: {
      orgId: orgId,
      userId: userId,
    },
  });

  const [
    getUserInfoByEmail,
    { loading: loadingQ2, error: errorQ2, data: dataQ2 },
  ] = Apollo.useLazyQuery<GetUserInfoByEmail, GetUserInfoByEmailVariables>(
    GET_USER_INFO_BY_EMAIL_GQL,
    {
      fetchPolicy: "network-only",
      variables: { email: userEmail },
    },
  );

  const [onboardUser, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<OnboardUser, OnboardUserVariables>(ONBOARD_USER_GQL, {
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    const userNode = _.first(dataQ2?.users?.edges)?.node;
    if (userNode) {
      sendUserInfo("updateUserInfoByEmail", { userInfoByEmail: userNode });
    }
  }, [dataQ2, sendUserInfo]);

  const onSubmit: SubmitHandler<OnboardUserInput> = async (data) => {
    resetM();
    await onboardUser({
      variables: {
        onboardUserInput: data,
      },
    });
    await getUserInfoByEmail();

    // proceed only when no failures
    if (!errorM && !errorQ2) {
      onDone();
    }
  };

  if (loadingM || loadingQ2) {
    return <LottieLoading />;
  }

  if (errorM || errorQ2) {
    console.error("UserOnboardingView | UserOnboardingView", {
      errorM,
      errorQ2,
    });
    throw new Error("Error getting UserOnboardingView");
  }

  const { userRules } = ValidationConstants;
  const personalInfoView = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-5"
      }>
      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>First name</span>
        </label>
        <input
          type={"text"}
          {...register("firstName", {
            required: LABELS.required,
            ...userRules.profileRules.firstNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.firstName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.firstName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-1"}>
        <label className={"label"}>
          <span className={"label-text"}>Initials</span>
        </label>
        <input
          type={"text"}
          {...register("middleName", {
            required: false,
            ...userRules.profileRules.middleNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.middleName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.middleName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Last name</span>
        </label>
        <input
          type={"text"}
          {...register("lastName", {
            required: LABELS.required,
            ...userRules.profileRules.lastNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.lastName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.lastName?.message}
          </span>
        )}
      </div>
    </div>
  );

  const agreementsView = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6"
      }>
      <div className={"form-control space-y-4"}>
        <div className={"flex"}>
          <div className={"flex h-5 items-center"}>
            <input
              type={"checkbox"}
              className={"checkbox checkbox-primary"}
              disabled={loadingM}
              {...register("acceptedTerms", { required: LABELS.required })}
            />
          </div>
          <div className={"ml-3 flex flex-col text-sm"}>
            <label className={"font-medium text-gray-700"}>
              I accept the ProCharted Inc.{" "}
              <a
                href={LinkConstants.policies.terms}
                className={"link link-primary"}
                target={"_blank"}
                rel={"noreferrer"}>
                Terms and conditions
              </a>
            </label>
            {errors?.acceptedTerms?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.acceptedTerms?.message}
              </span>
            )}
          </div>
        </div>
        <div className={"flex"}>
          <div className={"flex h-5 items-center"}>
            <input
              type={"checkbox"}
              className={"checkbox checkbox-primary"}
              disabled={loadingM}
              {...register("acceptedPrivacy", { required: LABELS.required })}
            />
          </div>
          <div className={"ml-3 flex flex-col text-sm"}>
            <label className={"font-medium text-gray-700"}>
              I accept the ProCharted Inc.{" "}
              <a
                href={LinkConstants.policies.privacy}
                className={"link link-primary"}
                target={"_blank"}
                rel={"noreferrer"}>
                Privacy policy
              </a>
            </label>
            {errors?.acceptedPrivacy?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.acceptedPrivacy?.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <OnboardingLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"Personal Information"}>
          {personalInfoView}
        </FormSection>
        <FormSection name={"Agreements"}>{agreementsView}</FormSection>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}
        <BrandButton
          buttonType={"submit"}
          colorType={"primary"}
          label={"Continue"}
          SvgIconLeft={AddStackSvg}
          disabled={loadingM}
        />
      </form>
    </OnboardingLayout>
  );
};
