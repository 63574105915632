import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const HamburgerSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      className={`inline-block ${sizeCx} fill-current stroke-current`}>
      <path
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeWidth={"2"}
        d={"M4 6h16M4 12h16M4 18h16"}
      />
    </svg>
  );
};
