import { PagesEnum } from "./types";

export enum HomePagesEnum {
  MyWorks = "my-works",
  FirmWorks = "firm-works",
  TeamWorks = "team-works",
}

export const HomePages = {
  path: PagesEnum.Home,
  label: "Home",
  component: null,
  children: {
    myWorks: { path: HomePagesEnum.MyWorks, label: "My Work", component: null },
    firmWorks: {
      path: HomePagesEnum.FirmWorks,
      label: "Firm's Work",
      component: null,
    },
    teamWorks: {
      path: HomePagesEnum.TeamWorks,
      label: "Team's Work",
      component: null,
    },
  },
};
