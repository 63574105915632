import React from "react";

const AddPeopleSvg = () => {
  return (
    <svg
      width={"24"}
      height={"18"}
      viewBox={"0 0 24 18"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M17 7H20M20 7H23M20 7V4M20 7V10M1 17V16C1 14.1435 1.7375 12.363 3.05025 11.0503C4.36301 9.7375 6.14348 9 8 9M8 9C9.85652 9 11.637 9.7375 12.9497 11.0503C14.2625 12.363 15 14.1435 15 16V17M8 9C9.06087 9 10.0783 8.57857 10.8284 7.82843C11.5786 7.07828 12 6.06087 12 5C12 3.93913 11.5786 2.92172 10.8284 2.17157C10.0783 1.42143 9.06087 1 8 1C6.93913 1 5.92172 1.42143 5.17157 2.17157C4.42143 2.92172 4 3.93913 4 5C4 6.06087 4.42143 7.07828 5.17157 7.82843C5.92172 8.57857 6.93913 9 8 9Z"
        }
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  );
};

export default AddPeopleSvg;
