import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction, useEffect } from "react";

import Button from "../../../components/button/Button";
import { useDeleteEmailMutation } from "../../../slices/EmailsSlice";
import { useAppSelector } from "../../../redux/redux";
import { Email } from "../../../factories/emails/models/emails.models";

type Props = {
  isActive: boolean;
  onClose: () => void;
  emailId: string;
  setSelectedMail?: Dispatch<SetStateAction<Email | null>>;
};

export const DeleteEmailModal: FC<Props> = ({
  isActive,
  onClose,
  emailId,
  setSelectedMail,
}) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [deleteEmail, { isSuccess, isLoading }] = useDeleteEmailMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setSelectedMail && setSelectedMail(null);
    }
  }, [isSuccess]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box w-fit min-w-[300px] max-w-[90%]"}>
        <div className={"mb-4 text-xl font-semibold text-gray-800"}>
          Delete?
        </div>
        <div className={"mb-4 text-base text-gray-800"}>Message text</div>
        <div className={"flex justify-between gap-4"}>
          <Button label={"Cancel"} colorType={"outline"} onClick={onClose} />
          <Button
            label={"Delete"}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => {
              deleteEmail({ userId: user.id, messageId: emailId });
            }}
          />
        </div>
      </div>
    </div>
  );
};
