import { FC, useState } from "react";

import { ContactNoteModel } from "../../../../../../../../factories/contacts/models/contact-note.models";
import { ContactCollapsedNote } from "./ContactCollapsedNote";
import { ContactNote } from "./ContactNote";

type Props = {
  note: ContactNoteModel;
  setCurrentNote: (value: ContactNoteModel) => void;
  setIsOpenAdd: (value: boolean) => void;
  deleteContactNote: (value: ContactNoteModel) => void;
};

export const ContactNoteView: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return isCollapsed ? (
    <ContactCollapsedNote note={note} setIsCollapsed={setIsCollapsed} />
  ) : (
    <ContactNote
      note={note}
      setIsCollapsed={setIsCollapsed}
      setCurrentNote={setCurrentNote}
      setIsOpenAdd={setIsOpenAdd}
      deleteContactNote={deleteContactNote}
    />
  );
};
