import React from "react";

import { BasicAvatar } from "../avatar/BasicAvatar";
import { BrandButton } from "../button/BrandButton";

export const VisualTable: React.FC<unknown> = () => {
  return (
    <div className={"overflow-x-auto"}>
      <table className={"table w-full"}>
        <thead>
          <tr>
            <th>
              <label>
                <input type={"checkbox"} className={"checkbox"} />
              </label>
            </th>
            <th>Name</th>
            <th>Job</th>
            <th>Favorite Color</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <label>
                <input type={"checkbox"} className={"checkbox"} />
              </label>
            </th>
            <td>
              <div className={"flex items-center space-x-3"}>
                <BasicAvatar size={"small"} />
                <div>
                  <div className={"font-bold"}>Hart Hagerty</div>
                  <div className={"text-sm opacity-50"}>Richmond</div>
                </div>
              </div>
            </td>
            <td>
              Zemlak, Daniel and Leannon
              <br />
              <span className={"badge badge-outline badge-sm"}>
                Desktop Support Technician
              </span>
            </td>
            <td>Purple</td>
            <th>
              <BrandButton
                colorType={"link"}
                label={"details"}
                size={"extra-small"}
              />
            </th>
          </tr>
          <tr>
            <th>
              <label>
                <input type={"checkbox"} className={"checkbox"} />
              </label>
            </th>
            <td>
              <div className={"flex items-center space-x-3"}>
                <BasicAvatar size={"small"} />
                <div>
                  <div className={"font-bold"}>Brice Swyre</div>
                  <div className={"text-sm opacity-50"}>Vancouver</div>
                </div>
              </div>
            </td>
            <td>
              Carroll Group
              <br />
              <span className={"badge badge-outline badge-sm"}>
                Tax Accountant
              </span>
            </td>
            <td>Red</td>
            <th>
              <BrandButton
                colorType={"link"}
                label={"details"}
                size={"extra-small"}
              />
            </th>
          </tr>
          <tr>
            <th>
              <label>
                <input type={"checkbox"} className={"checkbox"} />
              </label>
            </th>
            <td>
              <div className={"flex items-center space-x-3"}>
                <BasicAvatar size={"small"} />
                <div>
                  <div className={"font-bold"}>Marjy Ferencz</div>
                  <div className={"text-sm opacity-50"}>Burnaby</div>
                </div>
              </div>
            </td>
            <td>
              Rowe-Schoen
              <br />
              <span className={"badge badge-outline badge-sm"}>
                Office Assistant I
              </span>
            </td>
            <td>Crimson</td>
            <th>
              <BrandButton
                colorType={"link"}
                label={"details"}
                size={"extra-small"}
              />
            </th>
          </tr>
          <tr>
            <th>
              <label>
                <input type={"checkbox"} className={"checkbox"} />
              </label>
            </th>
            <td>
              <div className={"flex items-center space-x-3"}>
                <BasicAvatar size={"small"} />
                <div>
                  <div className={"font-bold"}>Yancy Tear</div>
                  <div className={"text-sm opacity-50"}>Coquitalam</div>
                </div>
              </div>
            </td>
            <td>
              Wyman-Ledner
              <br />
              <span className={"badge badge-outline badge-sm"}>
                Community Outreach Specialist
              </span>
            </td>
            <td>Indigo</td>
            <th>
              <BrandButton
                colorType={"link"}
                label={"details"}
                size={"extra-small"}
              />
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
