import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { validatePhoneFormat } from "../../../constants/constants";
import { UpdateOrgDto } from "../../../factories/org/factory-dtos";
import { orgUpdateFactory } from "../../../factories/org/update-org.factory";
import {
  useGetOrgQuery,
  useUpdateOrgMutation,
} from "../../../services/OrgService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const EditOrgDetails: FC = () => {
  const navigate = useNavigate();

  const { data: org, isLoading: isLoadingOrg } = useGetOrgQuery(
    getLinkedFirm()?.orgId || "",
  );

  const [
    updateOrd,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdated },
  ] = useUpdateOrgMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateOrgDto>({
    defaultValues: {
      id: getLinkedFirm()?.orgId,
      locationId: org?.locations?.[0]?.locationAddress?.id,
      locationsId: org?.locations?.[0]?.id,
      locationAddressId: org?.locations?.[0]?.locationAddress?.id,
    },
  });

  const onSubmit = (data: UpdateOrgDto) => {
    if (org) {
      const submitData = orgUpdateFactory.createModelFromDto(data);
      updateOrd(submitData);
    }
  };

  useEffect(() => {
    document.title = GetPageTitle("Edit firm details");
  }, []);

  useEffect(() => {
    if (isSuccessUpdated) {
      navigate("../");
    }
  }, [isSuccessUpdated]);

  if (isLoadingOrg) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit Firm Details</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"Firm Info"} weightTitle={"bold"}>
          <div className={"form-control mb-8"}>
            <label className={"label"}>
              <span className={"label-text"}>Name</span>
            </label>
            <input
              type={"text"}
              defaultValue={org?.legalName || ""}
              {...register("legalName", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
              })}
              className={"input input-bordered"}
            />
            {errors.legalName?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors.legalName?.message}
              </span>
            )}
          </div>
          <div className={"form-control mb-8"}>
            <label className={"label"}>
              <span className={"label-text"}>Display name</span>
            </label>
            <input
              type={"text"}
              defaultValue={org?.displayName || ""}
              {...register("displayName", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
              })}
              className={"input input-bordered"}
            />
            {errors.displayName?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors.displayName?.message}
              </span>
            )}
          </div>
          <div className={"form-control mb-8"}>
            <div className={"mb-4 w-full"}>
              <label className={"label"}>
                <span className={"label-text"}>Address</span>
              </label>
              <input
                type={"text"}
                defaultValue={org?.locations[0]?.locationAddress?.line1 || ""}
                {...register("line1", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered  w-full"}
              />
              {errors.line1?.message && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {errors.line1?.message}
                </span>
              )}
            </div>
            <div className={"w-full"}>
              <input
                type={"text"}
                defaultValue={org?.locations[0]?.locationAddress?.line2 || ""}
                {...register("line2")}
                className={"input input-bordered w-full"}
              />
              {errors.line2?.message && (
                <span className={"pt-2 text-sm font-bold text-error"}>
                  {errors.line2?.message}
                </span>
              )}
            </div>
          </div>
          <div className={"form-control mb-8 flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>City</span>
              </label>
              <input
                type={"text"}
                defaultValue={org?.locations?.[0]?.locationAddress?.city || ""}
                {...register("city", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.city?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.city?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Province/State</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  org?.locations?.[0]?.locationAddress?.region || ""
                }
                {...register("region", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.region?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.region?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"form-control flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Postal code/Zip</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  org?.locations?.[0]?.locationAddress?.postalCode || ""
                }
                {...register("postalCode", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.postalCode?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.postalCode?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Country</span>
              </label>
              <input
                type={"text"}
                defaultValue={
                  org?.locations?.[0]?.locationAddress?.country || ""
                }
                {...register("country")}
                className={"input input-bordered"}
              />
              {errors.country?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.country?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"w-1/2"}>
            <label className={"label"}>
              <span className={"label-text"}>Phone number</span>
            </label>
            <input
              type={"text"}
              defaultValue={org?.phoneNumbers?.[0]?.number || ""}
              {...register("phoneNumber", {
                validate: {
                  phoneFormat: (value) =>
                    value
                      ? validatePhoneFormat(value) ||
                        "Invalid phone number format"
                      : true,
                },
              })}
              className={"input input-bordered"}
            />
            {errors.phoneNumber?.message && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.phoneNumber?.message}
              </div>
            )}
          </div>
        </FormSection>
        <div className={"flex justify-start space-x-4 pb-4"}>
          <Button
            colorType={"secondary"}
            label={"Update"}
            buttonType={"submit"}
            extraClasses={"normal-case"}
            disabled={isLoadingUpdate}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
            extraClasses={"normal-case"}
          />
        </div>
      </form>
    </>
  );
};
