import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const ErrorSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        strokeWidth={3}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} stroke-current`}>
        <path
          fill={"none"}
          d={
            "M20 22H4C2.9 22 2 21.1 2 20V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V20C22 21.1 21.1 22 20 22Z"
          }></path>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          d={"M6 6L18 18"}></path>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          d={"M18 6L6 18"}></path>
      </svg>
    </>
  );
};
