import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SuccessCheckSvg } from "../svg/SuccessCheckSvg";

type AlertNoticeProps = { label: string };

export const AlertNotice: React.FC<AlertNoticeProps> = (props) => {
  const { label } = props;
  return (
    <>
      <div className={"flex items-center"}>
        <SuccessCheckSvg
          sizeCx={`${TW_SIZES.hw.xl} ${TW_SIZES.mr.md} text-green-600`}
        />
        <div
          className={`text-lg capitalize ${TW_SIZES.colors.textSuccess} font-semibold`}>
          {label}
        </div>
      </div>
    </>
  );
};
