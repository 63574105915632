import * as Xstate from "@xstate/react";
import moment from "moment/moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";
import { useParams } from "react-router-dom";

import { GlobalContext } from "../../../../../../app/stateMachines/GlobalContext";
import Button from "../../../../../../components/button/Button";
import { Card } from "../../../../../../components/card/Card";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import PreviewFile from "../../../../../../components/modal/PreviewFile";
import NoItems from "../../../../../../components/NoItems";
import ContactFileSvg from "../../../../../../components/svg/ContactFileSvg";
import fileIcon from "../../../../../../components/svg/file.svg";
import {
  useAddWorkFileMutation,
  useDeleteWorkFileMutation,
  useGetWorkFilesQuery,
  useLazyGetPreviewWorkFileQuery,
} from "../../../../../../slices/FilesSlice";
import { InputFile } from "../../../../../contacts/components/InputFile";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoDotFill } from "react-icons/go";

export const WorkFiles: FC = () => {
  const { workId } = useParams();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { data: workFiles, isLoading } = useGetWorkFilesQuery(workId || "");
  const [getPreviewFile, { data: previewFile, isLoading: isLoadingPreview }] =
    useLazyGetPreviewWorkFileQuery();

  const [addFile, { isLoading: isLoadingAdd, isError }] =
    useAddWorkFileMutation();
  const [deleteFile] = useDeleteWorkFileMutation();

  const addFileRef = useRef<HTMLInputElement>(null);

  const [activeFileName, setActiveFileName] = useState("");

  const [error, setError] = useState("");
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("document", file);
    formData.append("workId", `${workId}`);
    formData.append("assignedUserRelayId", `${userInfoByEmail?.id}`);
    formData.append("notes", "");

    addFile(formData);
  };

  useEffect(() => {
    if (isError) {
      setError("Something went wrong");
    }
  }, [isError]);

  return (
    <>
      <Card extraClasses={"shadow-box max-h-full rounded-[6px]"}>
        <div className={"max-w-full pt-4"}>
          <div className={"w-full px-5 pb-1"}>
            <div className={"flex w-full justify-between"}>
              <div className={"text-lg font-semibold"}>Recent Files</div>
              <InputFile
                setFile={handleFileChange}
                setError={setError}
                inputRef={addFileRef}
                isLoading={isLoadingAdd}
                isWorkPage={true}
              />
            </div>
            {error && (
              <div className={"text-sm font-semibold text-red-500"}>
                {error}
              </div>
            )}
          </div>
          <div>
            {isLoading ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !workFiles?.length ? (
              <div className={"p-6"}>
                <NoItems
                  icon={<ContactFileSvg />}
                  message={"There are no files"}
                  addButtonMessage={"Add File"}
                  openAdd={() => addFileRef?.current?.click()}
                />
              </div>
            ) : (
              <div className={"max-h-[211px] overflow-y-auto "}>
                {workFiles?.map((file) => (
                  <div
                    key={file?.id}
                    className={
                      "border-b border-gray-300 pl-5 pr-1 hover:bg-gray-100"
                    }>
                    <div
                      onClick={() => {
                        getPreviewFile(file?.id);
                        setIsOpenPreview(true);
                        setActiveFileName(file?.originalFileName);
                      }}
                      className={
                        "flex cursor-pointer items-center justify-between py-4"
                      }>
                      <div
                        data-tooltip-id={file.id}
                        className={"flex items-center space-x-3 text-[14px]"}>
                        <div>
                          <div
                            className={
                              "... overflow-hidden text-ellipsis  whitespace-nowrap  text-[14px] font-[500] xl:max-w-[180px] 2xl:max-w-[220px] 3xl:max-w-[270px] 4xl:max-w-[320px]"
                            }>
                            {file?.originalFileName.split(".").slice(0, -1)}
                          </div>
                          <div
                            className={"flex gap-1 text-[12px] text-gray-400"}>
                            {moment(file?.createdDate).format("MMM DD, YYYY")}
                            <div className={"flex items-center"}>
                              <GoDotFill className={"h-1 w-1"} />
                            </div>
                            <div className={"uppercase"}>
                              {file?.originalFileName?.split(".").pop()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ReactTooltip
                        id={file.id}
                        place="bottom"
                        content={file.originalFileName}
                        className={"!rounded-[8px] !p-2 !text-sm"}
                      />
                      <div>
                        <div
                          onClick={(e) => {
                            deleteFile(file?.id);
                            e.stopPropagation();
                          }}
                          className={
                            "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                          }>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className={"text-purple mr-[-1] cursor-pointer"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenPreview && (
        <PreviewFile
          close={() => setIsOpenPreview(false)}
          file={previewFile?.documentUrl || ""}
          fileName={activeFileName}
          isLoading={isLoadingPreview}
        />
      )}
    </>
  );
};
