import React from "react"

import { SvgIconProps } from "./SvgIconProps";

export const IndividualSvg: React.FC<{height: number, width: number, className: string}> = (props) => {
  const { height, width, className } = props;

  return (
    <>
      <img width={width} height={height} className={className} src={"/assets/images/individual.png"} />
    </>
  );
};
