import classNames from "classnames";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Card } from "../../../components/card/Card";
import { HomeTab } from "../../../factories/works/models/home-enums";
import { WorkTableSort } from "../../works/components/types/work-table";

type Props = {
  thisWeek: number;
  nextWeek: number;
  later: number;
  allOpen: number;
  completed: number;
};

export const HomeStats: FC<Props> = ({
  thisWeek,
  nextWeek,
  later,
  allOpen,
  completed,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;

  const handleChangeTab = (tab: HomeTab) => {
    setSearchParams({
      "home-tab": tab,
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  };

  useEffect(() => {
    if (!activeTab) {
      handleChangeTab(HomeTab.ThisWeek);
    }
  }, [activeTab]);

  return (
    <div className={"grid grid-cols-5 gap-6"}>
      <Card
        type={activeTab === HomeTab.ThisWeek ? "filled" : "outlined"}
        color={{ borderColor: "border-purple", bgColor: "bg-purple" }}
        extraClasses={"shadow-md shadow-gray-400 cursor-pointer"}>
        <div
          className={classNames(
            "flex h-[180px] flex-col items-center justify-center gap-2",
            activeTab === HomeTab.ThisWeek && "text-white",
          )}
          onClick={() => handleChangeTab(HomeTab.ThisWeek)}>
          <div className={"text-[48px] font-bold"}>{thisWeek}</div>
          <div className={"text-lg font-semibold"}>This week</div>
        </div>
      </Card>
      <Card
        type={activeTab === HomeTab.NextWeek ? "filled" : "outlined"}
        color={{ borderColor: "border-purple", bgColor: "bg-purple" }}
        extraClasses={"shadow-md shadow-gray-400 cursor-pointer"}>
        <div
          className={classNames(
            "flex h-[180px] flex-col items-center justify-center gap-2",
            activeTab === HomeTab.NextWeek && "text-white",
          )}
          onClick={() => handleChangeTab(HomeTab.NextWeek)}>
          <div className={"text-[48px] font-bold"}>{nextWeek}</div>
          <div className={"text-lg font-semibold"}>Next week</div>
        </div>
      </Card>
      <Card
        type={activeTab === HomeTab.DueLater ? "filled" : "outlined"}
        color={{ borderColor: "border-purple", bgColor: "bg-purple" }}
        extraClasses={"shadow-md shadow-gray-400 cursor-pointer"}>
        <div
          className={classNames(
            "flex h-[180px] flex-col items-center justify-center gap-2",
            activeTab === HomeTab.DueLater && "text-white",
          )}
          onClick={() => handleChangeTab(HomeTab.DueLater)}>
          <div className={"text-[48px] font-bold"}>{later}</div>
          <div className={"text-lg font-semibold"}>Later</div>
        </div>
      </Card>
      <Card
        type={activeTab === HomeTab.AllOpen ? "filled" : "outlined"}
        color={{ borderColor: "border-purple", bgColor: "bg-purple" }}
        extraClasses={"shadow-md shadow-gray-400 cursor-pointer"}>
        <div
          className={classNames(
            "flex h-[180px] flex-col items-center justify-center gap-2",
            activeTab === HomeTab.AllOpen && "text-white",
          )}
          onClick={() => handleChangeTab(HomeTab.AllOpen)}>
          <div className={"text-[48px] font-bold"}>{allOpen}</div>
          <div className={"text-lg font-semibold"}>All open</div>
        </div>
      </Card>
      <Card
        type={activeTab === HomeTab.Completed ? "filled" : "outlined"}
        color={{ borderColor: "border-[#47DE96]", bgColor: "bg-[#47DE96]" }}
        extraClasses={"shadow-md shadow-gray-400 cursor-pointer"}>
        <div
          className={classNames(
            "flex h-[180px] flex-col items-center justify-center gap-2",
            activeTab === HomeTab.Completed && "text-white",
          )}
          onClick={() => handleChangeTab(HomeTab.Completed)}>
          <div className={"text-[48px] font-bold"}>{completed}</div>
          <div className={"text-lg font-semibold"}>Completed</div>
        </div>
      </Card>
    </div>
  );
};
