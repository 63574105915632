export enum HomeTab {
  ThisWeek = "ThisWeek",
  NextWeek = "NextWeek",
  DueLater = "DueLater",
  AllOpen = "AllOpen",
  Completed = "Completed",
}

export enum HomeFilter {
  Ready = "Ready",
  InProgress = "InProgress",
  Waiting = "Waiting",
  OverDue = "OverDue",
}
