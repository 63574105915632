import { PagesEnum } from "./types";

import { ContactsView } from "../../../features/contacts/ContactsView";
import { ActiveContacts } from "../../../features/contacts/pages/ActiveContacts";
import { ArchivedContacts } from "../../../features/contacts/pages/ArchivedContacts";

export enum ContactsPagesEnum {
  active = "active-contacts",
  archived = "archived-contacts",
}

export const ContactsPages = {
  path: PagesEnum.Contacts,
  label: "Contacts",
  component: ContactsView,
  children: {
    active: {
      path: ContactsPagesEnum.active,
      label: "Active",
      component: ActiveContacts,
    },
    archived: {
      path: ContactsPagesEnum.archived,
      label: "Archived",
      component: ArchivedContacts,
    },
  },
};
