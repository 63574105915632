import React from "react";

const AddTimeSvg = () => {
  return (
    <svg
      width={"27"}
      height={"26"}
      viewBox={"0 0 27 26"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={"M12 6V12H18"}
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        }
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path d={"M25 16L20.5 13.5L14 19.5L16.5 25L25 16Z"} fill={"#7C66F0"} />
      <path
        d={"M17.8 20H20.5M20.5 20H23.2M20.5 20V17M20.5 20V23"}
        stroke={"white"}
        strokeWidth={"1.5"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  );
};

export default AddTimeSvg;
