import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const ArrowChevronRightSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          d={
            "M7.29289 3.29289C7.68342 2.90237 8.31658 2.90237 8.70711 3.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L8.70711 20.7071C8.31658 21.0976 7.68342 21.0976 7.29289 20.7071C6.90237 20.3166 6.90237 19.6834 7.29289 19.2929L14.5858 12L7.29289 4.70711C6.90237 4.31658 6.90237 3.68342 7.29289 3.29289Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"0.5"}
        />
      </svg>
    </>
  );
};
