import React from "react";

type Props = {
  ButtonComponent?: JSX.Element;
  ListComponent?: JSX.Element;
  extraCx?: string;
};

export const Dropdown: React.FC<Props> = ({
  ButtonComponent,
  ListComponent,
  extraCx = "",
}) => {
  return (
    <div className={"dropdown-end dropdown"}>
      <div tabIndex={0}>{ButtonComponent}</div>
      <ul
        tabIndex={0}
        className={`dropdown-content menu rounded-box z-30 my-2 w-52 border-4 bg-base-100 p-2 shadow ${extraCx}`}>
        <>{ListComponent}</>
      </ul>
    </div>
  );
};
