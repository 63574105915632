import React from "react";

import { IconButton } from "../../components/button/IconButton";
import { WarningExclaimSvg } from "../../components/svg/WarningExclaimSvg";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const TooltipsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Tooltips"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"tooltip"} data-tip={"hello"}>
          <button className={"btn"}>Hover me</button>
        </div>

        <div
          className={"tooltip tooltip-warning"}
          data-tip={"Warning caution danger!"}>
          <IconButton
            colorType={"secondary"}
            border={false}
            IconSvg={WarningExclaimSvg}>
            Hover me
          </IconButton>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
