import React, { useEffect, useState } from "react";
import {
  useAddTaxRateMutation,
  useDeleteTaxRateMutation,
  useGetTaxRateQuery,
  useUpdateTaxRateMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import Button from "../../../components/button/Button";

const TaxRate = () => {
  const [tax, setTax] = useState<number | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errorName, setErrorName] = useState("");

  const { data, isLoading } = useGetTaxRateQuery(getLinkedFirm()?.orgId);
  const [addTaxRate, { isSuccess: isSuccessAdd }] = useAddTaxRateMutation();
  const [updateTaxRate, { isSuccess: isSuccessUpdate }] =
    useUpdateTaxRateMutation();
  const [deleteTaxRate, {}] = useDeleteTaxRateMutation();

  useEffect(() => {
    if (data && data?.length > 0) {
      setTax(data?.[0]?.taxRate || 0);
      setName(data?.[0]?.taxName || "");
    }
  }, [data]);

  const onClickSave = () => {
    if (!name) {
      setErrorName("This field cannot be empty");
      return;
    }
    if (data && data?.length === 0) {
      addTaxRate({
        taxRate: tax || 0,
        taxName: name || "",
        orgId: getLinkedFirm()?.orgId,
      });
    }
    if (data && data?.length > 0) {
      updateTaxRate({ ...data[0], taxRate: tax || 0, taxName: name || "" });
    }
  };

  const deleteTax = () => {
    if (data && data?.length > 0) {
      deleteTaxRate(data[0]);
    }
  };

  useEffect(() => {
    if (isSuccessAdd || isSuccessUpdate) {
      setIsEdit(false);
    }
  }, [isSuccessAdd, isSuccessUpdate]);

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <div className={"text-sm font-semibold text-gray-800"}>
      <div className={"mb-4 flex items-center gap-4"}>
        <div>Default Tax Rate</div>
        {isEdit ? (
          <div>
            <input
              type={"text"}
              className={"input input-bordered w-[100px]"}
              value={tax || 0}
              onChange={(e) => {
                const value = /^[0-9]*$/.test(e.target.value)
                  ? Number(e.target.value)
                  : tax;
                setTax(value);
              }}
            />
            <span className={"ml-1"}>%</span>
          </div>
        ) : (
          <div>{tax !== null && tax + "%"}</div>
        )}
      </div>
      <div className={"flex items-center gap-4"}>
        <div>Tax name</div>
        {isEdit ? (
          <>
            <input
              type={"text"}
              className={"input input-bordered w-[150px]"}
              value={name || ""}
              onChange={(e) => {
                setName(e.target.value);
                setErrorName("");
              }}
            />
            {errorName && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errorName}
              </div>
            )}
          </>
        ) : (
          <div>{name}</div>
        )}
      </div>
      <div className={"mt-4 flex gap-4"}>
        {isEdit ? (
          <>
            <Button
              label={"Cancel"}
              onClick={() => setIsEdit(false)}
              extraClasses={"normal-case"}
              colorType={"ghost"}
            />
            <Button
              label={"Save"}
              onClick={onClickSave}
              extraClasses={"normal-case"}
            />
          </>
        ) : (
          <Button
            label={"Edit"}
            onClick={() => setIsEdit(true)}
            extraClasses={"normal-case"}
          />
        )}
      </div>
    </div>
  );
};

export default TaxRate;
