import React from "react";

const ContactWorkSvg = () => {
  return (
    <svg
      width={"35"}
      height={"35"}
      viewBox={"0 0 35 35"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M11.6677 10.2083H5.83439C5.06084 10.2083 4.31898 10.5156 3.772 11.0626C3.22502 11.6096 2.91772 12.3515 2.91772 13.125V27.7083C2.91772 28.4819 3.22502 29.2237 3.772 29.7707C4.31898 30.3177 5.06084 30.625 5.83439 30.625H29.1677C29.9413 30.625 30.6831 30.3177 31.2301 29.7707C31.7771 29.2237 32.0844 28.4819 32.0844 27.7083V13.125C32.0844 12.3515 31.7771 11.6096 31.2301 11.0626C30.6831 10.5156 29.9413 10.2083 29.1677 10.2083H23.3344M11.6677 10.2083V5.25C11.6677 5.01794 11.7599 4.79538 11.924 4.63128C12.0881 4.46719 12.3107 4.375 12.5427 4.375H22.4594C22.6915 4.375 22.914 4.46719 23.0781 4.63128C23.2422 4.79538 23.3344 5.01794 23.3344 5.25V10.2083M11.6677 10.2083H23.3344"
        }
        stroke={"#7C66F0"}
        strokeWidth={"2.1875"}
      />
    </svg>
  );
};

export default ContactWorkSvg;
