import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const TabsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Tabs"}>
      <ComponentSectionLayout title={"Bordered"}>
        <div className={"tabs"}>
          <a className={"tab tab-bordered"}>Tab 1</a>
          <a className={"tab tab-active tab-bordered"}>Tab 2</a>
          <a className={"tab tab-bordered"}>Tab 3</a>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Lifted"}>
        <div className={"tabs"}>
          <a className={"tab tab-lifted"}>Tab 1</a>
          <a className={"tab tab-active tab-lifted"}>Tab 2</a>
          <a className={"tab tab-lifted"}>Tab 3</a>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Boxed"}>
        <div className={"tabs tabs-boxed"}>
          <a className={"tab"}>Tab 1</a>
          <a className={"tab tab-active"}>Tab 2</a>
          <a className={"tab"}>Tab 3</a>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Sizes"}>
        <div className={"tabs"}>
          <a className={"tab tab-lifted tab-xs"}>Tiny</a>
          <a className={"tab tab-active tab-lifted tab-xs"}>Tiny</a>
          <a className={"tab tab-lifted tab-xs"}>Tiny</a>
        </div>
        <div className={"tabs"}>
          <a className={"tab tab-lifted tab-sm"}>Small</a>
          <a className={"tab tab-active tab-lifted tab-sm"}>Small</a>
          <a className={"tab tab-lifted tab-sm"}>Small</a>
        </div>
        <div className={"tabs"}>
          <a className={"tab tab-lifted"}>Normal</a>
          <a className={"tab tab-active tab-lifted"}>Normal</a>
          <a className={"tab tab-lifted"}>Normal</a>
        </div>
        <div className={"tabs"}>
          <a className={"tab tab-lifted tab-lg"}>Large</a>
          <a className={"tab tab-active tab-lifted tab-lg"}>Large</a>
          <a className={"tab tab-lifted tab-lg"}>Large</a>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
