import React from "react";

import {
  ContactInfoPanelProps,
  ContactInfoPanelView,
} from "./ContactInfoPanelView";
import {
  CustomerInfoPanelProps,
  CustomerInfoPanelView,
} from "./CustomerInfoPanelView";

type Props = {
  contact?: ContactInfoPanelProps | null;
  customer?: CustomerInfoPanelProps | null;
};

export const InfoPanelsView: React.FC<Props> = ({
  contact,
  customer,
  children,
}) => {
  return (
    <div
      className={
        "card card-body card-bordered my-6 border-base-300 bg-base-100 py-6"
      }>
      <div className={"grid grid-cols-1 xl:grid-cols-3"}>
        <div className={"col-span-1 px-4 py-2 xl:border-r-2 xl:py-0"}>
          <div className={"grid grid-cols-1 gap-4"}>
            {customer && <CustomerInfoPanelView customer={customer} />}
          </div>
        </div>
        <div className={"col-span-1 px-4 py-2 xl:border-r-2 xl:py-0"}>
          <div className={"grid grid-cols-1 gap-4"}>
            {contact && <ContactInfoPanelView contact={contact} />}
          </div>
        </div>
        <div className={"col-span-1 px-4 py-2 xl:py-0"}>
          <div className={"grid grid-cols-1 gap-4"}>
            {/*{service && <ServiceInfoPanelView service={service} />}*/}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
