import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const DrawersDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Wip - Drawers"}>
      <ComponentSectionLayout title={"default"} />
    </ComponentLayout>
  );
};
