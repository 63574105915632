import { FC, useEffect } from "react";
import { useGetUnreadNotificationsQuery } from "../../slices/UserSlice";
import { useAppSelector } from "../../redux/redux";
import { Notifications } from "./Notifications";
import { NotificationsFilter } from "./filter/NotificationsFilter";
import { NotificationTitleValue } from "../../factories/notifications/notification-title.enum";
import { useSearchParams } from "react-router-dom";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";

type Props = {};

export const UnreadList: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state) => state.appReducer);

  const { data: notifications, isLoading: isLoading } =
    useGetUnreadNotificationsQuery({
      userId: user?.id,
      orgId: getLinkedFirm().orgId,
      notificationType:
        searchParams.get("filter") || NotificationTitleValue.All,
    });

  useEffect(() => {
    setSearchParams({ filter: NotificationTitleValue.All });
  }, []);

  return (
    <div className={"grid w-full grid-cols-[max-content_1fr] gap-4"}>
      <NotificationsFilter />
      <Notifications
        notifications={notifications || []}
        isLoading={isLoading}
        notificationType={"unread"}
      />
    </div>
  );
};
