export interface ILinkedFirm {
  userId: string;
  orgId: string;
  user: null;
  org: null;
  id: string;
  relayId: null;
  createdAt: string;
}

export const setLinkedFirm = (linkedFirm: ILinkedFirm) => {
  sessionStorage.setItem("linked_firm", JSON.stringify(linkedFirm));
};
export const deleteLinkedFirm = () => {
  sessionStorage.removeItem("linked_firm");
};
export const getLinkedFirm = () => {
  const linkedFirm = sessionStorage.getItem("linked_firm");
  return linkedFirm ? JSON.parse(linkedFirm) : null;
};
