import { assign, createMachine } from "xstate";

import { GetOnboardingStatus } from "../../generated/operation-result-types";

type OnboardingInfoContext = {
  onboardingStatus?: GetOnboardingStatus;
};

// type EventT = { type: "foo"; onboardingStatus?: GetOnboardingStatus };

export const OnboardingInfoMachine = createMachine<OnboardingInfoContext>({
  id: "onboardingInfo",
  context: {
    onboardingStatus: undefined,
  },
  on: {
    updateOnboardingStatus: {
      actions: [
        assign((ctx, event) => {
          return {
            ...ctx,
            onboardingStatus: event.onboardingStatus,
          };
        }),
      ],
    },
  },
});
