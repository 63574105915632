import { gql } from "@apollo/client";

export const GET_BANK_DOCS_BY_LOCATION_GQL = gql`
  query GetBankDocsByLocation(
    $locationId: ID!
    $where: BankDocFilterInput
    $first: Int
    $after: String
    $order: [BankDocSortInput!]
  ) {
    bankDocsByLocation(
      locationId: $locationId
      where: $where
      first: $first
      after: $after
      order: $order
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          createdAt
          deletedAt

          bankDocStatusEnum {
            id
            statusType
          }
          metaBankName
          metaPageCount
          customer {
            id
            name
          }
          uploadedByUser {
            id
            userProfile {
              id
              fullName
              avatarInitials
            }
          }
        }
      }
    }
  }
`;

export const GET_BANK_DOC_BY_ID_GQL = gql`
  query GetBankDocById($bankDocId: ID!) {
    bankDocById(id: $bankDocId) {
      id
      name
      createdAt
      deletedAt
      blobUri
      isCreditCard
      resultTokenId

      bankDocStatusEnum {
        id
        statusType
      }
      customer {
        id
        name
        customerTaxIds {
          id
          taxIdType
          taxIdValue
        }
        contact {
          id
          fullName
          avatarInitials
          email {
            id
            emailAddress
          }
          phoneNumbers {
            id
            number
            phoneNumberTypeEnum {
              id
              phoneNumberType
            }
          }
        }
      }
      uploadedByUser {
        id
        userProfile {
          fullName
          avatarInitials
        }
      }
    }
  }
`;

export const UPDATE_BANK_DOC_GQL = gql`
  mutation UpdateBankDocOutput(
    $updateBankDocOutputInput: UpdateBankDocOutputInput!
  ) {
    updateBankDocOutput(input: $updateBankDocOutputInput) {
      bankDocOutput {
        id
      }
    }
  }
`;

export const GET_BANK_DOC_OUTPUT_BY_ID_GQL = gql`
  query GetBankDocOutputById($bankDocOutputId: ID!, $locationId: ID!) {
    bankDocOutputById(id: $bankDocOutputId, locationId: $locationId) {
      id
      orgId
      customerId
      bankDocOutputBalances {
        startingBalance
        endingBalance
      }
      bankDocOutputTable {
        bankDocOutputRows {
          id
          isSelected
          rowDate
          description
          memo
          bankDocOutputAmount {
            value
            typeEnum
          }
        }
      }
    }
  }
`;

export const UPDATE_BANK_DOC_STATE_GQL = gql`
  mutation UpdateBankDocState(
    $updateBankDocStateInput: UpdateBankDocStateInput!
  ) {
    updateBankDocState(input: $updateBankDocStateInput) {
      bankDoc {
        id
      }
    }
  }
`;

export const UPDATE_BANK_DOC_DETAILS_GQL = gql`
  mutation UpdateBankDocDetails(
    $updateBankDocDetailsInput: UpdateBankDocDetailsInput!
  ) {
    updateBankDocDetails(input: $updateBankDocDetailsInput) {
      bankDoc {
        id
      }
    }
  }
`;

export const GET_SEARCH_BANK_NAME_FID_GQL = gql`
  query GetSearchBankNameFid($searchTerm: String!) {
    searchBankNameFid(searchName: $searchTerm) {
      fid
      name
      urlHost
    }
  }
`;
