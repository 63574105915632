import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { HiEllipsisVertical } from "react-icons/hi2";

import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { DeleteEmailModal } from "./modals/DeleteEmail";
import { Email } from "../../factories/emails/models/emails.models";
import { Card } from "../../components/card/Card";
import moment from "moment";
import { getInitialsFromFullName } from "../../constants/constants";
import {
  useLazyDownloadAttachmentsQuery,
  useStarredEmailMutation,
} from "../../slices/EmailsSlice";
import { useAppSelector } from "../../redux/redux";
import Button from "../../components/button/Button";
import { Dropdown } from "../../components/dropdown/new-dropdown/Dropdown";
import { MdLocalPrintshop } from "react-icons/md";
import { FaFile, FaLongArrowAltRight, FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { ComposeEmailModal } from "../contacts/modals/ComposeEmailModal";
import { navigateToContact } from "../contacts/utils/navigateToContacts";
import { useNavigate } from "react-router-dom";
import { ForwardEmailModal } from "../contacts/modals/ForwardEmailModal";
import { useGetCustomersByEmailQuery } from "../../slices/ContactsSlice";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import PreviewFile from "../../components/modal/PreviewFile";

type Props = {
  email: Email;
  setSelectedMail?: Dispatch<SetStateAction<Email | null>>;
  selectedMail: Email | null;
  emails?: Email[];
  work?: boolean;
};

export const EmailDetails: FC<Props> = ({
  email,
  setSelectedMail,
  selectedMail,
}) => {
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenReply, setIsOpenReply] = useState(false);
  const [isOpenReplyAll, setIsOpenReplyAll] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [activeFileName, setActiveFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const [updateEmail, { data, isLoading: isLoadingUpdate }] =
    useStarredEmailMutation();
  const { data: emailsNames } = useGetCustomersByEmailQuery({
    email: email.from?.[0]?.email,
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [getFile, { data: currentAttachment }] =
    useLazyDownloadAttachmentsQuery();

  const { user } = useAppSelector((state) => state.appReducer);

  useEffect(() => {
    const fetchFile = async () => {
      if (!currentAttachment) {
        return;
      }

      const response = await fetch(currentAttachment, {
        headers: {
          Authorization:
            "Bearer nyk_v0_sJt41qUxIjfY2Bk9NOJNzZZb0Q7DUlC922COXwDeOTG7nYDuDEC2Hzrz1TpB6FKt",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setFileUrl(url);
    };
    fetchFile();
  }, [currentAttachment]);

  const starredEmail = () => {
    if (!isLoadingUpdate) {
      updateEmail({
        messageId: email.id,
        userId: user.id,
        body: {
          starred: !email.starred,
          unread: email.unread,
          folders: email.folders,
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      setSelectedMail && setSelectedMail(data.data);
    }
  }, [data]);

  const printContentRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    if (!printContentRef.current) return;

    const content = printContentRef.current.innerHTML;
    const originalBody = document.body.innerHTML;

    document.body.innerHTML = content;
    window.print();
    document.body.innerHTML = originalBody;
  };
  return (
    <>
      <Card extraClasses={"h-full"}>
        <div
          className={
            "grid h-full grid-rows-[max-content_max-content_1fr] py-3"
          }>
          <div
            className={
              "flex w-full items-center justify-between border-b border-gray-300 px-8 pb-2"
            }>
            <span
              className={
                "!font-notoSans text-base font-semibold text-gray-800"
              }>
              {email?.subject}
            </span>
            <div className={"flex items-center gap-4"}>
              <div
                className={
                  "flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] bg-transparent text-sm font-semibold hover:bg-gray-200"
                }
                onClick={starredEmail}>
                {email.starred ? (
                  <FaStar fontSize={"18px"} className={"text-yellow-400"} />
                ) : (
                  <CiStar fontSize={"18px"} className={"text-purple"} />
                )}
              </div>
              <div
                className={
                  "text-purple cursor-pointer !font-notoSans text-sm font-semibold hover:underline"
                }
                onClick={() => setIsOpenReply(true)}>
                Reply
              </div>
              <div
                className={
                  "text-purple cursor-pointer !font-notoSans text-sm font-semibold hover:underline"
                }
                onClick={() => setIsOpenReplyAll(true)}>
                Reply all
              </div>
              <div
                onClick={() => setIsOpenDelete(true)}
                className={
                  "text-purple cursor-pointer !font-notoSans text-sm font-semibold hover:underline"
                }>
                Delete
              </div>
              <div>
                <Dropdown
                  triggerElement={
                    <Button
                      label={""}
                      colorType={"ghost"}
                      size={"custom"}
                      icon={<HiEllipsisVertical size={22} />}
                      extraClasses={"min-h-fit h-fit w-fit p-1 !font-notoSans"}
                    />
                  }>
                  <div
                    className={
                      " rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                    }>
                    <div
                      className={
                        "flex w-fit cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] text-center !font-notoSans hover:bg-gray-300"
                      }
                      onClick={() => setIsForward(true)}>
                      <FaLongArrowAltRight />
                      Forward
                    </div>
                    <div
                      className={
                        "flex w-full cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] text-center !font-notoSans hover:bg-gray-300"
                      }
                      onClick={handlePrint}>
                      <MdLocalPrintshop />
                      Print
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            className={
              "flex w-full items-center justify-between border-b border-gray-300 px-8 py-2"
            }>
            <div className={"items-center gap-4"}>
              <div className={"grid w-full grid-rows-2 gap-1"}>
                <div className={"flex w-full items-center text-sm"}>
                  <span className={"mr-1"}>
                    <AvatarPlaceholder
                      size={"super-small"}
                      label={
                        email?.from?.[0]?.name
                          ? getInitialsFromFullName(
                              email?.from?.[0]?.name || "",
                            )
                          : getInitialsFromFullName(
                              `${user.userProfile.firstName}  ${user.userProfile.lastName}` ||
                                "",
                            )
                      }
                    />
                  </span>
                  {!email?.from?.[0]?.name ? (
                    <span
                      className={
                        "border-gray-800 !font-notoSans text-base font-bold"
                      }>
                      {user.userProfile.firstName} {user.userProfile.lastName}
                    </span>
                  ) : emailsNames?.length === 1 ? (
                    <span
                      className={
                        "cursor-pointer border-gray-800 text-lg font-bold hover:border-b"
                      }
                      onClick={() =>
                        navigate(
                          navigateToContact({
                            type: emailsNames?.[0]?.contactId
                              ? "contact"
                              : "organization",
                            customerId: emailsNames?.[0]?.id || "",
                            id: emailsNames?.[0]?.businessContactId || "",
                          }),
                        )
                      }>
                      {email?.from?.[0]?.name}
                    </span>
                  ) : (
                    <div>
                      <Dropdown
                        triggerElement={
                          <span
                            className={
                              "cursor-pointer border-gray-800 !font-notoSans text-base font-bold hover:border-b"
                            }>
                            {email?.from?.[0]?.name}
                          </span>
                        }>
                        {emailsNames && emailsNames?.length !== 0 ? (
                          <div
                            className={
                              "max-h-[300px] overflow-y-auto rounded-[5px] border border-gray-300 bg-white font-semibold shadow-box"
                            }>
                            {emailsNames?.map((customer) => (
                              <div
                                className={
                                  "flex w-full cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                                }
                                key={customer.id}
                                onClick={() =>
                                  navigate(
                                    navigateToContact({
                                      type: customer.contactId
                                        ? "contact"
                                        : "organization",
                                      customerId: customer?.id || "",
                                      id: customer?.businessContactId || "",
                                    }),
                                  )
                                }>
                                {customer.name}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div
                            className={
                              "mt-[50px] rounded-[5px] border border-gray-300 bg-white px-[15px] py-[5px] font-bold shadow-box"
                            }>
                            This user does not exist
                          </div>
                        )}
                      </Dropdown>
                    </div>
                  )}

                  <span className={"text-sm text-gray-400"}>
                    &lt;{email?.from?.[0]?.email}&gt;
                  </span>
                </div>
                <div className={"flex-col items-center gap-1 text-sm"}>
                  {email?.to?.[0] && (
                    <div className={"flex gap-2"}>
                      <span className={"!font-notoSans text-gray-400"}>
                        to:
                      </span>
                      <div
                        className={
                          "max-w-[200px] truncate !font-notoSans text-gray-500"
                        }>
                        {email &&
                          email?.to
                            ?.map((item) => item?.email)
                            .splice(0, 2)
                            .join(", ")}
                      </div>
                      {email?.to.length > 2 && (
                        <span
                          className={
                            "text-purple cursor-pointer !font-notoSans font-semibold hover:underline"
                          }>
                          +{email?.to.length - 2} more
                        </span>
                      )}
                    </div>
                  )}
                  {email.cc?.[0] && (
                    <div className={"flex gap-2"}>
                      <span className={"!font-notoSans text-gray-400"}>
                        cc:
                      </span>
                      <div
                        className={
                          "max-w-[200px] truncate !font-notoSans text-gray-500"
                        }>
                        {email &&
                          email?.cc
                            ?.map((item) => item?.email)
                            .splice(0, 2)
                            .join(", ")}
                      </div>
                      {email?.cc.length > 2 && (
                        <span
                          className={
                            "text-purple cursor-pointer !font-notoSans font-semibold hover:underline"
                          }>
                          +{email?.cc.length - 2} more
                        </span>
                      )}
                    </div>
                  )}
                  {email?.bcc?.[0] && (
                    <div className={"flex gap-2"}>
                      <span className={"!font-notoSans text-gray-400"}>
                        bcc:
                      </span>
                      <div
                        className={
                          "max-w-[200px] truncate !font-notoSans text-gray-500"
                        }>
                        {email &&
                          email?.bcc
                            ?.map((item) => item?.email)
                            .splice(0, 2)
                            .join(", ")}
                      </div>
                      {email?.bcc.length > 2 && (
                        <span
                          className={
                            "text-purple cursor-pointer !font-notoSans font-semibold hover:underline"
                          }>
                          +{email?.bcc.length - 2} more
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <span
              className={
                "flex items-center !font-notoSans text-sm font-normal text-gray-300"
              }>
              {moment.unix(email?.date).format("MMM DD, YYYY hh:mm a")}
            </span>
          </div>
          <div className={"overflow-y-auto px-8"} ref={printContentRef}>
            <div
              dangerouslySetInnerHTML={{
                __html: email?.body,
              }}
              className={"w-full p-6 text-sm font-semibold"}
            />
          </div>
          <div className={"flex flex-wrap gap-2 px-4 pt-2"}>
            {email?.attachments?.map((attachment) => (
              <div
                key={attachment.content_id}
                className={
                  "flex cursor-pointer items-center justify-between gap-2 rounded-[10px] border border-[#7c66f0] px-2 py-1 text-base"
                }
                onClick={() => {
                  getFile({
                    messageId: email.id,
                    attachmentId: attachment.id || "",
                    userId: user.id || "",
                  });
                  setIsOpenPreview(true);
                  setActiveFileName(attachment.filename);
                }}>
                <FaFile color={"#7c66f0"} />
                <div className={"font-semibold"}>{attachment.filename}</div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      {isForward && (
        <ForwardEmailModal
          isActive={isForward}
          email={email}
          onClose={() => setIsForward(false)}
        />
      )}
      {isOpenDelete && (
        <DeleteEmailModal
          setSelectedMail={setSelectedMail}
          isActive={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          emailId={email.id}
        />
      )}
      {isOpenReply && (
        <ComposeEmailModal
          isActive={isOpenReply}
          onClose={() => setIsOpenReply(false)}
          isReply={true}
          email={email}
          selectedMail={selectedMail}
        />
      )}
      {isOpenReplyAll && (
        <ComposeEmailModal
          isActive={isOpenReplyAll}
          onClose={() => setIsOpenReplyAll(false)}
          isReplyAll={true}
          email={email}
          selectedMail={selectedMail}
        />
      )}

      {isOpenPreview && (
        <PreviewFile
          close={() => setIsOpenPreview(false)}
          file={fileUrl || ""}
          fileName={activeFileName}
        />
      )}
    </>
  );
};
