import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment/moment";
import React, { FC, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../../../../constants/constants";
import {
  Invoice,
  SortEnum,
  useGetInvoicesQuery,
  useGetInvoiceStatusesQuery,
  useUpdateInvoiceMutation,
} from "../../../../../../services/BillingService";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import DropStatus from "../../../../../billing/DropStatus";
import { navigateToContact } from "../../../../utils/navigateToContacts";

interface Props {
  customerId: string;
}

const OrganizationInvoices: FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<{ name: SortEnum; order: boolean }>({
    name: SortEnum.InvoiceDate,
    order: false,
  });
  const [activeInvoiceId, setActiveInvoiceId] = useState("");

  const [updateInvoice, { isLoading: isLoadingUpdate }] =
    useUpdateInvoiceMutation();

  const {
    data: invoices,
    isLoading,
    isFetching,
    refetch,
  } = useGetInvoicesQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: [],
    sortBy: sort.name,
    sortOrder: sort.order ? "asc" : "desc",
    filterByStatus: [],
    filterByClient: [customerId],
    filterByAssignee: [],
    filterByLabel: [],
  });

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetInvoiceStatusesQuery(getLinkedFirm()?.orgId || "");

  const handleSort = (name: SortEnum) => {
    if (name === sort.name) {
      setSort({ name, order: !sort.order });
    } else {
      setSort({ name, order: true });
    }
  };

  const updateStatus = (statusId: string, invoice: Invoice) => {
    updateInvoice({
      id: invoice?.id || "",
      invoiceStatusId: statusId || null,
      customerId: invoice?.customerId,
      invoiceClientsIds: invoice?.invoiceClientsIds,
      createdByUserId: invoice?.createdByUserId,
      invoiceDate: invoice?.invoiceDate,
      termId: invoice?.termId,
      dueDate: invoice?.dueDate,
      invoiceNo: invoice?.invoiceNo,
      tax: invoice?.tax,
      orgId: invoice?.orgId,
      total: invoice?.total,
      discount: invoice?.discount,
      balance: invoice?.balance,
      discountTypeId: invoice?.discountTypeId,
      paymentRecieved: invoice?.paymentRecieved,
      assignedToUserId: invoice?.assignedToUserId,
      invoiceItem: invoice?.invoiceItem,
    });
  };

  useEffect(() => {
    refetch();
  }, [sort]);

  if (isLoading || isLoadingStatuses) {
    return <LottieLoading />;
  }

  const rows = invoices?.map((invoice) => {
    return (
      <tr
        key={invoice?.id}
        className={
          "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
        }
        onClick={() => navigate("/billing/invoices/" + invoice.id)}>
        <td className={"bg-transparent px-[16px] py-[8px]"}>
          <div className={"flex items-center"}>
            <div className={"flex items-center text-[14px]"}>
              <AvatarPlaceholder
                size={"extra-small"}
                type={invoice?.customer?.businessContactId ? "purple" : "blue"}
                label={getInitialsFromFullName(invoice?.customer?.name || "")}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    navigateToContact({
                      type: invoice?.customer?.businessContactId
                        ? "organization"
                        : "contact",
                      customerId: invoice?.customer?.id || "",
                      id: invoice?.customer?.businessContactId || "",
                    }),
                  );
                }}
                className={
                  "link-primary cursor-pointer pl-2 text-sm font-semibold hover:underline"
                }>
                {invoice?.customer?.name}
              </div>
            </div>
          </div>
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {invoice?.invoiceNo}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {moment(invoice?.invoiceDate).format("MM/DD/YYYY")}
        </td>
        <td
          className={`bg-transparent px-4 py-2 text-sm font-semibold ${
            invoice?.status === "OverDue" ? "text-red-500" : "text-gray-800"
          }`}>
          {moment(invoice?.dueDate).format("MM/DD/YYYY")}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(invoice?.total || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(invoice?.balance || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <div className={"flex items-center gap-2"}>
            {invoice?.status === "Paid" && (
              <FaCheckCircle className={"h-4 w-4 text-green-500"} />
            )}
            {invoice?.status}
          </div>
        </td>
        <td className={"relative bg-transparent px-4 py-2"}>
          {invoice?.assignedToUser && (
            <div className={"flex items-center text-sm"}>
              <AvatarPlaceholder
                size={"extra-small"}
                label={getInitials(
                  invoice?.assignedToUser?.userProfile?.firstName || "",
                  invoice?.assignedToUser?.userProfile?.lastName || "",
                )}
              />
              <div
                className={
                  "whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
                }>
                {invoice?.assignedToUser &&
                  `${invoice?.assignedToUser?.userProfile?.firstName} ${invoice?.assignedToUser?.userProfile?.lastName}`}
              </div>
            </div>
          )}
        </td>
        <td className={"bg-transparent px-4 py-2"}>
          <DropStatus
            invoiceStatus={invoice?.invoiceStatus}
            paymentStatus={null}
            statuses={statuses || []}
            isLoading={
              (isLoadingUpdate || isFetching) && invoice?.id === activeInvoiceId
            }
            selectStatus={(status) => {
              setActiveInvoiceId(invoice?.id);
              updateStatus(status?.id || "", invoice);
            }}
          />
        </td>
      </tr>
    );
  });

  return (
    <table className={"w-full"}>
      <thead className={"border-b-[1px] border-gray-300"}>
        <tr>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Customer)}>
              <div>Contact</div>
              {sort?.name === SortEnum.Customer && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.InvoiceNo)}>
              <div>Invoice No.</div>
              {sort?.name === SortEnum.InvoiceNo && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.InvoiceDate)}>
              <div>Invoice date</div>
              {sort?.name === SortEnum.InvoiceDate && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.DueDate)}>
              <div>Due date</div>
              {sort?.name === SortEnum.DueDate && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Total)}>
              <div>Total</div>
              {sort?.name === SortEnum.Total && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Balance)}>
              <div>Balance</div>
              {sort?.name === SortEnum.Balance && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.Status)}>
              <div>Status</div>
              {sort?.name === SortEnum.Status && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.AssignedUser)}>
              <div>Assignee</div>
              {sort?.name === SortEnum.AssignedUser && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
          <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
            <div
              className={
                "flex cursor-pointer whitespace-nowrap text-sm normal-case"
              }
              onClick={() => handleSort(SortEnum.InvoiceStatus)}>
              <div>Label</div>
              {sort?.name === SortEnum.InvoiceStatus && (
                <div className={"relative left-0 top-[-4px]"}>
                  {sort?.order ? (
                    <div className={"absolute"}>
                      <ChevronUp size={26} />
                    </div>
                  ) : (
                    <div className={"absolute"}>
                      <ChevronDown size={26} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody className={"text-sm"}>{rows}</tbody>
    </table>
  );
};

export default OrganizationInvoices;
