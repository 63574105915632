import React from "react";

import { SuccessCheckSvg } from "../svg/SuccessCheckSvg";

export type PricingTiersT = {
  name: string;
  action: JSX.Element;
  primaryAmount: string;
  primaryUnit: string;
  primaryDuration?: string | null;
  secondaryAmount?: string;
  secondaryUnit?: string;
  items: string[];
  flairText?: string | null;
};

type Props = {
  tiers: PricingTiersT[];
};

export const PricingColumns: React.FC<Props> = (props) => {
  const { tiers } = props;

  return (
    <section className={"body-font overflow-hidden text-gray-600"}>
      <div className={"container mx-auto px-5"}>
        <div className={"-m-4 flex flex-wrap justify-center"}>
          {tiers.map(
            ({
              items,
              name,
              primaryAmount,
              primaryUnit,
              primaryDuration,
              secondaryAmount,
              secondaryUnit,
              action,
              flairText,
            }) => (
              <div key={name} className={"w-full p-4 md:w-1/2 xl:w-1/3"}>
                <div
                  className={
                    "relative flex h-full flex-col justify-between overflow-hidden rounded-lg border-2 border-gray-300 p-6"
                  }>
                  {flairText && (
                    <span
                      className={
                        "absolute right-0 top-0 rounded-bl bg-accent px-3 py-1 text-xs tracking-widest text-white"
                      }>
                      {flairText}
                    </span>
                  )}
                  <div>
                    <div
                      className={
                        "title-font mb-1 text-sm font-medium uppercase tracking-widest"
                      }>
                      {name}
                    </div>
                    <div
                      className={
                        "mb-2 flex flex-col justify-between space-x-2 border-b border-gray-200 pb-4 lg:flex-row"
                      }>
                      <div className={"mb-4"}>
                        <div
                          className={
                            "text-2xl capitalize leading-none text-gray-900"
                          }>
                          {primaryAmount}
                        </div>
                        <div className={"text-sm capitalize opacity-60"}>
                          {primaryUnit}
                        </div>
                        <div className={"text-sm capitalize opacity-60"}>
                          {primaryDuration ?? ""}
                        </div>
                      </div>

                      {/* optional secondary */}
                      {secondaryAmount && secondaryUnit && (
                        <>
                          <div className={"border"}></div>
                          <div>
                            <div
                              className={
                                "text-2xl capitalize leading-none text-gray-900"
                              }>
                              + {secondaryAmount}
                            </div>
                            <div className={"text-sm capitalize opacity-60"}>
                              {secondaryUnit}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {items.map((featureName) => (
                      <div
                        key={featureName}
                        className={"mb-2 flex items-center text-gray-600"}>
                        <div className={"text-success"}>
                          <SuccessCheckSvg />
                        </div>
                        {featureName}
                      </div>
                    ))}
                  </div>

                  <div
                    className={
                      "mx-auto my-4 flex-col items-center justify-center"
                    }>
                    {action}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </section>
  );
};
