import { useMutation } from "@apollo/client";
import * as Xstate from "@xstate/react";
import { DatePicker } from "baseui/datepicker";
import _ from "lodash";
import React from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useTilg from "tilg";
import { useEffectOnce } from "usehooks-ts";
import { v4 as uuidV4 } from "uuid";

import {
  DATE_FORMATS,
  DATES,
  getLocalFromUtc,
  getLocalFromUtcs,
  getUtcFromLocal,
  isDateInFuture,
  NOW_UTC,
} from "../../../app/commonOps/CommonDateOps";
import {
  getRounded,
  validateDecimalPositive,
} from "../../../app/commonOps/CommonNumberOps";
import { isEmptyStr } from "../../../app/commonOps/CommonStringOps";
import { getFirstAlert, MultiAlertT } from "../../../app/commonOps/ErrorOps";
import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import {
  ExtractedTotalsDataT,
  PdfTotalsDataT,
} from "../../../app/stateMachines/BankDocDetailsMachine";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import { IconButton } from "../../../components/button/IconButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { PdfViewerIframe } from "../../../components/pdfViewer/PdfViewerIframe";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import { ErrorSvg } from "../../../components/svg/ErrorSvg";
import { WarningExclaimSvg } from "../../../components/svg/WarningExclaimSvg";
import {
  UpdateBankDocOutput,
  UpdateBankDocOutputVariables,
} from "../../../generated/operation-result-types";
import { UPDATE_BANK_DOC_GQL } from "../../../queries/BankDocQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { BankDocOutputReviewInfoPanelsView } from "./BankDocOutputReviewInfoPanelsView";
import {
  BankDocOutputDataRowT,
  BankDocOutputDataT,
} from "./BankDocOutputTypes";
import { BankDocStatics } from "./BankDocStatics";

export type Props = {
  blobUri: string;
  bankDocOutputId: string;
  locationId: string;
  bankDocOutputData: BankDocOutputDataT;
  isCreditCard: boolean;
  downloadFileName: string;
  forceRefetch: () => void;
};

export const BankDocOutputReviewView: React.FC<Props> = (props) => {
  useTilg();

  useEffectOnce(() => {
    document.title = GetPageTitle("Bank statement review");
  });

  const navigate = useNavigate();
  const {
    bankDocOutputId,
    blobUri,
    locationId,
    bankDocOutputData,
    isCreditCard,
    forceRefetch,
  } = props;

  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const [bankDocDetailsState] = Xstate.useActor(bankDocDetailsService);
  const { pdfTotalsData, matcher } = bankDocDetailsState.context;
  const endingBalancesMatch = Xstate.useSelector(
    bankDocDetailsService,
    (state) => {
      return state.context.matcher.endingEqual;
    },
  );

  // mutation
  const [updateBankDocOutputM, { loading: loadingM, error: errorM }] =
    useMutation<UpdateBankDocOutput, UpdateBankDocOutputVariables>(
      UPDATE_BANK_DOC_GQL,
      // refetch happens in parent
    );

  // RHF default values
  const entryDefaultValues: BankDocOutputDataRowT = {
    id: uuidV4(),
    date: NOW_UTC(),
    description: "Enter payee here...",
    memo: "Enter memo here...",
    credit: 0.1,
    debit: 0,
    isSelected: false,
    isNew: true,
  };

  // RHF
  const {
    register,
    control,
    formState: { errors: rhfErrors },
    getValues,
    setValue,
  } = useForm<BankDocOutputDataT>({
    defaultValues: bankDocOutputData,
    mode: "onBlur",
  });
  const { fields: entriesFields, append: entriesAppend } = useFieldArray({
    control,
    name: "extractedRows",
  });
  const entriesAppendWithDefaults = () => {
    entriesAppend({
      ...entryDefaultValues,
    });
  };
  const watchDataRows = useWatch({
    control,
    name: "extractedRows",
  });

  // state - checked all
  type HeaderSelectionT = "initial" | "all" | "none";
  const [headerSelectionState, setHeaderSelectionState] =
    React.useState<HeaderSelectionT>("initial");
  const [atLeastOneTxnSelected, setAtLeastOneTxnSelected] =
    React.useState(false);

  // local state - extracted totals
  const initialPdfTotalsData: PdfTotalsDataT = bankDocOutputData.pdfTotals;
  const [extractedTotalsData, setExtractedTotalsData] =
    React.useState<ExtractedTotalsDataT>(
      BankDocStatics.InitialExtractedTotalsData,
    );

  // effect - select all rows
  React.useEffect(() => {
    if (headerSelectionState === "initial") return;

    console.log("BankDocOutputEditView | useEffect.rerender");
    const dataRows = getValues("extractedRows");
    const isSelected = headerSelectionState === "all";
    const updatedTransactions = _.map(
      dataRows,
      (txn): BankDocOutputDataRowT => {
        return {
          ...txn,
          isSelected: isSelected,
        };
      },
    );
    setValue("extractedRows", updatedTransactions);
  }, [getValues, headerSelectionState, setValue]);

  // effect - set extracted totals
  React.useEffect(() => {
    // update totals
    const newExtractedTotals = BankDocStatics.GetExtractedTotals(watchDataRows);
    setExtractedTotalsData(newExtractedTotals);

    // set alert flag
    const someTxnSelected = _.some(watchDataRows, (e) => e.isSelected);
    setAtLeastOneTxnSelected(someTxnSelected);

    const allTxnSelected = _.every(watchDataRows, (e) => e.isSelected);
    if (allTxnSelected && headerSelectionState === "none")
      setHeaderSelectionState("all");
  }, [watchDataRows]);

  // state - row errors
  const [hasRowErrors, setHasRowErrors] = React.useState(false);
  React.useEffect(() => {
    setHasRowErrors(!!rhfErrors?.extractedRows);
  }, [rhfErrors]);

  // effect - extracted totals
  React.useEffect(() => {
    bankDocDetailsService.send("updateExtractedTotals", {
      extractedTotalsData: extractedTotalsData,
    });
  }, [bankDocDetailsService, extractedTotalsData]);

  // effect - pdf totals
  React.useEffect(() => {
    bankDocDetailsService.send("updatePdfTotals", {
      pdfTotalsData: initialPdfTotalsData,
    });
  }, [bankDocDetailsService, initialPdfTotalsData]);

  // effect - is credit card
  React.useEffect(() => {
    bankDocDetailsService.send("updateIsCreditCard", {
      isCreditCard: isCreditCard,
    });
  }, [bankDocDetailsService, isCreditCard]);

  // state - alerts
  const exportAlertsList: MultiAlertT = [
    {
      isVisible: hasRowErrors,
      msg: LABELS.messages.bankDocOutput.status.errorInTxnRow,
      level: "error",
    },
    {
      isVisible: !atLeastOneTxnSelected,
      msg: LABELS.messages.bankDocOutput.status.noTxnSelected,
      level: "error",
    },
    {
      isVisible: !endingBalancesMatch,
      msg: LABELS.messages.bankDocOutput.status.notMatched,
      level: "warning",
    },
  ];
  const firstExportAlert = getFirstAlert(exportAlertsList);
  const isExportDisabled = firstExportAlert?.level === "error" ?? false;

  const onSaveHandler = async () => {
    const extractedTxnRows = watchDataRows;
    const existingTxnRows = extractedTxnRows.filter((x) => !x.isNew);
    const newTxnRows = extractedTxnRows.filter((x) => x.isNew);

    await updateBankDocOutputM({
      variables: {
        updateBankDocOutputInput: {
          input: {
            bankDocOutputId: bankDocOutputId,
            locationId: locationId,
            bankDocOutputBalancesInput: {
              startingBalance: pdfTotalsData.starting,
              endingBalance: pdfTotalsData.ending,
            },
            existingInputs:
              BankDocStatics.TransformDataRowToRowInput(existingTxnRows),
            newInputs: BankDocStatics.TransformDataRowToRowInput(newTxnRows),
            isReviewed: matcher.endingEqual,
          },
        },
      },
    });

    if (errorM) {
      throw new Error("Error saving bank doc changes");
    }

    forceRefetch();
    navigate("../");
  };

  const entriesRows = entriesFields.map((entry, index) => {
    const [isSelected, credit, debit, txnDate, description] = getValues([
      `extractedRows.${index}.isSelected`,
      `extractedRows.${index}.credit`,
      `extractedRows.${index}.debit`,
      `extractedRows.${index}.date`,
      `extractedRows.${index}.description`,
    ]);
    const bgCx = isSelected ? "bg-base-300" : "bg-base-100";
    const isCreditDebitEmpty =
      getRounded(credit) === 0 && getRounded(debit) === 0;
    const isCreditDebitNonEmpty =
      getRounded(credit) > 0 && getRounded(debit) > 0;
    const isDescriptionEmpty = isEmptyStr(description);
    const isTxnDateInFuture = isDateInFuture(txnDate);
    const rowErrorsList: MultiAlertT = [
      {
        isVisible: isCreditDebitEmpty,
        msg: "Both credit and debit can not be empty.",
        level: "warning",
      },
      {
        isVisible: isCreditDebitNonEmpty,
        msg: "A transaction can not have both credit and debit.",
        level: "error",
      },
      {
        isVisible: isDescriptionEmpty,
        msg: "Description can not be empty",
        level: "error",
      },
      {
        isVisible: isTxnDateInFuture,
        msg: "Transaction date can not be in the future",
        level: "error",
      },
    ];
    const firstRowError = getFirstAlert(rowErrorsList);

    return (
      <div
        className={`grid grid-cols-1 gap-2 ${bgCx} p-2 lg:grid-cols-6`}
        key={entry.id}>
        <div className={"flex flex-row items-center space-x-2 lg:col-span-1"}>
          <input
            className={"checkbox checkbox-primary checkbox-sm"}
            type={"checkbox"}
            title={"Select transaction"}
            {...register(`extractedRows.${index}.isSelected`)}
          />
          <div className={"form-control"}>
            <label className={"label lg:hidden"}>
              <span className={"label-text"}>Date</span>
            </label>
            <Controller
              control={control}
              name={`extractedRows.${index}.date`}
              render={({ field: { onChange, value } }) => {
                const utcFromLocalDate = getUtcFromLocal(value);
                return (
                  <DatePicker
                    minDate={DATES.minus5Years}
                    maxDate={DATES.tomorrow}
                    value={utcFromLocalDate}
                    formatString={DATE_FORMATS.bankDateOnly}
                    onChange={({ date }) => {
                      if (!date) return;

                      if (_.isArray(date)) {
                        const validDates = date.flatMap((f) =>
                          !!f ? [f] : [],
                        );
                        onChange(getLocalFromUtcs(validDates));
                      } else {
                        onChange(getLocalFromUtc(date));
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className={"col-span-5 grid grid-cols-1 lg:grid-cols-5"}>
          <div className={"form-control lg:col-span-3"}>
            <label>
              <span className={"label-text text-sm font-semibold opacity-40"}>
                Payee
              </span>
            </label>
            <input
              type={"text"}
              {...register(`extractedRows.${index}.description`, {
                required: LABELS.required,
              })}
              className={"input input-bordered"}
            />
            {rhfErrors.extractedRows?.[index]?.description?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {rhfErrors.extractedRows?.[index]?.description?.message}
              </span>
            )}
          </div>
          <div className={"form-control lg:col-span-1"}>
            <label>
              <span className={"label-text text-sm font-semibold opacity-40"}>
                Debit
              </span>
            </label>
            <input
              type={"text"}
              {...register(`extractedRows.${index}.debit`, {
                required: LABELS.required,
                validate: {
                  positive: validateDecimalPositive,
                },
                setValueAs: (v) => getRounded(v),
              })}
              className={"input input-bordered"}
            />
            {rhfErrors.extractedRows?.[index]?.debit?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {rhfErrors.extractedRows?.[index]?.debit?.message}
              </span>
            )}
          </div>
          <div className={"form-control lg:col-span-1"}>
            <label>
              <span className={"label-text text-sm font-semibold opacity-40"}>
                Credit
              </span>
            </label>
            <input
              type={"text"}
              {...register(`extractedRows.${index}.credit`, {
                required: LABELS.required,
                validate: {
                  positive: validateDecimalPositive,
                },
                setValueAs: (v) => getRounded(v),
              })}
              className={"input input-bordered"}
            />
            {rhfErrors.extractedRows?.[index]?.credit?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {rhfErrors.extractedRows?.[index]?.credit?.message}
              </span>
            )}
          </div>
          <div className={"form-control lg:col-span-5"}>
            <label>
              <span className={"label-text text-sm font-semibold opacity-40"}>
                {"Memo"}
              </span>
            </label>
            <input
              type={"text"}
              {...register(`extractedRows.${index}.memo`, {
                required: LABELS.required,
                ...ValidationConstants.bankDocOutputReviewRules.memoRule
                  .valueLength,
              })}
              className={"input input-bordered"}
            />
            {rhfErrors.extractedRows?.[index]?.memo?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {rhfErrors.extractedRows?.[index]?.memo?.message}
              </span>
            )}
          </div>
          {firstRowError && (
            <div className={"lg:col-span-6"}>
              <Alert type={"error"} label={firstRowError.msg} />
            </div>
          )}
        </div>
      </div>
    );
  });

  const extractedTxnView = (
    <div className={"col-span-1 rounded"}>
      <FormSection
        name={"Transactions"}
        cardBodyExtraCx={"relative space-y-1"}
        customCx={"my-6 py-6 px-2"}
        viewFormat={"custom"}>
        <div className={"h-screen overflow-y-scroll"}>
          {/* table header */}
          <div
            className={
              "sticky top-0 z-20 flex items-center rounded bg-base-200 py-4 shadow shadow-md"
            }>
            <div className={"flex w-1/5 space-x-2 pl-2"}>
              <div className={"form-control flex"}>
                <input
                  className={"checkbox"}
                  type={"checkbox"}
                  title={"Select all transactions"}
                  checked={headerSelectionState === "all"}
                  onChange={() =>
                    setHeaderSelectionState((p) =>
                      p === "all" ? "none" : "all",
                    )
                  }
                />
              </div>
              <div>Date</div>
            </div>
            <div className={"w-1/2"}>Payee / Memo</div>
            <div className={"w-1/5 pl-8"}>Debit</div>
            <div className={"w-1/5 pl-2"}>Credit</div>
          </div>

          {/* table body */}
          <div>
            {entriesRows}

            <div className={"flex justify-between py-4"}>
              <OutlineButton
                colorType={"primary"}
                label={"Add new transaction"}
                SvgIconLeft={AddStackSvg}
                onClick={entriesAppendWithDefaults}
              />
            </div>
          </div>
        </div>
      </FormSection>
    </div>
  );

  const uploadedPdfView = (
    <div className={"col-span-1 rounded"}>
      <FormSection
        name={"Uploaded PDF"}
        customCx={"my-6 py-6 px-2"}
        viewFormat={"custom"}>
        <div className={"h-screen"}>
          <PdfViewerIframe blobUri={blobUri} />
        </div>
      </FormSection>
    </div>
  );

  return (
    <>
      <BankDocOutputReviewInfoPanelsView>
        <div
          className={
            "mx-8 flex flex-col items-center justify-center space-y-3"
          }>
          <OutlineButton
            size={"small"}
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
          <div className={"flex space-x-2"}>
            <BrandButton
              size={"small"}
              colorType={"primary"}
              label={"Save"}
              onClick={onSaveHandler}
              disabled={isExportDisabled || loadingM}
            />
            {firstExportAlert && (
              <div
                className={`tooltip-top tooltip tooltip-${firstExportAlert.level}`}
                data-tip={firstExportAlert.msg}>
                <IconButton
                  size={"extra-small"}
                  colorType={firstExportAlert.level}
                  border={false}
                  outline={false}
                  extraCx={"ml-1 mt-1 rounded-full"}
                  IconSvg={
                    firstExportAlert.level === "warning"
                      ? WarningExclaimSvg
                      : ErrorSvg
                  }
                />
              </div>
            )}
            {errorM && (
              <div
                className={"tooltip-top tooltip tooltip-error"}
                data-tip={LABELS.errors.errorSavingChanges}>
                <IconButton
                  size={"extra-small"}
                  colorType={"error"}
                  border={false}
                  extraCx={"ml-1 mt-1 rounded-full text-bold"}
                  IconSvg={ErrorSvg}
                />
              </div>
            )}
          </div>
        </div>
      </BankDocOutputReviewInfoPanelsView>

      <div className={"grid grid-cols-1 gap-1 xl:grid-cols-2"}>
        {uploadedPdfView}
        {extractedTxnView}
      </div>
    </>
  );
};
