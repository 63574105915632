import { ApolloQueryResult } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import * as devConfig from "../../app/configs/devConfig";
import { LABELS } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../components/button/BrandButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmailVariables,
} from "../../generated/operation-result-types";
import { IUserProfile } from "../../types/types";
//import TimezoneSelect, { ITimezone } from "react-timezone-select";

interface IEditUserProfileProps {
  closeModal: () => void;
  id?: string;
  refetch: (
    variables?: Partial<GetUserInfoByEmailVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetUserInfoByEmail>>;
}

const EditUserProfile: React.FC<IEditUserProfileProps> = ({
  closeModal,
  refetch,
  id,
}) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [userProfile, setUserProfile] = useState<IUserProfile>();

  /*const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );*/

  const getUser = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-profile/${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setUserProfile(data);
      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
    }
  };
  const updateUserProfile = async (data: any) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-profile/update-user-profile`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
    if (response.ok) {
      closeModal();
      refetch({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: userInfoByEmail!.userProfile!.email,
      });
    }
  };
  useEffectOnce(() => {
    getUser();
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userProfile?.firstName,
      lastName: userProfile?.lastName,
    },
  });
  const onSubmit = async (data: any) => {
    await updateUserProfile({
      ...userProfile,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  };

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit profile</div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"form-control mt-5"}>
            <label className={"label"}>
              <span className={"label-text"}>First name</span>
            </label>
            <input
              type={"text"}
              className={"input input-bordered"}
              placeholder={"First name"}
              {...register("firstName", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "First name cannot be only spaces";
                  }
                  return true;
                },
              })}
            />
            {errors?.firstName?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.firstName?.message}
              </span>
            )}
          </div>
          <div className={"form-control mt-5"}>
            <label className={"label"}>
              <span className={"label-text"}>Last name</span>
            </label>
            <input
              type={"text"}
              className={"input input-bordered"}
              placeholder={"Last name"}
              {...register("lastName", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
                validate: (value) => {
                  if (value?.trim() === "") {
                    return "Last name cannot be only spaces";
                  }
                  return true;
                },
              })}
            />
            {errors?.lastName?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.lastName?.message}
              </span>
            )}
          </div>
          {/*<div className={"form-control mt-5"}>
            <label className={"label"}>
              <span className={"label-text"}>Time zone</span>
            </label>
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
             <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
            />
          </div>*/}
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <BrandButton
              buttonType={"submit"}
              colorType={"primary"}
              label={"Update"}
              SvgIconLeft={AddStackSvg}
            />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeModal}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditUserProfile;
