import { getInitialsFromFullName } from "../../constants/constants";
import { CommonFactoryInterface } from "../common-factory-type";
import { CustomerDto } from "./models/customerDto";
import { CustomerModel } from "./models/customerModel";

class CustomerFactory
  implements CommonFactoryInterface<CustomerDto, CustomerModel>
{
  createModelFromDto = (dto: CustomerDto): CustomerModel => {
    return {
      id: dto?.id || "",
      type: dto?.businessContactId ? "organization" : "contact",
      openWorkCounts: dto?.openWorkCounts || 0,
      name: dto?.businessContactId
        ? dto?.businessContact?.companyName || ""
        : dto?.name || "",
      initials: getInitialsFromFullName(dto?.name || ""),
      avatarColor: dto?.businessContactId ? "purple" : "blue",
      phoneNumber: dto?.businessContactId
        ? dto?.businessContact?.phoneNumbers?.[0]?.number || ""
        : dto?.contact?.phoneNumbers?.[0]?.number || "",
      email: dto?.businessContactId
        ? dto?.businessContact?.email || ""
        : dto?.contact?.email?.emailAddress || "",
      contactType: dto?.businessContactId
        ? dto?.businessContact?.contactType?.contactType || ""
        : dto?.contact?.contactType?.contactType || "",
      contactId: dto?.contactId || "",
      businessContactId: dto?.businessContactId || "",
      locationId: dto?.locationId || "",
      createdAt: dto?.createdAt || null,
      imageURL: dto?.imageURL || null,
      customerTag: dto?.customerTag.map((item) => item.tags.tagName) || [],
    };
  };

  createModelsFromArrayOfDtos = (dtos: CustomerDto[]): CustomerModel[] => {
    return dtos.map((dto) => this.createModelFromDto(dto));
  };
}

export const customerFactory = new CustomerFactory();
