import React from "react";
import classNames from "classnames";

type Props = {
  title: string | JSX.Element;
  extraClasses?: string;
};

export const PageLayout: React.FC<Props> = (props) => {
  const { title, children, extraClasses } = props;
  return (
    <div className={classNames("w-full overflow-hidden pb-10", extraClasses)}>
      <h1 className={"text-3xl font-bold "}>{title}</h1>
      {children}
    </div>
  );
};
