export enum PagesEnum {
  Home = "home",
  Dashboard = "dashboard",
  Contacts = "contacts",
  Contact = "contact",
  Organization = "organization",
  Works = "works",
  Work = "work",
  Calendar = "calendar",
  Time = "time",
  Billing = "billing",
}
