import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import { useGetContactQuery } from "../../../../../../../../slices/ContactsSlice";
import { ContactEditInfo } from "./ContactEditInfo";
import { ContactInfo } from "./ContactInfo";

export const ContactInfoView: FC = () => {
  const { customerId } = useParams();

  const { data: contact, isLoading } = useGetContactQuery({
    customerId: customerId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });

  const [isEdit, setIsEdit] = useState(false);

  return contact ? (
    isLoading ? (
      <Card extraClasses={"shadow-box"}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <Button label={"Edit"} size={"custom"} extraClasses={"btn-sm"} />
          </div>
          <div>
            <LottieLoading />
          </div>
        </div>
      </Card>
    ) : (
      <>
        {isEdit ? (
          <ContactEditInfo contact={contact} setIsEdit={setIsEdit} />
        ) : (
          <ContactInfo contact={contact} setIsEdit={setIsEdit} />
        )}
      </>
    )
  ) : (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button label={"Edit"} size={"custom"} extraClasses={"btn-sm"} />
        </div>
      </div>
    </Card>
  );
};
