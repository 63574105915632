import React, { FC, useEffect, useState } from "react";
import { CustomLink } from "../../support/CustomLink";
import { Navigate, Route, Routes } from "react-router-dom";

import { PageLayout } from "../../components/layouts/PageLayout";
import { GetPageTitle } from "../../support/ScrollToTop";
import { ReadList } from "./ReadList";
import { UnreadList } from "./UnreadList";
import { Dropdown } from "../../components/dropdown/new-dropdown/Dropdown";
import Button from "../../components/button/Button";
import { HiEllipsisVertical } from "react-icons/hi2";
import classNames from "classnames";
import { SyncModal } from "../contacts/modals/SyncModal";
import { FaCog } from "react-icons/fa";

export const NotificationView: FC = () => {
  useEffect(() => {
    document.title = GetPageTitle("Notifications");
  }, []);

  const [isOpenSync, setIsOpenSync] = useState(false);

  return (
    <PageLayout title={"Notifications"}>
      <div className={"flex justify-between"}>
        <div className={"tabs py-8"}>
          <CustomLink to={"/notifications/unread"}>Unread</CustomLink>
          <CustomLink to={"/notifications/read"}>Read</CustomLink>
        </div>
        <div className={"flex items-center pr-1"}>
          <Dropdown
            triggerElement={
              <Button
                label={""}
                colorType={"ghost"}
                size={"custom"}
                icon={<FaCog size={20} className={"text-purple"} />}
                extraClasses={"min-h-fit h-fit w-fit p-1"}
                onClick={() => setIsOpenSync(true)}
              />
            }
            notification={true}
            propsPosition={true}></Dropdown>
        </div>
      </div>

      <Routes>
        <Route index element={<Navigate to={"./unread"} replace={true} />} />
        <Route path={"unread"} element={<UnreadList />} />
        <Route path={"read"} element={<ReadList />} />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>
      {isOpenSync && (
        <SyncModal
          isActive={isOpenSync}
          onClose={() => setIsOpenSync(false)}
          notifications={true}
        />
      )}
    </PageLayout>
  );
};
