import { AccordionDemo } from "./AccordionDemo";
import { AlertsDemo } from "./AlertsDemo";
import { AvatarsDemo } from "./AvatarsDemo";
import { BadgesDemo } from "./BadgesDemo";
import { BreadcrumbsDemo } from "./BreadcrumbsDemo";
import { ButtonGroupsDemo } from "./ButtonGroupsDemo";
import { ButtonsDemo } from "./ButtonsDemo";
import { CardsDemo } from "./CardsDemo";
import { DividersDemo } from "./DividersDemo";
import { DrawersDemo } from "./DrawersDemo";
import { DropdownsDemo } from "./DropdownsDemo";
import { FooterSectionsDemo } from "./FooterSectionsDemo";
import { FormCheckboxesDemo } from "./FormCheckboxesDemo";
import { FormInputsDemo } from "./FormInputsDemo";
import { FormRadiosDemo } from "./FormRadiosDemo";
import { FormRangesDemo } from "./FormRangesDemo";
import { FormSelectsDemo } from "./FormSelectsDemo";
import { FormTextAreasDemo } from "./FormTextAreasDemo";
import { FormTogglesDemo } from "./FormTogglesDemo";
import { GraphicsDemo } from "./GraphicsDemo";
import { HeroSectionsDemo } from "./HeroSectionsDemo";
import { IndicatorsDemo } from "./IndicatorsDemo";
import { LinksDemo } from "./LinksDemo";
import { MockupWindowsDemo } from "./MockupWindowsDemo";
import { ModalsDemo } from "./ModalsDemo";
import { NavbarsDemo } from "./NavbarsDemo";
import { PaginationsDemo } from "./PaginationsDemo";
import { PricingDemo } from "./PricingDemo";
import { ProgressBarsDemo } from "./ProgressBarsDemo";
import { StatCardsDemo } from "./StatCardsDemo";
import { StepsDemo } from "./StepsDemo";
import { StipeAddCustomer } from "./Stripe/StipeAddCustomer";
import { StripeSetSubscription } from "./Stripe/StripeSetSubscription";
import { TablesDemo } from "./TablesDemo";
import { TabsDemo } from "./TabsDemo";
import { TooltipsDemo } from "./TooltipsDemo";

export const componentsRouteMap = [
  { name: "accordions", component: AccordionDemo },
  { name: "alerts", component: AlertsDemo },
  { name: "avatars", component: AvatarsDemo },
  { name: "badges", component: BadgesDemo },
  { name: "breadcrumbs", component: BreadcrumbsDemo },
  { name: "buttons", component: ButtonsDemo },
  { name: "dividers", component: DividersDemo },
  { name: "dropdowns", component: DropdownsDemo },
  { name: "form-selects", component: FormSelectsDemo },
  { name: "graphics", component: GraphicsDemo },
  { name: "modals", component: ModalsDemo },
  { name: "navbars", component: NavbarsDemo },
  { name: "pricing", component: PricingDemo },
  { name: "progress-bars", component: ProgressBarsDemo },
  { name: "stat-cards", component: StatCardsDemo },
  { name: "stripe-add-customer", component: StipeAddCustomer },
  { name: "stripe-set-subscription", component: StripeSetSubscription },
  { name: "tables", component: TablesDemo },
  { name: "tabs", component: TabsDemo },
  { name: "wip-button-groups", component: ButtonGroupsDemo },
  { name: "wip-cards", component: CardsDemo },
  { name: "wip-drawers", component: DrawersDemo },
  { name: "wip-footer-sections", component: FooterSectionsDemo },
  { name: "wip-form-checkboxes", component: FormCheckboxesDemo },
  { name: "wip-form-inputs", component: FormInputsDemo },
  { name: "wip-form-radios", component: FormRadiosDemo },
  { name: "wip-form-ranges", component: FormRangesDemo },
  { name: "wip-form-text-area", component: FormTextAreasDemo },
  { name: "wip-form-toggles", component: FormTogglesDemo },
  { name: "wip-hero-sections", component: HeroSectionsDemo },
  { name: "wip-indicators", component: IndicatorsDemo },
  { name: "wip-links", component: LinksDemo },
  { name: "wip-mockup-windows", component: MockupWindowsDemo },
  { name: "wip-paginations", component: PaginationsDemo },
  { name: "wip-steps", component: StepsDemo },
  { name: "wip-tooltips", component: TooltipsDemo },
];
