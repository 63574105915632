import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useRef, useState } from "react";

import { detectFileType } from "../../constants/constants";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { LottieLoading } from "../graphics/LottieLoading";

type Props = {
  close: () => void;
  file: string;
  fileName: string;
  isLoading?: boolean;
};

const PreviewFile: FC<Props> = ({ close, file, fileName, isLoading }) => {
  const previewRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(previewRef, close);

  const [fileType, setFileType] = useState("");

  const isDocViewer =
    fileType === "xls" ||
    fileType === "xlsx" ||
    fileType === "doc" ||
    fileType === "docx" ||
    fileType === "ppt" ||
    fileType === "pptx";

  useEffect(() => {
    if (fileName) {
      setFileType(detectFileType(fileName) || "");
    } else {
      setFileType("");
    }
  }, [fileName]);

  const docs = [
    {
      uri: file,
      fileType: fileType,
    },
  ];

  const renderViewer = () => {
    if (isDocViewer) {
      return (
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
        />
      );
    } else {
      return (
        <iframe
          src={file}
          className={
            "flex h-full w-full min-w-[100px] items-center justify-center bg-gray-400"
          }
          title={fileName}
        />
      );
    }
  };

  return (
    <div
      onClick={close}
      className={
        "fixed left-0 top-0 z-[4] flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.3)]"
      }>
      <div
        ref={previewRef}
        className={
          "relative flex h-full max-h-[80vh] w-full max-w-[80vw] items-center justify-center"
        }>
        {isLoading ? (
          <div>
            <LottieLoading />
          </div>
        ) : (
          renderViewer()
        )}
      </div>
      <FontAwesomeIcon
        icon={faXmarkCircle}
        className={
          "absolute right-4 top-4 cursor-pointer text-[48px] text-white hover:text-gray-500"
        }
        onClick={close}
      />
    </div>
  );
};

export default PreviewFile;
