import React from "react";
import { match } from "ts-pattern";

type Props = {
  size: "small" | "medium" | "large";
};

export const BasicAvatar: React.FC<Props> = (props) => {
  const { size } = props;

  const avatarSizeClassName = match(size)
    .with("large", () => "w-24 h-24")
    .with("medium", () => "w-14 h-14")
    .with("small", () => "w-10 h-10")
    .exhaustive();

  return (
    <div className={"avatar"}>
      <div className={`rounded-btn ${avatarSizeClassName}`}>
        <img alt={"avatar"} src={"https://i.imgur.com/McqFyHh.png"} />
      </div>
    </div>
  );
};
