import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import { CustomPage } from "../../../../../../support/CustomLink";
import { FormSection } from "../../../../../../support/FormSection";
import OrganizationInvoices from "./OrganizationInvoices";
import OrganizationPayments from "./OrganizationPayments";

type Props = {
  customerId: string;
};
export enum PagesBillingEnum {
  invoices = "invoices",
  payments = "payments",
}
export const OrganizationBilling: React.FC<Props> = ({ customerId }) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const [activePageBilling, setActivePageBilling] = useState<PagesBillingEnum>(
    PagesBillingEnum.invoices,
  );
  const [isLoading, setIsLoading] = useState(false);

  const pages = {
    [PagesBillingEnum.invoices]: (
      <OrganizationInvoices customerId={customerId} />
    ),
    [PagesBillingEnum.payments]: (
      <OrganizationPayments customerId={customerId} />
    ),
  };

  return (
    <>
      <div className={"tabs my-8"}>
        <CustomPage
          onClick={() => setActivePageBilling(PagesBillingEnum.invoices)}
          isActive={activePageBilling === PagesBillingEnum.invoices}>
          Invoices
        </CustomPage>
        <CustomPage
          onClick={() => setActivePageBilling(PagesBillingEnum.payments)}
          isActive={activePageBilling === PagesBillingEnum.payments}>
          Payments
        </CustomPage>
      </div>
      <FormSection
        name={
          activePageBilling?.charAt(0).toUpperCase() +
          activePageBilling.slice(1)
        }>
        {isLoading ? (
          <LottieLoading />
        ) : customerId ? (
          pages[activePageBilling]
        ) : null}
      </FormSection>
    </>
  );
};
