import _ from "lodash";

// functions
export const setEmptyOrStr = (val: unknown) => {
  if (_.isString(val) && isEmptyStr(val)) return undefined;
  else if (_.isString(val)) return val;
  else throw new Error("Not a string");
};

export const isEmptyStr = (val: string) => {
  return _.isEmpty(val.trim());
};
