import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const AlertsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Alerts"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"alert"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              stroke={"#2196f3"}
              className={"mx-2 h-6 w-6"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"info"}>
        <div className={"alert alert-info"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              className={"mx-2 h-6 w-6 stroke-current"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={"M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"success"}>
        <div className={"alert alert-success"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              className={"mx-2 h-6 w-6 stroke-current"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={
                  "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                }
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"warning"}>
        <div className={"alert alert-warning"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              className={"mx-2 h-6 w-6 stroke-current"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={
                  "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                }
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"error"}>
        <div className={"alert alert-error"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              className={"mx-2 h-6 w-6 stroke-current"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={
                  "M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                }
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with action 1"}>
        <div className={"alert"}>
          <div className={"flex-1"}>
            <label className={"mx-3"}>
              Lorem ipsum dolor sit amet, consectetur adip!
            </label>
          </div>
          <div className={"flex-none"}>
            <button className={"btn btn-ghost btn-sm mr-2"}>Cancel</button>
            <button className={"btn btn-primary btn-sm"}>Apply</button>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with action 2"}>
        <div className={"alert"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              stroke={"#ff5722"}
              className={"mx-2 h-6 w-6"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={
                  "M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                }
              />
            </svg>
            <label>Lorem ipsum dolor sit amet, consectetur adip!</label>
          </div>
          <div className={"flex-none"}>
            <button className={"btn btn-ghost btn-sm"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"mr-2 inline-block w-4 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  }
                />
              </svg>
              Remind me later
            </button>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with action 3"}>
        <div className={"alert"}>
          <div className={"flex-1"}>
            <svg
              xmlns={"http://www.w3.org/2000/svg"}
              fill={"none"}
              viewBox={"0 0 24 24"}
              stroke={"#009688"}
              className={"mx-2 h-6 w-6 flex-shrink-0"}>
              <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={"2"}
                d={
                  "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                }
              />
            </svg>
            <label>
              <h4>Lorem ipsum dolor sit!</h4>
              <p className={"text-sm text-base-content text-opacity-60"}>
                Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
                sit amet, consectetur adip!Lorem ipsum dolor sit amet,
                consectetur adip!Lorem ipsum dolor sit amet, consectetur
                adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
                dolor sit amet, consectetur adip!
              </p>
            </label>
          </div>
          <div className={"flex-none"}>
            <button className={"btn btn-ghost btn-square btn-sm"}>
              <svg
                xmlns={"http://www.w3.org/2000/svg"}
                fill={"none"}
                viewBox={"0 0 24 24"}
                className={"h-6 w-6 stroke-current"}>
                <path
                  strokeLinecap={"round"}
                  strokeLinejoin={"round"}
                  strokeWidth={"2"}
                  d={
                    "M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
