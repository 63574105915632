import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetContactQuery,
  useGetOrgContactQuery,
} from "../../../../../../../../slices/ContactsSlice";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import { Card } from "../../../../../../../../components/card/Card";
import Button from "../../../../../../../../components/button/Button";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import { ContactEditInfo } from "../../../../../contact/pages/contact-profile/widgets/contact-info/ContactEditInfo";
import { ContactInfo } from "../../../../../contact/pages/contact-profile/widgets/contact-info/ContactInfo";
import { OrganizationEditInfo } from "./OrganizationEditInfo";
import { OrganizationInfo } from "./OrganizationInfo";

export const OrganizationInfoView: FC = () => {
  const { organizationId } = useParams();

  const { data: organization, isLoading } = useGetOrgContactQuery({
    id: organizationId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });

  const [isEdit, setIsEdit] = useState(false);

  return organization ? (
    isLoading ? (
      <Card extraClasses={"shadow-box"}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <Button label={"Edit"} size={"custom"} extraClasses={"btn-sm"} />
          </div>
          <div>
            <LottieLoading />
          </div>
        </div>
      </Card>
    ) : (
      <>
        {isEdit ? (
          <OrganizationEditInfo
            organization={organization}
            setIsEdit={setIsEdit}
          />
        ) : (
          <OrganizationInfo organization={organization} setIsEdit={setIsEdit} />
        )}
      </>
    )
  ) : (
    <Card extraClasses={"shadow-box"}>
      <div className={"px-6 py-4"}>
        <div className={"mb-4 flex justify-between"}>
          <div className={"text-lg font-bold"}>Contact Info</div>
          <Button label={"Edit"} size={"custom"} extraClasses={"btn-sm"} />
        </div>
      </div>
    </Card>
  );
};
