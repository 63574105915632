import { FC } from "react";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { generateRoutePath } from "../../app/constants/routes/utils";
import { Pages } from "../../app/constants/routes";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useGetWorkQuery } from "../../slices/WorkSlice";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { WorkTitle } from "./components/WorkTitle";
import { useGetWorkFilesQuery } from "../../slices/FilesSlice";
import { useGetWorkEmailQuery } from "../../slices/EmailsSlice";
import { useAppSelector } from "../../redux/redux";
import { useGetWorkNotesQuery } from "../../slices/WorkNotesSlice";

export const WorkView: FC = () => {
  const { workId } = useParams();
  const { user } = useAppSelector((state) => state.appReducer);

  const { data: work, isLoading: isLoadingWork } = useGetWorkQuery({
    workId: workId || "",
  });
  const { data: workNotes } = useGetWorkNotesQuery({
    workId: workId || "",
  });
  const { data: workEmails, isFetching: isLoading } = useGetWorkEmailQuery({
    userId: user?.id || "",
    workId: workId || "",
  });
  const { data: workFiles } = useGetWorkFilesQuery(workId || "");
  const { details, time, notes, repeats, files, emails } = Pages.work.children;

  const DetailsComponent = details.component;
  const TimeComponent = time.component;
  const NotesComponent = notes.component;
  const RepeatsComponent = repeats.component;
  const EmailsComponent = emails.component;

  if (isLoadingWork) {
    return <LottieLoading />;
  }

  if (!work) {
    return null;
  }
  return (
    <div className={"flex h-full justify-center pl-4 pr-4 xl:pl-4 xl:pr-4"}>
      <div className={"h-full w-full"}>
        <PageLayout
          title={<WorkTitle work={work} />}
          extraClasses={"overflow-visible"}>
          <div className={"tabs my-4"}>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                details.path,
              ])}>
              {details.label}
            </CustomLink>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                time.path,
              ])}>
              {time.label}
            </CustomLink>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                repeats.path,
              ])}>
              {repeats.label}
            </CustomLink>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                files.path,
              ])}>
              {files.label + " " + workFiles?.length}
            </CustomLink>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                notes.path,
              ])}>
              {notes.label + " " + workNotes?.length}
            </CustomLink>
            <CustomLink
              to={generateRoutePath([
                Pages.work.path,
                workId || "",
                emails.path,
              ])}>
              {emails.label + " " + workEmails?.data.length}
            </CustomLink>
          </div>
          <Routes>
            <Route
              index
              element={<Navigate to={"./" + details.path} replace={true} />}
            />
            <Route
              path={details.path}
              element={<DetailsComponent work={work} />}
            />
            <Route path={time.path} element={<TimeComponent />} />
            <Route path={notes.path} element={<NotesComponent />} />
            <Route path={repeats.path} element={<RepeatsComponent />} />
            <Route path={emails.path} element={<EmailsComponent />} />
          </Routes>
        </PageLayout>
      </div>
    </div>
  );
};
