import React from "react";

import { AddStackSvg } from "../../components/svg/AddStackSvg";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const BadgesDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"badges"}>
      <ComponentSectionLayout title={"default"}>
        <div className={"badge badge-primary"}>primary</div>
        <div className={"badge badge-secondary"}>secondary</div>
        <div className={"badge badge-accent"}>accent</div>
        <div className={"badge badge-ghost"}>ghost</div>
        <div className={"badge"}>neutral</div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"sizes"}>
        <div className={"badge badge-lg"}>987,654</div>
        <div className={"badge badge-md"}>987,654</div>
        <div className={"badge badge-sm"}>987,654</div>
        <div className={"badge badge-xs"}>987,654</div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"empty"}>
        <div className={"badge badge-lg"} />
        <div className={"badge badge-md"} />
        <div className={"badge badge-sm"} />
        <div className={"badge badge-xs"} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"variants"}>
        <div className={"badge badge-info"}>
          <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
          info
        </div>
        <div className={"badge badge-success"}>
          <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
          success
        </div>
        <div className={"badge badge-warning"}>
          <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
          warning
        </div>
        <div className={"badge badge-error"}>
          <AddStackSvg sizeCx={"w-4 h-4 mr-1"} />
          error
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"outline"}>
        <div className={"badge badge-outline"}>neutral</div>
        <div className={"badge badge-outline badge-primary"}>primary</div>
        <div className={"badge badge-outline badge-secondary"}>secondary</div>
        <div className={"badge badge-outline badge-accent"}>accent</div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with text"}>
        <div>
          <div>
            <h2 className={"mb-5 text-2xl"}>
              Heading
              <div className={"badge badge-outline badge-lg"}>New</div>
            </h2>
          </div>
          <div>
            <h2 className={"mb-5 text-xl"}>
              Heading
              <div className={"badge badge-outline badge-md"}>New</div>
            </h2>
          </div>
          <div>
            <h2 className={"mb-5 text-lg"}>
              Heading
              <div className={"badge badge-outline badge-md"}>New</div>
            </h2>
          </div>
          <div>
            <h2 className={"text-md mb-5"}>
              Heading
              <div className={"badge badge-outline badge-sm"}>New</div>
            </h2>
          </div>
          <div>
            <h2 className={"mb-5 text-sm"}>
              Heading
              <div className={"badge badge-outline badge-sm"}>New</div>
            </h2>
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"with button"}>
        <button className={"btn btn-primary"}>
          Inbox
          <div className={"badge badge-outline ml-2"}>3</div>
        </button>
        <button className={"btn btn-outline btn-secondary"}>
          Notifications
          <div className={"badge badge-outline ml-2"}>+999</div>
        </button>
        <button className={"btn btn-accent"}>
          accent
          <div className={"badge badge-outline ml-2"}>new</div>
        </button>
        <button className={"btn"}>
          Version 2.7.0
          <div className={"badge ml-2"}>new</div>
        </button>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
