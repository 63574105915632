import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import * as Xstate from "@xstate/react";
import React from "react";

import { protectedResources } from "../../app/configs/appConfig";
import { setAuthToken } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { getApiWithToken } from "../../support/FetchWithToken";

export const UserAuthLoadingMainView: React.FC<unknown> = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { userAuthService } = React.useContext(GlobalContext);
  const { send: sendUserAUth } = userAuthService;
  const isEmptyState = Xstate.useSelector(userAuthService, (state) =>
    state.matches("empty"),
  );

  React.useEffect(() => {
    const aFun = async () => {
      if (account && inProgress === "none") {
        if (isEmptyState) {
          sendUserAUth("FETCH");
        } else {
          sendUserAUth("RELOAD");
        }

        try {
          const { accessToken } = await instance.acquireTokenSilent({
            scopes: protectedResources.apiHello.scopes,
            account: account,
          });
          setAuthToken(accessToken);
          await getApiWithToken(protectedResources.apiHello.endpoint);
          sendUserAUth("SUCCESS", {
            userAuthInfo: {
              authToken: accessToken,
              email: account.username,
            },
          });
        } catch (error) {
          // todo: this happens when user is logged in and token expires after X days
          // in case if silent token acquisition fails, fallback to an interactive method
          if (!(error instanceof InteractionRequiredAuthError)) {
            console.error("AuthenticatedMainView | getToken - unknown error", {
              error,
            });
            return;
          }

          if (account && inProgress === "none") {
            try {
              await instance.acquireTokenRedirect({
                scopes: protectedResources.apiHello.scopes,
              });
              // since we are using a "tokenRedirect", this view will
              // be rendered and the previous "tokenSilent" will get the token.
            } catch {
              // sendUserAUth("FAILURE");
            }
          }
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    aFun();
  }, [account, inProgress, instance, isEmptyState, sendUserAUth]);

  return null;
};
