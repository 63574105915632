import { Pages } from "../../app/constants/routes";
import { ROUTE_NAMES } from "../../app/constants/TextConstants";

import { InvoicesView } from "../../features/billing/invoices/InvoicesView";
import { CalendarView } from "../../features/calendar/CalendarView";
import { OrganizationView } from "../../features/contacts/pages/organization/OrganizationView";
import { DashboardView } from "../../features/dashboard/DashboardView";
import { BankDocView } from "../../features/documents/bankStatements/BankDocView";
import { EmailsView } from "../../features/emails/EmailsView";
import { HomeAuthView } from "../../features/home/HomeAuthView";
import { SignOutView } from "../../features/main/SignOutView";
import { NotificationView } from "../../features/notifications/NotificationView";
import { SettingsView } from "../../features/settings/SettingsView";
import { TimeView } from "../../features/time/TimeView";
import { UserView } from "../../features/user/UserView";
import { WorkView } from "../../features/work/WorkView";
import { WorksView } from "../../features/works/WorksView";
import { ContactsView } from "../../features/contacts/ContactsView";
import {
  BillingIcon,
  CalendarIcon,
  ContactsIcon,
  DashboardIcon,
  HomeIcon,
  MailsIcon,
  TimeIcon,
  WorksIcon,
} from "../../components/svg/navIcons/index-svg";

export const sideNavRouteMap = [
  {
    name: ROUTE_NAMES.home,
    path: ROUTE_NAMES.home,
    component: HomeAuthView,
    icon: HomeIcon,
  },
  {
    name: ROUTE_NAMES.dashboard,
    path: ROUTE_NAMES.dashboard,
    component: DashboardView,
    icon: DashboardIcon,
  },
  {
    name: ROUTE_NAMES.clients.index,
    path: ROUTE_NAMES.clients.index,
    component: ContactsView,
    icon: ContactsIcon,
  },
  {
    name: ROUTE_NAMES.work,
    path: ROUTE_NAMES.works,
    component: WorksView,
    icon: WorksIcon,
  },
  { name: "mail", path: "mail", component: EmailsView, icon: MailsIcon },
  {
    name: ROUTE_NAMES.calendar,
    path: ROUTE_NAMES.calendar,
    component: CalendarView,
    icon: CalendarIcon,
  },
  { name: "time", path: "time", component: TimeView, icon: TimeIcon },
  {
    name: "billing",
    path: "billing",
    component: InvoicesView,
    icon: BillingIcon,
  },
];

export const invisibleRouteMap = [
  {
    name: "/" + Pages.contact.path + "/:" + Pages.contact.params,
    component: Pages.contact.component,
  },
  { name: "/organization/:organizationId", component: OrganizationView },
  { name: ROUTE_NAMES.documents.bankDoc.index, component: BankDocView },
  { name: ROUTE_NAMES.signOut, component: SignOutView },
  { name: ROUTE_NAMES.user.index, component: UserView },
  { name: "/work/:workId", component: WorkView },
  { name: ROUTE_NAMES.calendar, component: CalendarView },
  { name: ROUTE_NAMES.settings.index, component: SettingsView },
  { name: "/notifications", component: NotificationView },
  { name: "/billing", component: InvoicesView },
  { name: "/mail", component: EmailsView },
];
