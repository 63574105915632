import moment from "moment";

export enum TimePeriod {
  LastYear = "LastYear",
  YTD = "YTD",
  LastMonth = "LastMonth",
  ThisMonth = "ThisMonth",
  Today = "Today",
  Custom = "Custom",
}

type Props = {
  period: TimePeriod;
  from: Date | null;
  to: Date | null;
};

export const useDetectTimePeriod = (props: Props | null) => {
  if (!props) {
    return { from: null, to: null };
  }

  const { period, from, to } = props;

  if (period === TimePeriod.LastYear) {
    return {
      from: moment().startOf("D").subtract(1, "y").toDate(),
      to: moment().endOf("D").toDate(),
    };
  }
  if (period === TimePeriod.YTD) {
    return {
      from: moment().startOf("D").startOf("y").toDate(),
      to: moment().startOf("D").toDate(),
    };
  }
  if (period === TimePeriod.LastMonth) {
    return {
      from: moment().startOf("D").subtract(1, "M").startOf("M").toDate(),
      to: moment().startOf("D").subtract(1, "M").endOf("M").toDate(),
    };
  }
  if (period === TimePeriod.ThisMonth) {
    return {
      from: moment().startOf("D").startOf("M").toDate(),
      to: moment().endOf("M").toDate(),
    };
  }
  if (period === TimePeriod.Today) {
    return {
      from: moment().startOf("D").toDate(),
      to: moment().endOf("D").toDate(),
    };
  } else {
    if (from && to) {
      return { from: from, to: to };
    } else {
      return {
        from: moment(from || new Date())
          .startOf("D")
          .toDate(),
        to: moment(to || new Date())
          .endOf("D")
          .toDate(),
      };
    }
  }
};
