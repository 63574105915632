import { FC } from "react";
import { WorkDetailsModel } from "../../../../factories/work/model/work-details.model";
import { WorkDetails } from "./widgets/work-details/WorkDetails";
import { WorkFiles } from "./widgets/work-files/WorkFiles";
import { WorkMain } from "./widgets/work-main/WorkMain";
import { WorkTasks } from "./widgets/work-tasks/WorkTasks";
import { WorkNotes } from "./widgets/work-notes/WorkNotes";

type Props = {
  work: WorkDetailsModel;
};

export const WorkDetailsView: FC<Props> = ({ work }) => {
  return (
    <div className={"grid grid-cols-[1fr_2fr_1fr] gap-6"}>
      <div>
        <WorkDetails work={work} />
      </div>
      <div>
        <WorkTasks work={work} />
      </div>
      <div className={"flex flex-col gap-6"}>
        <div>
          <WorkNotes />
        </div>
        <div>
          <WorkFiles />
        </div>
      </div>
    </div>
  );
};
