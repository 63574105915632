import { OrganizationEmails } from "../../../features/contacts/pages/organization/pages/organization-emails/OrganizationEmails";
import { OrganizationProfile } from "../../../features/contacts/pages/organization/pages/organization-profile/OrganizationProfile";
import { OrganizationWorks } from "../../../features/contacts/pages/organization/pages/organization-works/OrganizationWorks";
import { ContactPagesEnum } from "./contact-routes";
import { PagesEnum } from "./types";
import { OrganizationBilling } from "../../../features/contacts/pages/organization/pages/organization-billing/OrganizationBilling";
import { OrganizationNotes } from "../../../features/contacts/pages/organization/pages/organization-notes/OrganizationNotes";

export enum OrganizationPagesEnum {
  Profile = "profile",
  Works = "work",
  Notes = "notes",
  Billing = "billing",
  Emails = "emails",
}

export const OrganizationPages = {
  path: PagesEnum.Organization,
  label: "Organization",
  component: null,
  children: {
    profile: {
      path: OrganizationPagesEnum.Profile,
      label: "Profile",
      component: OrganizationProfile,
    },
    works: {
      path: OrganizationPagesEnum.Works,
      label: "Works",
      component: OrganizationWorks,
    },
    notes: {
      path: OrganizationPagesEnum.Notes,
      label: "Notes",
      component: OrganizationNotes,
    },
    billing: {
      path: OrganizationPagesEnum.Billing,
      label: "Billing",
      component: OrganizationBilling,
    },
    emails: {
      path: ContactPagesEnum.Emails,
      label: "Email",
      component: OrganizationEmails,
    },
  },
};
