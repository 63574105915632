import React from "react";
import { InterpreterFrom } from "xstate";

import { bankDocDetailsMachine } from "./BankDocDetailsMachine";
import { OnboardingInfoMachine } from "./OnboardingInfoMachine";
import { userAuthMachine } from "./UserAuthMachine";
import { UserInfoMachine } from "./UserInfoMachine";

export const GlobalContext = React.createContext({
  userAuthService: {} as InterpreterFrom<typeof userAuthMachine>,
  userInfoService: {} as InterpreterFrom<typeof UserInfoMachine>,
  onboardingInfoService: {} as InterpreterFrom<typeof OnboardingInfoMachine>,
  bankDocDetailsService: {} as InterpreterFrom<typeof bankDocDetailsMachine>,
});
