import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const DividersDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"breadcrumbs"}>
      <ComponentSectionLayout title={"horizontal - with text"}>
        <div className={"flex w-full flex-col"}>
          <div
            className={
              "card rounded-box grid h-20 place-items-center bg-base-300"
            }>
            content
          </div>
          <div className={"divider"}>OR</div>
          <div
            className={
              "card rounded-box grid h-20 place-items-center bg-base-300"
            }>
            content
          </div>
        </div>
      </ComponentSectionLayout>
      <ComponentSectionLayout title={"vertical - with text"}>
        <div className={"flex w-full flex-row"}>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
          <div className={"divider divider-vertical"}>OR</div>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
        </div>
      </ComponentSectionLayout>
      <ComponentSectionLayout title={"horizontal - no text"}>
        <div className={"flex w-full flex-col"}>
          <div
            className={
              "card rounded-box grid h-20 place-items-center bg-base-300"
            }>
            content
          </div>
          <div className={"divider"}></div>
          <div
            className={
              "card rounded-box grid h-20 place-items-center bg-base-300"
            }>
            content
          </div>
        </div>
      </ComponentSectionLayout>
      <ComponentSectionLayout title={"vertical - no text"}>
        <div className={"flex w-full flex-row"}>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
          <div className={"divider divider-vertical"}></div>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
        </div>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"responsive - mobile"}>
        <div className={"flex w-full flex-col lg:flex-row"}>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
          <div className={"divider lg:divider-vertical"}>OR</div>
          <div
            className={
              "card rounded-box grid h-32 flex-grow place-items-center bg-base-300"
            }>
            content
          </div>
        </div>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
