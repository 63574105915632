import { ContactNoteModel } from "../../../factories/contacts/models/contact-note.models";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import CustomSelect from "../../../components/select/Select";
import { SelectOptionType } from "../../../types/types";
import { useGetSearchWorksQuery } from "../../../slices/WorkSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useDebounce } from "usehooks-ts";
import {
  useAddEmailToWorkMutation,
  useSendEmailMutation,
} from "../../../slices/EmailsSlice";
import { Email } from "../../../factories/emails/models/emails.models";
import { is } from "date-fns/locale";
import { useForm } from "react-hook-form";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { useAppSelector } from "../../../redux/redux";
import Select from "react-select";
import { MenuList } from "react-select/dist/declarations/src/components/Menu";

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: ContactNoteModel;
  contactId?: string;
  businessContactId?: string;
  selectedMail: Email | null;
  isForward?: boolean;
  email?: Email;
};
type FormData = {
  email: string;
};
export const AddEmailModal: FC<Props> = ({
  isActive,
  onClose,
  selectedMail,
  isForward,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
  });
  const [searchWorks, setSearchWorks] = useState("");
  const debounceSearchWork = useDebounce(searchWorks, 300);
  const [work, setWork] = useState<SelectOptionType | null>(null);
  const { user } = useAppSelector((state) => state.appReducer);

  const [sendEmail, { isSuccess: isSuccessSend }] = useSendEmailMutation();

  const { data: works, isLoading: isLoadingWorks } = useGetSearchWorksQuery({
    query: debounceSearchWork,
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [addWorkToEmail, { isSuccess: isSuccessAdd }] =
    useAddEmailToWorkMutation();

  const addWork = () => {
    addWorkToEmail({
      body: {
        messageId: selectedMail?.id || "",
        threadId: selectedMail?.thread_id || "",
        workId: work?.value || "",
      },
    });
  };

  const onSubmit = (data: FormData) => {
    if (isForward) {
      sendEmail({
        userId: user.id,
        body: {
          subject: selectedMail?.subject || "",
          body: selectedMail?.body || "",
          to: [{ email: data.email }],
          cc: [{ email: data.email }],
          bcc: [{ email: data.email }],
          tracking_options: {
            opens: true,
            links: true,
            thread_replies: true,
            label: "",
          },
        },
      });
    } else {
      addWork();
    }
  };

  useEffect(() => {
    if (isSuccessSend || isSuccessAdd) {
      onClose();
    }
  }, [isSuccessSend, isSuccessAdd]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box w-[350px] p-0"}>
        <div
          className={
            "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
          }>
          {isForward ? "Forward email" : "Add email"}
        </div>
        <div className={"px-5 py-4"}>
          <div className={"min-w-[200px]"}>
            <div className={"mb-0.5 text-base"}>Work name</div>
            <div className={"w-full"}>
              <CustomSelect
                removeSelectIcon
                value={work}
                placeholder={"Search..."}
                onChange={(newValue) => {
                  const value = newValue as SelectOptionType;
                  setWork(value);
                }}
                inputValue={searchWorks}
                onChangeInput={setSearchWorks}
                isLoading={isLoadingWorks}
                options={works || []}
              />
            </div>
          </div>
          <div className={"mt-4 flex justify-end gap-4"}>
            <Button label={"Add"} buttonType={"submit"} />
            <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
          </div>
        </div>
      </div>
    </form>
  );
};
