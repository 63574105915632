import React from "react";

import { AvatarGroup } from "../../components/avatar/AvatarGroup";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { BasicAvatar } from "../../components/avatar/BasicAvatar";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const AvatarsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Avatars"}>
      <ComponentSectionLayout title={"Square"}>
        <BasicAvatar size={"large"} />
        <BasicAvatar size={"medium"} />
        <BasicAvatar size={"small"} />
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Groups large"}>
        <AvatarGroup>
          <BasicAvatar size={"large"} />
          <BasicAvatar size={"large"} />
          <BasicAvatar size={"large"} />
        </AvatarGroup>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Groups medium"}>
        <AvatarGroup>
          <BasicAvatar size={"medium"} />
          <BasicAvatar size={"medium"} />
          <BasicAvatar size={"medium"} />
        </AvatarGroup>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Groups small"}>
        <AvatarGroup>
          <BasicAvatar size={"small"} />
          <BasicAvatar size={"small"} />
          <BasicAvatar size={"small"} />
        </AvatarGroup>
      </ComponentSectionLayout>

      <ComponentSectionLayout title={"Placeholder"}>
        <AvatarPlaceholder size={"extra-small"} label={"XY"} />
        <AvatarPlaceholder size={"small"} label={"XY"} />
        <AvatarPlaceholder size={"medium"} label={"XY"} />
        <AvatarPlaceholder size={"large"} label={"XY"} />
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
