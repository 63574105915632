import { ChevronDown } from "baseui/icon";
import classNames from "classnames";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import ContactWorkSvg from "../../../../../../../../components/svg/ContactWorkSvg";
import {
  getInitialsFromFullName,
  inactiveStatusId,
  showStatus,
} from "../../../../../../../../constants/constants";
import { WorkModel } from "../../../../../../../../factories/works/models/work.model";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import { useGetWorksQuery } from "../../../../../../../../slices/WorkSlice";
import { IOrganizationContact } from "../../../../../../../../types/types";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { WorkTableSort } from "../../../../../../../works/components/types/work-table";
import { StatusesDropdown } from "../../../../../../../works/dropdown/StatusesDropdown";
import { getStatusColor } from "../../../../../../../works/dropdown/utils";
import { AddWorkModal } from "../../../../../../../works/modals/AddWork.modal";
import { useAppSelector } from "../../../../../../../../redux/redux";

type Props = {
  organization: IOrganizationContact;
};
export const OrganizationWorksWidget: FC<Props> = ({ organization }) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);

  const [page, setPage] = useState(1);

  const {
    data: contactWorks,
    isFetching,
    isLoading,
  } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    sortBy: WorkTableSort.WorkName,
    sortOrder: "asc",

    orgId: getLinkedFirm()?.orgId,
    customerIds: [`${organization?.customerId}`],
    currentUserId: user.id,
  });

  const [works, setWorks] = useState<WorkModel[]>([]);

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const isActive = organization?.contactStatusEnumId !== inactiveStatusId;

  const isOverdue = (work: WorkModel) => {
    return moment().startOf("day").unix() > moment(work.dueDate).unix();
  };

  const hasPages = (contactWorks?.[0]?.totalRecords || 0) > page * 20;

  useEffect(() => {
    if (contactWorks?.length) {
      setWorks((currentWorks) => {
        const existingIds = new Set(currentWorks.map((work) => work.workId));
        const newWorks = contactWorks.filter(
          (work) => !existingIds.has(work.workId),
        );
        return [...currentWorks, ...newWorks];
      });
    }
  }, [contactWorks]);

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Work</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={() =>
                !isActive ? setIsOpenError(true) : setIsOpenAdd(true)
              }
            />
          </div>
          <div>
            {isLoading ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !works?.length ? (
              <div className={"p-6 pt-0"}>
                <NoItems
                  icon={<ContactWorkSvg />}
                  message={"There are no work items"}
                  addButtonMessage={"Add Work"}
                  openAdd={() =>
                    !isActive ? setIsOpenError(true) : setIsOpenAdd(true)
                  }
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-x-auto overflow-y-auto"}>
                <table className={"w-full"}>
                  <thead>
                    <tr className={"border-b border-gray-300"}>
                      <td
                        className={
                          "whitespace-nowrap px-4 pb-4 text-sm font-semibold"
                        }>
                        Work name
                      </td>
                      <td className={"px-4 pb-4 text-sm font-semibold"}>
                        Status
                      </td>
                      <td
                        className={
                          "whitespace-nowrap px-4 pb-4 text-sm font-semibold"
                        }>
                        Due date
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {works?.map((work) => (
                      <tr
                        key={work?.workId}
                        onClick={() => navigate("/work/" + work?.workId)}
                        className={
                          "border-b border-gray-300 text-start text-sm transition-colors hover:bg-gray-100"
                        }>
                        <td className={"max-w-[300px] px-4 py-2"}>
                          <div
                            className={
                              "link-primary w-fit max-w-full cursor-pointer truncate font-semibold hover:underline"
                            }>
                            {work?.workName}
                          </div>
                        </td>
                        <td
                          className={"px-4 py-2"}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}>
                          {work?.jobStatus && (
                            <StatusesDropdown
                              workTypeId={work?.workTypeId || ""}
                              workId={work?.workId || ""}
                              triggerElement={
                                <div
                                  className={
                                    "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
                                  }>
                                  <div
                                    className={
                                      "flex h-[10px] w-[10px] rounded-full"
                                    }
                                    style={{
                                      backgroundColor: getStatusColor(
                                        work?.jobStatus || "",
                                      ),
                                    }}
                                  />
                                  <div>{showStatus(work)}</div>
                                  <ChevronDown size={16} />
                                </div>
                              }
                            />
                          )}
                        </td>
                        <td
                          className={classNames(
                            "px-4 py-2 font-semibold",
                            isOverdue(work) ? "text-red-500" : "text-gray-800",
                          )}>
                          {moment(work?.dueDate).format("DD.MM.yyyy")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {hasPages && (
                  <div className={"px-4 py-2"}>
                    <Button
                      label={"Load more"}
                      size={"custom"}
                      onClick={() => setPage((prev) => prev + 1)}
                      fullWidth
                      extraClasses={"btn-sm"}
                      disabled={isFetching}
                      isLoading={isFetching}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <AddWorkModal
          isOpen={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          defaultContact={{
            value: organization?.customerId || "",
            label: `${organization?.companyName}`,
            avatar: getInitialsFromFullName(organization?.companyName),
            avatarColor: "purple",
          }}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
