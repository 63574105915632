/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBankDocsByLocation
// ====================================================

export interface GetBankDocsByLocation_bankDocsByLocation_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean; // Indicates whether more edges exist following the set defined by the clients arguments.
  hasPreviousPage: boolean; // Indicates whether more edges exist prior the set defined by the clients arguments.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  endCursor: string | null; // When paginating forwards, the cursor to continue.
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges_node_bankDocStatusEnum {
  __typename: "BankDocStatusEnum";
  id: string;
  statusType: string;
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges_node_customer {
  __typename: "Customer";
  id: string;
  name: string;
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges_node_uploadedByUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges_node_uploadedByUser {
  __typename: "User";
  id: string;
  userProfile: GetBankDocsByLocation_bankDocsByLocation_edges_node_uploadedByUser_userProfile | null;
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges_node {
  __typename: "BankDoc";
  id: string;
  name: string;
  createdAt: any;
  deletedAt: any | null;
  bankDocStatusEnum: GetBankDocsByLocation_bankDocsByLocation_edges_node_bankDocStatusEnum | null;
  metaBankName: string | null;
  metaPageCount: number | null;
  customer: GetBankDocsByLocation_bankDocsByLocation_edges_node_customer | null;
  uploadedByUser: GetBankDocsByLocation_bankDocsByLocation_edges_node_uploadedByUser | null;
}

export interface GetBankDocsByLocation_bankDocsByLocation_edges {
  __typename: "BankDocsByLocationEdge";
  node: GetBankDocsByLocation_bankDocsByLocation_edges_node; // The item at the end of the edge.
}

export interface GetBankDocsByLocation_bankDocsByLocation {
  __typename: "BankDocsByLocationConnection";
  pageInfo: GetBankDocsByLocation_bankDocsByLocation_pageInfo; // Information to aid in pagination.
  edges: GetBankDocsByLocation_bankDocsByLocation_edges[] | null; // A list of edges.
}

export interface GetBankDocsByLocation {
  bankDocsByLocation: GetBankDocsByLocation_bankDocsByLocation | null;
}

export interface GetBankDocsByLocationVariables {
  locationId: string;
  where?: BankDocFilterInput | null;
  first?: number | null;
  after?: string | null;
  order?: BankDocSortInput[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBankDocById
// ====================================================

export interface GetBankDocById_bankDocById_bankDocStatusEnum {
  __typename: "BankDocStatusEnum";
  id: string;
  statusType: string;
}

export interface GetBankDocById_bankDocById_customer_customerTaxIds {
  __typename: "CustomerTaxId";
  id: string;
  taxIdType: string;
  taxIdValue: string;
}

export interface GetBankDocById_bankDocById_customer_contact_email {
  __typename: "ContactEmail";
  id: string;
  emailAddress: string;
}

export interface GetBankDocById_bankDocById_customer_contact_phoneNumbers_phoneNumberTypeEnum {
  __typename: "PhoneNumberTypeEnum";
  id: string;
  phoneNumberType: string;
}

export interface GetBankDocById_bankDocById_customer_contact_phoneNumbers {
  __typename: "ContactPhoneNumber";
  id: string;
  number: string;
  phoneNumberTypeEnum: GetBankDocById_bankDocById_customer_contact_phoneNumbers_phoneNumberTypeEnum;
}

export interface GetBankDocById_bankDocById_customer_contact {
  __typename: "Contact";
  id: string;
  fullName: string;
  avatarInitials: string;
  email: GetBankDocById_bankDocById_customer_contact_email | null;
  phoneNumbers: GetBankDocById_bankDocById_customer_contact_phoneNumbers[];
}

export interface GetBankDocById_bankDocById_customer {
  __typename: "Customer";
  id: string;
  name: string;
  customerTaxIds: GetBankDocById_bankDocById_customer_customerTaxIds[];
  contact: GetBankDocById_bankDocById_customer_contact | null;
}

export interface GetBankDocById_bankDocById_uploadedByUser_userProfile {
  __typename: "UserProfile";
  fullName: string;
  avatarInitials: string;
}

export interface GetBankDocById_bankDocById_uploadedByUser {
  __typename: "User";
  id: string;
  userProfile: GetBankDocById_bankDocById_uploadedByUser_userProfile | null;
}

export interface GetBankDocById_bankDocById {
  __typename: "BankDoc";
  id: string;
  name: string;
  createdAt: any;
  deletedAt: any | null;
  blobUri: string | null;
  isCreditCard: boolean;
  resultTokenId: any | null;
  bankDocStatusEnum: GetBankDocById_bankDocById_bankDocStatusEnum | null;
  customer: GetBankDocById_bankDocById_customer | null;
  uploadedByUser: GetBankDocById_bankDocById_uploadedByUser | null;
}

export interface GetBankDocById {
  bankDocById: GetBankDocById_bankDocById;
}

export interface GetBankDocByIdVariables {
  bankDocId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBankDocOutput
// ====================================================

export interface UpdateBankDocOutput_updateBankDocOutput_bankDocOutput {
  __typename: "BankDocOutput";
  id: string;
}

export interface UpdateBankDocOutput_updateBankDocOutput {
  __typename: "UpdateBankDocOutputPayload";
  bankDocOutput: UpdateBankDocOutput_updateBankDocOutput_bankDocOutput | null;
}

export interface UpdateBankDocOutput {
  updateBankDocOutput: UpdateBankDocOutput_updateBankDocOutput;
}

export interface UpdateBankDocOutputVariables {
  updateBankDocOutputInput: UpdateBankDocOutputInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBankDocOutputById
// ====================================================

export interface GetBankDocOutputById_bankDocOutputById_bankDocOutputBalances {
  __typename: "BankDocOutputBalances";
  startingBalance: any;
  endingBalance: any;
}

export interface GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows_bankDocOutputAmount {
  __typename: "BankDocOutputAmount";
  value: any;
  typeEnum: P5AmountTypeEnum;
}

export interface GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows {
  __typename: "BankDocOutputRow";
  id: string;
  isSelected: boolean;
  rowDate: any;
  description: string;
  memo: string;
  bankDocOutputAmount: GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows_bankDocOutputAmount;
}

export interface GetBankDocOutputById_bankDocOutputById_bankDocOutputTable {
  __typename: "BankDocOutputTable";
  bankDocOutputRows: GetBankDocOutputById_bankDocOutputById_bankDocOutputTable_bankDocOutputRows[];
}

export interface GetBankDocOutputById_bankDocOutputById {
  __typename: "BankDocOutput";
  id: string;
  orgId: string;
  customerId: string;
  bankDocOutputBalances: GetBankDocOutputById_bankDocOutputById_bankDocOutputBalances;
  bankDocOutputTable: GetBankDocOutputById_bankDocOutputById_bankDocOutputTable;
}

export interface GetBankDocOutputById {
  bankDocOutputById: GetBankDocOutputById_bankDocOutputById | null;
}

export interface GetBankDocOutputByIdVariables {
  bankDocOutputId: string;
  locationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBankDocState
// ====================================================

export interface UpdateBankDocState_updateBankDocState_bankDoc {
  __typename: "BankDoc";
  id: string;
}

export interface UpdateBankDocState_updateBankDocState {
  __typename: "UpdateBankDocStatePayload";
  bankDoc: UpdateBankDocState_updateBankDocState_bankDoc | null;
}

export interface UpdateBankDocState {
  updateBankDocState: UpdateBankDocState_updateBankDocState;
}

export interface UpdateBankDocStateVariables {
  updateBankDocStateInput: UpdateBankDocStateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBankDocDetails
// ====================================================

export interface UpdateBankDocDetails_updateBankDocDetails_bankDoc {
  __typename: "BankDoc";
  id: string;
}

export interface UpdateBankDocDetails_updateBankDocDetails {
  __typename: "UpdateBankDocDetailsPayload";
  bankDoc: UpdateBankDocDetails_updateBankDocDetails_bankDoc | null;
}

export interface UpdateBankDocDetails {
  updateBankDocDetails: UpdateBankDocDetails_updateBankDocDetails;
}

export interface UpdateBankDocDetailsVariables {
  updateBankDocDetailsInput: UpdateBankDocDetailsInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchBankNameFid
// ====================================================

export interface GetSearchBankNameFid_searchBankNameFid {
  __typename: "BankNameFidDto";
  fid: string;
  name: string;
  urlHost: string;
}

export interface GetSearchBankNameFid {
  searchBankNameFid: GetSearchBankNameFid_searchBankNameFid[];
}

export interface GetSearchBankNameFidVariables {
  searchTerm: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomersByLocation
// ====================================================

export interface GetCustomersByLocation_customers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean; // Indicates whether more edges exist following the set defined by the clients arguments.
  hasPreviousPage: boolean; // Indicates whether more edges exist prior the set defined by the clients arguments.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  endCursor: string | null; // When paginating forwards, the cursor to continue.
}

export interface GetCustomersByLocation_customers_edges_node_contact_email {
  __typename: "ContactEmail";
  id: string;
  emailAddress: string;
}

export interface GetCustomersByLocation_customers_edges_node_contact_phoneNumbers {
  __typename: "ContactPhoneNumber";
  id: string;
  number: string;
}

export interface ContactType {
  id: string;
  contactType: string;
}

export interface GetCustomersByLocation_customers_edges_node_contact {
  __typename: "Contact";
  id: string;
  fullName: string;
  avatarInitials: string;
  contactTypeEnumId: string;
  email: GetCustomersByLocation_customers_edges_node_contact_email | null;
  phoneNumbers: GetCustomersByLocation_customers_edges_node_contact_phoneNumbers[];
}

export interface GetCustomersByLocation_customers_edges_node {
  __typename: "Customer";
  id: string;
  name: string;
  deletedAt: any | null;
  relayId: string;
  contact: GetCustomersByLocation_customers_edges_node_contact | null;
}

export interface GetCustomersByLocation_customers_edges {
  __typename: "CustomersEdge";
  node: GetCustomersByLocation_customers_edges_node; // The item at the end of the edge.
}

export interface GetCustomersByLocation_customers {
  __typename: "CustomersConnection";
  pageInfo: GetCustomersByLocation_customers_pageInfo; // Information to aid in pagination.
  edges: GetCustomersByLocation_customers_edges[] | null; // A list of edges.
}

export interface GetCustomersByLocation {
  customers: GetCustomersByLocation_customers | null;
}

export interface GetCustomersByLocationVariables {
  where?: CustomerFilterInput | null;
  first?: number | null;
  after?: string | null;
  order?: CustomerSortInput[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomerById
// ====================================================

export interface GetCustomerById_customerById_customerTypeEnum {
  __typename: "CustomerTypeEnum";
  id: string;
  customerType: string;
}

export interface GetCustomerById_customerById_contact_email {
  __typename: "ContactEmail";
  id: string;
  emailAddress: string;
}

export interface GetCustomerById_customerById_contact_phoneNumbers_phoneNumberTypeEnum {
  __typename: "PhoneNumberTypeEnum";
  id: string;
  phoneNumberType: string;
}

export interface GetCustomerById_customerById_contact_phoneNumbers {
  __typename: "ContactPhoneNumber";
  id: string;
  phoneNumberTypeEnum: GetCustomerById_customerById_contact_phoneNumbers_phoneNumberTypeEnum;
  number: string;
}

export interface GetCustomerById_customerById_contact {
  __typename: "Contact";
  id: string;
  fullName: string;
  avatarInitials: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: GetCustomerById_customerById_contact_email | null;
  phoneNumbers: GetCustomerById_customerById_contact_phoneNumbers[];
}

export interface GetCustomerById_customerById_addresses_addressTypeEnum {
  __typename: "AddressTypeEnum";
  id: string;
  addressType: string;
}

export interface GetCustomerById_customerById_addresses {
  __typename: "CustomerAddress";
  id: string;
  line1: string | null;
  line2: string | null;
  city: string;
  region: string;
  country: string;
  postalCode: string;
  addressTypeEnum: GetCustomerById_customerById_addresses_addressTypeEnum;
}

export interface GetCustomerById_customerById_customerTaxIds {
  __typename: "CustomerTaxId";
  id: string;
  taxIdType: string;
  taxIdValue: string;
}

export interface GetCustomerById_customerById {
  __typename: "Customer";
  id: string;
  name: string;
  deletedAt: any | null;
  customerTypeEnum: GetCustomerById_customerById_customerTypeEnum | null;
  contact: GetCustomerById_customerById_contact | null;
  addresses: GetCustomerById_customerById_addresses[];
  customerTaxIds: GetCustomerById_customerById_customerTaxIds[];
}

export interface GetCustomerById {
  customerById: GetCustomerById_customerById;
}

export interface GetCustomerByIdVariables {
  customerId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomersByLocationForAdd
// ====================================================

export interface GetCustomersByLocationForAdd_customers_edges_node {
  __typename: "Customer";
  id: string;
  name: string;
}

export interface GetCustomersByLocationForAdd_customers_edges {
  __typename: "CustomersEdge";
  node: GetCustomersByLocationForAdd_customers_edges_node; // The item at the end of the edge.
}

export interface GetCustomersByLocationForAdd_customers {
  __typename: "CustomersConnection";
  edges: GetCustomersByLocationForAdd_customers_edges[] | null; // A list of edges.
}

export interface GetCustomersByLocationForAdd {
  customers: GetCustomersByLocationForAdd_customers | null;
}

export interface GetCustomersByLocationForAddVariables {
  locationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCustomer
// ====================================================

export interface EditCustomer_editCustomer_customer {
  __typename: "Customer";
  id: string;
}

export interface EditCustomer_editCustomer {
  __typename: "EditCustomerPayload";
  customer: EditCustomer_editCustomer_customer | null;
}

export interface EditCustomer {
  editCustomer: EditCustomer_editCustomer;
}

export interface EditCustomerVariables {
  editCustomerInput: EditCustomerInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCustomer
// ====================================================

export interface AddCustomer_addCustomer_customer {
  __typename: "Customer";
  id: string;
}

export interface AddCustomer_addCustomer {
  __typename: "AddCustomerPayload";
  customer: AddCustomer_addCustomer_customer | null;
}

export interface AddCustomer {
  addCustomer: AddCustomer_addCustomer;
}

export interface AddCustomerVariables {
  addCustomerInput: AddCustomerInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerState
// ====================================================

export interface UpdateCustomerState_updateCustomerState_customer {
  __typename: "Customer";
  id: string;
}

export interface UpdateCustomerState_updateCustomerState {
  __typename: "UpdateCustomerStatePayload";
  customer: UpdateCustomerState_updateCustomerState_customer | null;
}

export interface UpdateCustomerState {
  updateCustomerState: UpdateCustomerState_updateCustomerState;
}

export interface UpdateCustomerStateVariables {
  updateCustomerStateInput: UpdateCustomerStateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBankDocsAggByDate
// ====================================================

export interface GetBankDocsAggByDate_bankDocsAggByDate_rows {
  __typename: "BankDocsAggByDateRow";
  eventDate: any;
  eventCount: number;
}

export interface GetBankDocsAggByDate_bankDocsAggByDate {
  __typename: "BankDocsAggByDateOutput";
  rows: GetBankDocsAggByDate_bankDocsAggByDate_rows[];
}

export interface GetBankDocsAggByDate {
  bankDocsAggByDate: GetBankDocsAggByDate_bankDocsAggByDate;
}

export interface GetBankDocsAggByDateVariables {
  locationId: string;
  currentDt: any;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobsByUserAndDueDate
// ====================================================

export interface GetJobsByUserAndDueDate_jobs_edges_node_customer {
  __typename: "Customer";
  id: string;
  name: string;
}

export interface GetJobsByUserAndDueDate_jobs_edges_node_jobStatus {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  color: string;
}

export interface GetJobsByUserAndDueDate_jobs_edges_node_assignedUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetJobsByUserAndDueDate_jobs_edges_node_assignedUser {
  __typename: "User";
  id: string;
  userProfile: GetJobsByUserAndDueDate_jobs_edges_node_assignedUser_userProfile | null;
}

export interface GetJobsByUserAndDueDate_jobs_edges_node {
  __typename: "Job";
  id: string;
  name: string;
  description: string | null;
  dueDate: any;
  customer: GetJobsByUserAndDueDate_jobs_edges_node_customer;
  jobStatus: GetJobsByUserAndDueDate_jobs_edges_node_jobStatus | null;
  assignedUser: GetJobsByUserAndDueDate_jobs_edges_node_assignedUser | null;
}

export interface GetJobsByUserAndDueDate_jobs_edges {
  __typename: "JobsEdge";
  node: GetJobsByUserAndDueDate_jobs_edges_node; // The item at the end of the edge.
}

export interface GetJobsByUserAndDueDate_jobs {
  __typename: "JobsConnection";
  edges: GetJobsByUserAndDueDate_jobs_edges[] | null; // A list of edges.
}

export interface GetJobsByUserAndDueDate {
  jobs: GetJobsByUserAndDueDate_jobs | null;
}

export interface GetJobsByUserAndDueDateVariables {
  userId: string;
  fromDueDate: any;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobCommentsByJobId
// ====================================================

export interface GetJobCommentsByJobId_jobComments_edges_node_job {
  __typename: "Job";
  id: string;
  name: string;
}

export interface GetJobCommentsByJobId_jobComments_edges_node_byUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetJobCommentsByJobId_jobComments_edges_node_byUser {
  __typename: "User";
  id: string;
  userProfile: GetJobCommentsByJobId_jobComments_edges_node_byUser_userProfile | null;
}

export interface GetJobCommentsByJobId_jobComments_edges_node {
  __typename: "JobComment";
  id: string;
  comment: string;
  createdAt: any;
  job: GetJobCommentsByJobId_jobComments_edges_node_job | null;
  byUser: GetJobCommentsByJobId_jobComments_edges_node_byUser | null;
}

export interface GetJobCommentsByJobId_jobComments_edges {
  __typename: "JobCommentsEdge";
  node: GetJobCommentsByJobId_jobComments_edges_node; // The item at the end of the edge.
}

export interface GetJobCommentsByJobId_jobComments {
  __typename: "JobCommentsConnection";
  edges: GetJobCommentsByJobId_jobComments_edges[] | null; // A list of edges.
}

export interface GetJobCommentsByJobId {
  jobComments: GetJobCommentsByJobId_jobComments | null;
}

export interface GetJobCommentsByJobIdVariables {
  jobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddJobComment
// ====================================================

export interface AddJobComment_addJobComment_jobComment {
  __typename: "JobComment";
  id: string;
}

export interface AddJobComment_addJobComment {
  __typename: "AddJobCommentPayload";
  jobComment: AddJobComment_addJobComment_jobComment | null;
}

export interface AddJobComment {
  addJobComment: AddJobComment_addJobComment;
}

export interface AddJobCommentVariables {
  addJobCommentInput: AddJobCommentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobTasksByJobId
// ====================================================

export interface GetJobTasksByJobId_jobTasks_edges_node {
  __typename: "JobTask";
  id: string;
  name: string;
  description: string | null;
  linkName: string | null;
  linkUrl: string | null;
  order: number;
  isDone: boolean;
}

export interface GetJobTasksByJobId_jobTasks_edges {
  __typename: "JobTasksEdge";
  node: GetJobTasksByJobId_jobTasks_edges_node; // The item at the end of the edge.
}

export interface GetJobTasksByJobId_jobTasks {
  __typename: "JobTasksConnection";
  edges: GetJobTasksByJobId_jobTasks_edges[] | null; // A list of edges.
}

export interface GetJobTasksByJobId {
  jobTasks: GetJobTasksByJobId_jobTasks | null;
}

export interface GetJobTasksByJobIdVariables {
  jobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobTaskStatusByIds
// ====================================================

export interface UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks_edges_node {
  __typename: "JobTask";
  id: string;
  isDone: boolean;
}

export interface UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks_edges {
  __typename: "JobTasksEdge";
  node: UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks_edges_node; // The item at the end of the edge.
}

export interface UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks {
  __typename: "JobTasksConnection";
  edges:
    | UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks_edges[]
    | null; // A list of edges.
}

export interface UpdateJobTaskStatusByIds_updateJobTaskStatus_job {
  __typename: "Job";
  id: string;
  jobTasks: UpdateJobTaskStatusByIds_updateJobTaskStatus_job_jobTasks | null;
}

export interface UpdateJobTaskStatusByIds_updateJobTaskStatus {
  __typename: "UpdateJobTaskStatusPayload";
  job: UpdateJobTaskStatusByIds_updateJobTaskStatus_job | null;
}

export interface UpdateJobTaskStatusByIds {
  updateJobTaskStatus: UpdateJobTaskStatusByIds_updateJobTaskStatus;
}

export interface UpdateJobTaskStatusByIdsVariables {
  updateJobTaskStatusInput: UpdateJobTaskStatusInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobsByLocation
// ====================================================

export interface GetJobsByLocation_jobs_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean; // Indicates whether more edges exist following the set defined by the clients arguments.
  hasPreviousPage: boolean; // Indicates whether more edges exist prior the set defined by the clients arguments.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  endCursor: string | null; // When paginating forwards, the cursor to continue.
}

export interface GetJobsByLocation_jobs_edges_node_customer {
  __typename: "Customer";
  id: string;
  name: string;
}

export interface GetJobsByLocation_jobs_edges_node_jobStatus {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  color: string;
}

export interface GetJobsByLocation_jobs_edges_node_assignedUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetJobsByLocation_jobs_edges_node_assignedUser {
  __typename: "User";
  id: string;
  userProfile: GetJobsByLocation_jobs_edges_node_assignedUser_userProfile | null;
}

export interface GetJobsByLocation_jobs_edges_node_service {
  __typename: "Service";
  id: string;
  name: string;
}

export interface GetJobsByLocation_jobs_edges_node {
  __typename: "Job";
  id: string;
  name: string;
  description: string | null;
  dueDate: any;
  startDate: any;
  customer: GetJobsByLocation_jobs_edges_node_customer;
  jobStatus: GetJobsByLocation_jobs_edges_node_jobStatus | null;
  assignedUser: GetJobsByLocation_jobs_edges_node_assignedUser | null;
  service: GetJobsByLocation_jobs_edges_node_service | null;
}

export interface GetJobsByLocation_jobs_edges {
  __typename: "JobsEdge";
  node: GetJobsByLocation_jobs_edges_node; // The item at the end of the edge.
}

export interface GetJobsByLocation_jobs {
  __typename: "JobsConnection";
  pageInfo: GetJobsByLocation_jobs_pageInfo; // Information to aid in pagination.
  edges: GetJobsByLocation_jobs_edges[] | null; // A list of edges.
}

export interface GetJobsByLocation {
  jobs: GetJobsByLocation_jobs | null;
}

export interface GetJobsByLocationVariables {
  where?: JobFilterInput | null;
  first?: number | null;
  after?: string | null;
  order?: JobSortInput[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobsByCustomerId
// ====================================================

export interface GetJobsByCustomerId_jobs_edges_node_jobStatus {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  color: string;
}

export interface GetJobsByCustomerId_jobs_edges_node_assignedUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetJobsByCustomerId_jobs_edges_node_assignedUser {
  __typename: "User";
  id: string;
  userProfile: GetJobsByCustomerId_jobs_edges_node_assignedUser_userProfile | null;
}

export interface GetJobsByCustomerId_jobs_edges_node {
  __typename: "Job";
  id: string;
  name: string;
  dueDate: any;
  jobStatus: GetJobsByCustomerId_jobs_edges_node_jobStatus | null;
  assignedUser: GetJobsByCustomerId_jobs_edges_node_assignedUser | null;
}

export interface GetJobsByCustomerId_jobs_edges {
  __typename: "JobsEdge";
  node: GetJobsByCustomerId_jobs_edges_node; // The item at the end of the edge.
}

export interface GetJobsByCustomerId_jobs {
  __typename: "JobsConnection";
  edges: GetJobsByCustomerId_jobs_edges[] | null; // A list of edges.
}

export interface GetJobsByCustomerId {
  jobs: GetJobsByCustomerId_jobs | null;
}

export interface GetJobsByCustomerIdVariables {
  customerId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobById
// ====================================================

export interface GetJobById_jobById_customer_contact_email {
  __typename: "ContactEmail";
  id: string;
  emailAddress: string;
}

export interface GetJobById_jobById_customer_contact_phoneNumbers_phoneNumberTypeEnum {
  __typename: "PhoneNumberTypeEnum";
  id: string;
  phoneNumberType: string;
}

export interface GetJobById_jobById_customer_contact_phoneNumbers {
  __typename: "ContactPhoneNumber";
  id: string;
  number: string;
  phoneNumberTypeEnum: GetJobById_jobById_customer_contact_phoneNumbers_phoneNumberTypeEnum;
}

export interface GetJobById_jobById_customer_contact {
  __typename: "Contact";
  id: string;
  fullName: string;
  avatarInitials: string;
  email: GetJobById_jobById_customer_contact_email | null;
  phoneNumbers: GetJobById_jobById_customer_contact_phoneNumbers[];
}

export interface GetJobById_jobById_customer_customerTaxIds {
  __typename: "CustomerTaxId";
  id: string;
  taxIdType: string;
  taxIdValue: string;
  order: number;
}

export interface GetJobById_jobById_customer {
  __typename: "Customer";
  id: string;
  name: string;
  contact: GetJobById_jobById_customer_contact | null;
  customerTaxIds: GetJobById_jobById_customer_customerTaxIds[];
}

export interface GetJobById_jobById_jobStatus {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  color: string;
}

export interface GetJobById_jobById_assignedUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetJobById_jobById_assignedUser {
  __typename: "User";
  id: string;
  userProfile: GetJobById_jobById_assignedUser_userProfile | null;
}

export interface GetJobById_jobById_service_serviceFee_serviceFeeTypeEnum {
  __typename: "ServiceFeeTypeEnum";
  id: string;
  feeType: string;
}

export interface GetJobById_jobById_service_serviceFee {
  __typename: "ServiceFee";
  id: string;
  rate: any;
  serviceFeeTypeEnum: GetJobById_jobById_service_serviceFee_serviceFeeTypeEnum | null;
}

export interface GetJobById_jobById_service {
  __typename: "Service";
  id: string;
  name: string;
  description: string | null;
  serviceFee: GetJobById_jobById_service_serviceFee;
}

export interface GetJobById_jobById {
  __typename: "Job";
  id: string;
  name: string;
  description: string | null;
  dueDate: any;
  customer: GetJobById_jobById_customer;
  jobStatus: GetJobById_jobById_jobStatus | null;
  assignedUser: GetJobById_jobById_assignedUser | null;
  service: GetJobById_jobById_service | null;
}

export interface GetJobById {
  jobById: GetJobById_jobById;
}

export interface GetJobByIdVariables {
  jobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersAndServicesForAddJob
// ====================================================

export interface GetUsersAndServicesForAddJob_users_edges_node_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetUsersAndServicesForAddJob_users_edges_node {
  __typename: "User";
  id: string;
  userProfile: GetUsersAndServicesForAddJob_users_edges_node_userProfile | null;
}

export interface GetUsersAndServicesForAddJob_users_edges {
  __typename: "UsersEdge";
  node: GetUsersAndServicesForAddJob_users_edges_node; // The item at the end of the edge.
}

export interface GetUsersAndServicesForAddJob_users {
  __typename: "UsersConnection";
  edges: GetUsersAndServicesForAddJob_users_edges[] | null; // A list of edges.
}

export interface GetUsersAndServicesForAddJob_services_edges_node {
  __typename: "Service";
  id: string;
  name: string;
}

export interface GetUsersAndServicesForAddJob_services_edges {
  __typename: "ServicesEdge";
  node: GetUsersAndServicesForAddJob_services_edges_node; // The item at the end of the edge.
}

export interface GetUsersAndServicesForAddJob_services {
  __typename: "ServicesConnection";
  edges: GetUsersAndServicesForAddJob_services_edges[] | null; // A list of edges.
}

export interface GetUsersAndServicesForAddJob {
  users: GetUsersAndServicesForAddJob_users | null;
  services: GetUsersAndServicesForAddJob_services | null;
}

export interface GetUsersAndServicesForAddJobVariables {
  orgId: string;
  locationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddWork
// ====================================================

export interface AddJob_addJob_job {
  __typename: "Job";
  id: string;
}

export interface AddJob_addJob {
  __typename: "AddJobPayload";
  job: AddJob_addJob_job | null;
}

export interface AddJob {
  addJob: AddJob_addJob;
}

export interface AddJobVariables {
  addJobInput: AddJobInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersAndStatusesForUpdateJobSummary
// ====================================================

export interface GetUsersAndStatusesForUpdateJobSummary_users_edges_node_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface GetUsersAndStatusesForUpdateJobSummary_users_edges_node {
  __typename: "User";
  id: string;
  userProfile: GetUsersAndStatusesForUpdateJobSummary_users_edges_node_userProfile | null;
}

export interface GetUsersAndStatusesForUpdateJobSummary_users_edges {
  __typename: "UsersEdge";
  node: GetUsersAndStatusesForUpdateJobSummary_users_edges_node; // The item at the end of the edge.
}

export interface GetUsersAndStatusesForUpdateJobSummary_users {
  __typename: "UsersConnection";
  edges: GetUsersAndStatusesForUpdateJobSummary_users_edges[] | null; // A list of edges.
}

export interface GetUsersAndStatusesForUpdateJobSummary_serviceStatuses_edges_node {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  description: string | null;
  color: string;
  order: number;
}

export interface GetUsersAndStatusesForUpdateJobSummary_serviceStatuses_edges {
  __typename: "ServiceStatusesEdge";
  node: GetUsersAndStatusesForUpdateJobSummary_serviceStatuses_edges_node; // The item at the end of the edge.
}

export interface GetUsersAndStatusesForUpdateJobSummary_serviceStatuses {
  __typename: "ServiceStatusesConnection";
  edges: GetUsersAndStatusesForUpdateJobSummary_serviceStatuses_edges[] | null; // A list of edges.
}

export interface GetUsersAndStatusesForUpdateJobSummary {
  users: GetUsersAndStatusesForUpdateJobSummary_users | null;
  serviceStatuses: GetUsersAndStatusesForUpdateJobSummary_serviceStatuses | null;
}

export interface GetUsersAndStatusesForUpdateJobSummaryVariables {
  orgId: string;
  serviceId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobSummary
// ====================================================

export interface UpdateJobSummary_updateJobSummary_job_jobStatus {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  color: string;
}

export interface UpdateJobSummary_updateJobSummary_job_assignedUser_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
}

export interface UpdateJobSummary_updateJobSummary_job_assignedUser {
  __typename: "User";
  id: string;
  userProfile: UpdateJobSummary_updateJobSummary_job_assignedUser_userProfile | null;
}

export interface UpdateJobSummary_updateJobSummary_job {
  __typename: "Job";
  id: string;
  dueDate: any;
  jobStatus: UpdateJobSummary_updateJobSummary_job_jobStatus | null;
  assignedUser: UpdateJobSummary_updateJobSummary_job_assignedUser | null;
}

export interface UpdateJobSummary_updateJobSummary {
  __typename: "UpdateJobSummaryPayload";
  job: UpdateJobSummary_updateJobSummary_job | null;
}

export interface UpdateJobSummary {
  updateJobSummary: UpdateJobSummary_updateJobSummary;
}

export interface UpdateJobSummaryVariables {
  updateJobSummaryInput: UpdateJobSummaryInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLocationsByOrgId
// ====================================================

export interface GetLocationsByOrgId_orgById_locations_edges_node_locationAddress {
  __typename?: "LocationAddress";
  id: string;
  line1: string | null;
  line2: string | null;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

export interface GetLocationsByOrgId_orgById_locations_edges_node {
  __typename?: "Location";
  id: string;
  locationAddress: GetLocationsByOrgId_orgById_locations_edges_node_locationAddress | null;
}

export interface GetLocationsByOrgId_orgById_locations_edges {
  __typename?: "LocationsEdge";
  node: GetLocationsByOrgId_orgById_locations_edges_node; // The item at the end of the edge.
}

export interface GetLocationsByOrgId_orgById_locations {
  __typename?: "LocationsConnection";
  edges: GetLocationsByOrgId_orgById_locations_edges[] | null; // A list of edges.
}

export interface GetLocationsByOrgId_orgById {
  __typename: "Org";
  id: string;
  legalName: string;
  locations: GetLocationsByOrgId_orgById_locations | null;
}

export interface GetLocationsByOrgId {
  orgById: GetLocationsByOrgId_orgById;
}

export interface GetLocationsByOrgIdVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOnboardingStatus
// ====================================================

export interface GetOnboardingStatus {
  orgIsOnboarded: boolean;
  userIsOnboarded: boolean;
}

export interface GetOnboardingStatusVariables {
  orgId: string;
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OnboardUser
// ====================================================

export interface OnboardUser_onboardUser_user {
  __typename: "User";
  id: string;
}

export interface OnboardUser_onboardUser {
  __typename: "OnboardUserPayload";
  user: OnboardUser_onboardUser_user | null;
}

export interface OnboardUser {
  onboardUser: OnboardUser_onboardUser;
}

export interface OnboardUserVariables {
  onboardUserInput: OnboardUserInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OnboardOrg
// ====================================================

export interface OnboardOrg_onboardOrg_org {
  __typename: "Org";
  id: string;
}

export interface OnboardOrg_onboardOrg {
  __typename: "OnboardOrgPayload";
  org: OnboardOrg_onboardOrg_org | null;
}

export interface OnboardOrg {
  onboardOrg: OnboardOrg_onboardOrg;
}

export interface OnboardOrgVariables {
  onboardOrgInput: OnboardOrgInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgBillingPlanLimits
// ====================================================

export interface GetOrgBillingPlanLimits_orgBillings_edges_node_totalUsageSavings {
  __typename: "UsageSavingsDto";
  totalPageCount: number;
  totalTimeSaved: number;
}

export interface GetOrgBillingPlanLimits_orgBillings_edges_node {
  __typename: "OrgBilling";
  bankDocsIsFreeTrialPlan: boolean;
  hasExceededBdPlanLimit: boolean;
  totalUsageSavings: GetOrgBillingPlanLimits_orgBillings_edges_node_totalUsageSavings;
}

export interface GetOrgBillingPlanLimits_orgBillings_edges {
  __typename: "OrgBillingsEdge";
  node: GetOrgBillingPlanLimits_orgBillings_edges_node; // The item at the end of the edge.
}

export interface GetOrgBillingPlanLimits_orgBillings {
  __typename: "OrgBillingsConnection";
  edges: GetOrgBillingPlanLimits_orgBillings_edges[] | null; // A list of edges.
}

export interface GetOrgBillingPlanLimits {
  orgBillings: GetOrgBillingPlanLimits_orgBillings | null;
}

export interface GetOrgBillingPlanLimitsVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgBilling
// ====================================================

export interface GetOrgBilling_orgBillings_edges_node_subscriptionPlan {
  __typename: "ProcBdSubscriptionPlan";
  id: string;
  isCurrent: boolean;
  name: string;
  primaryAmount: any;
  primaryDuration: string | null;
  primaryUnit: string;
  currency: string;
  additionalAmount: any | null;
  additionalUnit: string | null;
}

export interface GetOrgBilling_orgBillings_edges_node_paymentInfo_procCardInfo {
  __typename: "ProcCardInfo";
  cardLast4: string | null;
  cardBrand: string | null;
}

export interface GetOrgBilling_orgBillings_edges_node_paymentInfo {
  __typename: "ProcPaymentInfo";
  procCardInfo: GetOrgBilling_orgBillings_edges_node_paymentInfo_procCardInfo | null;
}

export interface GetOrgBilling_orgBillings_edges_node_totalUsageSavings {
  __typename: "UsageSavingsDto";
  totalPageCount: number;
  totalTimeSaved: number;
}

export interface GetOrgBilling_orgBillings_edges_node_billingCycleUsage_upcomingInvoiceInfo {
  __typename: "ProcUpcomingInvoiceInfo";
  startDate: any;
  endDate: any;
  amount: any;
  currency: string;
}

export interface GetOrgBilling_orgBillings_edges_node_billingCycleUsage {
  __typename: "ProcBillingCycleUsageOutput";
  upcomingInvoiceInfo: GetOrgBilling_orgBillings_edges_node_billingCycleUsage_upcomingInvoiceInfo;
  totalBdCustomersPurchased: any;
  totalBdCustomersUsed: any;
}

export interface GetOrgBilling_orgBillings_edges_node {
  __typename: "OrgBilling";
  id: string;
  createdAt: any;
  subscriptionPlan: GetOrgBilling_orgBillings_edges_node_subscriptionPlan;
  paymentInfo: GetOrgBilling_orgBillings_edges_node_paymentInfo;
  totalUsageSavings: GetOrgBilling_orgBillings_edges_node_totalUsageSavings;
  billingCycleUsage: GetOrgBilling_orgBillings_edges_node_billingCycleUsage;
}

export interface GetOrgBilling_orgBillings_edges {
  __typename: "OrgBillingsEdge";
  node: GetOrgBilling_orgBillings_edges_node; // The item at the end of the edge.
}

export interface GetOrgBilling_orgBillings {
  __typename: "OrgBillingsConnection";
  edges: GetOrgBilling_orgBillings_edges[] | null; // A list of edges.
}

export interface GetOrgBilling {
  orgBillings: GetOrgBilling_orgBillings | null;
}

export interface GetOrgBillingVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubscriptionPlans
// ====================================================

export interface GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans {
  __typename: "ProcBdSubscriptionPlan";
  id: string;
  isCurrent: boolean;
  canChoose: boolean;
  name: string;
  primaryAmount: any;
  primaryDuration: string | null;
  primaryUnit: string;
  currency: string;
  additionalAmount: any | null;
  additionalUnit: string | null;
  features: string[];
  flairText: string | null;
}

export interface GetSubscriptionPlans_orgBillings_edges_node_paymentInfo_procCardInfo {
  __typename: "ProcCardInfo";
  cardLast4: string | null;
  cardBrand: string | null;
}

export interface GetSubscriptionPlans_orgBillings_edges_node_paymentInfo {
  __typename: "ProcPaymentInfo";
  procCardInfo: GetSubscriptionPlans_orgBillings_edges_node_paymentInfo_procCardInfo | null;
  setupIntentId: string | null;
  clientPublishableKey: string;
}

export interface GetSubscriptionPlans_orgBillings_edges_node {
  __typename: "OrgBilling";
  id: string;
  subscriptionPlans: GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans[];
  paymentInfo: GetSubscriptionPlans_orgBillings_edges_node_paymentInfo;
}

export interface GetSubscriptionPlans_orgBillings_edges {
  __typename: "OrgBillingsEdge";
  node: GetSubscriptionPlans_orgBillings_edges_node; // The item at the end of the edge.
}

export interface GetSubscriptionPlans_orgBillings {
  __typename: "OrgBillingsConnection";
  edges: GetSubscriptionPlans_orgBillings_edges[] | null; // A list of edges.
}

export interface GetSubscriptionPlans {
  orgBillings: GetSubscriptionPlans_orgBillings | null;
}

export interface GetSubscriptionPlansVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SwitchBdSubscriptionPlan
// ====================================================

export interface SwitchBdSubscriptionPlan_switchBdSubscription_switchBdSubscriptionOutput {
  __typename: "SwitchBdSubscriptionOutput";
  newSubscriptionPlanId: string;
}

export interface SwitchBdSubscriptionPlan_switchBdSubscription {
  __typename: "SwitchBdSubscriptionPayload";
  switchBdSubscriptionOutput: SwitchBdSubscriptionPlan_switchBdSubscription_switchBdSubscriptionOutput | null;
}

export interface SwitchBdSubscriptionPlan {
  switchBdSubscription: SwitchBdSubscriptionPlan_switchBdSubscription;
}

export interface SwitchBdSubscriptionPlanVariables {
  switchBdSubscriptionInput: SwitchBdSubscriptionInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddBdLicenses
// ====================================================

export interface AddBdLicenses_addBdLicenses_orgBilling {
  __typename: "OrgBilling";
  id: string;
}

export interface AddBdLicenses_addBdLicenses {
  __typename: "AddBdLicensesPayload";
  orgBilling: AddBdLicenses_addBdLicenses_orgBilling | null;
}

export interface AddBdLicenses {
  addBdLicenses: AddBdLicenses_addBdLicenses;
}

export interface AddBdLicensesVariables {
  addBdLicensesInput: AddBdLicensesInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgs
// ====================================================

export interface GetOrgs_orgs_edges_node {
  __typename: "Org";
  id: string;
  legalName: string;
}

export interface GetOrgs_orgs_edges {
  __typename: "OrgsEdge";
  node: GetOrgs_orgs_edges_node; // The item at the end of the edge.
}

export interface GetOrgs_orgs {
  __typename: "OrgsConnection";
  edges: GetOrgs_orgs_edges[] | null; // A list of edges.
}

export interface GetOrgs {
  orgs: GetOrgs_orgs | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrgById
// ====================================================

export interface GetOrgById_orgById {
  __typename: "Org";
  id: string;
  legalName: string;
  createdAt: string;
  locations: {
    edges: [
      {
        node: {
          createdAt: string;
          id: string;
          locationAddress: {
            createdAt: string;
            id: string;
            city: string;
            country: string;
            line1: string;
            line2: string;
            region: string;
            postalCode: string;
          };
        };
      },
    ];
  };
}

export interface GetOrgById {
  orgById: GetOrgById_orgById;
}

export interface GetOrgByIdVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameOrg
// ====================================================

export interface RenameOrg_renameOrg_org {
  __typename: "Org";
  id: string;
}

export interface RenameOrg_renameOrg {
  __typename: "RenameOrgPayload";
  org: RenameOrg_renameOrg_org | null;
}

export interface RenameOrg {
  renameOrg: RenameOrg_renameOrg;
}

export interface RenameOrgVariables {
  input: RenameOrgInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServicesByLocation
// ====================================================

export interface GetServicesByLocation_services_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean; // Indicates whether more edges exist following the set defined by the clients arguments.
  hasPreviousPage: boolean; // Indicates whether more edges exist prior the set defined by the clients arguments.
  startCursor: string | null; // When paginating backwards, the cursor to continue.
  endCursor: string | null; // When paginating forwards, the cursor to continue.
}

export interface GetServicesByLocation_services_edges_node_serviceFee_serviceFeeTypeEnum {
  __typename: "ServiceFeeTypeEnum";
  id: string;
  feeType: string;
}

export interface GetServicesByLocation_services_edges_node_serviceFee {
  __typename: "ServiceFee";
  id: string;
  rate: any;
  serviceFeeTypeEnum: GetServicesByLocation_services_edges_node_serviceFee_serviceFeeTypeEnum | null;
}

export interface GetServicesByLocation_services_edges_node {
  __typename: "Service";
  id: string;
  name: string;
  description: string | null;
  serviceFee: GetServicesByLocation_services_edges_node_serviceFee;
}

export interface GetServicesByLocation_services_edges {
  __typename: "ServicesEdge";
  node: GetServicesByLocation_services_edges_node; // The item at the end of the edge.
}

export interface GetServicesByLocation_services {
  __typename: "ServicesConnection";
  pageInfo: GetServicesByLocation_services_pageInfo; // Information to aid in pagination.
  edges: GetServicesByLocation_services_edges[] | null; // A list of edges.
}

export interface GetServicesByLocation {
  services: GetServicesByLocation_services | null;
}

export interface GetServicesByLocationVariables {
  where?: ServiceFilterInput | null;
  first?: number | null;
  after?: string | null;
  order?: ServiceSortInput[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServiceById
// ====================================================

export interface GetServiceById_serviceById_serviceFee_serviceFeeTypeEnum {
  __typename: "ServiceFeeTypeEnum";
  id: string;
  feeType: string;
}

export interface GetServiceById_serviceById_serviceFee {
  __typename: "ServiceFee";
  id: string;
  rate: any;
  serviceFeeTypeEnum: GetServiceById_serviceById_serviceFee_serviceFeeTypeEnum | null;
}

export interface GetServiceById_serviceById_serviceStatuses_edges_node {
  __typename: "ServiceStatus";
  id: string;
  name: string;
  description: string | null;
  color: string;
  order: number;
}

export interface GetServiceById_serviceById_serviceStatuses_edges {
  __typename: "ServiceStatusesEdge";
  node: GetServiceById_serviceById_serviceStatuses_edges_node; // The item at the end of the edge.
}

export interface GetServiceById_serviceById_serviceStatuses {
  __typename: "ServiceStatusesConnection";
  edges: GetServiceById_serviceById_serviceStatuses_edges[] | null; // A list of edges.
}

export interface GetServiceById_serviceById_serviceTasks_edges_node {
  __typename: "ServiceTask";
  id: string;
  name: string;
  description: string | null;
  linkName: string | null;
  linkUrl: string | null;
  order: number;
}

export interface GetServiceById_serviceById_serviceTasks_edges {
  __typename: "ServiceTasksEdge";
  node: GetServiceById_serviceById_serviceTasks_edges_node; // The item at the end of the edge.
}

export interface GetServiceById_serviceById_serviceTasks {
  __typename: "ServiceTasksConnection";
  edges: GetServiceById_serviceById_serviceTasks_edges[] | null; // A list of edges.
}

export interface GetServiceById_serviceById {
  __typename: "Service";
  id: string;
  relayId: string;
  locationId: any | null;
  name: string;
  description: string | null;
  serviceFee: GetServiceById_serviceById_serviceFee;
  serviceStatuses: GetServiceById_serviceById_serviceStatuses | null;
  serviceTasks: GetServiceById_serviceById_serviceTasks | null;
}

export interface GetServiceById {
  serviceById: GetServiceById_serviceById;
}

export interface GetServiceByIdVariables {
  serviceId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetServiceFeeTypeEnums
// ====================================================

export interface GetServiceFeeTypeEnums_serviceFeeTypeEnums_edges_node {
  __typename: "ServiceFeeTypeEnum";
  id: string;
  feeType: string;
}

export interface GetServiceFeeTypeEnums_serviceFeeTypeEnums_edges {
  __typename: "ServiceFeeTypeEnumsEdge";
  node: GetServiceFeeTypeEnums_serviceFeeTypeEnums_edges_node; // The item at the end of the edge.
}

export interface GetServiceFeeTypeEnums_serviceFeeTypeEnums {
  __typename: "ServiceFeeTypeEnumsConnection";
  edges: GetServiceFeeTypeEnums_serviceFeeTypeEnums_edges[] | null; // A list of edges.
}

export interface GetServiceFeeTypeEnums {
  serviceFeeTypeEnums: GetServiceFeeTypeEnums_serviceFeeTypeEnums | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddService
// ====================================================

export interface AddService_addService_service {
  __typename: "Service";
  id: string;
}

export interface AddService_addService {
  __typename: "AddServicePayload";
  service: AddService_addService_service | null;
}

export interface AddService {
  addService: AddService_addService;
}

export interface AddServiceVariables {
  addServiceInput: AddServiceInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersByOrgId
// ====================================================

export interface GetUsersByOrgId_orgById_users_edges_node_userRoleEnum {
  __typename: "UserRoleEnum";
  id: string;
  userRole: string;
}

export interface GetUsersByOrgId_orgById_users_edges_node_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
  email: string;
}

export interface GetUsersByOrgId_orgById_users_edges_node {
  __typename: "User";
  id: string;
  userRoleEnum: GetUsersByOrgId_orgById_users_edges_node_userRoleEnum | null;
  userProfile: GetUsersByOrgId_orgById_users_edges_node_userProfile | null;
}

export interface GetUsersByOrgId_orgById_users_edges {
  __typename: "UsersEdge";
  node: GetUsersByOrgId_orgById_users_edges_node; // The item at the end of the edge.
}

export interface GetUsersByOrgId_orgById_users {
  __typename: "UsersConnection";
  edges: GetUsersByOrgId_orgById_users_edges[] | null; // A list of edges.
}

export interface GetUsersByOrgId_orgById {
  __typename: "Org";
  id: string;
  legalName: string;
  users: GetUsersByOrgId_orgById_users | null;
}

export interface GetUsersByOrgId {
  orgById: GetUsersByOrgId_orgById;
}

export interface GetUsersByOrgIdVariables {
  orgId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserInfoByEmail
// ====================================================

export interface GetUserInfoByEmail_users_edges_node_userProfile {
  __typename: "UserProfile";
  id: string;
  fullName: string;
  avatarInitials: string;
  email: string;
}

export interface GetUserInfoByEmail_users_edges_node_userRoleEnum {
  __typename: "UserRoleEnum";
  id: string;
  userRole: string;
}

export interface GetUserInfoByEmail_users_edges_node_org_locations_edges_node_locationAddress {
  __typename: "LocationAddress";
  id: string;
  city: string;
  region: string;
}

export interface GetUserInfoByEmail_users_edges_node_org_locations_edges_node {
  __typename: "Location";
  id: string;
  locationAddress: GetUserInfoByEmail_users_edges_node_org_locations_edges_node_locationAddress | null;
}

export interface GetUserInfoByEmail_users_edges_node_org_locations_edges {
  __typename: "LocationsEdge";
  node: GetUserInfoByEmail_users_edges_node_org_locations_edges_node; // The item at the end of the edge.
}

export interface GetUserInfoByEmail_users_edges_node_org_locations {
  __typename: "LocationsConnection";
  edges: GetUserInfoByEmail_users_edges_node_org_locations_edges[] | null; // A list of edges.
}

export interface GetUserInfoByEmail_users_edges_node_org {
  __typename: "Org";
  id: string;
  legalName: string;
  locations: GetUserInfoByEmail_users_edges_node_org_locations | null;
}

export interface GetUserInfoByEmail_users_edges_node {
  __typename: "User";
  id: string;
  userProfile: GetUserInfoByEmail_users_edges_node_userProfile | null;
  userRoleEnum: GetUserInfoByEmail_users_edges_node_userRoleEnum | null;
  org: GetUserInfoByEmail_users_edges_node_org | null;
}

export interface GetUserInfoByEmail_users_edges {
  __typename: "UsersEdge";
  node: GetUserInfoByEmail_users_edges_node; // The item at the end of the edge.
}

export interface GetUserInfoByEmail_users {
  __typename: "UsersConnection";
  edges: GetUserInfoByEmail_users_edges[] | null; // A list of edges.
}

export interface GetUserInfoByEmail {
  users: GetUserInfoByEmail_users | null;
}

export interface GetUserInfoByEmailVariables {
  email: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserQuickStart
// ====================================================

export interface UpdateUserQuickStart_updateUserQuickStartDone_userFeatureFlag {
  __typename: "UserFeatureFlag";
  id: string;
  quickStartV1Completed: boolean;
}

export interface UpdateUserQuickStart_updateUserQuickStartDone {
  __typename: "UpdateUserQuickStartDonePayload";
  userFeatureFlag: UpdateUserQuickStart_updateUserQuickStartDone_userFeatureFlag | null;
}

export interface UpdateUserQuickStart {
  updateUserQuickStartDone: UpdateUserQuickStart_updateUserQuickStartDone;
}

export interface UpdateUserQuickStartVariables {
  input: UpdateUserQuickStartDoneInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserFeatureFlags
// ====================================================

export interface GetUserFeatureFlags_userFeatureFlags_edges_node {
  __typename: "UserFeatureFlag";
  id: string;
  quickStartV1Completed: boolean;
}

export interface GetUserFeatureFlags_userFeatureFlags_edges {
  __typename: "UserFeatureFlagsEdge";
  node: GetUserFeatureFlags_userFeatureFlags_edges_node; // The item at the end of the edge.
}

export interface GetUserFeatureFlags_userFeatureFlags {
  __typename: "UserFeatureFlagsConnection";
  edges: GetUserFeatureFlags_userFeatureFlags_edges[] | null; // A list of edges.
}

export interface GetUserFeatureFlags {
  userFeatureFlags: GetUserFeatureFlags_userFeatureFlags | null;
}

export interface GetUserFeatureFlagsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum P5AmountTypeEnum {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}

export enum BankDocStateEnum {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}

export enum CustomerStateEnum {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}

// null
export interface BankDocFilterInput {
  and?: BankDocFilterInput[] | null;
  or?: BankDocFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  isCreditCard?: BooleanOperationFilterInput | null;
  isReviewed?: BooleanOperationFilterInput | null;
  uploadedByUser?: UserFilterInput | null;
  uploadedByUserId?: ComparableNullableOfGuidOperationFilterInput | null;
  bankDocStatusEnum?: BankDocStatusEnumFilterInput | null;
  bankDocStatusEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  internalStatusMessage?: StringOperationFilterInput | null;
  bankDocInternalStatusEnum?: BankDocInternalStatusEnumFilterInput | null;
  bankDocInternalStatusEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  metaBankName?: StringOperationFilterInput | null;
  metaPageCount?: ComparableNullableOfInt32OperationFilterInput | null;
  metaTxnRowCount?: ComparableNullableOfInt32OperationFilterInput | null;
  metaTxnYear?: StringOperationFilterInput | null;
  metaOutputRowCount?: ComparableNullableOfInt32OperationFilterInput | null;
  resultTokenId?: ComparableNullableOfGuidOperationFilterInput | null;
  hashSha256?: StringOperationFilterInput | null;
  hashMd5?: StringOperationFilterInput | null;
  notifiedCustomerByEmail?: BooleanOperationFilterInput | null;
  customer?: CustomerFilterInput | null;
  customerId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface StringOperationFilterInput {
  and?: StringOperationFilterInput[] | null;
  or?: StringOperationFilterInput[] | null;
  eq?: string | null;
  neq?: string | null;
  contains?: string | null;
  ncontains?: string | null;
  in?: (string | null)[] | null;
  nin?: (string | null)[] | null;
  startsWith?: string | null;
  nstartsWith?: string | null;
  endsWith?: string | null;
  nendsWith?: string | null;
}

// null
export interface BooleanOperationFilterInput {
  eq?: boolean | null;
  neq?: boolean | null;
}

// null
export interface UserFilterInput {
  and?: UserFilterInput[] | null;
  or?: UserFilterInput[] | null;
  userProfile?: UserProfileFilterInput | null;
  userAgreement?: UserAgreementFilterInput | null;
  org?: OrgFilterInput | null;
  orgId?: ComparableNullableOfGuidOperationFilterInput | null;
  userRoleEnum?: UserRoleEnumFilterInput | null;
  userRoleEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  jobs?: ListFilterInputTypeOfJobFilterInput | null;
  bankDocs?: ListFilterInputTypeOfBankDocFilterInput | null;
  jobComments?: ListFilterInputTypeOfJobCommentFilterInput | null;
  azureB2CUserId?: StringOperationFilterInput | null;
  azureB2CTempPass?: StringOperationFilterInput | null;
  invitedAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  invitationCount?: ComparableInt32OperationFilterInput | null;
  userFeatureFlag?: UserFeatureFlagFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface UserProfileFilterInput {
  and?: UserProfileFilterInput[] | null;
  or?: UserProfileFilterInput[] | null;
  firstName?: StringOperationFilterInput | null;
  middleName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  email?: StringOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ComparableNullableOfGuidOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: (any | null)[] | null;
  nin?: (any | null)[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

// null
export interface IdOperationFilterInput {
  eq?: string | null;
  neq?: string | null;
  in?: (string | null)[] | null;
  nin?: (string | null)[] | null;
}

// null
export interface ComparableDateTimeOffsetOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: any[] | null;
  nin?: any[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

// null
export interface ComparableNullableOfDateTimeOffsetOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: (any | null)[] | null;
  nin?: (any | null)[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

// null
export interface UserAgreementFilterInput {
  and?: UserAgreementFilterInput[] | null;
  or?: UserAgreementFilterInput[] | null;
  acceptedTermsAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  acceptedPrivacyAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface OrgFilterInput {
  and?: OrgFilterInput[] | null;
  or?: OrgFilterInput[] | null;
  legalName?: StringOperationFilterInput | null;
  orgBilling?: OrgBillingFilterInput | null;
  orgFeatureFlag?: OrgFeatureFlagFilterInput | null;
  users?: ListFilterInputTypeOfUserFilterInput | null;
  locations?: ListFilterInputTypeOfLocationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface OrgBillingFilterInput {
  and?: OrgBillingFilterInput[] | null;
  or?: OrgBillingFilterInput[] | null;
  stripeCustomerId?: StringOperationFilterInput | null;
  bankDocsIsFreeTrialPlan?: BooleanOperationFilterInput | null;
  bankDocsTotalCustomersPurchased?: ComparableInt64OperationFilterInput | null;
  org?: OrgFilterInput | null;
  orgId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ComparableInt64OperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: any[] | null;
  nin?: any[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

// null
export interface OrgFeatureFlagFilterInput {
  and?: OrgFeatureFlagFilterInput[] | null;
  or?: OrgFeatureFlagFilterInput[] | null;
  org?: OrgFilterInput | null;
  orgId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfUserFilterInput {
  all?: UserFilterInput | null;
  none?: UserFilterInput | null;
  some?: UserFilterInput | null;
  any?: boolean | null;
}

// null
export interface ListFilterInputTypeOfLocationFilterInput {
  all?: LocationFilterInput | null;
  none?: LocationFilterInput | null;
  some?: LocationFilterInput | null;
  any?: boolean | null;
}

// null
export interface LocationFilterInput {
  and?: LocationFilterInput[] | null;
  or?: LocationFilterInput[] | null;
  locationAddress?: LocationAddressFilterInput | null;
  org?: OrgFilterInput | null;
  orgId?: ComparableNullableOfGuidOperationFilterInput | null;
  services?: ListFilterInputTypeOfServiceFilterInput | null;
  customers?: ListFilterInputTypeOfCustomerFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface LocationAddressFilterInput {
  and?: LocationAddressFilterInput[] | null;
  or?: LocationAddressFilterInput[] | null;
  location?: LocationFilterInput | null;
  locationId?: ComparableNullableOfGuidOperationFilterInput | null;
  line1?: StringOperationFilterInput | null;
  line2?: StringOperationFilterInput | null;
  city?: StringOperationFilterInput | null;
  region?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  postalCode?: StringOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfServiceFilterInput {
  all?: ServiceFilterInput | null;
  none?: ServiceFilterInput | null;
  some?: ServiceFilterInput | null;
  any?: boolean | null;
}

// null
export interface ServiceFilterInput {
  and?: ServiceFilterInput[] | null;
  or?: ServiceFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  serviceFee?: ServiceFeeFilterInput | null;
  serviceStatuses?: ListFilterInputTypeOfServiceStatusFilterInput | null;
  serviceTasks?: ListFilterInputTypeOfServiceTaskFilterInput | null;
  location?: LocationFilterInput | null;
  locationId?: ComparableNullableOfGuidOperationFilterInput | null;
  jobs?: ListFilterInputTypeOfJobFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ServiceFeeFilterInput {
  and?: ServiceFeeFilterInput[] | null;
  or?: ServiceFeeFilterInput[] | null;
  rate?: ComparableDecimalOperationFilterInput | null;
  service?: ServiceFilterInput | null;
  serviceId?: ComparableNullableOfGuidOperationFilterInput | null;
  serviceFeeTypeEnum?: ServiceFeeTypeEnumFilterInput | null;
  serviceFeeTypeEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ComparableDecimalOperationFilterInput {
  eq?: any | null;
  neq?: any | null;
  in?: any[] | null;
  nin?: any[] | null;
  gt?: any | null;
  ngt?: any | null;
  gte?: any | null;
  ngte?: any | null;
  lt?: any | null;
  nlt?: any | null;
  lte?: any | null;
  nlte?: any | null;
}

// null
export interface ServiceFeeTypeEnumFilterInput {
  and?: ServiceFeeTypeEnumFilterInput[] | null;
  or?: ServiceFeeTypeEnumFilterInput[] | null;
  feeType?: StringOperationFilterInput | null;
  serviceFees?: ListFilterInputTypeOfServiceFeeFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfServiceFeeFilterInput {
  all?: ServiceFeeFilterInput | null;
  none?: ServiceFeeFilterInput | null;
  some?: ServiceFeeFilterInput | null;
  any?: boolean | null;
}

// null
export interface ListFilterInputTypeOfServiceStatusFilterInput {
  all?: ServiceStatusFilterInput | null;
  none?: ServiceStatusFilterInput | null;
  some?: ServiceStatusFilterInput | null;
  any?: boolean | null;
}

// null
export interface ServiceStatusFilterInput {
  and?: ServiceStatusFilterInput[] | null;
  or?: ServiceStatusFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  color?: StringOperationFilterInput | null;
  order?: ComparableInt32OperationFilterInput | null;
  service?: ServiceFilterInput | null;
  serviceId?: ComparableNullableOfGuidOperationFilterInput | null;
  jobs?: ListFilterInputTypeOfJobFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ComparableInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: number[] | null;
  nin?: number[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

// null
export interface ListFilterInputTypeOfJobFilterInput {
  all?: JobFilterInput | null;
  none?: JobFilterInput | null;
  some?: JobFilterInput | null;
  any?: boolean | null;
}

// null
export interface JobFilterInput {
  and?: JobFilterInput[] | null;
  or?: JobFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  dueDate?: ComparableDateTimeOffsetOperationFilterInput | null;
  jobTasks?: ListFilterInputTypeOfJobTaskFilterInput | null;
  jobComments?: ListFilterInputTypeOfJobCommentFilterInput | null;
  assignedUser?: UserFilterInput | null;
  assignedUserId?: ComparableNullableOfGuidOperationFilterInput | null;
  service?: ServiceFilterInput | null;
  serviceId?: ComparableNullableOfGuidOperationFilterInput | null;
  jobStatus?: ServiceStatusFilterInput | null;
  jobStatusId?: ComparableNullableOfGuidOperationFilterInput | null;
  customer?: CustomerFilterInput | null;
  customerId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfJobTaskFilterInput {
  all?: JobTaskFilterInput | null;
  none?: JobTaskFilterInput | null;
  some?: JobTaskFilterInput | null;
  any?: boolean | null;
}

// null
export interface JobTaskFilterInput {
  and?: JobTaskFilterInput[] | null;
  or?: JobTaskFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  linkName?: StringOperationFilterInput | null;
  linkUrl?: StringOperationFilterInput | null;
  order?: ComparableInt32OperationFilterInput | null;
  isDone?: BooleanOperationFilterInput | null;
  job?: JobFilterInput | null;
  jobId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfJobCommentFilterInput {
  all?: JobCommentFilterInput | null;
  none?: JobCommentFilterInput | null;
  some?: JobCommentFilterInput | null;
  any?: boolean | null;
}

// null
export interface JobCommentFilterInput {
  and?: JobCommentFilterInput[] | null;
  or?: JobCommentFilterInput[] | null;
  comment?: StringOperationFilterInput | null;
  byUser?: UserFilterInput | null;
  byUserId?: ComparableNullableOfGuidOperationFilterInput | null;
  order?: ComparableInt32OperationFilterInput | null;
  job?: JobFilterInput | null;
  jobId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface CustomerFilterInput {
  and?: CustomerFilterInput[] | null;
  or?: CustomerFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  customerTypeEnum?: CustomerTypeEnumFilterInput | null;
  customerTypeEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  contact?: ContactFilterInput | null;
  contactId?: ComparableNullableOfGuidOperationFilterInput | null;
  addresses?: ListFilterInputTypeOfCustomerAddressFilterInput | null;
  jobs?: ListFilterInputTypeOfJobFilterInput | null;
  bankDocs?: ListFilterInputTypeOfBankDocFilterInput | null;
  customerTaxIds?: ListFilterInputTypeOfCustomerTaxIdFilterInput | null;
  location?: LocationFilterInput | null;
  locationId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface CustomerTypeEnumFilterInput {
  and?: CustomerTypeEnumFilterInput[] | null;
  or?: CustomerTypeEnumFilterInput[] | null;
  customerType?: StringOperationFilterInput | null;
  customers?: ListFilterInputTypeOfCustomerFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfCustomerFilterInput {
  all?: CustomerFilterInput | null;
  none?: CustomerFilterInput | null;
  some?: CustomerFilterInput | null;
  any?: boolean | null;
}

// null
export interface ContactFilterInput {
  and?: ContactFilterInput[] | null;
  or?: ContactFilterInput[] | null;
  firstName?: StringOperationFilterInput | null;
  middleName?: StringOperationFilterInput | null;
  lastName?: StringOperationFilterInput | null;
  email?: ContactEmailFilterInput | null;
  phoneNumbers?: ListFilterInputTypeOfContactPhoneNumberFilterInput | null;
  customers?: ListFilterInputTypeOfCustomerFilterInput | null;
  statusType?: ContactStatusTypeEnumFilterInput | null;
  contactStatusEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ContactEmailFilterInput {
  and?: ContactEmailFilterInput[] | null;
  or?: ContactEmailFilterInput[] | null;
  emailAddress?: StringOperationFilterInput | null;
  contact?: ContactFilterInput | null;
  contactId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfContactPhoneNumberFilterInput {
  all?: ContactPhoneNumberFilterInput | null;
  none?: ContactPhoneNumberFilterInput | null;
  some?: ContactPhoneNumberFilterInput | null;
  any?: boolean | null;
}

// null
export interface ContactPhoneNumberFilterInput {
  and?: ContactPhoneNumberFilterInput[] | null;
  or?: ContactPhoneNumberFilterInput[] | null;
  phoneNumberTypeEnum?: PhoneNumberTypeEnumFilterInput | null;
  phoneNumberTypeEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  number?: StringOperationFilterInput | null;
  contact?: ContactFilterInput | null;
  contactId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface PhoneNumberTypeEnumFilterInput {
  and?: PhoneNumberTypeEnumFilterInput[] | null;
  or?: PhoneNumberTypeEnumFilterInput[] | null;
  phoneNumberType?: StringOperationFilterInput | null;
  contactPhoneNumbers?: ListFilterInputTypeOfContactPhoneNumberFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ContactStatusTypeEnumFilterInput {
  and?: ContactStatusTypeEnumFilterInput[] | null;
  or?: ContactStatusTypeEnumFilterInput[] | null;
  statusType?: StringOperationFilterInput | null;
  contacts?: ListFilterInputTypeOfContactFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfContactFilterInput {
  all?: ContactFilterInput | null;
  none?: ContactFilterInput | null;
  some?: ContactFilterInput | null;
  any?: boolean | null;
}

// null
export interface ListFilterInputTypeOfCustomerAddressFilterInput {
  all?: CustomerAddressFilterInput | null;
  none?: CustomerAddressFilterInput | null;
  some?: CustomerAddressFilterInput | null;
  any?: boolean | null;
}

// null
export interface CustomerAddressFilterInput {
  and?: CustomerAddressFilterInput[] | null;
  or?: CustomerAddressFilterInput[] | null;
  addressTypeEnum?: AddressTypeEnumFilterInput | null;
  addressTypeEnumId?: ComparableNullableOfGuidOperationFilterInput | null;
  customer?: CustomerFilterInput | null;
  customerId?: ComparableNullableOfGuidOperationFilterInput | null;
  line1?: StringOperationFilterInput | null;
  line2?: StringOperationFilterInput | null;
  city?: StringOperationFilterInput | null;
  region?: StringOperationFilterInput | null;
  country?: StringOperationFilterInput | null;
  postalCode?: StringOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface AddressTypeEnumFilterInput {
  and?: AddressTypeEnumFilterInput[] | null;
  or?: AddressTypeEnumFilterInput[] | null;
  addressType?: StringOperationFilterInput | null;
  customerAddresses?: ListFilterInputTypeOfCustomerAddressFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfBankDocFilterInput {
  all?: BankDocFilterInput | null;
  none?: BankDocFilterInput | null;
  some?: BankDocFilterInput | null;
  any?: boolean | null;
}

// null
export interface ListFilterInputTypeOfCustomerTaxIdFilterInput {
  all?: CustomerTaxIdFilterInput | null;
  none?: CustomerTaxIdFilterInput | null;
  some?: CustomerTaxIdFilterInput | null;
  any?: boolean | null;
}

// null
export interface CustomerTaxIdFilterInput {
  and?: CustomerTaxIdFilterInput[] | null;
  or?: CustomerTaxIdFilterInput[] | null;
  taxIdType?: StringOperationFilterInput | null;
  taxIdValue?: StringOperationFilterInput | null;
  order?: ComparableInt32OperationFilterInput | null;
  customer?: CustomerFilterInput | null;
  customerId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ListFilterInputTypeOfServiceTaskFilterInput {
  all?: ServiceTaskFilterInput | null;
  none?: ServiceTaskFilterInput | null;
  some?: ServiceTaskFilterInput | null;
  any?: boolean | null;
}

// null
export interface ServiceTaskFilterInput {
  and?: ServiceTaskFilterInput[] | null;
  or?: ServiceTaskFilterInput[] | null;
  name?: StringOperationFilterInput | null;
  description?: StringOperationFilterInput | null;
  linkName?: StringOperationFilterInput | null;
  linkUrl?: StringOperationFilterInput | null;
  order?: ComparableInt32OperationFilterInput | null;
  service?: ServiceFilterInput | null;
  serviceId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface UserRoleEnumFilterInput {
  and?: UserRoleEnumFilterInput[] | null;
  or?: UserRoleEnumFilterInput[] | null;
  userRole?: StringOperationFilterInput | null;
  users?: ListFilterInputTypeOfUserFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface UserFeatureFlagFilterInput {
  and?: UserFeatureFlagFilterInput[] | null;
  or?: UserFeatureFlagFilterInput[] | null;
  quickStartV1Completed?: BooleanOperationFilterInput | null;
  user?: UserFilterInput | null;
  userId?: ComparableNullableOfGuidOperationFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface BankDocStatusEnumFilterInput {
  and?: BankDocStatusEnumFilterInput[] | null;
  or?: BankDocStatusEnumFilterInput[] | null;
  statusType?: StringOperationFilterInput | null;
  level?: StringOperationFilterInput | null;
  bankDocs?: ListFilterInputTypeOfBankDocFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface BankDocInternalStatusEnumFilterInput {
  and?: BankDocInternalStatusEnumFilterInput[] | null;
  or?: BankDocInternalStatusEnumFilterInput[] | null;
  internalStatusType?: StringOperationFilterInput | null;
  bankDocs?: ListFilterInputTypeOfBankDocFilterInput | null;
  id?: IdOperationFilterInput | null;
  createdAt?: ComparableDateTimeOffsetOperationFilterInput | null;
  deletedAt?: ComparableNullableOfDateTimeOffsetOperationFilterInput | null;
}

// null
export interface ComparableNullableOfInt32OperationFilterInput {
  eq?: number | null;
  neq?: number | null;
  in?: (number | null)[] | null;
  nin?: (number | null)[] | null;
  gt?: number | null;
  ngt?: number | null;
  gte?: number | null;
  ngte?: number | null;
  lt?: number | null;
  nlt?: number | null;
  lte?: number | null;
  nlte?: number | null;
}

// null
export interface BankDocSortInput {
  name?: SortEnumType | null;
  isCreditCard?: SortEnumType | null;
  isReviewed?: SortEnumType | null;
  uploadedByUser?: UserSortInput | null;
  uploadedByUserId?: SortEnumType | null;
  bankDocStatusEnum?: BankDocStatusEnumSortInput | null;
  bankDocStatusEnumId?: SortEnumType | null;
  internalStatusMessage?: SortEnumType | null;
  bankDocInternalStatusEnum?: BankDocInternalStatusEnumSortInput | null;
  bankDocInternalStatusEnumId?: SortEnumType | null;
  metaBankName?: SortEnumType | null;
  metaPageCount?: SortEnumType | null;
  metaTxnRowCount?: SortEnumType | null;
  metaTxnYear?: SortEnumType | null;
  metaOutputRowCount?: SortEnumType | null;
  resultTokenId?: SortEnumType | null;
  hashSha256?: SortEnumType | null;
  hashMd5?: SortEnumType | null;
  notifiedCustomerByEmail?: SortEnumType | null;
  customer?: CustomerSortInput | null;
  customerId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UserSortInput {
  userProfile?: UserProfileSortInput | null;
  userAgreement?: UserAgreementSortInput | null;
  org?: OrgSortInput | null;
  orgId?: SortEnumType | null;
  userRoleEnum?: UserRoleEnumSortInput | null;
  userRoleEnumId?: SortEnumType | null;
  azureB2CUserId?: SortEnumType | null;
  azureB2CTempPass?: SortEnumType | null;
  invitedAt?: SortEnumType | null;
  invitationCount?: SortEnumType | null;
  userFeatureFlag?: UserFeatureFlagSortInput | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UserProfileSortInput {
  firstName?: SortEnumType | null;
  middleName?: SortEnumType | null;
  lastName?: SortEnumType | null;
  email?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UserAgreementSortInput {
  acceptedTermsAt?: SortEnumType | null;
  acceptedPrivacyAt?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface OrgSortInput {
  legalName?: SortEnumType | null;
  orgBilling?: OrgBillingSortInput | null;
  orgFeatureFlag?: OrgFeatureFlagSortInput | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface OrgBillingSortInput {
  stripeCustomerId?: SortEnumType | null;
  bankDocsIsFreeTrialPlan?: SortEnumType | null;
  bankDocsTotalCustomersPurchased?: SortEnumType | null;
  org?: OrgSortInput | null;
  orgId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface OrgFeatureFlagSortInput {
  org?: OrgSortInput | null;
  orgId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UserRoleEnumSortInput {
  userRole?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UserFeatureFlagSortInput {
  quickStartV1Completed?: SortEnumType | null;
  user?: UserSortInput | null;
  userId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface BankDocStatusEnumSortInput {
  statusType?: SortEnumType | null;
  level?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface BankDocInternalStatusEnumSortInput {
  internalStatusType?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface CustomerSortInput {
  name?: SortEnumType | null;
  customerTypeEnum?: CustomerTypeEnumSortInput | null;
  customerTypeEnumId?: SortEnumType | null;
  contact?: ContactSortInput | null;
  contactId?: SortEnumType | null;
  location?: LocationSortInput | null;
  locationId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface CustomerTypeEnumSortInput {
  customerType?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ContactSortInput {
  firstName?: SortEnumType | null;
  middleName?: SortEnumType | null;
  lastName?: SortEnumType | null;
  email?: ContactEmailSortInput | null;
  statusType?: ContactStatusTypeEnumSortInput | null;
  contactStatusEnumId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ContactEmailSortInput {
  emailAddress?: SortEnumType | null;
  contact?: ContactSortInput | null;
  contactId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ContactStatusTypeEnumSortInput {
  statusType?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface LocationSortInput {
  locationAddress?: LocationAddressSortInput | null;
  org?: OrgSortInput | null;
  orgId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface LocationAddressSortInput {
  location?: LocationSortInput | null;
  locationId?: SortEnumType | null;
  line1?: SortEnumType | null;
  line2?: SortEnumType | null;
  city?: SortEnumType | null;
  region?: SortEnumType | null;
  country?: SortEnumType | null;
  postalCode?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface UpdateBankDocOutputInput {
  input: UpdateBankDocsOutputInput;
}

// null
export interface UpdateBankDocsOutputInput {
  locationId: string;
  bankDocOutputId: string;
  bankDocOutputBalancesInput: BankDocOutputBalancesInput;
  existingInputs: BankDocOutputRowExistingInput[];
  newInputs: BankDocOutputRowNewInput[];
  isReviewed: boolean;
}

// null
export interface BankDocOutputBalancesInput {
  startingBalance: any;
  endingBalance: any;
}

// null
export interface BankDocOutputRowExistingInput {
  id: string;
  isSelected: boolean;
  rowDate: any;
  description: string;
  memo: string;
  bankDocOutputAmountInput: BankDocOutputAmountInput;
}

// null
export interface BankDocOutputAmountInput {
  value: any;
  typeEnum: P5AmountTypeEnum;
}

// null
export interface BankDocOutputRowNewInput {
  id: any;
  isSelected: boolean;
  rowDate: any;
  description: string;
  memo: string;
  bankDocOutputAmountInput: BankDocOutputAmountInput;
}

// null
export interface UpdateBankDocStateInput {
  orgId: string;
  locationId: string;
  bankDocId: string;
  bankDocStateEnum: BankDocStateEnum;
}

// null
export interface UpdateBankDocDetailsInput {
  orgId: string;
  bankDocId: string;
  fileName: string;
  isCreditCard: boolean;
}

// null
export interface EditCustomerInput {
  orgId: string;
  locationId: string;
  customerId?: string | null;
  customerName: string;
  editCustomerTaxIdInputs: EditCustomerTaxIdInput[];
  editCustomerAddressInputs: EditCustomerAddressInput[];
  editContactInput: EditContactInput;
}

// null
export interface EditCustomerTaxIdInput {
  id?: string | null;
  taxIdType: string;
  taxIdValue: string;
}

// null
export interface EditCustomerAddressInput {
  id?: string | null;
  addressTypeEnumId: string;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

// null
export interface EditContactInput {
  id?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  editContactPhoneNumberInputs: EditContactPhoneNumberInput[];
  editContactEmailInput: EditContactEmailInput;
}

// null
export interface EditContactPhoneNumberInput {
  id?: string | null;
  phoneNumberTypeEnumId: string;
  number: string;
}

// null
export interface EditContactEmailInput {
  id?: string | null;
  emailAddress: string;
}

// null
export interface AddCustomerInput {
  orgId: string;
  locationId: string;
  customerName: string;
  customerTypeEnumId: string;
  addCustomerTaxIdInputs: AddCustomerTaxIdInput[];
  addCustomerAddressInputs: AddCustomerAddressInput[];
  addContactInput: AddContactInput;
}

// null
export interface AddCustomerTaxIdInput {
  taxIdType: string;
  taxIdValue: string;
}

// null
export interface AddCustomerAddressInput {
  addressTypeEnumId: string;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

// null
export interface AddContactInput {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  contactTypeEnumId?: string | null;
  addContactPhoneNumberInputs: AddContactPhoneNumberInput[];
  addContactEmailInput: AddContactEmailInput;
}

// null
export interface AddContactPhoneNumberInput {
  phoneNumberTypeEnumId: string;
  number: string;
}

// null
export interface AddContactEmailInput {
  emailAddress: string;
}

// null
export interface UpdateCustomerStateInput {
  orgId: string;
  locationId: string;
  customerId: string;
  customerStateEnum: CustomerStateEnum;
}

// null
export interface AddJobCommentInput {
  orgId: string;
  jobId: string;
  comment: string;
  byUserId: string;
}

// null
export interface UpdateJobTaskStatusInput {
  orgId: string;
  jobId: string;
  taskStatusInputs: TaskStatusInput[];
}

// null
export interface TaskStatusInput {
  id: string;
  isDone: boolean;
}

// null
export interface JobSortInput {
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  dueDate?: SortEnumType | null;
  assignedUser?: UserSortInput | null;
  assignedUserId?: SortEnumType | null;
  service?: ServiceSortInput | null;
  serviceId?: SortEnumType | null;
  jobStatus?: ServiceStatusSortInput | null;
  jobStatusId?: SortEnumType | null;
  customer?: CustomerSortInput | null;
  customerId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ServiceSortInput {
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  serviceFee?: ServiceFeeSortInput | null;
  location?: LocationSortInput | null;
  locationId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ServiceFeeSortInput {
  rate?: SortEnumType | null;
  service?: ServiceSortInput | null;
  serviceId?: SortEnumType | null;
  serviceFeeTypeEnum?: ServiceFeeTypeEnumSortInput | null;
  serviceFeeTypeEnumId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ServiceFeeTypeEnumSortInput {
  feeType?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface ServiceStatusSortInput {
  name?: SortEnumType | null;
  description?: SortEnumType | null;
  color?: SortEnumType | null;
  order?: SortEnumType | null;
  service?: ServiceSortInput | null;
  serviceId?: SortEnumType | null;
  id?: SortEnumType | null;
  createdAt?: SortEnumType | null;
  deletedAt?: SortEnumType | null;
}

// null
export interface AddJobInput {
  orgId: string;
  customerId: string;
  description: string;
  dueDate: any;
  assignedUserId: string;
  serviceId: string;
  startDate?: any;
}

// null
export interface UpdateJobSummaryInput {
  orgId: string;
  jobId: string;
  assignedUserId: string;
  jobStatusId: string;
  dueDate: any;
}

// null
export interface OnboardUserInput {
  orgId: string;
  userId: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  acceptedTerms: boolean;
  acceptedPrivacy: boolean;
}

// null
export interface OnboardOrgInput {
  orgId: string;
  userId: string;
  name: string;
  locationAddressInput: OnboardOrgLocationAddressInput;
  subscribeFreePlan: boolean;
}

// null
export interface OnboardOrgLocationAddressInput {
  line1: string;
  line2?: string | null;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

// null
export interface SwitchBdSubscriptionInput {
  orgId: string;
  newSubscriptionPlanId: string;
  customerQuantity: number;
}

// null
export interface AddBdLicensesInput {
  orgId: string;
  customerQuantity: number;
}

// null
export interface RenameOrgInput {
  id: string;
  /*name: string;
        
          locations: GetLocationsByOrgId_orgById_locations;*/
}

export interface RenameOrgInputForm {
  id: string;
  name: string;
  displayName: string;
  phoneNumber: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  region: string;
}

// null
export interface AddServiceInput {
  orgId: string;
  locationId: string;
  name: string;
  description?: string | null;
  feeInput: ServiceFeeInput;
  tasksInputs: ServiceTasksInput[];
  statusesInputs: ServiceStatusesInput[];
}

// null
export interface ServiceFeeInput {
  rate: any;
  serviceFeeTypeEnumId: string;
}

// null
export interface ServiceTasksInput {
  name: string;
  description?: string | null;
  linkName?: string | null;
  linkUrl?: string | null;
  order: number;
}

// null
export interface ServiceStatusesInput {
  name: string;
  description?: string | null;
  color: string;
  order: number;
}

// null
export interface UpdateUserQuickStartDoneInput {
  orgId: string;
  userId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
export interface GetTaskTemplates {
  taskTemplates: GetTaskTemplatesLocation | null;
}

export interface GetTaskTemplatesLocation {
  edges: GetTaskTemplatesByLocation_edges[] | null; // A list of edges.
}

export interface GetTaskTemplatesByLocation_edges {
  node: GetTaskTemplatesByLocation_edges_node; // The item at the end of the edge.
}

export interface GetTaskTemplatesByLocation_edges_node {
  id: string;
}

export interface AddTaskTemplateInput {
  orgId: string;
  locationId: string;
  name: string;
  description?: string | null;
}

export interface IAddTaskTemplate {
  addTaskTemplate: AddTaskTemplate_addTaskTemplate;
}

export interface AddTaskTemplate_addTaskTemplate {
  __typename: "AddServicePayload";
  service: AddTaskTemplate_addTaskTemplate_taskTemplate | null;
}

export interface AddTaskTemplate_addTaskTemplate_taskTemplate {
  __typename: "Service";
  id: string;
}

export interface AddTaskTemplateVariables {
  addTaskTemplateInput: AddTaskTemplateInput;
}

export interface AddUser {
  addUser: AddUser_addUser;
}

export interface AddUser_addUser {
  __typename: "AddUserPayload";
  user: AddUser_addUser_User | null;
}

export interface AddUser_addUser_User {
  __typename: "User";
  id: string;
}

export interface AddUserVariables {
  input: AddUserInput;
}

export interface AddUserForm {
  password: string;
  userRole: number;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
}
export interface AddUserInput {
  orgId: string;
  locationId: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  userRoleEnum: {
    id: string;
    relayId: string;
    userRole: string;
    createdAt: string;
  };
  password: string;
}
