import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  GetOnboardingStatus,
  GetOnboardingStatusVariables,
} from "../../generated/operation-result-types";
import { GET_ONBOARDING_STATUS_GQL } from "../../queries/OnboardingQueries.gql";
import { OrgOnboardingView } from "./OrgOnboardingView";
import { UserOnboardingView } from "./UserOnboardingView";

export const OnboardingView: React.FC<unknown> = () => {
  // xstate
  const { userInfoService, onboardingInfoService } =
    React.useContext(GlobalContext);

  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { send: sendOnboardingInfo } = onboardingInfoService;
  const onboardingStatus = Xstate.useSelector(
    onboardingInfoService,
    (state) => state.context.onboardingStatus,
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userId = userInfoByEmail!.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userEmail = userInfoByEmail!.userProfile!.email;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;

  const [
    getOnboardingStatus,
    { loading: loadingQ1, error: errorQ1, data: dataQ1 },
  ] = Apollo.useLazyQuery<GetOnboardingStatus, GetOnboardingStatusVariables>(
    GET_ONBOARDING_STATUS_GQL,
    {
      fetchPolicy: "network-only",
      variables: { orgId: orgId, userId: userId },
    },
  );

  React.useEffect(() => {
    if (!!dataQ1) {
      sendOnboardingInfo("updateOnboardingStatus", {
        onboardingStatus: dataQ1,
      });
    }
  }, [dataQ1, sendOnboardingInfo]);

  React.useEffect(() => {
    getOnboardingStatus();
    // eslint-disable-next-line
  }, []);

  if (loadingQ1) {
    return <LottieLoading />;
  }

  if (errorQ1) {
    console.error("OnboardingView | OnboardingView", { errorQ: errorQ1 });
    throw new Error("Error getting OnboardingView");
  }

  const onDoneHandler = () => {
    getOnboardingStatus();
  };

  if (onboardingStatus?.userIsOnboarded === false) {
    return (
      <UserOnboardingView
        orgId={orgId}
        userId={userId}
        userEmail={userEmail}
        onDone={() => onDoneHandler()}
      />
    );
  }

  if (onboardingStatus?.orgIsOnboarded === false) {
    return (
      <OrgOnboardingView
        orgId={orgId}
        userId={userId}
        onDone={() => onDoneHandler()}
      />
    );
  }

  return null;
};
