import React, { useState } from "react";

import EditPayment from "./EditPayment";
import PaymentDetails from "./PaymentDetails";

const PaymentView = () => {
  const [view, setView] = useState<"details" | "edit">("details");

  const renderContent = () => {
    switch (view) {
      case "details":
        return <PaymentDetails setView={setView} />;
      case "edit":
        return <EditPayment setView={setView} />;
    }
  };
  return renderContent();
};

export default PaymentView;
