import * as Apollo from "@apollo/client";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LABELS, ROUTE_NAMES } from "../../../app/constants/TextConstants";
import { Alert } from "../../../components/alerts/alert";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetBankDocById_bankDocById,
  GetBankDocOutputById,
  GetBankDocOutputByIdVariables,
} from "../../../generated/operation-result-types";
import { GET_BANK_DOC_OUTPUT_BY_ID_GQL } from "../../../queries/BankDocQueries.gql";
import { BankDocOutputReadOnlyView } from "./BankDocOutputReadOnlyView";
import { BankDocOutputReviewView } from "./BankDocOutputReviewView";
import { BankDocStatics } from "./BankDocStatics";

export type BankDocOutputLoaderViewProp = {
  locationId: string;
  bankDocById: GetBankDocById_bankDocById;
};

export const BankDocOutputLoaderView: React.FC<BankDocOutputLoaderViewProp> = (
  props,
) => {
  const { locationId, bankDocById } = props;
  const { id: bankDocId, name, isCreditCard, blobUri, customer } = bankDocById;
  const downloadFileName = `${customer?.name}-${name}`;

  // gql
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    refetch,
  } = Apollo.useQuery<GetBankDocOutputById, GetBankDocOutputByIdVariables>(
    GET_BANK_DOC_OUTPUT_BY_ID_GQL,
    {
      fetchPolicy: "network-only",
      variables: { bankDocOutputId: bankDocId, locationId: locationId },
    },
  );

  const [shouldRefetch, setShouldRefetch] = React.useState(false);

  React.useEffect(() => {
    async function fun() {
      if (shouldRefetch) {
        await refetch();
        setShouldRefetch(false);
      }
    }
    fun();
  }, [shouldRefetch, refetch]);

  // conditional logic
  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("BankDocOutputLoaderView | BankDocOutputLoaderView", {
      errorQ,
    });
    throw new Error("Error getting BankDocOutputLoaderView");
  }

  const bankDocOutputById = dataQ?.bankDocOutputById;
  if (!bankDocOutputById) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }

  const bankDocOutputData =
    BankDocStatics.TransformByIdToDataT(bankDocOutputById);

  if (!blobUri) {
    return <Alert type={"error"} label={LABELS.errors.default} />;
  }

  return (
    <div className={"py-4"}>
      <Routes>
        <Route path={"*"} element={<Outlet />}>
          <Route
            index
            element={
              <Navigate
                to={`./${ROUTE_NAMES.documents.bankDoc.details}`}
                replace={true}
              />
            }
          />
          <Route
            path={ROUTE_NAMES.documents.bankDoc.details}
            element={
              <BankDocOutputReadOnlyView
                bankDocId={bankDocId}
                bankDocOutputId={bankDocOutputById.id}
                isCreditCard={isCreditCard}
                blobUri={blobUri}
                locationId={locationId}
                bankDocOutputData={bankDocOutputData}
                downloadFileName={downloadFileName}
              />
            }
          />
          <Route
            path={ROUTE_NAMES.documents.bankDoc.review}
            element={
              <BankDocOutputReviewView
                bankDocOutputId={bankDocOutputById.id}
                isCreditCard={isCreditCard}
                blobUri={blobUri}
                locationId={locationId}
                bankDocOutputData={bankDocOutputData}
                downloadFileName={downloadFileName}
                forceRefetch={() => setShouldRefetch(true)}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
};
