import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const ContactsSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={"M22 2H4V22H22V2Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={"M5 11.5H2V12.5H5V11.5Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={"M5 18H2V19H5V18Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={"M5 5H2V6H5V5Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={"M18 18H8V17C8 14.24 10.24 12 13 12C15.76 12 18 14.24 18 17V18Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M13 12C14.6569 12 16 10.6569 16 9C16 7.34315 14.6569 6 13 6C11.3431 6 10 7.34315 10 9C10 10.6569 11.3431 12 13 12Z"
          }
        />
      </svg>
    </>
  );
};
