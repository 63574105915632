import React from "react";

import { getFormattedCurrency } from "../../app/commonOps/CurrencyOps";
import { BrandButton } from "../../components/button/BrandButton";
import {
  PricingColumns,
  PricingTiersT,
} from "../../components/pricing/PricingColumns";
import { ArrowChevronRightSvg } from "../../components/svg/ArrowChevronRightSvg";
import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const PricingDemo: React.FC<unknown> = () => {
  const pricingFeatures: PricingTiersT[] = [
    {
      name: "free trial",
      primaryAmount: getFormattedCurrency(0),
      primaryUnit: "per page",
      action: (
        <BrandButton
          colorType={"primary"}
          label={"Select plan"}
          SvgIconRight={ArrowChevronRightSvg}
        />
      ),
      items: [
        "10 pages per month",
        "1 User",
        "Due date reminders",
        "Job Tracking",
        "Recurring Works",
      ],
    },
    {
      name: "Pay as you go",
      primaryAmount: getFormattedCurrency(0.8 * 100),
      primaryUnit: "per page",
      action: (
        <BrandButton
          colorType={"primary"}
          label={"Select plan"}
          SvgIconRight={ArrowChevronRightSvg}
        />
      ),
      items: [
        "Unlimited users",
        "Due date reminders",
        "Job Tracking",
        "Recurring Jobs",
        "Roles & Permissions",
        "24 x 7 Support",
      ],
    },
    {
      name: "Pro",
      primaryAmount: getFormattedCurrency(99 * 100),
      primaryUnit: "flat fee for first 150 pages",
      secondaryAmount: getFormattedCurrency(65),
      secondaryUnit: "per additional page",
      action: (
        <BrandButton
          colorType={"primary"}
          label={"Select plan"}
          SvgIconRight={ArrowChevronRightSvg}
        />
      ),
      items: [
        "150 pages per month",
        "Unlimited users",
        "Job Tracking",
        "Recurring Jobs",
        "Roles & Permissions",
        "24 x 7 Support",
      ],
    },
  ];

  return (
    <ComponentLayout title={"Alerts"}>
      <ComponentSectionLayout title={"default"}>
        <PricingColumns tiers={pricingFeatures} />
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};
