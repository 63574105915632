import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import {
  ContactFileModel,
  ContactPreviewFileModel,
} from "../factories/contacts/models/contact-file.model";

export const filesSlice = createApi({
  reducerPath: "filesSlice",
  tagTypes: ["ContactFiles", "WorkFile"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getContactFiles: build.query<ContactFileModel[], string>({
      query: (contactId) => ({
        url: "api/contact-documents/get-by-contactId/" + contactId,
      }),
      providesTags: () => ["ContactFiles"],
    }),
    getOrgContactFiles: build.query<ContactFileModel[], string>({
      query: (organizationId) => ({
        url: "api/contact-documents/get-by-busnessContactId/" + organizationId,
      }),
      providesTags: () => ["ContactFiles"],
    }),
    getPreviewContactFile: build.query<ContactPreviewFileModel, string>({
      query: (id) => ({
        url: "api/contact-documents/preview-document/" + id,
      }),
    }),
    addContactFile: build.mutation<unknown, FormData>({
      query: (body) => ({
        url: "api/contact-documents/add-document",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ContactFiles"],
    }),
    deleteContactFile: build.mutation<unknown, string>({
      query: (id) => ({
        url: "api/contact-documents",
        method: "DELETE",
        params: { docId: id },
      }),
      invalidatesTags: () => ["ContactFiles"],
    }),

    /** Work files*/

    getWorkFiles: build.query<ContactFileModel[], string>({
      query: (workId) => ({
        url: "api/documents/get-by-workId/" + workId,
      }),
      providesTags: () => ["WorkFile"],
    }),
    getPreviewWorkFile: build.query<ContactPreviewFileModel, string>({
      query: (id) => ({
        url: "api/documents/preview-document/" + id,
      }),
    }),
    addWorkFile: build.mutation<unknown, FormData>({
      query: (body) => ({
        url: "api/documents/add-document",
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["WorkFile"],
    }),
    deleteWorkFile: build.mutation<unknown, string>({
      query: (id) => ({
        url: "api/documents",
        method: "DELETE",
        params: { docId: id },
      }),
      invalidatesTags: () => ["WorkFile"],
    }),
  }),
});
export const {
  useGetContactFilesQuery,
  useGetOrgContactFilesQuery,
  useLazyGetPreviewContactFileQuery,
  useAddContactFileMutation,
  useDeleteContactFileMutation,

  /** Work files*/

  useGetWorkFilesQuery,
  useLazyGetPreviewWorkFileQuery,
  useAddWorkFileMutation,
  useDeleteWorkFileMutation,
} = filesSlice;
