import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { SyntheticEvent, useState } from "react";

import { BrandButton } from "../../../components/button/BrandButton";
import { ComponentLayout } from "../../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../../support/ComponentSectionLayout";
import { BASE_URL } from "./StripeShared";

export const StripeSetSubscription: React.FC<unknown> = () => {
  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);

  React.useEffect(() => {
    async function foo() {
      const { publishableKey } = await fetch(`${BASE_URL}/config`).then((x) =>
        x.json(),
      );
      const sp = await loadStripe(publishableKey);
      setStripePromise(sp);
    }

    foo();
  }, []);

  const spOptions: StripeElementsOptions = {
    clientSecret:
      "seti_1LHfZeAMOUcBi2M2JjNNvTM3_secret_LzetLyBmiAYySWZNL7C2oKXvnv2K5Xm",
    appearance: {},
  };

  return (
    <ComponentLayout title={"stripe demo"}>
      <ComponentSectionLayout title={"react element"}>
        <Elements stripe={stripePromise} options={spOptions}>
          <CheckoutForm />
        </Elements>
      </ComponentSectionLayout>
    </ComponentLayout>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // todo: handle the status https://stripe.com/docs/payments/save-and-reuse?platform=web#web-submit-payment-details=
        return_url: "http://localhost:3000/components/stripe-payment-complete",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <PaymentElement />
      <CardElement />

      <BrandButton
        colorType={"primary"}
        buttonType={"submit"}
        disabled={!stripe}
        label={"submit"}
      />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
