import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

import { AddIndividualView } from "../../features/contacts/refactor/modals/AddIndividualView";
import AddOrganizationView from "../../features/contacts/refactor/modals/AddOrganizationView";
import { IndividualSvg } from "../svg/IndividualSvg";
import { OrganizationSvg } from "../svg/OrganizationSvg";

interface IAddContactProps {
  isOpen: boolean;
  isSearch?: boolean;
  isIdLikeContactId?: boolean;
  closeModal: () => void;
  setIsLoading?: (isLoading: boolean) => void;
  setContactsForSort?: (newContact: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId?: string;
  }) => void;
}
const AddContact: React.FC<IAddContactProps> = ({
  isOpen,
  closeModal,
  setIsLoading,
  setContactsForSort,
  isSearch = false,
  isIdLikeContactId = false,
}) => {
  const [isAddCustomerPopupOpen, setIsAddCustomerPopupOpen] = useState(false);
  const [
    isAddCustomerOrganizationPopupOpen,
    setIsAddCustomerOrganizationPopupOpen,
  ] = useState(false);
  const closeAddCustomerPopupModal = () => {
    setIsAddCustomerPopupOpen(false);
  };

  const openAddCustomerPopupModal = () => {
    setIsAddCustomerPopupOpen(true);
  };
  const closeAddCustomerOrganizationPopupModal = () => {
    setIsAddCustomerOrganizationPopupOpen(false);
  };

  const openAddCustomerOrganizationPopupModal = () => {
    setIsAddCustomerOrganizationPopupOpen(true);
  };
  const addCustomerPopup = (
    <>
      <div
        onClick={openAddCustomerPopupModal}
        className={
          "card card-body card-bordered my-6 cursor-pointer border-blue-500 bg-base-100 py-6 text-center"
        }>
        <h2>
          <b>Individual</b>
        </h2>
        <IndividualSvg width={75} height={75} className={"img-center-align"} />
        <div className={"text-sm font-semibold text-gray-500"}>
          Individuals and
          <br />
          Sole Proprietors
        </div>
      </div>
      <Transition appear show={isAddCustomerPopupOpen} as={Fragment}>
        <Dialog
          as={"div"}
          className={"z-header relative"}
          onClose={closeAddCustomerPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-header w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                  }>
                  <div>
                    <AddIndividualView
                      setIsLoading={setIsLoading}
                      closeModal={closeModal}
                      closeAddCustomerPopupModal={closeAddCustomerPopupModal}
                      setContactsForSort={setContactsForSort}
                      isSearch={isSearch}
                      isIdLikeContactId={isIdLikeContactId}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  const addCustomerOrganizationPopup = (
    <>
      <div
        onClick={openAddCustomerOrganizationPopupModal}
        className={
          "card card-body card-bordered my-6 cursor-pointer border-blue-500 bg-base-100 py-6 text-center"
        }>
        <h2>
          <b>Organization</b>
        </h2>
        <OrganizationSvg />
        <div className={"text-sm font-semibold text-gray-500"}>
          Corporations, Trusts
          <br />
          and Nonprofits
        </div>
      </div>
      <Transition
        appear
        show={isAddCustomerOrganizationPopupOpen}
        as={Fragment}>
        <Dialog
          as={"div"}
          className={"z-header relative"}
          onClose={closeAddCustomerOrganizationPopupModal}>
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}>
            <div className={"fixed inset-0 bg-black bg-opacity-25"} />
          </Transition.Child>

          <div className={"fixed inset-0 overflow-y-auto"}>
            <div
              className={
                "flex min-h-full items-center justify-center p-4 text-center"
              }>
              <Transition.Child
                as={Fragment}
                enter={"ease-out duration-300"}
                enterFrom={"opacity-0 scale-95"}
                enterTo={"opacity-100 scale-100"}
                leave={"ease-in duration-200"}
                leaveFrom={"opacity-100 scale-100"}
                leaveTo={"opacity-0 scale-95"}>
                <Dialog.Panel
                  className={
                    "z-header w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                  }>
                  <div>
                    <AddOrganizationView
                      setIsLoading={setIsLoading}
                      closeModal={closeModal}
                      closeAddCustomerOrganizationPopupModal={
                        closeAddCustomerOrganizationPopupModal
                      }
                      setContactsForSort={setContactsForSort}
                      isSearch={isSearch}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"z-header relative"} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-header w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <div className={"py-8"}>
                  <div className={"row mt-5 text-center"}>
                    <h3>
                      <b>Select one:</b>
                    </h3>
                  </div>
                  <div className={"row mt-5"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-6"}>
                      <div className={"lg:col-span-3"}>{addCustomerPopup}</div>
                      <div className={"ml-2 lg:col-span-3"}>
                        {addCustomerOrganizationPopup}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddContact;
