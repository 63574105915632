import React, { FC, useRef } from "react";
import IndividualContactSvg from "../../../components/svg/IndividualContactSvg";
import { OrganizationSvg } from "../../../components/svg/OrganizationSvg";
import Button from "../../../components/button/Button";
import classNames from "classnames";
import { InputFile } from "../components/InputFile";
import {
  useAddPictureMutation,
  useDeletePictureMutation,
} from "../../../slices/ContactsSlice";
import { IContactProfile, IOrganizationContact } from "../../../types/types";
import OrganizationIconSvg from "../../../components/svg/organizationIconSvg";

type Props = {
  contact?: IContactProfile;
  organization?: IOrganizationContact;
  isActive: boolean;
  onClose: () => void;
};
export const UpdateAvatarModal: FC<Props> = ({
  contact,
  isActive,
  onClose,
  organization,
}) => {
  const addFileRef = useRef<HTMLInputElement>(null);

  const [addPicture, {}] = useAddPictureMutation();
  const [deletePicture] = useDeletePictureMutation();
  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    if (contact) {
      addPicture({ contactId: contact?.contactId, body: formData });
    } else {
      addPicture({
        businessContactId: organization?.contactId,
        body: formData,
      });
    }
  };
  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div
        className={
          "modal-box flex h-[600px] w-[600px] flex-col justify-between"
        }>
        <div
          className={"close-popup cursor-pointer text-end"}
          onClick={onClose}>
          <img src={"/img/closse.png"} alt={""} />
        </div>
        <div className={"mt-[20px] flex h-[80%] justify-center"}>
          <div
            className={
              "flex h-[300px] w-[300px] items-center justify-center rounded-[50%] bg-gray-600"
            }>
            {contact?.imageURL || organization?.imageURL ? (
              <img
                src={contact?.imageURL || organization?.imageURL}
                alt="avatar"
              />
            ) : contact ? (
              <IndividualContactSvg />
            ) : (
              <OrganizationIconSvg />
            )}
          </div>
        </div>

        <div className={"mb-[20px] flex justify-center gap-4"}>
          <InputFile
            button={
              <Button buttonType={"button"} label={"Upload from computer"} />
            }
            setFile={handleFileChange}
            inputRef={addFileRef}
            accept={"image/*"}
          />
          <Button
            buttonType={"button"}
            label={"Default avatar"}
            onClick={() =>
              contact
                ? deletePicture({ contactId: contact?.contactId })
                : deletePicture({
                    businessContactId: organization?.contactId,
                  })
            }
            disabled={!(contact?.imageURL || organization?.imageURL)}
          />
        </div>
      </div>
    </div>
  );
};
