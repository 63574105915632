import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const ServicesSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeWidth={"1"}
          stroke={"#221b38"}
          fill={"none"}
          d={
            "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M12 8V7"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M12 17V16"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M8 12H7"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M17 12H16"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M14.8284 9.17158L15.5355 8.46448"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M8.46446 15.5356L9.17157 14.8285"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M9.17158 9.17158L8.46448 8.46448"}
        />
        <path
          strokeLinecap={"square"}
          strokeWidth={"1"}
          stroke={"#221b38"}
          d={"M15.5355 15.5356L14.8284 14.8285"}
        />
        <path
          strokeWidth={"1"}
          stroke={"#221b38"}
          fill={"none"}
          d={
            "M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
        />
      </svg>
    </>
  );
};
