import React from "react";
import { useNavigate } from "react-router-dom";

export const LogoLink: React.FC<unknown> = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/home")}
      className={
        "flex-0 nuxt-link-active btn btn-ghost px-2 !font-notoSans !text-[21px] !font-bold  !normal-case !text-white md:px-4"
      }
      aria-label={"Home"}>
      ProCharted
    </div>
  );
};
