import moment from "moment";
import React, { FC } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { getInitials } from "../../../../../../../../constants/constants";
import { ContactNoteModel } from "../../../../../../../../factories/contacts/models/contact-note.models";
import { parseNote } from "../../../../../../utils/parseNote";
import { Dropdown } from "../../../../../../../../components/dropdown/new-dropdown/Dropdown";

type Props = {
  note: ContactNoteModel;
  setIsCollapsed: (isCollapsed: boolean) => void;
  setCurrentNote: (value: ContactNoteModel) => void;
  setIsOpenAdd: (isOpenAdd: boolean) => void;
  deleteContactNote: (value: ContactNoteModel) => void;
};

export const ContactNote: FC<Props> = ({
  note,
  setIsCollapsed,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  return (
    <div
      onClick={() => setIsCollapsed(true)}
      className={
        "grid cursor-pointer grid-cols-[max-content_1fr_max-content] items-center gap-3 border-b border-gray-300 px-4 py-2 transition last:border-b-0 hover:bg-gray-100"
      }>
      <div>
        <AvatarPlaceholder
          size={"super-small"}
          label={getInitials(
            note?.createdByUser?.userProfile?.firstName,
            note?.createdByUser?.userProfile?.lastName,
          )}
        />
      </div>
      <div className={"grid grid-rows-[repeat(3,max-content)] text-sm"}>
        <div
          className={
            "grid grid-cols-[repeat(2,max-content)] justify-between gap-2 text-gray-400"
          }>
          <div className={"w-full truncate"}>
            {note?.createdByUser?.userProfile?.firstName}{" "}
            {note?.createdByUser?.userProfile?.lastName}
          </div>
          <div>{moment(note.createdAt).format("MMM DD, YYYY")}</div>
        </div>
        <div className={"w-full truncate font-semibold"}>
          {note?.title || "No title"}
        </div>
        <div className={"w-ful truncate text-gray-400"}>
          {parseNote(note.note)}
        </div>
      </div>
      <div>
        <Dropdown
          triggerElement={
            <Button
              label={""}
              colorType={"ghost"}
              size={"custom"}
              icon={<HiEllipsisVertical size={24} />}
              extraClasses={"min-h-fit h-fit w-fit p-1"}
            />
          }>
          <div
            className={
              " rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
            }>
            <div
              onClick={() => {
                setCurrentNote(note);
                setIsOpenAdd(true);
              }}
              className={
                "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
              }>
              Edit
            </div>
            <div
              onClick={() => deleteContactNote(note)}
              className={
                "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
              }>
              Delete
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
