import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const ChevronDoubleLeftSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"current"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          fill={"current"}
          d={
            "M20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L13.4142 12L20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"1"}></path>
        <path
          fill={"current"}
          d={
            "M12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071C13.0976 20.3166 13.0976 19.6834 12.7071 19.2929L5.41421 12L12.7071 4.70711C13.0976 4.31658 13.0976 3.68342 12.7071 3.29289Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
          strokeWidth={"1"}></path>
      </svg>
    </>
  );
};
