import moment from "moment";

import { WorkDetailsModel } from "../../../factories/work/model/work-details.model";
import { WorkTaskModel } from "../../../factories/work/model/work-task.model";

export const getCompletedTasks = (workTasks: WorkTaskModel[]): number => {
  return workTasks?.filter((item) => item?.isDone)?.length || 0;
};

export const getWorkDays = (
  work: WorkDetailsModel,
): { totalDays: number; leftDays: number; dueIn: number } => {
  const today = moment().startOf("D");
  const workStart = moment(work?.startDate).startOf("D");
  const workDue = moment(work?.dueDate).endOf("D");

  const leftDays = workStart.diff(today, "days") || 1;
  const totalDays = workStart.diff(workDue, "days");

  const dueIn = today.unix() < workDue.unix() ? workDue.diff(today, "days") : 0;

  return { totalDays, leftDays, dueIn };
};
