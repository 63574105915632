import React from "react";

import { ComponentLayout } from "../../support/ComponentLayout";
import { ComponentSectionLayout } from "../../support/ComponentSectionLayout";

export const StepsDemo: React.FC<unknown> = () => {
  return (
    <ComponentLayout title={"Wip - Steps"}>
      <ComponentSectionLayout title={"default"}></ComponentSectionLayout>
    </ComponentLayout>
  );
};
