import { IOrganizationContactNote } from "../../../../../../types/types";
import React, { FC, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { Card } from "../../../../../../components/card/Card";
import Button from "../../../../../../components/button/Button";
import { CiCirclePlus } from "react-icons/ci";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import ErrorPopup from "../../../../modals/ErrorPopup";
import { OrganizationNoteModal } from "../../../../modals/OrganizationNoteModal";
import {
  useDeleteOrganizationNoteMutation,
  useGetOrganizationNotesQuery,
} from "../../../../../../slices/OrganizationNotesSlice";
import { OrganizationNote } from "./OrganizationNote";

export const OrganizationNotes: FC = () => {
  const { organizationId } = useParams();

  const { data: contactNotes, isFetching } = useGetOrganizationNotesQuery({
    orgId: getLinkedFirm()?.orgId,
    organizationId: organizationId || "",
  });
  const [deleteOrganizationNote] = useDeleteOrganizationNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [currentNote, setCurrentNote] =
    useState<IOrganizationContactNote | null>(null);

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px] overflow-visible bg-white"}>
        <div className={"max-w-full px-[24px] py-4"}>
          <div className={"mb-4 flex w-full justify-between"}>
            <div className={" text-lg font-bold"}>Notes</div>
          </div>
          <Button
            label={""}
            colorType={"outline"}
            icon={
              <CiCirclePlus
                size={25}
                color={"#8773f1"}
                className={"stroke-1"}
              />
            }
            fullWidth
            size={"custom"}
            extraClasses={
              "btn-sm mb-4 normal-case px-2 w-[100%] h-[55px] bg-white border-[1px] border-[#8773f1] text-[#8773f1] flex items-center justify-center hover:bg-white hover:border-[#8773f1] hover:text-[#8773f1]"
            }
            onClick={handleOpenAddModal}>
            Add Note
          </Button>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : (
              <div className={"flex flex-col gap-4"}>
                {contactNotes?.map((note) => (
                  <OrganizationNote
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteOrganizationNote={deleteOrganizationNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <OrganizationNoteModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          defaultValues={currentNote || undefined}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
