import React, { FC, useEffect, useRef, useState } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Pages } from "../../../../app/constants/routes";
import { generateRoutePath } from "../../../../app/constants/routes/utils";
import { LABELS } from "../../../../app/constants/TextConstants";
import Button from "../../../../components/button/Button";
import { IconButton } from "../../../../components/button/IconButton";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import OrganizationIconSvg from "../../../../components/svg/organizationIconSvg";
import { WarningExclaimSvg } from "../../../../components/svg/WarningExclaimSvg";
import { inactiveStatusId } from "../../../../constants/constants";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import {
  useActivateOrganizationMutation,
  useAddPictureMutation,
  useArchiveOrganizationMutation,
  useDeleteContactMutation,
  useGetOrgContactQuery,
} from "../../../../slices/ContactsSlice";
import { CustomLink } from "../../../../support/CustomLink";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import DeleteMessageContactPopup from "../../refactor/modals/DeleteMessageContactPopup";
import { MergeContactModal } from "../../refactor/modals/MergeContactModal";
import { toast } from "react-toastify";
import { UpdateAvatarModal } from "../../modals/UpdateAvatarModal";

export const OrganizationView: FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const addFileRef = useRef<HTMLInputElement>(null);

  const { data: organization, isLoading } = useGetOrgContactQuery({
    id: organizationId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });

  const [addPicture, {}] = useAddPictureMutation();
  const [
    deleteOrganization,
    { isLoading: isLoadingDelete, isSuccess, isError },
  ] = useDeleteContactMutation();
  const [activateOrganization, { isLoading: isLoadingActivate }] =
    useActivateOrganizationMutation();
  const [archiveOrganization, { isLoading: isLoadingArchive }] =
    useArchiveOrganizationMutation();

  const [isOpenMerge, setIsOpenMerge] = useState(false);
  const [isOpenActivation, setIsOpenActivation] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isUpdateAvatar, setIsUpdateAvatar] = useState(false);

  const { profile, works, notes, billing, emails } =
    Pages.organization.children;

  const ProfileComponent = profile.component;
  const WorksComponent = works.component;
  const NotesComponent = notes.component;
  const BillingComponent = billing.component;
  const EmailsComponent = emails.component;

  const isActive = organization?.contactStatusEnumId !== inactiveStatusId;

  const handleFileChange = (file: File) => {
    const formData = new FormData();
    formData.append("image", file);

    addPicture({ businessContactId: organization?.contactId, body: formData });
  };

  useEffect(() => {
    document.title = GetPageTitle(Pages.organization.label);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigate("/contacts");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  if (isLoading || isLoadingActivate || isLoadingArchive) {
    return <LottieLoading />;
  }

  if (!organization) {
    return null;
  }

  return (
    <>
      <div className={"grid grid-cols-1 justify-center"}>
        <div className={"w-full max-w-[1920px]"}>
          <div className={"flex w-full items-center gap-4"}>
            <div
              className={
                "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#581C87]"
              }
              /*onClick={() => setIsUpdateAvatar(true)}*/
            >
              {organization.imageURL ? (
                <img src={organization.imageURL} alt="avatar" />
              ) : (
                <OrganizationIconSvg />
              )}
            </div>
            <div className={"flex items-end"}>
              <h1 className={"text-2xl"}>{organization?.companyName}</h1>
              {!isActive && (
                <div
                  className={"tooltip tooltip-bottom tooltip-warning"}
                  data-tip={LABELS.warnings.inactiveTooltip}>
                  <IconButton
                    size={"extra-small"}
                    colorType={"secondary"}
                    border={false}
                    extraCx={"ml-1 mt-1 rounded-full"}
                    IconSvg={WarningExclaimSvg}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={"my-4 flex w-full justify-between"}>
            <div className={"tabs"}>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.organization.path,
                    organizationId || "",
                    profile.path,
                  ]),
                }}>
                {profile.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.organization.path,
                    organizationId || "",
                    works.path,
                  ]),
                }}>
                {works.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.organization.path,
                    organizationId || "",
                    notes.path,
                  ]),
                }}>
                {notes.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.organization.path,
                    organizationId || "",
                    billing.path,
                  ]),
                }}>
                {billing.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.organization.path,
                    organizationId || "",
                    emails.path,
                  ]),
                }}>
                {emails.label}
              </CustomLink>
            </div>
            <div>
              <div className={"dropdown dropdown-end"}>
                <div>
                  <Button
                    label={""}
                    colorType={"ghost"}
                    size={"custom"}
                    icon={<HiEllipsisVertical size={24} />}
                    extraClasses={"min-h-fit h-fit w-fit p-1"}
                  />
                </div>
                <div
                  className={"dropdown-content rounded-[5px] bg-white shadow"}>
                  <div
                    onMouseDown={() => setIsOpenActivation(true)}
                    className={
                      "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                    }>
                    {isActive ? "Archive" : "Activate"}
                  </div>
                  <div
                    onMouseDown={() => setIsOpenMerge(true)}
                    className={
                      "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                    }>
                    Merge
                  </div>
                  {!isActive && (
                    <div
                      onMouseDown={() => setIsOpenDelete(true)}
                      className={
                        "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                      }>
                      Delete
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Routes>
            <Route
              index
              element={
                <Navigate
                  to={{
                    pathname: profile.path,
                  }}
                  replace={true}
                />
              }
            />
            <Route
              path={profile.path}
              element={<ProfileComponent organization={organization} />}
            />
            <Route
              path={works.path}
              element={<WorksComponent organization={organization} />}
            />
            <Route path={notes.path} element={<NotesComponent />} />
            <Route
              path={billing.path}
              element={
                <BillingComponent customerId={organization?.customerId || ""} />
              }
            />
            <Route
              path={emails.path + "/*"}
              element={<EmailsComponent organization={organization} />}
            />
          </Routes>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
      {isOpenActivation && (
        <ConfirmModal
          confirm={() => {
            isActive
              ? archiveOrganization({ id: organization?.id || "" })
              : activateOrganization({ id: organization?.id || "" });
            setIsOpenActivation(false);
          }}
          close={() => setIsOpenActivation(false)}
          title={isActive ? "Archive client?" : "Reactivate client?"}
          message={
            isActive ? (
              <div>
                You are about to archive this client. Their profile will be
                <br />
                moved to the "Archived" section. You can always
                <br />
                reactivate them later
              </div>
            ) : (
              <div>
                You are about to reactivate this client. Their profile will be{" "}
                <br />
                moved to the "Active" section. You can always
                <br />
                archive them later
              </div>
            )
          }
        />
      )}
      {isUpdateAvatar && (
        <UpdateAvatarModal
          organization={organization}
          isActive={isUpdateAvatar}
          onClose={() => setIsUpdateAvatar(false)}
        />
      )}
      {isOpenMerge && organization && (
        <MergeContactModal
          customerId={organizationId || ""}
          isActive={isOpenMerge}
          onClose={() => setIsOpenMerge(false)}
          name={organization?.companyName || ""}
          type={"organization"}
        />
      )}
      {isOpenDelete && (
        <DeleteMessageContactPopup
          isOpen={isOpenDelete}
          isLoading={isLoadingDelete}
          closePopup={() => setIsOpenDelete(false)}
          confirmDeleteContact={() => {
            setIsOpenDelete(false);
            deleteOrganization({ id: organization?.customerId || "" });
          }}
        />
      )}
    </>
  );
};
