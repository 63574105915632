import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as devConfig from "../../../../../../app/configs/devConfig";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { getAuthHeader } from "../../../../../../app/globalState/GlobalAuthState";
import { IContactProfile } from "../../../../../../types/types";
import ContactInvoices from "./ContactInvoices";
import ContactPayments from "./ContactPayments";
import { CustomPage } from "../../../../../../support/CustomLink";
import { FormSection } from "../../../../../../support/FormSection";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";

export enum PagesBillingEnum {
  invoices = "invoices",
  payments = "payments",
}

export const ContactBilling: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  const [activePageBilling, setActivePageBilling] = useState<PagesBillingEnum>(
    PagesBillingEnum.invoices,
  );

  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getContact = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contacts/get/${customerId}?orgId=${
        getLinkedFirm()?.orgId
      }`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IContactProfile = await response.json();
      setId(data?.customerId);
    } else {
    }
  };

  const pages = {
    [PagesBillingEnum.invoices]: <ContactInvoices customerId={id} />,
    [PagesBillingEnum.payments]: <ContactPayments customerId={id} />,
  };

  useEffect(() => {
    if (customerId) {
      setIsLoading(true);
      getContact().then(() => setIsLoading(false));
    }
  }, [customerId]);

  return (
    <>
      <div className={"tabs my-8"}>
        <CustomPage
          onClick={() => setActivePageBilling(PagesBillingEnum.invoices)}
          isActive={activePageBilling === PagesBillingEnum.invoices}>
          Invoices
        </CustomPage>
        <CustomPage
          onClick={() => setActivePageBilling(PagesBillingEnum.payments)}
          isActive={activePageBilling === PagesBillingEnum.payments}>
          Payments
        </CustomPage>
      </div>
      <FormSection
        name={
          activePageBilling?.charAt(0).toUpperCase() +
          activePageBilling.slice(1)
        }>
        {isLoading ? <LottieLoading /> : id ? pages[activePageBilling] : null}
      </FormSection>
    </>
  );
};
