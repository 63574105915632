import { HomeTab } from "../../../factories/works/models/home-enums";
import { HomeStatsModel } from "../../../factories/works/models/home-stats.model";

export const getStatusesStats = ({
  activeTab,
  stats,
}: {
  activeTab: HomeTab;
  stats?: HomeStatsModel;
}) => {
  if (activeTab === HomeTab.ThisWeek) {
    return {
      ready: stats?.thisWeek?.ready || 0,
      inProgress: stats?.thisWeek?.inProgress || 0,
      waiting: stats?.thisWeek?.waiting || 0,
      overdue: stats?.thisWeek?.overdue || 0,
    };
  }
  if (activeTab === HomeTab.NextWeek) {
    return {
      ready: stats?.nextWeek?.ready || 0,
      inProgress: stats?.nextWeek?.inProgress || 0,
      waiting: stats?.nextWeek?.waiting || 0,
      overdue: 0,
    };
  }
  if (activeTab === HomeTab.DueLater) {
    return {
      ready: stats?.later?.ready || 0,
      inProgress: stats?.later?.inProgress || 0,
      waiting: stats?.later?.waiting || 0,
      overdue: 0,
    };
  }
  if (activeTab === HomeTab.AllOpen) {
    return {
      ready: stats?.allOpen?.ready || 0,
      inProgress: stats?.allOpen?.inProgress || 0,
      waiting: stats?.allOpen?.waiting || 0,
      overdue: 0,
    };
  }
  if (activeTab === HomeTab.Completed) {
    return {
      ready: 0,
      inProgress: 0,
      waiting: 0,
      overdue: 0,
    };
  }
  return {
    ready: 0,
    inProgress: 0,
    waiting: 0,
    overdue: 0,
  };
};
