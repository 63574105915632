import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PageLayout } from "../../../components/layouts/PageLayout";
import { CustomLink } from "../../../support/CustomLink";
import AddPayment from "../payments/AddPayment";
import PayInvoice from "../payments/PayInvoice";
import { Payments } from "../payments/Payments";
import PaymentView from "../payments/PaymentView";
import AddInvoice from "./AddInvoice";
import { InvoicesListView } from "./InvoicesListView";
import InvoiceView from "./InvoiceView";
import { useAppDispatch, useAppSelector } from "../../../redux/redux";
import { getUser } from "../../../services/AppService";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import { LottieLoading } from "../../../components/graphics/LottieLoading";

export const InvoicesView: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { user, isLoadingUser } = useAppSelector((state) => state.appReducer);
  useEffect(() => {
    dispatch(
      getUser({
        userId: `${userInfoByEmail?.id}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
  }, []);
  const viewBillingsPermission = "434d4588-3532-4e3a-ac3c-d294ef2d76a6";

  if (isLoadingUser) {
    return <LottieLoading />;
  }
  return (
    <PageLayout title={"Billing"} extraClasses={"overflow-visible"}>
      <div className={"tabs my-8"}>
        <CustomLink to={"/billing/invoices"}>Invoices</CustomLink>
        <CustomLink to={"/billing/payments"}>Payments</CustomLink>
      </div>
      {(user.permissionIds &&
        user.permissionIds.includes(viewBillingsPermission)) ||
      user?.userRoleEnum?.userRole === "Admin" ? (
        <Routes>
          <Route
            index
            element={<Navigate to={"./invoices"} replace={true} />}
          />
          <Route path={"invoices"} element={<Outlet />}>
            <Route index element={<InvoicesListView />} />
            <Route path={":id"} element={<InvoiceView />} />
            <Route path={"add"} element={<AddInvoice />} />
          </Route>
          <Route path={"payments"} element={<Outlet />}>
            <Route index element={<Payments />} />
            <Route path={":id"} element={<PaymentView />} />
            <Route path={"add"} element={<AddPayment />} />
            <Route path={"pay/:id"} element={<PayInvoice />} />
          </Route>
          <Route path={"open"} element={<>Todo open invoices</>} />
          <Route path={"closed"} element={<>Todo closed invoices</>} />
          <Route path={"*"} element={<>Not found</>} />
        </Routes>
      ) : (
        <div
          className={
            "flex h-[75vh] items-center justify-center text-[42px] font-bold text-gray-400"
          }>
          You don`t have access to this page
        </div>
      )}
      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
