import { ChevronDown, ChevronUp } from "baseui/icon";
import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { BillingStatusType } from "../../services/BillingService";

type Props = {
  invoiceStatus: BillingStatusType | null;
  paymentStatus: BillingStatusType | null;
  selectStatus: (status: BillingStatusType) => void;
  statuses: BillingStatusType[];
  isLoading?: boolean;
  isSmall?: boolean;
};

const DropStatus: React.FC<Props> = ({
  invoiceStatus,
  paymentStatus,
  statuses,
  selectStatus,
  isLoading,
  isSmall,
}) => {
  const statusRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    top: number;
    left: number;
    bottom?: number;
  }>({
    top: 0,
    left: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(statusRef, () => setIsOpen(false));

  const handleScroll = () => {
    const rect = statusRef?.current?.getBoundingClientRect();
    if (rect) {
      const bottom = window.innerHeight - rect.bottom;
      const fitsBelow = rect.bottom + 300 <= window.innerHeight; // 300 - высота меню
      setPosition({
        top: Math.ceil(rect.top),
        left: Math.ceil(rect.left),
        bottom: fitsBelow ? undefined : Math.ceil(bottom),
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <div className={"relative"}>
      <div className={"relative w-fit"} ref={statusRef}>
        <div className={"flex items-center"}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleScroll();
              setIsOpen(!isOpen);
            }}
            className={
              "flex cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
            }>
            {isLoading ? (
              <div
                className={`font-extrabold opacity-30 ${
                  isSmall ? "text-xs" : ""
                }`}>
                Loading...
              </div>
            ) : (
              <>
                <div
                  className={"flex h-[10px] w-[10px] rounded-full"}
                  style={{
                    backgroundColor:
                      invoiceStatus?.color || paymentStatus?.color || "black",
                  }}
                />
                <div
                  className={`max-w-[100px] truncate whitespace-nowrap ${
                    isSmall ? "text-xs" : ""
                  }`}>
                  {invoiceStatus?.name || paymentStatus?.name}
                </div>
                {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <div
            className={
              "pointer-events-none fixed left-0 top-0 z-[1] min-h-[100vh] min-w-[100vw] bg-transparent"
            }>
            <div
              style={{
                top: position.bottom
                  ? "auto"
                  : position.top
                  ? `${position.top}px`
                  : 0,
                left: position.left ? `${position.left}px` : 0,
                bottom: position.bottom ? `${position.bottom}px` : "auto",
              }}
              className={
                "pointer-events-auto absolute z-[1] mt-[24px] max-h-[300px] w-fit overflow-y-auto rounded-[8px] border-[1px] border-black border-opacity-[0.2] bg-white"
              }>
              {statuses?.map((status) => (
                <div key={status.id} className={"overflow-hidden"}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                      if (
                        status?.id !== invoiceStatus?.id &&
                        status?.id !== paymentStatus?.id
                      ) {
                        selectStatus(status);
                      }
                    }}
                    className={
                      "mb-2 flex cursor-pointer items-center whitespace-nowrap px-4 py-2 font-[600] last:mb-0 last:mr-0 hover:bg-gray-200"
                    }>
                    <div
                      className={" mr-2 h-[10px] w-[10px] rounded-full"}
                      style={{
                        background: `${status?.color}`,
                      }}></div>
                    <div className={"text-sm font-semibold text-gray-800"}>
                      {status?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropStatus;
