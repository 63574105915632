import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as devConfig from "../../../../app/configs/devConfig";
import { LABELS } from "../../../../app/constants/TextConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import SearchContacts from "../../../../components/searchContacts";
import { SelectContacts } from "../../../../components/select/SelectContacts";
import { useAddOrgContactMutation } from "../../../../slices/ContactsSlice";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { SelectOptionType } from "../../../../types/types";

type Props = {
  setIsLoading?: (isLoading: boolean) => void;
  closeModal: () => void;
  closeAddCustomerOrganizationPopupModal: () => void;
  isSearch?: boolean;
  setContactsForSort?: (newContact: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId?: string;
  }) => void;
};

type FormData = {
  contact: SelectOptionType | null;
  companyName: string;
  businessNumber: string;
  role: string;
};

const AddOrganizationView: React.FC<Props> = ({
  closeModal,
  closeAddCustomerOrganizationPopupModal,
  setContactsForSort,
  isSearch,
}) => {
  const navigate = useNavigate();
  const [contactType, setContactType] = useState("Client");
  const [organizationAdd, setOrganizationAdd] = useState({
    location: { id: "" },
    contactStatusType: { id: "" },
    contactTypes: [{ id: "" }],
  });
  const [addOrgContact, { data: newOrgContact, isSuccess }] =
    useAddOrgContactMutation();

  useEffect(() => {
    getOrganizationAdd();
  }, []);

  const getOrganizationAdd = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/business-contacts/add`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setOrganizationAdd(data);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      companyName: "",
      businessNumber: "",
      contact: null,
      role: "",
    },
  });

  const updateContactTypeClient = () => {
    setContactType("Client");
  };
  const updateContactTypeProspect = () => {
    setContactType("Prospect");
  };
  const updateContactTypeOther = () => {
    setContactType("Other");
  };

  const onSubmit = async (data: FormData) => {
    await addOrgContact({
      companyName: data.companyName.trim(),
      businessNumber: data.businessNumber,
      role: data.role,
      contactId: data.contact?.value || null,
      contactTypeEnumId: organizationAdd?.contactTypes?.find(
        (t: any) => t.contactType === contactType,
      )?.id,
      locationId: getLinkedFirm()?.locationId || null,
      contactStatusEnumId: organizationAdd.contactStatusType.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (isSearch) {
        setContactsForSort &&
          setContactsForSort({
            name: `${newOrgContact?.companyName}`,
            id: `${newOrgContact?.id}`,
            customerId: `${newOrgContact?.businessCustomers[0]?.id}`,
            isBusinessContact: true,
          });
        closeModal();
        closeAddCustomerOrganizationPopupModal();
      } else {
        navigate(`/organization/${newOrgContact.id}`);
        closeModal();
        closeAddCustomerOrganizationPopupModal();
      }
    }
  }, [isSuccess]);

  const companyNameRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Company Name*</span>
        </label>
        <Controller
          control={control}
          rules={{
            required: LABELS.required,
            maxLength: {
              value: 50,
              message: "Company name shouldn't be greater than 50 characters",
            },
            validate: (value) => {
              return value.trim().length > 0 || LABELS.required;
            },
          }}
          render={({ field: { value, name } }) => (
            <SearchContacts
              setValue={(value) => {
                setValue(name, value.trim());
              }}
              value={value}
              placeholder={"Company name"}
              close={() => {
                closeModal();
                closeAddCustomerOrganizationPopupModal();
              }}
              type={"organization"}
            />
          )}
          name={"companyName"}
        />
        {errors?.companyName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.companyName?.message}
          </span>
        )}
      </div>
    </>
  );
  const businessNumberRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Business Number</span>
        </label>
        <input
          type={"text"}
          {...register("businessNumber")}
          className={"input input-bordered"}
          placeholder={"Business number"}
        />
      </div>
    </>
  );

  const contactTypeRows = (
    <div className={"mt-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Contact type</span>
      </label>
      <div className={"flex gap-4"}>
        <div className={"form-control w-fit "}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Client" ? "secondary" : "ghost"}
            label={"Client"}
            onClick={updateContactTypeClient}
          />
        </div>
        <div className={"form-control w-fit "}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Prospect" ? "secondary" : "ghost"}
            label={"Prospect"}
            onClick={updateContactTypeProspect}
          />
        </div>
        <div className={"form-control w-fit "}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Other" ? "secondary" : "ghost"}
            label={"Other"}
            onClick={updateContactTypeOther}
          />
        </div>
      </div>
    </div>
  );

  const contactPersonRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Contact Person</span>
        </label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <SelectContacts value={value} onChange={(data) => onChange(data)} />
          )}
          control={control}
          name={"contact"}
        />
      </div>
    </>
  );
  const roleRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Role</span>
        </label>
        <input
          type={"text"}
          {...register("role")}
          className={"input input-bordered"}
          placeholder={"Role"}
        />
      </div>
    </>
  );

  return (
    <>
      <div
        className={
          "grid grid-cols-[1fr_max-content] border-b border-gray-300 px-6 py-4 text-center text-xl font-semibold"
        }>
        <div>New Organization</div>
        <div
          className={"close-popup cursor-pointer text-end"}
          onClick={closeAddCustomerOrganizationPopupModal}>
          <img src={"/img/closse.png"} alt={""} />
        </div>
      </div>
      <div className={"px-6 pb-8 pt-4"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {companyNameRow}
          {businessNumberRow}
          {contactTypeRows}
          {contactPersonRow}
          {roleRow}
          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button onClick={handleSubmit(onSubmit)} label={"Create"} />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeAddCustomerOrganizationPopupModal}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddOrganizationView;
