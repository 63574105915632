import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import * as devConfig from "../../../../app/configs/devConfig";
import { LABELS } from "../../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../../app/constants/ValidationConstants";
import { getAuthHeader } from "../../../../app/globalState/GlobalAuthState";
import Button from "../../../../components/button/Button";
import { OutlineButton } from "../../../../components/button/OutlineButton";
import SearchContacts from "../../../../components/searchContacts";
import CustomSelect from "../../../../components/select/Select";
import { validatePhoneFormat } from "../../../../constants/constants";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { useAddContactMutation } from "../../../../slices/ContactsSlice";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import {
  addIndividualState,
  IContactType,
  IPhoneType,
  SelectOptionType,
} from "../../../../types/types";

interface IAddIndividualProps {
  setIsLoading?: (isLoading: boolean) => void;
  closeModal: () => void;
  closeAddCustomerPopupModal: () => void;
  setContactsForSort?: (newContact: {
    id: string;
    name: string;
    isBusinessContact: boolean;
    customerId: string;
  }) => void;
  isSearch?: boolean;
  isIdLikeContactId?: boolean;
}

type FormData = {
  phoneNumberTypeEnumId: SelectOptionType;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  socialIdentificationNumber: string;
};

export const AddIndividualView: React.FC<IAddIndividualProps> = ({
  setIsLoading,
  closeAddCustomerPopupModal,
  isSearch,
  setContactsForSort,
  isIdLikeContactId,
  closeModal,
}) => {
  const [contactType, setContactType] = useState("Client");
  const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<SelectOptionType[]>([]);
  const [addIndividualState, setAddIndividualState] =
    useState<addIndividualState>();
  const [addContact, { isSuccess, data: newContact }] = useAddContactMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Add Customer");
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      socialIdentificationNumber: "",
    },
  });
  const updateContactTypeClient = () => {
    setContactType("Client");
  };
  const updateContactTypeProspect = () => {
    setContactType("Prospect");
  };
  const updateContactTypeOther = () => {
    setContactType("Other");
  };
  const getPhoneTypes = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/phone-num-types/all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IPhoneType[] = await response.json();
      const sortData = data.sort((a: any, b: any) => {
        if (a?.phoneNumberType === "Mobile") {
          return -1;
        } else if (b?.phoneNumberType === "Mobile") {
          return 1;
        } else {
          return 0;
        }
      });

      const options: SelectOptionType[] = sortData.map((item) => ({
        value: item?.id,
        label: item?.phoneNumberType,
      }));

      setValue("phoneNumberTypeEnumId", options[0]);
      setPhoneTypes(options);
    }
  };
  const getContactTypes = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contact-types/all`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setContactTypes(data);
    }
  };
  const getDataForAddContact = async () => {
    await getPhoneTypes();
    await getContactTypes();
  };
  useEffectOnce(() => {
    getDataForAddContact().then();
    getAddIndividualApiCall();
  });

  const navigate = useNavigate();
  const getAddIndividualApiCall = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/contacts/add-individual`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const addedResponse = await response.json();
      setAddIndividualState(addedResponse);
    }
  };

  const onSubmit = async (data: any) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    await addContact({
      customerTypeEnumId: addIndividualState?.customerType?.id,
      orgId: getLinkedFirm()?.orgId,
      locationId: getLinkedFirm()?.locationId || null,
      email: data.email,
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      middleName: data.middleName?.trim(),
      socialIdentificationNumber: data.socialIdentificationNumber,
      phoneNumberTypeEnumId: data.phoneNumberTypeEnumId?.value,
      contactTypeEnumId: contactTypes.find((i) => i.contactType === contactType)
        ?.id,
      phoneNumber: data.phone,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (isSearch) {
        isIdLikeContactId && closeModal();
        closeAddCustomerPopupModal();
        setContactsForSort &&
          setContactsForSort({
            name: `${newContact?.firstName} ${newContact?.lastName}`,
            id: isIdLikeContactId
              ? `${newContact.id}`
              : `${newContact?.customerId}`,
            customerId: `${newContact?.customerId}`,
            isBusinessContact: false,
          });
      } else {
        navigate(`/contact/${newContact?.customerId}?`);
        closeModal();
        closeAddCustomerPopupModal();
      }
    }
  }, [isSuccess]);

  const { profileRules, contactNameRule } = ValidationConstants.customerRules;

  const emailRows = (
    <div className={"form-control mt-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Email address</span>
      </label>
      <input
        type={"text"}
        {...register("email", {
          ...profileRules.emailLength,
          pattern: {
            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            message: "Invalid email address format",
          },
        })}
        className={"input input-bordered"}
        placeholder={"Email address"}
      />
      {errors?.email && (
        <span className={"pt-2 text-sm font-bold text-error"}>
          {errors?.email?.message}
        </span>
      )}
    </div>
  );
  const phoneRows = (
    <div className={"mt-5 grid grid-cols-1 lg:grid-cols-5"}>
      <div className={"form-control lg:col-span-3"}>
        <label className={"label"}>
          <span className={"label-text"}>Phone number</span>
        </label>
        <input
          type={"text"}
          {...register("phone", {
            ...profileRules.emailLength,
            validate: {
              phoneFormat: (value) =>
                value
                  ? validatePhoneFormat(value) || "Invalid phone number format"
                  : true,
            },
          })}
          className={"input input-bordered"}
          placeholder={"Phone number"}
        />
        {errors.phone && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors.phone.message}
          </span>
        )}
      </div>
      <div className={"form-control ml-5 lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>&nbsp;</span>
        </label>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <CustomSelect
              options={phoneTypes}
              value={value}
              onChange={(newValue) => {
                onChange(newValue as SelectOptionType);
              }}
            />
          )}
          name={"phoneNumberTypeEnumId"}
        />
        {errors?.phoneNumberTypeEnumId?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.phoneNumberTypeEnumId?.message}
          </span>
        )}
      </div>
    </div>
  );

  const contactTypeRows = (
    <div className={"mt-5"}>
      <label className={"label"}>
        <span className={"label-text"}>Contact type</span>
      </label>
      <div className={" flex gap-4"}>
        <div className={"form-control w-fit"}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Client" ? "secondary" : "ghost"}
            label={"Client"}
            onClick={updateContactTypeClient}
          />
        </div>
        <div className={"form-control w-fit "}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Prospect" ? "secondary" : "ghost"}
            label={"Prospect"}
            onClick={updateContactTypeProspect}
          />
        </div>
        <div className={"form-control w-fit "}>
          <Button
            buttonType={"button"}
            colorType={contactType === "Other" ? "secondary" : "ghost"}
            label={"Other"}
            onClick={updateContactTypeOther}
          />
        </div>
      </div>
    </div>
  );

  const customerInfoRow = (
    <>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>First Name *</span>
        </label>
        <Controller
          control={control}
          rules={{
            required: LABELS.required,
            ...contactNameRule.valueLength,
            validate: (value) => {
              return value.trim().length > 0 || LABELS.required;
            },
          }}
          render={({ field: { value, name } }) => (
            <SearchContacts
              setValue={(value) => {
                setValue(name, value.trim());
              }}
              value={value}
              placeholder={"First name"}
              close={() => {
                closeModal();
                closeAddCustomerPopupModal();
              }}
              type={"contact"}
            />
          )}
          name={"firstName"}
        />
        {errors?.firstName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.firstName?.message}
          </span>
        )}
      </div>
      <div className={"form-control mt-5 lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Middle Name</span>
        </label>
        <Controller
          control={control}
          rules={{
            ...contactNameRule.valueLength,
            required: false,
          }}
          render={({ field: { value, name } }) => (
            <SearchContacts
              setValue={(value) => setValue(name, value.trim())}
              value={value}
              placeholder={"Middle name"}
              close={() => {
                closeModal();
                closeAddCustomerPopupModal();
              }}
              type={"contact"}
            />
          )}
          name={"middleName"}
        />
        {errors?.middleName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.middleName?.message}
          </span>
        )}
      </div>
      <div className={"form-control mt-5 lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Last Name *</span>
        </label>
        <Controller
          control={control}
          rules={{
            ...contactNameRule.valueLength,
            required: LABELS.required,
            validate: (value) => {
              return value.trim().length > 0 || LABELS.required;
            },
          }}
          render={({ field: { value, name } }) => (
            <SearchContacts
              setValue={(value) => setValue(name, value.trim())}
              value={value}
              placeholder={"Last name"}
              close={() => {
                closeModal();
                closeAddCustomerPopupModal();
              }}
              type={"contact"}
            />
          )}
          name={"lastName"}
        />
        {errors?.lastName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.lastName?.message}
          </span>
        )}
      </div>
      <div className={"form-control mt-5 lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Tax ID</span>
        </label>
        <input
          type={"text"}
          {...register("socialIdentificationNumber")}
          className={"input input-bordered"}
          placeholder={"Tax ID"}
        />
        {errors?.socialIdentificationNumber?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.socialIdentificationNumber?.message}
          </span>
        )}
      </div>
    </>
  );

  return (
    <>
      <div
        className={
          "grid grid-cols-[1fr_max-content] border-b border-gray-300 px-6 py-4 text-center text-xl font-semibold"
        }>
        <div>New Individual</div>
        <div
          className={"close-popup cursor-pointer text-end"}
          onClick={closeAddCustomerPopupModal}>
          <img src={"/img/closse.png"} alt={""} />
        </div>
      </div>
      <div className={"px-6 pb-8 pt-4"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {customerInfoRow}
          {emailRows}
          {phoneRows}
          {contactTypeRows}

          <div className={"mt-5 flex justify-start space-x-4 pb-4"}>
            <Button onClick={handleSubmit(onSubmit)} label={"Create"} />
            <OutlineButton
              colorType={"neutral"}
              label={"Cancel"}
              onClick={closeAddCustomerPopupModal}
            />
          </div>
        </form>
      </div>
    </>
  );
};
