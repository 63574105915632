import React, { FC } from "react";

import Button from "../../../components/button/Button";

type Props = {
  isActive: boolean;
  isLoading: boolean;
  onClose: () => void;
  confirm: () => void;
};

export const ConfirmLinkedProfileModal: FC<Props> = ({
  isActive,
  onClose,
  confirm,
  isLoading,
}) => {
  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-fit max-w-[90%]"}>
        <div className={"mb-4 text-base font-semibold text-gray-800"}>
          Linked profile
        </div>
        <div className={"mb-4 text-sm text-gray-800"}>
          Linking a new profile will also add the current contact to the other
          profile
        </div>
        <div className={"flex w-full justify-end gap-4"}>
          <Button
            colorType={"secondary"}
            label={"Continue"}
            onClick={confirm}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button colorType={"outline"} label={"Cancel"} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
