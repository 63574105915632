import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { Email } from "../factories/emails/models/emails.models";
import { TFolder } from "../types/types";
import { EmailReplyModel } from "../factories/emails/models/email-reply.model";
import {
  AddEmailToWorkModel,
  TestModel,
} from "../factories/emails/models/add-email-to-work.model";
import { EmailUpdateModel } from "../factories/emails/models/update-email.model";
import { sendEmailModel } from "../factories/emails/models/send-email.model";
import { WorkEmail } from "../factories/emails/models/work-email.model";

export const emailsSlice = createApi({
  reducerPath: "emailsSlice",
  tagTypes: ["Email"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getEmails: build.query<
      { data: Email[]; nextPageValue: string },
      {
        userId: string;
        folderId: string;
        pageNumber: string | undefined;
      }
    >({
      query: ({ userId, folderId, pageNumber }) => ({
        url: "api/emails/get-messages",
        params: { userId, limit: 30, folderId, pageNumber },
      }),
      transformResponse: (res: { data: Email[]; next_cursor: string }) => {
        return { data: res?.data || [], nextPageValue: res.next_cursor };
      },
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    getContactEmails: build.query<
      { data: Email[]; nextPageValue: string },
      {
        userId: string;
        pageNumber: string | undefined;
        contactEmail: string;
        folder: string;
        orgId: string;
      }
    >({
      query: ({ userId, pageNumber, contactEmail, folder, orgId }) => ({
        url: "api/emails/get-linked-contact",
        params: {
          userId,
          limit: 30,
          pageNumber,
          contactEmail,
          folder,
          orgId,
        },
      }),
      transformResponse: (res: {
        data: { data: Email[] }[];
        next_cursor: string;
      }) => {
        const result: Email[] = [];
        res?.data.forEach((item) => item.data.map((i) => result.push(i)));

        return {
          data: result.sort((a, b) => b.date - a.date) || [],
          nextPageValue: res.next_cursor,
        };
      },
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    getRedirectUrl: build.mutation<
      any,
      { provider: string; returnUrl: string; userId: string }
    >({
      query: (params) => ({
        url: "nylas/get-auth-url",
        params,
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
    }),
    getEmailFolders: build.query<TFolder[], { userId: string }>({
      query: ({ userId }) => ({
        url: "api/emails/get-folders",
        method: "Get",
        params: { userId },
      }),
      transformResponse: (res: { data: TFolder[] }) => {
        return res?.data || [];
      },
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),

    searchEmail: build.query<
      { data: Email[]; nextPageValue: string },
      { userId: string; pageNumber: string; query: string }
    >({
      query: ({ userId, pageNumber, query }) => ({
        url: "api/emails/search",
        method: "Get",
        params: { userId, limit: 30, pageNumber, query },
      }),
      transformResponse: (res: { data: Email[]; next_cursor: string }) => {
        return { data: res?.data || [], nextPageValue: res.next_cursor };
      },
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    messageByIds: build.query<
      { data: Email[] },
      { userId: string; messageIds: string[] }
    >({
      query: ({ userId, messageIds }) => ({
        url: "api/emails/message-by-ids",
        method: "Get",
        params: { userId, messageIds },
      }),

      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    sendEmail: build.mutation<
      { data: Email },
      { userId: string; body: sendEmailModel }
    >({
      query: ({ userId, body }) => ({
        url: "api/emails/send-message",
        method: "Post",
        body,
        params: { userId },
      }),
      invalidatesTags: ["Email"],
    }),
    replyEmail: build.mutation<
      unknown,
      { userId: string; body: EmailReplyModel }
    >({
      query: ({ userId, body }) => ({
        url: "api/emails/reply-message",
        method: "Post",
        body,
        params: { userId },
      }),
      invalidatesTags: ["Email"],
    }),
    starredEmail: build.mutation<
      { data: Email },
      { body: EmailUpdateModel; userId: string; messageId: string }
    >({
      query: ({ body, userId, messageId }) => ({
        url: "api/emails/update-message",
        method: "Put",
        body,
        params: { userId, messageId },
      }),
      invalidatesTags: ["Email"],
    }),

    addEmailToWork: build.mutation<unknown, { body: AddEmailToWorkModel }>({
      query: ({ body }) => ({
        url: "api/emails/add-email-to-work",
        method: "Post",
        body,
      }),
      invalidatesTags: ["Email"],
    }),

    getWorkEmail: build.query<
      { data: Email[]; nextPageValue: string },
      { userId: string; workId: string }
    >({
      query: ({ userId, workId }) => ({
        url: "api/emails/fetch-work-email",
        method: "Get",
        params: { userId, workId },
      }),
      transformResponse: (res: {
        data: { data: Email[] }[];
        next_cursor: string;
      }) => {
        const result: Email[] = [];

        res?.data.forEach(
          (item) => item.data && item?.data.map((i) => result.push(i)),
        );
        return {
          data: result.sort((a, b) => b.date - a.date) || [],
          nextPageValue: res.next_cursor,
        };
      },
      providesTags: ["Email"],
    }),
    getLinkedWork: build.query<string, { threadId: string }>({
      query: ({ threadId }) => ({
        url: "api/emails/get-linked-work",
        method: "Get",
        params: { threadId },
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["Email"],
    }),
    disconnectEmail: build.mutation<unknown, { userId: string }>({
      query: (params) => ({
        url: "api/emails/disconnect",
        method: "Delete",
        params,
      }),
      invalidatesTags: ["Email"],
    }),
    deleteEmail: build.mutation<unknown, { userId: string; messageId: string }>(
      {
        query: (params) => ({
          url: "api/emails/delete-message",
          method: "Delete",
          params,
        }),
        invalidatesTags: ["Email"],
      },
    ),
    downloadAttachments: build.query<
      string,
      { userId: string; messageId: string; attachmentId: string }
    >({
      query: ({ userId, messageId, attachmentId }) => ({
        url: "api/emails/download-attachment",
        method: "Get",
        params: { userId, messageId, attachmentId },
        headers: {
          ClientSecret:
            "Bearer nyk_v0_sJt41qUxIjfY2Bk9NOJNzZZb0Q7DUlC922COXwDeOTG7nYDuDEC2Hzrz1TpB6FKt",
        },
        responseHandler: (response) => response.text(),
      }),

      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    downloadMetadata: build.query<
      unknown,
      { userId: string; messageId: string; attachmentId: string }
    >({
      query: ({ userId, messageId, attachmentId }) => ({
        url: "api/emails/attachment-metadata",
        method: "Get",
        params: { userId, messageId, attachmentId },

        responseHandler: (response) => response.text(),
      }),

      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
      providesTags: () => ["Email"],
    }),
    unlinkEmail: build.mutation<unknown, { body: AddEmailToWorkModel }>({
      query: ({ body }) => ({
        url: "api/emails/unlink-work",
        method: "Delete",
        body,
      }),
      invalidatesTags: ["Email"],
    }),
    addTest: build.mutation<unknown, TestModel>({
      query: (body) => ({
        url: "/nylas/add-webhook-destination",
        method: "Post",
        body,
      }),
      invalidatesTags: ["Email"],
    }),
  }),
});
export const {
  useLazyGetEmailsQuery,
  useLazyGetContactEmailsQuery,
  useGetRedirectUrlMutation,
  useGetEmailFoldersQuery,
  useGetWorkEmailQuery,
  useLazyGetLinkedWorkQuery,
  useAddEmailToWorkMutation,
  useLazySearchEmailQuery,
  useSendEmailMutation,
  useReplyEmailMutation,
  useStarredEmailMutation,
  useDisconnectEmailMutation,
  useDeleteEmailMutation,
  useLazyDownloadAttachmentsQuery,
  useLazyDownloadMetadataQuery,
  useUnlinkEmailMutation,
  useLazyMessageByIdsQuery,
} = emailsSlice;
