export const getWorkProgress = ({
  taskCounts,
  completedTaskCounts,
}: {
  taskCounts: number;
  completedTaskCounts: number;
}) => {
  const progressCount = (completedTaskCounts / taskCounts) * 100;
  return Math.floor(progressCount || 0);
};
