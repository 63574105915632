import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { getAzAiCsForEnv, PROC_VERSION } from "../configs/appConfig";

export const reactPlugin = new ReactPlugin();

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=snippet#configuration
const connectionString = getAzAiCsForEnv();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    disableFetchTracking: true,
  },
});
appInsights.loadAppInsights();

let isUserInfoSet = false;

export function setAppInsightsUserInfo(props: {
  userId: string;
  userEmail?: string;
  orgId?: string;
}) {
  const { userId, userEmail, orgId } = props;
  if (isUserInfoSet || !userEmail || !orgId) return;

  appInsights.setAuthenticatedUserContext(userEmail, orgId, true);
  appInsights.addTelemetryInitializer((item) => {
    // eslint-disable-next-line no-param-reassign
    item.ver = PROC_VERSION;
    // eslint-disable-next-line no-param-reassign
    item.data = { orgId: orgId, userId: userId, userEmail: userEmail };
  });
  isUserInfoSet = true;
}
