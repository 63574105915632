import React from "react";
import { match } from "ts-pattern";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "../svg/SvgIconProps";

interface Props {
  buttonType?: "button" | "submit";
  colorType: "primary" | "secondary" | "accent" | "ghost" | "link" | "neutral";
  size?: "small" | "medium" | "large" | "extra-small";
  label: string;
  disabled?: boolean;
  wide?: boolean;
  onClick?: () => void;
  SvgIconLeft?: React.FunctionComponent<SvgIconProps>;
  SvgIconRight?: React.FunctionComponent<SvgIconProps>;
  icon?: JSX.Element;
  extraClasses?: string;
}

export const OutlineButton: React.FC<Props> = (props) => {
  const {
    buttonType = "button",
    colorType = "primary",
    disabled = false,
    size = "medium",
    wide = false,
    label,
    onClick,
    SvgIconLeft,
    SvgIconRight,
    icon,
    extraClasses,
  } = props;

  const btnActiveClass: string = match(colorType)
    .with("primary", () => "btn-primary")
    .with("secondary", () => "btn-secondary")
    .with("accent", () => "btn-accent")
    .with("ghost", () => "btn-ghost")
    .with("link", () => "btn-link")
    .with("neutral", () => "")
    .exhaustive();

  const btnDisabledClass = "text-neutral border-neutral";

  const btnClass = disabled ? btnDisabledClass : btnActiveClass;

  const btnSizeClass: string = match(size)
    .with("large", () => "btn-lg")
    .with("small", () => "btn-sm")
    .with("extra-small", () => "btn-xs")
    .with("medium", () => "")
    .exhaustive();

  const btnWideClass = wide ? "btn-wide" : "";

  const svgIconMarginClass = match(size)
    .with("extra-small", () => (SvgIconLeft ? TW_SIZES.mr.xs : TW_SIZES.ml.xs))
    .with("small", () => (SvgIconLeft ? TW_SIZES.mr.sm : TW_SIZES.ml.sm))
    .with("medium", () => (SvgIconLeft ? TW_SIZES.mr.md : TW_SIZES.ml.md))
    .with("large", () => (SvgIconLeft ? TW_SIZES.mr.lg : TW_SIZES.ml.lg))
    .exhaustive();

  const svgIconSizeClass = match(size)
    .with("extra-small", () => TW_SIZES.hw.xs)
    .with("small", () => TW_SIZES.hw.sm)
    .with("medium", () => TW_SIZES.hw.md)
    .with("large", () => TW_SIZES.hw.lg)
    .exhaustive();

  const svgIconClassNames = `${svgIconSizeClass} ${svgIconMarginClass}`;

  return (
    <button
      type={buttonType}
      className={`btn btn-outline ${btnClass} ${btnSizeClass} ${btnWideClass} ${extraClasses}`}
      disabled={disabled}
      onClick={onClick}>
      {SvgIconLeft && <SvgIconLeft sizeCx={svgIconClassNames} />}
      {icon && <div className={"h-fit"}>{icon}</div>}
      {label}
      {SvgIconRight && <SvgIconRight sizeCx={svgIconClassNames} />}
    </button>
  );
};
