import React from "react";

const ContactNoteSvg = () => {
  return (
    <svg
      width={"35"}
      height={"35"}
      viewBox={"0 0 35 35"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}>
      <path
        d={
          "M29.1688 17.5003V8.38422C29.1689 8.26911 29.1462 8.15511 29.1022 8.04875C29.0582 7.9424 28.9936 7.84578 28.9121 7.76443L24.3213 3.1736C24.1573 3.00946 23.9349 2.91714 23.7029 2.91693H6.71045C6.47838 2.91693 6.25583 3.00912 6.09173 3.17321C5.92764 3.33731 5.83545 3.55987 5.83545 3.79193V31.2086C5.83545 31.4407 5.92764 31.6632 6.09173 31.8273C6.25583 31.9914 6.47838 32.0836 6.71045 32.0836H16.0438M11.6688 14.5836H23.3354M11.6688 8.75026H17.5021M11.6688 20.4169H16.0438M26.185 24.7044L27.6434 23.2461C27.7952 23.0941 27.9755 22.9736 28.1739 22.8914C28.3723 22.8091 28.585 22.7668 28.7998 22.7668C29.0146 22.7668 29.2273 22.8091 29.4257 22.8914C29.6242 22.9736 29.8045 23.0941 29.9563 23.2461C30.2627 23.5527 30.4349 23.9684 30.4349 24.4018C30.4349 24.8353 30.2627 25.251 29.9563 25.5576L28.4979 27.0159L26.185 24.703L21.8232 29.0648C21.6051 29.2836 21.4621 29.566 21.4148 29.8713L21.059 32.1419L23.3296 31.7876C23.6349 31.7403 23.9173 31.5973 24.1361 31.3792L28.4965 27.0159"
        }
        stroke={"#7C66F0"}
        strokeWidth={"2.1875"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
      <path
        d={
          "M23.3354 2.91693V7.87527C23.3354 8.10733 23.4276 8.32989 23.5917 8.49398C23.7558 8.65808 23.9784 8.75027 24.2104 8.75027H29.1688"
        }
        stroke={"#7C66F0"}
        strokeWidth={"2.1875"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </svg>
  );
};

export default ContactNoteSvg;
