import { DatePicker } from "baseui/datepicker";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { getUsers } from "../../../api/api";
import { DATE_FORMATS } from "../../../app/commonOps/CommonDateOps";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import InputTime from "../../../components/inputs/inputTime";
import { convertTimeToSeconds } from "../../../constants/constants";
import { useAppDispatch } from "../../../redux/redux";
import {
  deleteTime,
  getTimesByWork,
  updateTime,
} from "../../../services/AppService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { ITime, IUser } from "../../../types/types";
import { transformDateToUTC } from "../../../utils/transformDate";
import { WorkTimeModel } from "../../../factories/work/model/work-time.model";

interface IEditTimeWorkProps {
  closeModal: () => void;
  time: WorkTimeModel | any;
}
const EditTimeWork: React.FC<IEditTimeWorkProps> = ({ closeModal, time }) => {
  const { jobId } = useParams();

  const dispatch = useAppDispatch();

  const [users, setUsers] = useState<IUser[]>([]);
  const [error, setError] = useState("");
  const [duration, setDuration] = useState({
    h: (moment.duration(time?.duration, "seconds").hours() || 0).toString(),
    m: (moment.duration(time?.duration, "seconds").minutes() || 0).toString(),
  });

  useEffect(() => {
    getUsers(getLinkedFirm()?.orgId).then((res) => setUsers(res));
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      assignedUserId: time.assignedUserId,
      date: new Date(),
      notes: time.notes,
    },
  });

  useEffect(() => {
    setValue("date", new Date(time.date) || new Date());
    setValue("notes", time.notes);
    setValue("assignedUserId", time.assignedUserId);
    setDuration({
      h: (moment.duration(time?.duration, "seconds").hours() || 0).toString(),
      m: (moment.duration(time?.duration, "seconds").minutes() || 0).toString(),
    });
  }, [time.id]);

  const onSubmit = async (data: any) => {
    if (!error) {
      await dispatch(
        updateTime({
          id: time.id,
          workId: time.workId,
          assignedUserId: data.assignedUserId,
          duration: convertTimeToSeconds(duration),
          contactId: time.work?.customerId
            ? time.work?.customer.contactId
            : null,
          businessContactId: time.work?.customerId
            ? null
            : time.work?.businessContactId,
          notes: data.notes,
          date: moment(data.date).utc(),
        }),
      );
      await setTimeout(
        () =>
          dispatch(
            getTimesByWork({
              jobId: `${jobId}`,
              orgId: `${getLinkedFirm()?.orgId}`,
            }),
          ),
        100,
      );
      closeModal();
    }
  };

  return (
    <div
      onClick={closeModal}
      id={"edit-time"}
      className={
        "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        onClick={(event) => event.stopPropagation()}
        className={
          "max-h-[750px] w-[480px] overflow-hidden rounded-[10px] bg-white "
        }>
        <div
          className={"max-h-[750px] w-full overflow-auto px-[20px] py-[15px]"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={"w-full text-center"}>Edit Time</div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Assignee</div>
                <div>
                  <select
                    {...register("assignedUserId")}
                    className={"select select-bordered w-full"}>
                    {users.map((user) => (
                      <option
                        key={user.id}
                        value={user.id}
                        selected={user.id === time.assignedUserId}>
                        {user.userProfile &&
                          `${user.userProfile.firstName} ${user.userProfile.middleName} ${user.userProfile.lastName}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={"mb-4 flex justify-between space-x-[20px]"}>
                <div>
                  <div className={"label-text mb-2"}>Date</div>
                  <div>
                    <Controller
                      control={control}
                      name={"date"}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          overrides={{
                            MonthYearSelectPopover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: { zIndex: 10000 },
                                  },
                                },
                              },
                            },
                            Input: {
                              props: {
                                mountNode: document.getElementById("edit-time"),
                                overrides: {
                                  Input: {
                                    style: () => ({
                                      backgroundColor: "#FFFFFF",
                                    }),
                                  },
                                  Root: {
                                    style: () => ({
                                      borderTopWidth: "1px",
                                      borderRightWidth: "1px",
                                      borderBottomWidth: "1px",
                                      borderLeftWidth: "1px",
                                      borderTopColor: "#D4D6D9",
                                      borderRightColor: "#D4D6D9",
                                      borderBottomColor: "#D4D6D9",
                                      borderLeftColor: "#D4D6D9",
                                    }),
                                  },
                                },
                              },
                            },
                            Popover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: {
                                      zIndex: 9999,
                                    },
                                  },
                                },
                              },
                            },
                          }}
                          value={value}
                          formatString={DATE_FORMATS.dateOnly}
                          onChange={({ date }) => {
                            if (date instanceof Date) {
                              onChange(transformDateToUTC(date));
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div className={"label-text mb-2"}>Duration</div>
                  <div>
                    <InputTime value={duration} onChange={setDuration} />
                  </div>
                  {error && (
                    <div className={"mt-2 text-[12px] text-red-500"}>
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Notes</div>
                <div>
                  <textarea
                    {...register("notes")}
                    className={
                      "input input-bordered min-h-[100px] w-full py-[8px]"
                    }
                  />
                </div>
              </div>
              <div className={"flex justify-between"}>
                <div>
                  <Button
                    buttonType={"button"}
                    onClick={() => {
                      dispatch(deleteTime(time));
                      setTimeout(
                        () =>
                          dispatch(
                            getTimesByWork({
                              jobId: `${jobId}`,
                              orgId: `${getLinkedFirm()?.orgId}`,
                            }),
                          ),
                        100,
                      );
                      closeModal();
                    }}
                    label={"Delete"}
                    colorType={"error"}
                    extraClasses={"normal-case"}
                  />
                </div>
                <div className={"flex space-x-[10px]"}>
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={closeModal}
                    extraClasses={"normal-case"}
                  />
                  <Button
                    buttonType={"submit"}
                    label={"Save"}
                    extraClasses={"normal-case"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTimeWork;
