import React from "react";
import { match } from "ts-pattern";

import { InfoSvg } from "../svg/InfoSvg";
import { SuccessCheckSvg } from "../svg/SuccessCheckSvg";
import { WarningExclaimSvg } from "../svg/WarningExclaimSvg";

type AlertProps = {
  type: "warning" | "info" | "success" | "error";
  label?: string;
  actionButton1?: { name: string; onClick: () => void };
};

export const AlertSimple: React.FC<AlertProps> = (props) => {
  const { type, label, children, actionButton1 } = props;

  const iconElement = match(type)
    .with("error", () => <WarningExclaimSvg />)
    .with("warning", () => <WarningExclaimSvg />)
    .with("info", () => <InfoSvg />)
    .with("success", () => <SuccessCheckSvg />)
    .exhaustive();

  return (
    <>
      <div className={"alert my-2"}>
        <div className={"flex-1"}>
          <div className={"text-primary"}>{iconElement}</div>
          <label>
            {label && <h4>{label}</h4>}
            <div className={"text-base-content text-opacity-60"}>
              {children}
            </div>
          </label>
        </div>
        {actionButton1 && (
          <div className={"flex-none"}>
            <button
              className={"btn btn-primary btn-sm"}
              onClick={actionButton1.onClick}>
              {actionButton1.name}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
