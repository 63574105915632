import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import CsvDownloader from "react-csv-downloader";
import { useNavigate } from "react-router-dom";

import {
  DATE_FORMATS,
  getDateOnly,
} from "../../../app/commonOps/CommonDateOps";
import {
  LABELS,
  PATHS,
  ROUTE_NAMES,
} from "../../../app/constants/TextConstants";
import { PdfTotalsDataT } from "../../../app/stateMachines/BankDocDetailsMachine";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../components/button/BrandButton";
import { IconButton } from "../../../components/button/IconButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import { PdfViewerIframe } from "../../../components/pdfViewer/PdfViewerIframe";
import { ArrowChevronDownSvg } from "../../../components/svg/ArrowChevronDownSvg";
import { InfoSvg } from "../../../components/svg/InfoSvg";
import { WarningExclaimSvg } from "../../../components/svg/WarningExclaimSvg";
import { FormSection } from "../../../support/FormSection";
import { BankDocDownloadConstants } from "./BankDocDownloadConstants";
import { BankDocOutputReadOnlyInfoPanelsView } from "./BankDocOutputReadOnlyInfoPanelsView";
import { BankDocOutputDataT } from "./BankDocOutputTypes";
import { BankDocStatics } from "./BankDocStatics";

export type Props = {
  bankDocId: string;
  blobUri: string;
  bankDocOutputId: string;
  locationId: string;
  bankDocOutputData: BankDocOutputDataT;
  isCreditCard: boolean;
  downloadFileName: string;
};

export const BankDocOutputReadOnlyView: React.FC<Props> = (props) => {
  const {
    bankDocId,
    blobUri,
    bankDocOutputData,
    isCreditCard,
    downloadFileName,
  } = props;

  // RR
  const navigate = useNavigate();

  // xstate
  const { bankDocDetailsService } = React.useContext(GlobalContext);
  const endingBalancesMatch = Xstate.useSelector(
    bankDocDetailsService,
    (state) => {
      return state.context.matcher.endingEqual;
    },
  );
  const firstExportAlert = endingBalancesMatch
    ? undefined
    : LABELS.messages.bankDocOutput.status.notMatched;

  // local state - extracted totals
  const initialPdfTotalsData: PdfTotalsDataT = bankDocOutputData.pdfTotals;
  const extractedTotalsData = BankDocStatics.GetExtractedTotals(
    bankDocOutputData.extractedRows,
  );

  // effect - extracted totals
  React.useEffect(() => {
    bankDocDetailsService.send("updateExtractedTotals", {
      extractedTotalsData: extractedTotalsData,
    });
    bankDocDetailsService.send("updatePdfTotals", {
      pdfTotalsData: initialPdfTotalsData,
    });
    bankDocDetailsService.send("updateIsCreditCard", {
      isCreditCard: isCreditCard,
    });
  }, [
    bankDocDetailsService,
    extractedTotalsData,
    initialPdfTotalsData,
    isCreditCard,
  ]);

  const columns = [
    {
      id: "cell1",
      displayName: "Date",
    },
    {
      id: "cell2",
      displayName: "Description",
    },
    {
      id: "cell3",
      displayName: "Debits",
    },
    {
      id: "cell4",
      displayName: "Credits",
    },
  ];

  const txnRows = bankDocOutputData.extractedRows.map((txnRow) => {
    const { id, isSelected, date, description, memo, credit, debit } = txnRow;
    const checkboxView = (
      <input
        className={"checkbox checkbox-primary"}
        type={"checkbox"}
        title={"Include transaction in download"}
        checked={isSelected}
        disabled={true}
      />
    );
    const getTruncatedView = (value: string, length: number) => {
      const truncatedValue = _.truncate(value, {
        length: length,
        // separator: " ",
      });
      const isTruncated = truncatedValue !== value;
      return (
        <>
          {truncatedValue}
          {isTruncated && (
            <div
              className={
                "tooltip tooltip-bottom tooltip-secondary before:max-w-[50rem] before:content-[attr(data-tip)]"
              }
              data-tip={value}>
              <IconButton
                size={"extra-small"}
                colorType={"secondary"}
                border={false}
                extraCx={"ml-1 mt-1 rounded-full"}
                IconSvg={InfoSvg}
              />
            </div>
          )}
        </>
      );
    };
    const descriptionView = getTruncatedView(description, 32);
    const memoView = getTruncatedView(memo, 80);

    return (
      <div className={"grid grid-cols-1 gap-2 p-2 lg:grid-cols-6"} key={id}>
        <div
          className={
            "flex flex-row items-center justify-center space-x-2 lg:col-span-1"
          }>
          <div>{checkboxView}</div>
          <div className={"-mt-1"}>{getDateOnly(date)}</div>
        </div>
        <div className={"col-span-5 grid grid-cols-1 lg:grid-cols-5"}>
          <div className={"form-control lg:col-span-3"}>
            <div className={"flex flex-row space-x-2"}>
              <div className={"cursor-default font-semibold opacity-40"}>
                Payee
              </div>
              <div>{descriptionView}</div>
            </div>
          </div>
          <div className={"form-control lg:col-span-1"}>{credit}</div>
          <div className={"form-control lg:col-span-1"}>{debit}</div>
          <div className={"form-control lg:col-span-5"}>
            <div className={"flex flex-row space-x-2"}>
              <div className={"cursor-default font-semibold opacity-40"}>
                Memo
              </div>
              <div className={"font-semibold opacity-40"}>{memoView}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const extractedTxnView = (
    <div className={"col-span-1 rounded"}>
      <FormSection
        name={"Transactions"}
        cardBodyExtraCx={"space-y-1"}
        customCx={"my-6 py-6 px-2"}
        viewFormat={"custom"}>
        <div className={"h-screen overflow-y-scroll"}>
          {/* table header */}
          <div
            className={
              "sticky top-0 z-20 flex items-center rounded bg-base-200 py-4 shadow shadow-md"
            }>
            <div className={"flex w-1/5 space-x-2 pl-2"}>
              <div className={"form-control flex w-12"}></div>
              <div>Date</div>
            </div>
            <div className={"w-1/2"}>Payee / Memo</div>
            <div className={"w-1/5 pl-8"}>Debit</div>
            <div className={"w-1/5 pl-2"}>Credit</div>
          </div>

          {/* table body */}
          <div>{txnRows}</div>
        </div>
      </FormSection>
    </div>
  );

  const uploadedPdfView = (
    <div className={"col-span-1 rounded"}>
      <FormSection
        name={"Uploaded PDF"}
        customCx={"my-6 py-6 px-2"}
        viewFormat={"custom"}>
        <div className={"h-screen"}>
          <PdfViewerIframe blobUri={blobUri} />
        </div>
      </FormSection>
    </div>
  );

  const asyncSubmitAndGetData = async () => {
    const allTxnRows = bankDocOutputData.extractedRows;
    const selectedTxnRows = allTxnRows.filter((txn) => txn.isSelected);
    const derivedTxnRows =
      selectedTxnRows.length > 0 ? selectedTxnRows : allTxnRows;

    const csvTnxRows: Array<{ [p: string]: string }> = derivedTxnRows.map(
      (txn) => {
        const desc = txn.description;
        const derivedDescription = desc.includes(",") ? `"${desc}"` : desc;
        return {
          cell1: getDateOnly(txn.date, DATE_FORMATS.bankDateOnly),
          cell2: derivedDescription,
          cell3: txn.credit === 0 ? "" : txn.credit.toString(),
          cell4: txn.debit === 0 ? "" : txn.debit.toString(),
        };
      },
    );
    return csvTnxRows;
  };

  const reviewButtonView = (
    <BrandButton
      colorType={"primary"}
      label={`1. ${ROUTE_NAMES.documents.bankDoc.review}`}
      size={"small"}
      onClick={() => navigate(`../${ROUTE_NAMES.documents.bankDoc.review}`)}
    />
  );

  const downloadButtonView = (
    <div>
      <Dropdown
        extraCx={"w-96"}
        ButtonComponent={
          <div className={"flex space-x-2"}>
            <OutlineButton
              buttonType={"button"}
              size={"small"}
              colorType={"secondary"}
              SvgIconRight={ArrowChevronDownSvg}
              label={"2. Download"}
            />
            {firstExportAlert && (
              <div
                className={"tooltip-top tooltip tooltip-warning"}
                data-tip={firstExportAlert}>
                <IconButton
                  size={"extra-small"}
                  colorType={"warning"}
                  border={false}
                  outline={false}
                  extraCx={"ml-1 mt-1 rounded-full"}
                  IconSvg={WarningExclaimSvg}
                />
              </div>
            )}
          </div>
        }
        ListComponent={
          <>
            {BankDocDownloadConstants.accEnumList.map((accSw) => {
              return (
                <li key={accSw}>
                  <button
                    className={"link link-secondary no-underline"}
                    onClick={() =>
                      navigate(PATHS.bankDocByIdDownload(bankDocId, accSw))
                    }>
                    {BankDocDownloadConstants.getAccSw(accSw).displayName}
                  </button>
                </li>
              );
            })}
            <CsvDownloader
              filename={downloadFileName}
              extension={".csv"}
              separator={","}
              columns={columns}
              datas={asyncSubmitAndGetData}
              text={"Download (Excel CSV)"}
              className={"link link-secondary px-4 py-2 text-left no-underline"}
            />
          </>
        }
      />
    </div>
  );

  return (
    <>
      <BankDocOutputReadOnlyInfoPanelsView>
        <div
          className={
            "mx-8 flex flex-col items-center justify-center space-y-3"
          }>
          {reviewButtonView}
          {downloadButtonView}
        </div>
      </BankDocOutputReadOnlyInfoPanelsView>
      <div className={"grid grid-cols-1 gap-1 xl:grid-cols-2"}>
        {uploadedPdfView}
        {extractedTxnView}
      </div>
    </>
  );
};
