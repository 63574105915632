import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const EditSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M17.82 2.29L5.01 15.11L2 22L8.89 18.99L21.71 6.18C22.1 5.79 22.1 5.16 21.71 4.77L19.24 2.3C18.84 1.9 18.21 1.9 17.82 2.29Z"
          }
          clipRule={"evenodd"}
          fillRule={"evenodd"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={"M5.01 15.11L8.89 18.99L2 22L5.01 15.11Z"}
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M19.23 8.65999L15.34 4.76999L17.81 2.29999C18.2 1.90999 18.83 1.90999 19.22 2.29999L21.69 4.76999C22.08 5.15999 22.08 5.78999 21.69 6.17999L19.23 8.65999Z"
          }
        />
      </svg>
    </>
  );
};
