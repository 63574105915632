import React from "react";
import { useNavigate } from "react-router-dom";

import { LABELS } from "../../app/constants/TextConstants";
import { Alert } from "../alerts/alert";
import { BrandButton } from "../button/BrandButton";

export const ErrorFallback: React.FC<unknown> = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Alert type={"error"} label={LABELS.errors.default}>
        <BrandButton
          buttonType={"button"}
          colorType={"link"}
          onClick={() => {
            // need to reload after setting new location
            // https://stackoverflow.com/a/63060656/2877168
            navigate("/home");
            window.location.reload();
          }}
          label={"Refresh"}
        />
      </Alert>
    </div>
  );
};
