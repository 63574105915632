import { FC } from "react";

import { IContactProfile } from "../../../../../../types/types";
import { ContactFilesWidget } from "./widgets/contact-files/ContactFilesWidget";
import { ContactInfoView } from "./widgets/contact-info/ContactInfoView";
import { ContactInvoicesWidget } from "./widgets/contact-invoices/ContactInvoicesWidget";
import { ContactLinkedProfilesWidget } from "./widgets/contact-linked-profiles/ContactLinkedProfilesWidget";
import { ContactNotesWidget } from "./widgets/contact-notes/ContactNotesWidget";
import { ContactPaymentsWidget } from "./widgets/contact-payments/ContactPaymentsWidget";
import { ContactWorksWidget } from "./widgets/contact-works/ContactWorksWidget";
import { ContactTagsWidget } from "./widgets/contact-tags/ContactTagsWidget";

type Props = {
  contact: IContactProfile;
};

export const ContactProfile: FC<Props> = ({ contact }) => {
  return (
    <div className={"grid grid-cols-3 gap-6"}>
      <div className={"flex h-fit flex-col gap-6"}>
        <ContactInfoView />
        <ContactLinkedProfilesWidget contact={contact} />
        <ContactTagsWidget contact={contact} />
      </div>
      <div className={"flex h-fit flex-col gap-6"}>
        <ContactWorksWidget contact={contact} />
        <ContactInvoicesWidget contact={contact} />
        <ContactPaymentsWidget contact={contact} />
      </div>
      <div className={"flex h-fit flex-col gap-6"}>
        <ContactNotesWidget contact={contact} />
        <ContactFilesWidget contact={contact} />
      </div>
    </div>
  );
};
