import _ from "lodash";

export type SingleAlertT = {
  isVisible: boolean;
  msg: string;
  level: "error" | "warning";
};
export type MultiAlertT = Array<SingleAlertT>;

export const getFirstAlert = (
  values: MultiAlertT,
): SingleAlertT | undefined => {
  return _.chain(values)
    .filter((o) => o.isVisible)
    .first()
    .value();
};
