import React from "react";

import { SvgIconProps } from "./SvgIconProps";

export const AddStackSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={
            "M18 5V6H9C7.34 6 6 7.34 6 9V18H5C3.34 18 2 16.66 2 15V5C2 3.34 3.34 2 5 2H15C16.66 2 18 3.34 18 5Z"
          }
        />
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M19 22H9C7.34 22 6 20.66 6 19V9C6 7.34 7.34 6 9 6H19C20.66 6 22 7.34 22 9V19C22 20.66 20.66 22 19 22Z"
          }
        />
        <path
          strokeLinejoin={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={"M9 14H19"}
        />
        <path
          strokeLinejoin={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          d={"M14 9V19"}
        />
      </svg>
    </>
  );
};
