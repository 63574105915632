import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import {
  useReceiveNotificationsMutation,
  useSyncEmailsMutation,
  useUpdateEmailMutation,
} from "../../../slices/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/redux";
import { getUser } from "../../../services/AppService";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";

type Props = {
  isActive: boolean;
  onClose: () => void;
  notifications?: boolean;
};
export const SyncModal: FC<Props> = ({ isActive, onClose, notifications }) => {
  const dispatch = useAppDispatch();
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const [syncEmails, { isSuccess, isError }] = useSyncEmailsMutation();
  const [receiveNotifications, { isSuccess: successNotifications }] =
    useReceiveNotificationsMutation();
  const [updateEmail] = useUpdateEmailMutation();

  const { user } = useAppSelector((state) => state.appReducer);

  const [isChecked, seIsChecked] = useState(user.syncEmails ?? true);
  const [isReceiveNotifications, setIsReceiveNotifications] = useState(
    user.receiveEmailNotification ?? true,
  );
  const [emailValue, setEmailValue] = useState(
    user.sendNotificationToEmail || user.nylasToken.email,
  );
  const onSubmit = () => {
    if (notifications) {
      if (isReceiveNotifications) {
        receiveNotifications({
          userId: user.id,
          receiveEmail: true,
        });
      } else {
        receiveNotifications({
          userId: user.id,
          receiveEmail: false,
        });
      }
      updateEmail({ email: emailValue, userId: user.id });
    } else {
      if (isChecked) {
        syncEmails({
          userId: user.id,
          syncEmail: true,
        });
      } else {
        syncEmails({
          userId: user.id,
          syncEmail: false,
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        getUser({
          userId: `${userInfoByEmail?.id}`,
          orgId: `${userInfoByEmail?.org?.id}`,
        }),
      );
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  useEffect(() => {
    if (successNotifications) {
      dispatch(
        getUser({
          userId: `${userInfoByEmail?.id}`,
          orgId: `${userInfoByEmail?.org?.id}`,
        }),
      );
      onClose();
    }
  }, [successNotifications]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div
        className={classNames(
          "modal-box flex  w-[600px] flex-col justify-between p-0",
          notifications ? "h-[350px]" : "h-[300px]",
        )}>
        <div
          className={
            "h-[60px] border-b border-gray-300 px-6 py-4 text-start text-xl font-semibold"
          }>
          <div>Email Sync</div>
        </div>
        <div className={"flex items-start justify-between pl-6 font-semibold"}>
          {notifications
            ? "Email notifications"
            : "Sync emails with client profiles and works"}
          {notifications ? (
            <input
              type="checkbox"
              className={classNames(
                "toggle toggle-accent mr-4 mt-2",
                isReceiveNotifications
                  ? "border-[#7c66f0] bg-[#7c66f0]"
                  : "border-gray-300 bg-gray-300",
              )}
              onChange={(e) =>
                e.target.checked
                  ? setIsReceiveNotifications(true)
                  : setIsReceiveNotifications(false)
              }
              checked={isReceiveNotifications}
            />
          ) : (
            <input
              type="checkbox"
              className={classNames(
                "toggle toggle-accent mr-4 ",
                isChecked
                  ? "border-[#7c66f0] bg-[#7c66f0]"
                  : "border-gray-300 bg-gray-300",
              )}
              onChange={(e) =>
                e.target.checked ? seIsChecked(true) : seIsChecked(false)
              }
              checked={isChecked}
            />
          )}
        </div>
        {notifications ? (
          <div>
            <div className={"border-b border-gray-300 px-6 pb-2 text-sm"}>
              By enabling this feature, you will receive an email every
              <br />
              time there is a new notification
            </div>
            <div className={"pl-6"}>
              <div className={"py-2 font-semibold"}>Email address</div>
              <div className={"pb-2 text-sm"}>
                This is where your notifications will be sent
              </div>
              <div className={"pb-2"}>
                <input
                  className={"input input-bordered w-[70%]"}
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={"px-6 text-sm"}>
            Enabling email sync will show incoming and outgoing emails in client
            <br />
            profiles and associated work items.
            <br />
            By disabling this feature you will still be able to see your emails
            here
            <br />
            but they will not be visible in client profiles and work items.
          </div>
        )}

        <div className={"mb-2 mr-2 flex justify-end gap-2"}>
          <Button
            label={"Save"}
            onClick={() => {
              onSubmit();
            }}
          />
          <Button label={"Cancel"} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
